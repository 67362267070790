import {Link} from 'react-router-dom'
import {parseId} from '../../../helpers'
import {EdgeMemberPolicy} from '../../../types/policiesApi'

interface IInfoClientProps {
  id: string
  members: EdgeMemberPolicy[]
  isQuote?: boolean
}

export const InfoClientPolicy: React.FC<IInfoClientProps> = ({id, members, isQuote}) => {
  const findHolder = members.find(({node}) => node.rol === 'HOLDER')

  if (!findHolder) {
    return (
      <div>
        <Link
          to={isQuote ? `/one-quote/${parseId(id)}` : `/sold?policy=${parseId(id)}`}
          className='text-info'
        >
          No holder yet
        </Link>
        <p className='m-0 p-0'></p>
        <p className='m-0 p-0'></p>
      </div>
    )
  }

  return (
    <div>
      <Link
        to={isQuote ? `/one-quote/${parseId(id)}` : `/sold?policy=${parseId(id)}`}
        className='text-info'
      >
        {findHolder.node.firstName} {findHolder.node.lastName}
      </Link>
      <p className='m-0 p-0'>{findHolder.node.phone}</p>
      <p className='m-0 p-0'>{findHolder.node.physicalAddress}</p>
    </div>
  )
}
