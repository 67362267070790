import {gql} from '@apollo/client'

export const GET_ALL_MEMBERS = gql`
  query allMembers(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
  ) {
    allUsers(offset: $offset, before: $before, after: $after, first: $first, last: $last, id: $id) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          firstName
          lastName
          email
          cellphone
        }
      }
    }
  }
`
export const GET_USER_MEMBERS = gql`
  query allUsers(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
  ) {
    allUsers(offset: $offset, before: $before, after: $after, first: $first, last: $last, id: $id) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          firstName
          lastName
          email
          cellphone
          groups {
            edges {
              node {
                id
                name
              }
            }
          }
          groupAssignmentSet {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
export const GET_ONE_MEMBER_USER = gql`
  query allUsers(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
  ) {
    allUsers(offset: $offset, before: $before, after: $after, first: $first, last: $last, id: $id) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          firstName
          lastName
          email
          cellphone
          applyDeductions
          groups {
            edges {
              node {
                id
                name
              }
            }
          }
          groupAssignmentSet {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const GET_FILTERS_MEMBERS = gql`
  query allMembers($offset: Int, $id: Float) {
    allUsers(offset: $offset, id: $id) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`
