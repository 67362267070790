import {gql} from '@apollo/client'

export const ADD_MEMBER_POLICY = gql`
  mutation memberPolicyCreateupdateMutation($input: member_policy_createUpdate_mutationInput!) {
    memberPolicyCreateupdateMutation(input: $input) {
      memberPolicy {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

export const ADD_PAYMENT_POLICY = gql`
  mutation paymentCreateupdateMutation($input: payment_createUpdate_mutationInput!) {
    paymentCreateupdateMutation(input: $input) {
      payment {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export const ADD_EXTRA_INFO_POLICY = gql`
  mutation extraInfoCreateupdateMutation($input: extra_info_createUpdate_mutationInput!) {
    extraInfoCreateupdateMutation(input: $input) {
      extraInfo {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
