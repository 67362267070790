import {Link} from 'react-router-dom'
import {Button, Card, Offcanvas} from 'react-bootstrap'
import {useState} from 'react'
import {InitInboxContent} from './components/InitInboxContent'
import {SendMessageContent} from './components/SendMessageContent'
import {useDisclourse} from '../../hooks/useDisclourse'
import {OneMessage} from './components/OneMessage'

interface Item {
  id: number
  name: string
  urlImg: string
  title: string
  lastDate: string
  badge:
    | null
    | {
        text: string
        color: string
      }[]
}

const NAV_ITEMS = [
  {
    id: 1,
    icon: 'envelope',
    title: 'Inbox',
    badge: 3,
  },
  {
    id: 2,
    icon: 'star-fill',
    title: 'Marked',
    badge: null,
  },
  {
    id: 3,
    icon: 'file-earmark',
    title: 'Draft',
    badge: 5,
  },
  {
    id: 4,
    icon: 'send-fill',
    title: 'Send',
    badge: null,
  },
  {
    id: 5,
    icon: 'trash',
    title: 'Trash',
    badge: null,
  },
]

type TTab = 'init' | 'add-msg' | 'one_msg'

export const InboxPage = () => {
  const [tab, setTab] = useState<TTab>('init')
  const {isOpen: isOpenMenu, onClose: onCloseMenu, onOpen: onOpenMenu} = useDisclourse()
  const [infoMsg, setInfoMsg] = useState<Item | null>(null)

  const ButtonBack = (
    <button className='bg-transparent d-block d-lg-none' style={{border: 0}} onClick={onOpenMenu}>
      <i className='bi bi-grid-3x3-gap fs-3' />
    </button>
  )

  const updateInfo = (info: any) => {
    setInfoMsg(info)
    setTab('one_msg')
  }

  return (
    <>
      <section>
        <div>
          <h2>Messages</h2>
          <span className='text-muted'>
            <Link to='/'>Home </Link> <span> - inbox</span>
          </span>
        </div>

        <div className='d-flex gap-4 mt-4'>
          <aside className='card d-none d-lg-flex flex-column' style={{minWidth: '275px'}}>
            <div className='card-body'>
              <Button variant='primary' className='w-100 mb-8' onClick={() => setTab('add-msg')}>
                New Message
              </Button>

              <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10'>
                {NAV_ITEMS.map((info) => (
                  <div
                    key={info.id}
                    className='menu-item mb-3'
                    role='button'
                    onClick={() => setTab('init')}
                  >
                    <span
                      className='menu-link'
                      style={
                        info.title === 'Inbox' && tab === 'init'
                          ? {color: '#1b84ff', backgroundColor: '#f9f9f9'}
                          : undefined
                      }
                    >
                      <span className='menu-icon'>
                        <i className={`bi bi-${info.icon}`} />
                      </span>
                      <span className='menu-title fw-bold'>{info.title}</span>
                      {info.badge && (
                        <span className='badge badge-light-success'>{info.badge}</span>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </aside>
          <Card className='w-100'>
            {tab === 'init' && <InitInboxContent ButtonMenu={ButtonBack} updateInfo={updateInfo} />}
            {tab === 'add-msg' && (
              <SendMessageContent onBack={() => setTab('init')} ButtonMenu={ButtonBack} />
            )}
            {tab === 'one_msg' && infoMsg && (
              <OneMessage ButtonMenu={ButtonBack} infoMsg={infoMsg} onBack={() => setTab('init')} />
            )}
          </Card>
        </div>
      </section>
      <Offcanvas show={isOpenMenu} onHide={onCloseMenu}>
        <Offcanvas.Body>
          <aside className='d-flex flex-column' style={{minWidth: '275px'}}>
            <div className=''>
              <Button variant='primary' className='w-100 mb-8' onClick={() => setTab('add-msg')}>
                New Message
              </Button>

              <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10'>
                {NAV_ITEMS.map((info) => (
                  <div
                    key={info.id}
                    className='menu-item mb-3'
                    role='button'
                    onClick={() => setTab('init')}
                  >
                    <span
                      className='menu-link'
                      style={
                        info.title === 'Inbox' && tab === 'init'
                          ? {color: '#1b84ff', backgroundColor: '#f9f9f9'}
                          : undefined
                      }
                    >
                      <span className='menu-icon'>
                        <i className={`bi bi-${info.icon}`} />
                      </span>
                      <span className='menu-title fw-bold'>{info.title}</span>
                      {info.badge && (
                        <span className='badge badge-light-success'>{info.badge}</span>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </aside>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
