import {Button, Col, Form, Row, Spinner} from 'react-bootstrap'
import {IValuesForm} from '../../soldTypes'
import {useFormik} from 'formik'
import {IExtraInfo} from '../../../../types/policiesApi'
import {useMutation} from '@apollo/client'
import {ADD_EXTRA_INFO_POLICY} from '../../../../gql/mutations/membersPolicyMutations'
import {IResAddExtraInfoPolicy} from '../../../../types/membersPolicy'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {useSearchParams} from 'react-router-dom'
import {parseId} from '../../../../helpers'

const INIT_VALUE = {
  id: '',
  primaryDoctorInfo: '',
  medicinesNeeded: '',
  infoExtra: '',
  cancellationDate: '',
  specialEnrollmentDate: '',
  specialEnrollmentReason: '',
}

interface IExtraTab {
  onPrevStep: () => void
  handleSubmit: () => void
  values: IValuesForm
  onReturnInit: () => void
  refetch: () => void
  node: IExtraInfo | null
  isDisableEdit?: boolean
}

export const ExtraTab: React.FC<IExtraTab> = ({
  onPrevStep,
  // handleSubmit,
  // values,
  // onReturnInit,
  node,
  refetch,
  isDisableEdit = false,
}) => {
  const [gqlAddExtraInfo, {data, loading, error}] =
    useMutation<IResAddExtraInfoPolicy>(ADD_EXTRA_INFO_POLICY)

  const [searchParams] = useSearchParams()
  const policy = searchParams.get('policy')

  const formik = useFormik({
    initialValues: INIT_VALUE,
    onSubmit: (e) => {
      if (!policy) return
      const input = {
        ...e,
        policy,
        id: node && node.id ? parseId(node.id) : undefined,
        __typename: undefined,
      }
      gqlAddExtraInfo({variables: {input}})
    },
  })

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.extraInfoCreateupdateMutation?.extraInfo?.id) {
      toast.success(`Extra information ${node && node.id ? 'updated' : 'added'} successfully`)
      refetch()
    } else if (
      data.extraInfoCreateupdateMutation?.errors &&
      data.extraInfoCreateupdateMutation?.errors?.length > 0
    ) {
      toast.error(
        `Error: ${
          data.extraInfoCreateupdateMutation?.errors[0].messages?.[0] || 'Something wrong!'
        }`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!node || !node.id) return
    formik.setValues({
      cancellationDate: node.cancellationDate || '',
      id: node.id || '',
      infoExtra: node.infoExtra || '',
      medicinesNeeded: node.medicinesNeeded || '',
      primaryDoctorInfo: node.primaryDoctorInfo || '',
      specialEnrollmentDate: node.specialEnrollmentDate || '',
      specialEnrollmentReason: node.specialEnrollmentReason || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])

  /* const handleAddPolicySubmit = () => {
    if (
      !values.policyName ||
      !values.metalLevel ||
      !values.carrier ||
      !values.effectiveDate ||
      !values.typePolicy
    ) {
      return onReturnInit()
    }
    handleSubmit()
  } */
  return (
    <div>
      <div className='kt-heading kt-heading--md custom-border-brand'>
        Add relevant information and submit the policy for revision
      </div>
      <Row className='mb-3'>
        <Col md={6} sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='primaryDoctorInfo' style={{fontSize: '12px'}}>
              Primary Doctor information
            </Form.Label>
            <Form.Control
              placeholder='Type here the Primary Doctor information'
              as='textarea'
              rows={3}
              name='primaryDoctorInfo'
              id='primaryDoctorInfo'
              value={formik.values.primaryDoctorInfo}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='medicinesNeeded' style={{fontSize: '12px'}}>
              Medicines needed
            </Form.Label>
            <Form.Control
              placeholder='List the medicines the client requested to be covered'
              as='textarea'
              rows={3}
              name='medicinesNeeded'
              id='medicinesNeeded'
              value={formik.values.medicinesNeeded}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={6} sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='infoExtra' style={{fontSize: '12px'}}>
              Extra notes or comments
            </Form.Label>
            <Form.Control
              placeholder='Type here relevant information or comments about this policy...'
              as='textarea'
              rows={5}
              name='infoExtra'
              id='infoExtra'
              value={formik.values.infoExtra}
              onChange={formik.handleChange}
            />
            <small className='text-primary fw-bold'>
              <span style={{textDecoration: 'underline'}}>IMPORTANT:</span> The client can view the
              information provided in this field.
            </small>
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='cancellationDate' style={{fontSize: '12px'}}>
              Cancellation date
            </Form.Label>
            <Form.Control
              type='date'
              name='cancellationDate'
              id='cancellationDate'
              value={formik.values.cancellationDate}
              onChange={formik.handleChange}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='specialEnrollmentDate' style={{fontSize: '12px'}}>
              Special enrollment date
            </Form.Label>
            <Form.Control
              type='date'
              name='specialEnrollmentDate'
              id='specialEnrollmentDate'
              value={formik.values.specialEnrollmentDate}
              onChange={formik.handleChange}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='specialEnrollmentReason' style={{fontSize: '12px'}}>
              Special enrollment reason
            </Form.Label>
            <Form.Control
              placeholder='Special enrollment reason...'
              as='textarea'
              rows={2}
              name='specialEnrollmentReason'
              id='specialEnrollmentReason'
              value={formik.values.specialEnrollmentReason}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className='my-5'>
        <Button
          size='sm'
          variant='info'
          onClick={() => formik.handleSubmit()}
          disabled={loading || isDisableEdit}
        >
          {loading && <Spinner animation='border' size='sm' />}
          {node ? 'Save Changes' : 'Add Changes'}
        </Button>
      </div>
      <div
        className='py-5 px-3 d-flex align-items-center justify-content-between'
        style={{borderTop: '1px solid #e1e1e1'}}
      >
        <Button variant='secondary' onClick={onPrevStep}>
          PREVIOUS
        </Button>
        {/* <Button onClick={handleAddPolicySubmit} disabled={isDisableEdit}>
          SUBMIT
        </Button> */}
      </div>
    </div>
  )
}
