import {gql} from '@apollo/client'

export const GET_ALL_POLICIES_TYPES = gql`
  query allPoliciesType(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $typePolicy: String
  ) {
    allPoliciesType(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      typePolicy: $typePolicy
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          typePolicy
        }
      }
    }
  }
`
