import {gql} from '@apollo/client'

export const GET_ALL_LEAD_SOURCE = gql`
  query allCategoryLeadSource(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $name: String
    $name_Icontains: String
    $name_Istartswith: String
  ) {
    allCategoryLeadSource(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      name: $name
      name_Icontains: $name_Icontains
      name_Istartswith: $name_Istartswith
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          created
          name
          leadSourceSet {
            edges {
              node {
                id
                created
                name
                category {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_LEAD_SOURCE_FILTER = gql`
  query allLeadSources($offset: Int, $id: Float) {
    allLeadSources(offset: $offset, id: $id) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          category {
            id
            name
          }
        }
      }
    }
  }
`
