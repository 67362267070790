import {useState} from 'react'
import {PolicyHolderTab} from './policyFormTabs/PolicyHolderTab'
import {FamilyMembersTab} from './policyFormTabs/FamilyMembersTab'
import {InsurancePaymentsTab} from './policyFormTabs/InsurancePaymentsTab'
import {ExtraTab} from './policyFormTabs/ExtraTab'
import {IValuesForm} from '../soldTypes'
import {TFormKeys} from '../../policies/policiesTypes'
import {FormikErrors, FormikTouched} from 'formik'
import {Node} from '../../../types/policiesApi'
import {useSearchParams} from 'react-router-dom'
import {STATUS_POLICY} from '../../../helpers/consts'

const FORM_TABS = [
  {
    div: [
      {num: 1, name: 'Policyholder'},
      {num: 2, name: 'Family members'},
    ],
  },
  {
    div: [
      {num: 3, name: 'Insurance & Payment'},
      {num: 4, name: 'Extras'},
    ],
  },
] as const

type TFormTab = 'Policyholder' | 'Family members' | 'Insurance & Payment' | 'Extras'

interface IPolicyProps {
  values: IValuesForm
  handleChange: (field: TFormKeys, value: string) => void
  handleBlur: (field: TFormKeys) => void
  handleSubmit: () => void
  errors: FormikErrors<IValuesForm>
  touched: FormikTouched<IValuesForm>
  loadingCreate: boolean
  dataPolicy: Node
  refetch: () => void
}

export const PolicyForm: React.FC<IPolicyProps> = ({
  values,
  handleBlur,
  handleChange,
  handleSubmit,
  errors,
  touched,
  loadingCreate,
  dataPolicy,
  refetch,
}) => {
  const [searchParams] = useSearchParams()
  const lead = searchParams.get('lead')

  const [formTab, setFormTab] = useState<TFormTab>('Policyholder')

  const onReturnInit = () => {
    setFormTab('Policyholder')
  }
  console.log('formTab', formTab)
  return (
    <div>
      <div className='d-flex flex-column gap-2 flex-xl-row'>
        {FORM_TABS.map(({div}, i) => (
          <div key={i} className='d-flex flex-column flex-md-row' style={{flex: 1}}>
            {div.map((d) => (
              <button
                key={d.num}
                className='btn d-flex align-items-center gap-2 btn-block bg-white'
                style={{
                  flex: 1,
                  boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.12), 0 -1px 3px 0 rgba(0,0,0,0.12)',
                  opacity: d.name === formTab ? 1 : 0.3,
                }}
                disabled={d.name === formTab}
                onClick={() => {
                  if (values.lead && Number(values.lead)) return
                  setFormTab(d.name)
                }}
              >
                <span
                  style={{
                    fontSize: '1.3rem',
                    fontWeight: 600,
                    flex: '0 0 2.75rem',
                    height: '2.75rem',
                    width: '2.75rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(93, 120, 255, 0.08)',
                    color: '#5d78ff',
                    marginRight: '1rem',
                    borderRadius: ' 0.5rem',
                  }}
                >
                  {d.num}
                </span>
                <span style={{color: d.name === formTab ? '#5d78ff' : 'gray'}}>
                  {d.name === 'Family members' ? 'Members' : d.name}
                </span>
              </button>
            ))}
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
          backgroundColor: '#fff',
          marginBottom: '20px',
          borderRadius: '4px',
          padding: '8px 10px',
        }}
      >
        {formTab === 'Policyholder' && (
          <PolicyHolderTab
            onNextStep={() => setFormTab('Family members')}
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            errors={errors}
            touched={touched}
            onReturnInit={onReturnInit}
            loadingCreate={loadingCreate}
            refetch={refetch}
            isEdit={dataPolicy.status !== STATUS_POLICY.PENDING}
          />
        )}
        {formTab === 'Family members' && (
          <FamilyMembersTab
            onNextStep={() => setFormTab('Insurance & Payment')}
            onPrevStep={() => setFormTab('Policyholder')}
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            loadingCreate={loadingCreate}
            errors={errors}
            touched={touched}
            onReturnInit={onReturnInit}
            handleSubmit={handleSubmit}
            members={lead ? [] : dataPolicy ? dataPolicy.memberPolicySet?.edges : []}
            refetch={refetch}
            isDisableEdit={dataPolicy.status !== STATUS_POLICY.PENDING}
          />
        )}
        {formTab === 'Insurance & Payment' && (
          <InsurancePaymentsTab
            onNextStep={() => setFormTab('Extras')}
            onPrevStep={() => setFormTab('Family members')}
            refetch={refetch}
            node={dataPolicy.payment}
          />
        )}
        {formTab === 'Extras' && (
          <ExtraTab
            onPrevStep={() => setFormTab('Insurance & Payment')}
            handleSubmit={handleSubmit}
            values={values}
            onReturnInit={onReturnInit}
            refetch={refetch}
            node={dataPolicy.extraInfo}
            isDisableEdit={dataPolicy.status !== STATUS_POLICY.PENDING}
          />
        )}
      </div>
    </div>
  )
}
