import {Button, Card, Col, Form, Pagination, Row} from 'react-bootstrap'
import {IResultsJson} from '../../oneLead/oneleadTypes'
import {useState} from 'react'
import {ModalInfoPlan} from './ModalInfoPlan'
import {useDisclourse} from '../../../hooks/useDisclourse'
import Lottie from 'lottie-react'
import endLottie from '../../../assets/lotties/end-quotes.json'

interface IResultProps {
  result: IResultsJson
  loadMoreQuotes: (pag: number) => void
  infoPagination: {
    hasNext: boolean
    offset: number
    initialOffset: number
  }
  arrOffset: number[]
  currentPage: number
  updatePage: (n: number) => void
  plusPage: () => void
}

export const ResultsCards = ({
  result,
  arrOffset,
  infoPagination,
  loadMoreQuotes,
  currentPage,
  plusPage,
  updatePage,
}: IResultProps) => {
  const [infoPlan, setInfoPlan] = useState<null | IResultProps['result']['plans'][0]>(null)
  const {isOpen, onClose, onOpen} = useDisclourse()

  if (result.error) {
    return <div>No found data</div>
  }
  console.log('infoPagination', infoPagination)
  console.log('currentPage', currentPage)
  return (
    <>
      <div className='mt-6'>
        <div className='d-flex algin-items-md-center justify-content-between flex-column flex-md-row align-items-start gap-2'>
          <Pagination size='sm'>
            {arrOffset.map((n) => (
              <Pagination.Item
                key={n}
                active={n === currentPage}
                onClick={() => {
                  if (n === currentPage) return
                  updatePage(n)
                  loadMoreQuotes(n)
                }}
              >
                {n}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={!infoPagination.hasNext}
              onClick={() => {
                plusPage()
                loadMoreQuotes(arrOffset.length + 1)
              }}
            />
          </Pagination>
          <div className='d-flex align-items-center gap-1'>
            <Form.Select aria-label='Default select example'>
              <option>Open this select menu</option>
              <option value='1'>One</option>
              <option value='2'>Two</option>
              <option value='3'>Three</option>
            </Form.Select>
            <Form.Select aria-label='Default select example'>
              <option>Open this select menu</option>
              <option value='1'>One</option>
              <option value='2'>Two</option>
              <option value='3'>Three</option>
            </Form.Select>
          </div>
        </div>
        <div className='mt-4'>
          {result.plans.map((plan) => (
            <Card className='rounded-1 mb-4' key={plan.id}>
              <Card.Header className='align-items-center'>
                <Card.Title>{plan.issuer.name}</Card.Title>
                <div>
                  <Button
                    variant='secondary'
                    size='sm'
                    onClick={() => {
                      onOpen()
                      setInfoPlan(plan)
                    }}
                  >
                    <i className='bi bi-search' />
                    PLAN DETAILS
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <h4 className='text-info fs-3'>{plan.name}</h4>
                <div
                  className='d-flex align-items-center mb-1 flex-wrap'
                  style={{fontSize: '11px'}}
                >
                  <span>{plan.metal_level}</span>
                  <span>
                    <i className='bi bi-dot fs-1' />
                  </span>
                  <span>{plan.type}</span>
                  <span>
                    <i className='bi bi-dot fs-1' />
                  </span>
                  <span>
                    <span className='fw-bold'>Plan ID:</span> {plan.id}
                  </span>
                  <span>
                    <i className='bi bi-dot fs-1' />
                  </span>
                  <span>
                    <span className='fw-bold'>Rating:</span>{' '}
                    {[1, 2, 3, 4, 5].map((n) => (
                      <i
                        className='bi bi-star-fill'
                        key={n}
                        style={{
                          color: n < plan.quality_rating.global_rating ? '#da9400' : '#565454',
                        }}
                      />
                    ))}
                  </span>
                  <span>
                    <i className='bi bi-dot fs-1' />
                  </span>
                  <span className='d-flex align-items-center'>
                    <span className='fw-bold'>Dental adults:</span>{' '}
                    {plan.benefits.find((p) => p.type === 'BASIC_DENTAL_CARE_ADULT')?.covered ? (
                      <i className='bi bi-check fs-2' style={{color: 'green'}} />
                    ) : (
                      <i className='bi bi-x fs-2' style={{color: 'red'}} />
                    )}
                  </span>
                  <span>
                    <i className='bi bi-dot fs-1' />
                  </span>
                  <span className='d-flex align-items-center'>
                    <span className='fw-bold'>Dental children:</span>{' '}
                    {plan.benefits.find((p) => p.type === 'DENTAL_CHECK_UP_FOR_CHILDREN')
                      ?.covered ? (
                      <i className='bi bi-check fs-2' style={{color: 'green'}} />
                    ) : (
                      <i className='bi bi-x fs-2' style={{color: 'red'}} />
                    )}
                  </span>
                </div>
                <div className='d-flex  gap-1 flex-wrap flex-column flex-lg-row'>
                  <Card style={{backgroundColor: '#f1f3ff', flex: 1}}>
                    <Card.Body>
                      <h6>Monthly premium:</h6>
                      <div>
                        <h3 className='fs-2 fw-bold'>
                          <small>
                            <sup>$</sup>
                          </small>
                          {Math.floor(Number(plan.premium))}
                          <small>
                            <sup>.{Number(plan.premium).toFixed(2).slice(-2)}</sup>
                          </small>
                        </h3>
                      </div>
                      <p className='m-0 p-0 text-muted'>
                        Savings total:{' '}
                        <span className='text-success fw-bold'>
                          {Number(Number(plan.ehb_premium) - Number(plan.premium)).toFixed(2)}
                        </span>
                      </p>
                      <p className='m-0 p-0 text-muted'>
                        Plan was: <span className='text-danger fw-bold'>{plan.ehb_premium}</span>
                      </p>
                    </Card.Body>
                  </Card>
                  <Card style={{backgroundColor: '#f1f3ff', flex: 1}}>
                    <Card.Body>
                      <h6>Deductible:</h6>
                      <div>
                        <span className='fs-2 fw-bold'>
                          {plan.deductibles?.[0]?.amount || 'N/R'}
                        </span>
                      </div>

                      <p className='m-0 p-0 text-muted'>
                        Individual total (${plan.deductibles?.[0]?.amount || 'N/R'} per person)
                      </p>

                      <p className='m-0 p-0 text-muted'> {plan.deductibles?.[0]?.type || 'N/R'}</p>
                    </Card.Body>
                  </Card>
                  <Card style={{backgroundColor: '#f1f3ff', flex: 1}}>
                    <Card.Body>
                      <h6>Out-of-pocket max:</h6>
                      <div>
                        <span className='fs-2 fw-bold'>{plan.moops?.[0]?.amount}</span>
                      </div>

                      <p className='m-0 p-0 text-muted'>Individual total</p>

                      <p className='m-0 p-0 text-muted'>
                        Maximum for Medical and Drug {plan.moops?.[0]?.type}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                    <h5>Primary Doctor visits</h5>
                    <p>
                      {plan.benefits.find((p) => p.type === 'BASIC_DENTAL_CARE_ADULT')
                        ?.cost_sharings?.[0]?.display_string || 'N/R'}
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                    <h5>Specialist Visits</h5>
                    <p>
                      {plan.benefits.find((p) => p.type === 'SPECIALIST_VISIT')?.cost_sharings?.[0]
                        ?.display_string || 'N/R'}
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                    <h5>Urgent care</h5>
                    <p>
                      {plan.benefits.find((p) => p.type === 'URGENT_CARE_CENTERS_OR_FACILITIES')
                        ?.cost_sharings?.[0]?.display_string || 'N/R'}
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                    <h5>Emergencies</h5>
                    <p>
                      {plan.benefits.find((p) => p.type === 'EMERGENCY_TRANSPORTATION_AMBULANCE')
                        ?.cost_sharings?.[0]?.display_string || 'N/R'}
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                    <h5>Mental health</h5>
                    <p>
                      {plan.benefits.find(
                        (p) => p.type === 'MENTAL_BEHAVIORAL_HEALTH_OUTPATIENT_SERVICES'
                      )?.cost_sharings?.[0]?.display_string || 'N/R'}
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                    <h5>Generic drugs</h5>
                    <p>
                      {plan.benefits.find((p) => p.type === 'GENERIC_DRUGS')?.cost_sharings?.[0]
                        ?.display_string || 'N/R'}
                    </p>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          ))}
          {result.plans.length === 0 && (
            <Card>
              <Card.Header>
                <Card.Title>There are no more quotes</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className='d-flex align-items-center justify-content-center'>
                  <Lottie
                    animationData={endLottie}
                    autoPlay
                    style={{maxWidth: '50%', borderRadius: '60px', overflow: 'hidden'}}
                  />
                </div>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
      {infoPlan && isOpen && <ModalInfoPlan isOpen={isOpen} onClose={onClose} info={infoPlan} />}
    </>
  )
}
