import {Dispatch, SetStateAction} from 'react'
import {TTabs} from '../leadTypes'

export const NavMainEditLead: React.FC<{
  setFormType: Dispatch<SetStateAction<TTabs>>
  formType: TTabs
}> = ({setFormType, formType}) => {
  return (
    <>
      <div
        style={{
          borderBottom: 0,
          display: 'flex',
          background: '#f6f7fb',
          padding: '6px',
          borderRadius: '60px',
        }}
        className='d-flex align-items-center'
      >
        {(
          [
            {title: 'Main'},
            {title: 'Notes'},
            {title: 'Appointments'},
            {title: 'Files'},
            {title: 'Consent'},
          ] as const
        ).map((nav) => (
          <button
            key={nav.title}
            style={{
              flex: 1,
              borderRadius: '20px',
              color:
                formType === 'EDIT' && nav.title === 'Main'
                  ? 'white'
                  : nav.title === formType
                  ? 'white'
                  : undefined,
            }}
            className={`border-0 ${
              formType === 'EDIT' && nav.title === 'Main'
                ? 'bg-primary'
                : nav.title === formType
                ? 'bg-primary'
                : 'bg-transparent'
            }`}
            onClick={() => {
              setFormType(nav.title)
            }}
          >
            {nav.title}
          </button>
        ))}
      </div>
      <hr className='my-4' style={{color: '#71839b'}} />
    </>
  )
}
