import {useLazyQuery} from '@apollo/client'
import React, {useEffect, useRef, useState} from 'react'
import {Form, Button, Col, Modal, Row, Alert, Spinner, Accordion} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {GET_ZIP_INFO} from '../../../gql/queries/leadsQuery'
import {IResZipCode} from '../../../types/quoteTypes'
import {toast} from 'react-toastify'
import {INIT_PEOPLE} from '../../../helpers/consts'

interface IPerson {
  age: string
  dob: string
  gender: string
  isPregnant: boolean
  pregnantWith: string
  isParent: boolean
  usesTobacco: boolean
  lastTobaccoUseDate: string
  hasMec: boolean
  aptcEligible: boolean
  utilizationLevel: string
  relationship: string
  doesNotCohabitate: string
  currentEnrollment?: null | {
    planId: string
    effectiveDate: string
    usesTobacco: boolean
  }
  id: number
}

interface IModalAddQProps {
  isOpen: boolean
  onClose: () => void
  info?: {
    id: string
    dateBirthLead: string | null | undefined
  }
}

export const ModalAddQoute: React.FC<IModalAddQProps> = ({isOpen, onClose, info}) => {
  const [gqlGetMarket, {data, loading, error}] = useLazyQuery<IResZipCode>(GET_ZIP_INFO, {
    fetchPolicy: 'no-cache',
  })
  const refControl = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  const [eventKey, setEventKey] = useState('0')

  const [arrPeople, setArrPeople] = useState<IPerson[]>([])

  const [infoEnrollement, setInfoEnrollement] = useState({
    id: 1 as null | number,
    effectiveDate: '',
    planId: '',
    usesTobacco: false,
  })

  const [formData, setFormData] = useState({
    queryType: 'BUSCAR_PLANES',
    year: 2024,
    householdIncome: '0',
    market: 'Individual',
    countyfips: '', //17033
    state: '', //IL
    zipCode: '', //62466
    limit: 10,
    offset: 0,
    order: 'asc',
    utilizationLevel: 'Low',
    personOne: {
      age: '',
      dob: '',
      gender: 'Male',
      isPregnant: false,
      pregnantWith: '',
      isParent: false,
      usesTobacco: false,
      lastTobaccoUseDate: '',
      hasMec: false,
      aptcEligible: false,
      utilizationLevel: '',
      relationship: '',
      doesNotCohabitate: '',
      currentEnrollment: '',
    },
  })

  /* useEffect(() => {
    if (!refControl.current) return
    const handleBlur = () => {
      console.log('blublur')
    }
    const input = refControl.current
    input.addEventListener('blur', handleBlur)

    return () => {
      input.removeEventListener('blur', handleBlur)
    }
  }, []) */

  useEffect(() => {
    if (!data) return
    if (data.zipcodeByZip) {
      setFormData((p) => ({
        ...p,
        countyfips: data.zipcodeByZip.countyFips,
        state: data.zipcodeByZip.stateId,
      }))
    }
  }, [data])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (info?.dateBirthLead) {
      setFormData((p) => ({...p, dob: info.dateBirthLead || ''}))
    }
  }, [info])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    /* const dataStr = JSON.stringify({
      ...formData,
      householdIncome: formData.householdIncome.replaceAll(',', ''),
    }) */

    const obj = {
      ...formData,
      personOne: {
        ...formData.personOne,
        currentEnrollment:
          infoEnrollement.id === 1
            ? {
                id: 1,
                effectiveDate: infoEnrollement.effectiveDate,
                planId: infoEnrollement.planId,
                usesTobacco: infoEnrollement.usesTobacco,
              }
            : null,
      },
      householdIncome: formData.householdIncome.replaceAll(',', ''),
      people: [...arrPeople].map((inf) => {
        if (inf.id === infoEnrollement.id) {
          return {
            ...inf,
            currentEnrollment: {
              id: inf.id,
              effectiveDate: infoEnrollement.effectiveDate,
              planId: infoEnrollement.planId,
              usesTobacco: infoEnrollement.usesTobacco,
            },
          }
        }
        return {...inf}
      }),
    }
    const objStr = JSON.stringify(obj)
    console.log('obj', obj)
    // if (arrPeople) return

    const decode = window.btoa(objStr)
    navigate(`/quote/${encodeURIComponent(decode)}`)
    onClose()
    /*  gqlGetMarket({
      variables: {
        ...formData,
        householdIncome: formData.householdIncome.replaceAll(',', ''),
        dob: formData.dob || undefined,
      },
    }) */
  }

  const handleAddPeople = () => {
    setEventKey(`${arrPeople.length + 1}`)
    setArrPeople((p) => [
      ...p,
      {
        id: Date.now(),
        ...INIT_PEOPLE,
      },
    ])
  }

  console.log('infoEnrollement.usesTobacco', infoEnrollement.usesTobacco)

  return (
    <Modal show={isOpen} onHide={onClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>
          Add Quote
          {loading && <Spinner animation='border' size='sm' className='ms-2' />}
        </Modal.Title>
      </Modal.Header>
      {/* {page === 'form' ? ( */}
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Alert variant='primary' className='my-5 d-flex align-items-center gap-2'>
            <span>
              <i className='bi bi-exclamation-circle fs-1' style={{color: 'inherit'}} />
            </span>
            Please fill in the necessary information below to receive a quote for insurance
            plans.asdf
          </Alert>
          <Row>
            <Col lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>Household Income</Form.Label>
                <Form.Control
                  type='text'
                  name='householdIncome'
                  value={formData.householdIncome}
                  onChange={(e) => {
                    const {value} = e.target
                    const noComma = value.replaceAll(',', '')
                    if (noComma !== '' && isNaN(Number(noComma))) return
                    const str = Intl.NumberFormat('en-US').format(Number(noComma))
                    setFormData((p) => ({...p, householdIncome: str}))
                  }}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  ref={refControl}
                  type='text'
                  name='zipCode'
                  value={formData.zipCode}
                  onChange={handleChange}
                  onBlur={(e) => {
                    e.preventDefault()
                    console.log('blur')
                    if (formData.zipCode.length < 3) return
                    setFormData((p) => ({...p, countyfips: '', state: ''}))

                    gqlGetMarket({
                      variables: {
                        zipCode: formData.zipCode,
                      },
                    })
                  }}
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>County FIPS</Form.Label>
                <Form.Select
                  value={formData.countyfips}
                  onChange={(e) => setFormData((p) => ({...p, countyfips: e.target.value}))}
                >
                  <option value=''>Select county FIPS</option>
                  {data &&
                    data?.zipcodeByZip?.countyNamesAll?.split('|').map((str, idx) => (
                      <option key={str} value={data?.zipcodeByZip?.countyFipsAll?.split('|')[idx]}>
                        {str} - {data?.zipcodeByZip?.countyFipsAll?.split('|')[idx]}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col style={{display: 'none'}} lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>County FIPS</Form.Label>
                <Form.Control
                  type='text'
                  name='countyfips'
                  value={formData.countyfips}
                  required
                  // onChange={handleChange}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col style={{display: 'none'}} lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  type='text'
                  name='state'
                  value={formData.state}
                  required
                  // onChange={handleChange}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>Order</Form.Label>
                <Form.Control
                  as='select'
                  name='order'
                  value={formData.order}
                  onChange={handleChange}
                >
                  <option value='asc'>Ascending</option>
                  <option value='desc'>Descending</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <div className='mt-4'>
            <Accordion activeKey={eventKey} flush>
              <Accordion.Item eventKey='0'>
                <Accordion.Header onClick={() => setEventKey('0')}>Person 1</Accordion.Header>
                <Accordion.Body>
                  <Row className='mt-4'>
                    <Col lg={4}>
                      <Form.Group>
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                          type='date'
                          name='dob'
                          required={Boolean(!formData.personOne.age && !formData.personOne.dob)}
                          value={formData.personOne.dob}
                          onChange={(e) => {
                            if (e.target.value === '') {
                              setFormData((p) => ({
                                ...p,
                                personOne: {...p.personOne, age: ''},
                              }))
                            }
                            console.log('e.target.value', e.target.value)
                            const [yy] = e.target.value.split('-')
                            if (yy) {
                              setFormData((p) => ({
                                ...p,
                                personOne: {
                                  ...p.personOne,
                                  age: `${new Date().getFullYear() - Number(yy)}`,
                                },
                              }))
                            }
                            setFormData((p) => ({
                              ...p,
                              personOne: {...p.personOne, dob: e.target.value},
                            }))
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={4}>
                      <Form.Group>
                        <Form.Label>Age</Form.Label>
                        <Form.Control
                          required={Boolean(!formData.personOne.age && !formData.personOne.dob)}
                          type='text'
                          name='Age'
                          disabled={Boolean(formData.personOne.dob)}
                          value={formData.personOne.age}
                          onChange={(e) => {
                            if (e.target.value === '') {
                              setFormData((p) => ({
                                ...p,
                                personOne: {...p.personOne, age: ''},
                              }))
                            }
                            if (isNaN(Number(e.target.value))) return
                            setFormData((p) => ({
                              ...p,
                              personOne: {...p.personOne, age: e.target.value},
                            }))
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={4}>
                      <Form.Group>
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                          required
                          as='select'
                          name='gender'
                          value={formData.personOne.gender}
                          onChange={(e) => {
                            setFormData((p) => ({
                              ...p,
                              personOne: {...p.personOne, gender: e.target.value},
                            }))
                          }}
                        >
                          <option value='Male'>Male</option>
                          <option value='Female'>Female</option>
                          <option value='Other'>Other</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col lg={4} md={6} sm={12} xs={12}>
                      <Form.Group className='d-flex'>
                        <Form.Check
                          type='switch'
                          // label='Is Pregnant'
                          name='isPregnant'
                          id='isPregnant'
                          checked={formData.personOne.isPregnant}
                          onChange={(e) => {
                            setFormData((p) => ({
                              ...p,
                              personOne: {...p.personOne, isPregnant: e.target.checked},
                            }))
                          }}
                        />
                        <Form.Label htmlFor='isPregnant'>
                          Is Pregnant ({formData.personOne.isPregnant ? 'YES' : 'NO'})
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12}>
                      <Form.Group className='d-flex'>
                        <Form.Check
                          type='switch'
                          // label='Is Pregnant'
                          name='isParent'
                          id='isParent'
                          checked={formData.personOne.isParent}
                          onChange={(e) => {
                            setFormData((p) => ({
                              ...p,
                              personOne: {...p.personOne, isParent: e.target.checked},
                            }))
                          }}
                        />
                        <Form.Label htmlFor='isParent'>
                          Is Parent ({formData.personOne.isParent ? 'YES' : 'NO'})
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12}>
                      <Form.Group className='d-flex'>
                        <Form.Check
                          type='switch'
                          // label='Is Pregnant'
                          name='aptcEligible'
                          id='aptcEligible'
                          checked={formData.personOne.aptcEligible}
                          onChange={(e) => {
                            setFormData((p) => ({
                              ...p,
                              personOne: {...p.personOne, aptcEligible: e.target.checked},
                            }))
                          }}
                        />
                        <Form.Label htmlFor='aptcEligible'>
                          APTC Eligible ({formData.personOne.aptcEligible ? 'YES' : 'NO'})
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12}>
                      <Form.Group className='d-flex'>
                        <Form.Check
                          type='switch'
                          // label='Is Pregnant'
                          name='usesTobacco'
                          id='usesTobacco'
                          checked={formData.personOne.usesTobacco}
                          onChange={(e) => {
                            setFormData((p) => ({
                              ...p,
                              personOne: {...p.personOne, usesTobacco: e.target.checked},
                            }))
                          }}
                        />
                        <Form.Label htmlFor='usesTobacco'>
                          Uses Tobacco ({formData.personOne.usesTobacco ? 'YES' : 'NO'})
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    {/* <Col lg={4} md={6} sm={12} xs={12}>
                      <Form.Group className='d-flex'>
                        <Form.Check
                          type='switch'
                          // label='Is Pregnant'
                          name='hasMarriedCouple'
                          id='hasMarriedCouple'
                          checked={formData.personOne.hasMarriedCouple}
                          onChange={handleChange}
                        />
                        <Form.Label htmlFor='hasMarriedCouple'>
                          Has Married Couple ({formData.personOne.hasMarriedCouple ? 'YES' : 'NO'})
                        </Form.Label>
                      </Form.Group>
                    </Col> */}
                    <Col lg={4} md={6} sm={12} xs={12}>
                      <Form.Group className='d-flex'>
                        <Form.Check
                          type='switch'
                          // label='Is Pregnant'
                          name='hasMec'
                          id='hasMec'
                          checked={formData.personOne.hasMec}
                          onChange={(e) => {
                            setFormData((p) => ({
                              ...p,
                              personOne: {...p.personOne, hasMec: e.target.checked},
                            }))
                          }}
                        />
                        <Form.Label htmlFor='hasMec'>
                          Has MEC ({formData.personOne.hasMec ? 'YES' : 'NO'})
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  {infoEnrollement.id === 1 ? (
                    <>
                      <hr />
                      <div className='d-flex w-100 align-items-center justify-content-between'>
                        <small>Current enrollment</small>
                        <button
                          className='btn btn-reset'
                          title='Delete enrollment'
                          onClick={() => {
                            setInfoEnrollement({
                              effectiveDate: '',
                              id: null,
                              planId: '',
                              usesTobacco: false,
                            })
                          }}
                        >
                          <i className='bi bi-x fs-1' />
                        </button>
                      </div>
                      <Row className='mt-4'>
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Effective Date</Form.Label>
                            <Form.Control
                              type='date'
                              name='effectiveDate'
                              value={infoEnrollement.effectiveDate}
                              onChange={(e) =>
                                setInfoEnrollement((p) => ({...p, effectiveDate: e.target.value}))
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Plan ID</Form.Label>
                            <Form.Control
                              name='planId'
                              value={infoEnrollement.planId}
                              onChange={(e) =>
                                setInfoEnrollement((p) => ({...p, planId: e.target.value}))
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group
                            className='d-flex mt-4'
                            style={{justifySelf: 'end', alignSelf: 'flex-end'}}
                          >
                            <Form.Check
                              type='switch'
                              // label='Is Pregnant'
                              name='usesTobacco'
                              id='usesTobacco'
                              checked={infoEnrollement.usesTobacco}
                              onChange={(e) =>
                                setInfoEnrollement((p) => ({...p, usesTobacco: e.target.checked}))
                              }
                            />
                            <Form.Label htmlFor='isPregnant'>
                              Use Tobacco ({infoEnrollement.usesTobacco ? 'YES' : 'NO'})
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div className='d-flex align-items-end w-100 justify-content-end'>
                      <Button
                        size='sm'
                        variant='danger'
                        style={{
                          alignSelf: 'end',
                        }}
                        className='d-flex px-2 py-1'
                        onClick={() => {
                          setInfoEnrollement({
                            effectiveDate: '',
                            id: 1,
                            planId: '',
                            usesTobacco: false,
                          })
                        }}
                      >
                        Enrollment
                      </Button>
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              {arrPeople.map((inf, idx) => (
                <Accordion.Item key={inf.id} eventKey={`${idx + 1}`}>
                  <Accordion.Header onClick={() => setEventKey(`${idx + 1}`)}>
                    <span>Person {idx + 2}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className='d-flex align-items-center justify-content-end'>
                      <button
                        className='btn-reset text-danger'
                        title='Delete'
                        onClick={() => {
                          setArrPeople((p) => {
                            const newArr = [...p]
                            return newArr.filter((pre) => pre.id !== inf.id)
                          })
                        }}
                      >
                        <i className='bi bi-x fs-1 text-danger' />
                      </button>
                    </div>
                    <Row className='mt-4'>
                      <Col lg={4}>
                        <Form.Group>
                          <Form.Label>Date of Birth</Form.Label>
                          <Form.Control
                            type='date'
                            name='dob'
                            required={Boolean(!inf.age && !inf.dob)}
                            value={inf.dob}
                            onChange={(e) => {
                              const {value} = e.target

                              const [yy] = value.split('-')
                              if (yy) {
                                setArrPeople((prevPeople) =>
                                  prevPeople.map((person) =>
                                    person.id === inf.id
                                      ? {...person, age: `${new Date().getFullYear() - Number(yy)}`}
                                      : person
                                  )
                                )
                              }

                              setArrPeople((prevPeople) =>
                                prevPeople.map((person) =>
                                  person.id === inf.id ? {...person, dob: value} : person
                                )
                              )
                            }}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <Form.Group>
                          <Form.Label>Age</Form.Label>
                          <Form.Control
                            type='text'
                            name='Age'
                            required={Boolean(!inf.age && !inf.dob)}
                            disabled={Boolean(inf.dob)}
                            value={inf.age}
                            onChange={(e) => {
                              const {value} = e.target

                              if (value === '') {
                                setArrPeople((prevPeople) =>
                                  prevPeople.map((person) =>
                                    person.id === inf.id ? {...person, age: ''} : person
                                  )
                                )
                              }
                              if (isNaN(Number(value))) return

                              setArrPeople((prevPeople) =>
                                prevPeople.map((person) =>
                                  person.id === inf.id ? {...person, age: value} : person
                                )
                              )
                            }}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <Form.Group>
                          <Form.Label>Gender</Form.Label>
                          <Form.Control
                            required
                            as='select'
                            name='gender'
                            value={inf.gender}
                            onChange={(e) => {
                              const {value} = e.target
                              setArrPeople((prevPeople) =>
                                prevPeople.map((person) =>
                                  person.id === inf.id ? {...person, gender: value} : person
                                )
                              )
                            }}
                          >
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                            <option value='Other'>Other</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-4'>
                      <Col lg={4} md={6} sm={12} xs={12}>
                        <Form.Group className='d-flex'>
                          <Form.Check
                            type='switch'
                            // label='Is Pregnant'
                            name='isPregnant'
                            id='isPregnant'
                            checked={inf.isPregnant}
                            onChange={(e) => {
                              const {checked} = e.target
                              setArrPeople((prevPeople) =>
                                prevPeople.map((person) =>
                                  person.id === inf.id ? {...person, isPregnant: checked} : person
                                )
                              )
                            }}
                          />
                          <Form.Label htmlFor='isPregnant'>
                            Is Pregnant ({inf.isPregnant ? 'YES' : 'NO'})
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={6} sm={12} xs={12}>
                        <Form.Group className='d-flex'>
                          <Form.Check
                            type='switch'
                            // label='Is Pregnant'
                            name='isParent'
                            id='isParent'
                            checked={inf.isParent}
                            onChange={(e) => {
                              const {checked} = e.target
                              setArrPeople((prevPeople) =>
                                prevPeople.map((person) =>
                                  person.id === inf.id ? {...person, isParent: checked} : person
                                )
                              )
                            }}
                          />
                          <Form.Label htmlFor='isParent'>
                            Is Parent ({inf.isParent ? 'YES' : 'NO'})
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={6} sm={12} xs={12}>
                        <Form.Group className='d-flex'>
                          <Form.Check
                            type='switch'
                            // label='Is Pregnant'
                            name='aptcEligible'
                            id='aptcEligible'
                            checked={inf.aptcEligible}
                            onChange={(e) => {
                              const {checked} = e.target
                              setArrPeople((prevPeople) =>
                                prevPeople.map((person) =>
                                  person.id === inf.id ? {...person, aptcEligible: checked} : person
                                )
                              )
                            }}
                          />
                          <Form.Label htmlFor='aptcEligible'>
                            APTC Eligible ({inf.aptcEligible ? 'YES' : 'NO'})
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={6} sm={12} xs={12}>
                        <Form.Group className='d-flex'>
                          <Form.Check
                            type='switch'
                            // label='Is Pregnant'
                            name='usesTobacco'
                            id='usesTobacco'
                            checked={inf.usesTobacco}
                            onChange={(e) => {
                              const {checked} = e.target
                              setArrPeople((prevPeople) =>
                                prevPeople.map((person) =>
                                  person.id === inf.id ? {...person, usesTobacco: checked} : person
                                )
                              )
                            }}
                          />
                          <Form.Label htmlFor='usesTobacco'>
                            Uses Tobacco ({inf.usesTobacco ? 'YES' : 'NO'})
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      {/* <Col lg={4} md={6} sm={12} xs={12}>
                        <Form.Group className='d-flex'>
                          <Form.Check
                            type='switch'
                            // label='Is Pregnant'
                            name='hasMarriedCouple'
                            id='hasMarriedCouple'
                            checked={inf.hasMarriedCouple}
                            onChange={handleChange}
                          />
                          <Form.Label htmlFor='hasMarriedCouple'>
                            Has Married Couple ({inf.hasMarriedCouple ? 'YES' : 'NO'})
                          </Form.Label>
                        </Form.Group>
                      </Col> */}
                      <Col lg={4} md={6} sm={12} xs={12}>
                        <Form.Group className='d-flex'>
                          <Form.Check
                            type='switch'
                            // label='Is Pregnant'
                            name='hasMec'
                            id='hasMec'
                            checked={inf.hasMec}
                            onChange={(e) => {
                              const {checked} = e.target
                              setArrPeople((prevPeople) =>
                                prevPeople.map((person) =>
                                  person.id === inf.id ? {...person, hasMec: checked} : person
                                )
                              )
                            }}
                          />
                          <Form.Label htmlFor='hasMec'>
                            Has MEC ({inf.hasMec ? 'YES' : 'NO'})
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    {infoEnrollement.id === inf.id ? (
                      <>
                        <hr />
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                          <small>Current enrollment</small>
                          <button
                            className='btn btn-reset'
                            title='Delete enrollment'
                            onClick={() => {
                              setInfoEnrollement({
                                effectiveDate: '',
                                id: null,
                                planId: '',
                                usesTobacco: false,
                              })
                            }}
                          >
                            <i className='bi bi-x fs-1' />
                          </button>
                        </div>
                        <Row className='mt-4'>
                          <Col lg={4}>
                            <Form.Group>
                              <Form.Label>Effective Date</Form.Label>
                              <Form.Control
                                type='date'
                                name='effectiveDate'
                                value={infoEnrollement.effectiveDate}
                                onChange={(e) =>
                                  setInfoEnrollement((p) => ({...p, effectiveDate: e.target.value}))
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <Form.Group>
                              <Form.Label>Plan ID</Form.Label>
                              <Form.Control
                                name='planId'
                                value={infoEnrollement.planId}
                                onChange={(e) =>
                                  setInfoEnrollement((p) => ({...p, planId: e.target.value}))
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <Form.Group
                              className='d-flex mt-4'
                              style={{justifySelf: 'end', alignSelf: 'flex-end'}}
                            >
                              <Form.Check
                                type='switch'
                                // label='Is Pregnant'
                                name='usesTobacco'
                                id='usesTobacco'
                                checked={infoEnrollement.usesTobacco}
                                onChange={(e) =>
                                  setInfoEnrollement((p) => ({...p, usesTobacco: e.target.checked}))
                                }
                              />
                              <Form.Label htmlFor='isPregnant'>
                                Use Tobacco ({infoEnrollement.usesTobacco ? 'YES' : 'NO'})
                              </Form.Label>
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <div className='d-flex align-items-end w-100 justify-content-end'>
                        <Button
                          size='sm'
                          variant='danger'
                          style={{
                            alignSelf: 'end',
                          }}
                          className='d-flex px-2 py-1'
                          onClick={() => {
                            setInfoEnrollement({
                              effectiveDate: '',
                              id: inf.id,
                              planId: '',
                              usesTobacco: false,
                            })
                          }}
                        >
                          Enrollment
                        </Button>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>

          <div className='mt-2'>
            <Button size='sm' variant='info' type='button' onClick={handleAddPeople}>
              Add person
            </Button>
          </div>

          <Row className='mt-4'>
            {/* <Col lg={4}>
                <Form.Group>
                  <Form.Label>Market</Form.Label>
                  <Form.Control
                    as='select'
                    name='market'
                    value={formData.market}
                    onChange={handleChange}
                  >
                    <option value='Individual'>Individual</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
          </Row>

          {/* <Row className='mt-4'>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Limit</Form.Label>
                  <Form.Control
                    type='number'
                    name='limit'
                    value={formData.limit}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type='number'
                    name='offset'
                    value={formData.offset}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' type='button' onClick={onClose}>
            Close
          </Button>
          <Button variant='primary' type='submit' disabled={!Boolean(formData.zipCode)}>
            Search
          </Button>
        </Modal.Footer>
      </Form>
      {/* ) : (
        <>
          <Modal.Body style={{backgroundColor: '#f8f8f8'}}>
            <div style={{minHeight: '600px'}}>
              <div className='mb-4'>
                <button
                  className='d-flex align-items-center gap-2 bg-light-primary rounded'
                  style={{border: 0}}
                  onClick={() => {
                    setPage('form')
                    setResult(null)
                  }}
                >
                  <i className='bi bi-arrow-left' style={{color: 'inherit'}} />
                  Volver
                </button>
              </div>
              {result && <ResultsAddQuote result={result} />}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose}>Acept</Button>
          </Modal.Footer>
        </>
      )} */}
    </Modal>
  )
}
