import {gql} from '@apollo/client'

export const GET_ALL_REASON_LOSS = gql`
  query allReasonLeadLoss(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $reason: String
    $reason_Icontains: String
    $reason_Istartswith: String
  ) {
    allReasonLeadLoss(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      reason: $reason
      reason_Icontains: $reason_Icontains
      reason_Istartswith: $reason_Istartswith
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          created
          updated
          reason
        }
      }
    }
  }
`

export const GET_ALL_REASON_LOSS_FILTER = gql`
  query allReasonLeadLoss($offset: Int) {
    allReasonLeadLoss(offset: $offset) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          reason
        }
      }
    }
  }
`
