import {Button, Col, Form, Modal, Row, Spinner} from 'react-bootstrap'
import {ICommissions, IResEditCommission} from '../../../types/carriers'
import {InputCurrency} from '../../../components/InputCurrency'
import {useEffect, useState} from 'react'
import {useMutation} from '@apollo/client'
import {EDIT_COMMISSION} from '../../../gql/mutations/carriersMutations'
import {parseId} from '../../../helpers'
import {toast} from 'react-toastify'

interface IModalEditProps {
  info: ICommissions
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}

export const ModalEdit: React.FC<IModalEditProps> = ({info, isOpen, onClose, refetch}) => {
  const [gqlEdit, {data, loading, error}] = useMutation<IResEditCommission>(EDIT_COMMISSION)

  const [nominalValue, setNominalValue] = useState('')
  const [percentageValue, setPercentageValue] = useState('')

  useEffect(() => {
    if (!info) return
    if (Number(info.nominalValue)) setNominalValue(info.nominalValue)
    if (Number(info.percentageValue)) setPercentageValue(info.percentageValue)
  }, [info])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.updateCommission.commission?.id) {
      toast.success('Commission edited successfully')
      onClose()
      refetch()
    } else if (data.updateCommission.errors && data.updateCommission.errors?.length > 0) {
      toast.error(`Error: ${data.updateCommission.errors?.[0]?.message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleEdit = () => {
    if (!nominalValue && !percentageValue) return
    const commissionDate = {
      percentageValue: percentageValue ? Number(percentageValue) : 0,
      nominalValue: nominalValue ? Number(nominalValue) : 0,
      policyType: parseId(info.policyType.id),
      state: parseId(info.state.id),
    }
    gqlEdit({
      variables: {
        commissionDate,
        id: parseId(info.id),
      },
    })
  }
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit commission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>State </Form.Label>
              <Form.Control placeholder='State' defaultValue={info.state.name} readOnly />
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Policy Type</Form.Label>
              <Form.Control
                placeholder='State'
                defaultValue={info.policyType.typePolicy}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
          <Form.Label>Nominal Value</Form.Label>
          <InputCurrency
            placeholder='0'
            value={nominalValue}
            onValueChange={(v) => {
              setNominalValue(v || '')
              setPercentageValue('')
            }}
          />
        </Form.Group>
        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
          <Form.Label>Percentage Value</Form.Label>
          <InputCurrency
            placeholder='0'
            value={percentageValue}
            onValueChange={(v) => {
              setPercentageValue(v || '')
              setNominalValue('')
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
        <Button variant='primary' onClick={handleEdit} disabled={loading}>
          {loading && <Spinner animation='border' size='sm' className='me-2' />}
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
