import {Button, Card, Form, Placeholder, Spinner} from 'react-bootstrap'

export const LoadingLeadsPage = () => {
  return (
    <div className='d-flex flex-column w-100'>
      <div className='w-100 mb-4 d-flex justify-content-between flex-column flex-md-row gap-4'>
        <div className='d-flex gap-2'>
          <Form.Select aria-label='Default select example' disabled>
            <option>Pipeline</option>
          </Form.Select>
          <Form.Select size='sm' disabled>
            <option>View Active Stages</option>
          </Form.Select>
        </div>
        <div>
          <Button id='dropdown-basic-button' title='+ Add' disabled>
            <i className='bi bi-person-vcard' />
            Add Lead
          </Button>
        </div>
      </div>
      <div className='d-flex gap-2 container-draggs' style={{maxWidth: '100%', overflowX: 'auto'}}>
        {[1, 2, 3, 4].map((el, ind) => (
          <Card
            key={el}
            style={{
              background: 'white',
              padding: 8,
              width: 250,
              flex: '1 0 250px',
            }}
          >
            <div
              style={{borderBottom: '1px solid #f1f1f2'}}
              className='px-3 py-2 d-flex align-items-center justify-content-between'
            >
              <Card.Title style={{width: '94px'}}>
                <Placeholder animation='wave'>
                  <Placeholder xs={12} />
                </Placeholder>
              </Card.Title>
              <div>
                <span
                  className='d-flex align-items-center justify-content-center px-3 py-1 text-primary'
                  style={{
                    borderRadius: '20px',
                    backgroundColor: '#f6f7fb',
                  }}
                >
                  <Spinner animation='border' size='sm' />
                </span>
              </div>
            </div>
            <div style={{borderBottom: '1px solid #f1f1f2'}} className='px-3 py-2 pb-2 mb-4'>
              <div className='d-flex align-items-center justify-content-between w-100'>
                <div>
                  <Form.Select size='sm' className='border-0' style={{color: 'gray'}}>
                    <option>Select option</option>
                  </Form.Select>
                </div>
                <div>
                  <div className='d-flex align-items-center gap-3'>
                    <button className='m-0 p-0 border-0 bg-transparent '>
                      <i className='bi bi-arrow-left' />
                    </button>
                    <span className='text-secondary'>1/ -</span>
                    <button className='m-0 p-0 border-0 bg-transparent'>
                      <i className='bi bi-arrow-right' />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <Card.Body
              className='placeholder-glow p-0 mb-1'
              style={{maxHeight: '100%', overflowY: 'auto'}}
            >
              {[1, 2, 3, 4, 5].map((m) => (
                <Card
                  key={m}
                  className='placeholder'
                  style={{
                    padding: 8 * 2,
                    margin: `0 0 ${8}px 0`,
                    background: '#c4c4c4',
                    height: `${80 + (Math.floor(Math.random() * 10) % 2 === 0 ? 20 : 0)}px`,
                    display: 'block',
                  }}
                ></Card>
              ))}
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  )
}
