import {Dispatch, SetStateAction} from 'react'
import {Button, ListGroup, Offcanvas} from 'react-bootstrap'
import {Tooltip} from 'react-tooltip'

const FILTERS_NAME_ARR = [
  {
    name: 'OE 2024 Obamacare (ACA)',
    icon: 'heart-fill',
  },
  {
    name: 'OE 2024 Medicare',
    icon: 'heart-fill',
  },
  {
    name: 'Important',
    icon: 'star-fill',
  },
]

export const AsideMenuPolicies: React.FC<{
  isOpen: boolean
  onClose: () => void
  tabFilters: string
  setTabFilters: Dispatch<SetStateAction<string>>
}> = ({isOpen, onClose, setTabFilters, tabFilters}) => {
  return (
    <Offcanvas show={isOpen} onHide={onClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            backgroundColor: '#fff',
            marginBottom: '20px',
            padding: '8px 10px',
          }}
        >
          <div
            style={{
              borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
              paddingBottom: '7px',
            }}
          >
            <h5 style={{fontSize: '13px'}}>Menu</h5>
          </div>

          <div>
            <ListGroup className='mt-5' variant='flush'>
              <ListGroup.Item
                action
                variant={tabFilters === 'Policies' ? 'primary' : 'light'}
                className='my-2 py-4'
                onClick={() => setTabFilters('Policies')}
              >
                <i className='bi bi-person-fill fs-3' style={{color: 'inherit'}} /> Policies
              </ListGroup.Item>
              {FILTERS_NAME_ARR.map((lis) => (
                <ListGroup.Item
                  key={lis.name}
                  action
                  variant={tabFilters === lis.name ? 'primary' : 'light'}
                  className='my-1 py-4 d-flex align-items-center justify-content-between'
                  onClick={() => setTabFilters(lis.name)}
                >
                  <span className='d-flex align-items-center justify-content-between gap-1'>
                    <i className={`bi bi-${lis.icon} fs-3`} style={{color: 'inherit'}} /> {lis.name}
                  </span>
                </ListGroup.Item>
              ))}
              <ListGroup.Item
                action
                variant={'light'}
                className='my-2 py-4'
                // onClick={() => setTabFilters('Policies')}
              >
                <i className='bi bi-clock fs-3' style={{color: 'inherit'}} /> Reminders
              </ListGroup.Item>
              <ListGroup.Item
                action
                variant={'light'}
                className='my-2 py-4'
                // onClick={() => setTabFilters('Policies')}
              >
                <i className='bi bi-folder-fill fs-3' style={{color: 'inherit'}} /> Archived
              </ListGroup.Item>
            </ListGroup>
          </div>

          <div
            style={{
              borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
            }}
            className='d-flex align-items-center justify-content-between'
          >
            <h5 className='m-0 p-0'>Agency Folders</h5>
            <Button variant='link'>
              <i className='bi bi-pencil-square' style={{color: 'inherit'}} /> Manage
            </Button>
          </div>
          <p className='my-4'>Your agency has no folders.</p>
          <div
            style={{
              borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
            }}
            className='d-flex align-items-center justify-content-between mt-5'
          >
            <h5 className='m-0 p-0'>
              Agency Folders
              <span>
                <span data-tooltip-id='asdf'>
                  <i className='bi bi-info-circle-fill fs-5 ms-1' style={{color: '#5867dd'}} />
                </span>
                <Tooltip
                  id='asdf'
                  place='right'
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                    opacity: 1,
                  }}
                >
                  <div className='tooltip-tfi'>
                    <h6 className='text-center'>English</h6>
                    <p className='text-muted'>
                      Only policies created by you can be stored in your personal folders.
                    </p>
                    <h6 className='text-center'>Español</h6>
                    <p className='text-muted'>
                      Una dirección de facturación es la dirección relacionada con una forma de pago
                      específica.
                    </p>
                  </div>
                </Tooltip>
              </span>
            </h5>
            <Button variant='link'>
              <i className='bi bi-pencil-square' style={{color: 'inherit'}} /> Manage
            </Button>
          </div>
          <p className='my-4'>You have no personal folders.</p>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
