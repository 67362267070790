import {Badge} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {IAllMembersNode} from '../../../types/members'

export const OverviewTab = ({node}: {node: IAllMembersNode}) => {
  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>

        <button className='btn btn-primary align-self-center'>Edit Profile</button>
      </div>

      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>
              {node.firstName} {node.lastName}
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Company</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>The Future Insurance</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            Contact Phone
            <i
              className='fas fa-exclamation-circle ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Phone number must be active'
            ></i>
          </label>

          <div className='col-lg-8 d-flex align-items-center'>
            <span className='fw-bolder fs-6 me-2'>{node.cellphone || 'N/R'}</span>

            {node.cellphone && <span className='badge badge-success'>Verified</span>}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Company Site</label>

          <div className='col-lg-8'>
            <a
              href='www.thefutureinsurance.com'
              target='_blank'
              rel='noopener noreferrer'
              className='fw-bold fs-6 text-info text-hover-primary text-hover-underline'
            >
              www.thefutureinsurance.com
            </a>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            Country
            <i
              className='fas fa-exclamation-circle ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Country of origination'
            ></i>
          </label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>United States</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Communication</label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>{node.email || 'N/R'}</span>
          </div>
        </div>

        <div className='row mb-10'>
          <label className='col-lg-4 fw-bold text-muted'>Apply deductions</label>

          <div className='col-lg-8'>
            <Badge bg={node.applyDeductions ? 'success' : 'danger'}>
              {node.applyDeductions ? 'YES' : 'NO'}
            </Badge>
          </div>
        </div>

        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
          <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
              <div className='fs-6 text-gray-600 d-flex align-items-center gap-2'>
                Your payment was declined. To start using tools, please
                <button className='fw-bolder btn btn-link'> Add Payment Method</button>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
