import {useEffect, useState} from 'react'
import {Button, Form, Spinner, ButtonGroup, Dropdown, DropdownButton} from 'react-bootstrap'
import {useDisclourse} from '../../hooks/useDisclourse'
import {DrawerAddLead} from './components/DrawerAddLead'
import {IEdgesStagePipe, IFilterStage, IInfoLead} from './leadTypes'
import {DrawerEditLead} from './components/DrawerEditLead'
import {useLazyQuery, useQuery} from '@apollo/client'
import {GET_ALL_PIPELINES, GET_ONE_PIPELINE} from '../../gql/queries/pipelines'
import {IResPipelines, IStagePipelineSet} from '../../types/pipelines'
import {LoadingLeadsPage} from './components/LoadingLeadsPage'
import {toast} from 'react-toastify'
import {ContentDragDropLeads} from './components/ContentDragDropLeads'
import {useGetUser} from '../../store/userStore'
import {hasPermission, parseId} from '../../helpers'
import {ALL_PERMISSIONS, STORAGE_NAMES} from '../../helpers/consts'
import {ContentListLeads} from './components/ContentListLeads'
import {isMobileDevice} from '../../../../_metronic/assets/ts/_utils'
import DatePicker from 'react-multi-date-picker'
import {IResAllMembers} from '../../types/members'
import {GET_FILTERS_MEMBERS} from '../../gql/queries/membersQuery'
import {ModalReasingLeads} from './components/ModalReasingLeads'
import {ModalAssignAgentsToPipeline} from './components/ModalAssignAgentsToPipeline'

export const STATUS_LEADS = {
  Declined: 'Declined',
  Sold: 'Sold',
}

export const SEMAFORO_LIMITS = {
  red: 5,
  green: 3,
}

export const getClassTraffic = (dateLead: Date) => {
  const ahora = new Date()
  const diferenciaMs = ahora.getTime() - dateLead.getTime()
  const dias = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24))
  if (dias >= SEMAFORO_LIMITS.red) {
    return {
      class: 'traffic-red',
      title: 'This element has ' + SEMAFORO_LIMITS.red + ' days old',
      diff: dias,
    }
  }
  if (dias >= SEMAFORO_LIMITS.green && dias < SEMAFORO_LIMITS.red) {
    return {
      class: 'traffic-orange',
      title: 'This element has ' + SEMAFORO_LIMITS.green + ' days old',
      diff: dias,
    }
  }
  return {
    class: 'traffic-green',
    title: 'This element has ' + SEMAFORO_LIMITS.green + ' days old',
    diff: dias,
  }
}

export const LeadsPage = () => {
  const permissions = useGetUser((s) => s.user?.permissions)
  const isRingIntegration = localStorage.getItem(STORAGE_NAMES.RING_CENTRAL) ? true : false

  const {
    data: dataPipelines,
    loading: loadingPipelines,
    error: errorPipelines,
  } = useQuery<IResPipelines>(GET_ALL_PIPELINES, {fetchPolicy: 'no-cache'})

  const [
    gqlGetPipeline,
    {data: dataPipelineSelected, loading: loadingPipeline, error: errorPipeline},
  ] = useLazyQuery<IResPipelines>(GET_ONE_PIPELINE, {
    fetchPolicy: 'no-cache',
  })

  const {data: dataMembers, error: errorMembers} = useQuery<IResAllMembers>(GET_FILTERS_MEMBERS, {
    fetchPolicy: 'no-cache',
  })

  const isMobile = isMobileDevice()
  const [pipelineId, setPipelineId] = useState('')

  const [flag, setFlag] = useState(0)
  const [pageRefresh, setPageRefresh] = useState(0)

  const [flagView, setFlagView] = useState<'card' | 'list'>('card')

  const [isLoadingChangeState, setIsLoadingChangeState] = useState(false)

  const [infoLead, setInfoLead] = useState<null | IInfoLead>(null)
  const [infoReAssign, setInfoReAssign] = useState<null | {
    fromUserId: string
    fromUserName: string
    pipelineId: string
    statePipeline: string
    stateNamePipeline: string
    searchName: string
    searchPhone: string
    dateFrom: Date | null
    dateTo: Date | null
    assignType: string
    leadsSelected?: {id: string; name: string; stage: string; user: string}[]
  }>(null)

  const [leadsSelected, setLeadSelected] = useState<
    {id: string; name: string; stage: string; user: string}[] | undefined
  >([])
  const [stageByPipeline, setStageByPipeline] = useState<IStagePipelineSet[] | null>(null)

  const [infoAssignPipeline, setInfoAssignPipeline] = useState<null | {
    pipelineName: string
    pipelineId: string
  }>(null)
  const {isOpen: isOpenEdit, onClose: onCloseEdit, onOpen: onOpenEdit} = useDisclourse()
  const {isOpen: isOpenAdd, onClose: onCloseAdd, onOpen: onOpenAdd} = useDisclourse()
  const {isOpen: isOpenReAssign, onClose: onCloseReAssign, onOpen: onOpenReAssign} = useDisclourse()
  const {
    isOpen: isOpenAssignPipeline,
    onClose: onCloseAssignPipeline,
    onOpen: onOpenAssignPipeline,
  } = useDisclourse()

  const [filterStage, setFilterStage] = useState<IFilterStage>('all')
  const [statePipeline, setStagePipelineId] = useState('')
  const [searchName, setSearchName] = useState('')
  const [searchPhone, setSearchPhone] = useState('')
  const [searchPhoneText, setSearchPhoneText] = useState('')
  const [searchNameText, setSearchNameText] = useState('')
  const [valueUser, setValueUser] = useState('')
  const [stagesDeclined, setStagesDeclined] = useState<IEdgesStagePipe | null>(null)

  const [dateFrom, setDateFrom] = useState<Date | null>(null)
  const [dateTo, setDateTo] = useState<Date | null>(null)

  useEffect(() => {
    if (!pipelineId) return
    gqlGetPipeline({variables: {id: parseId(pipelineId)}})
    if (flagView === 'list') {
      setStagePipelineId('')
      refreshList()
    }
    setLeadsSelected([])
  }, [pipelineId, flagView, gqlGetPipeline])

  useEffect(() => {
    if (!dataPipelines) return
    if (dataPipelines.allPipelines.edges.length > 0) {
      setPipelineId(dataPipelines.allPipelines.edges[0].node.id)
    }
  }, [dataPipelines])

  useEffect(() => {
    if (!dataPipelineSelected) return
    const declinedStage =
      dataPipelineSelected?.allPipelines?.edges[0]?.node.stagePipelineSet?.edges.find(
        ({node}) => node.name === STATUS_LEADS.Declined
      )
    if (declinedStage) {
      setStagesDeclined(declinedStage)
    } else {
      setStagesDeclined(null)
    }
    const stagesByPipelineSelected =
      dataPipelineSelected?.allPipelines?.edges[0]?.node.stagePipelineSet?.edges

    if (stagesByPipelineSelected) setStageByPipeline(stagesByPipelineSelected)
    else setStageByPipeline(null)
    setLeadsSelected([])
  }, [dataPipelineSelected])

  useEffect(() => {
    if (errorPipelines) toast.error(`Error: ${errorPipelines.message}`)
    if (errorPipeline) toast.error(`Error: ${errorPipeline.message}`)
  }, [errorPipelines, errorPipeline])

  const refreshList = () => {
    setPageRefresh((p) => p + 1)
  }

  const updateListLeads = () => {
    setLeadsSelected([])
    console.log('leadsSelected',leadsSelected)
    setFlag((p) => p + 1)
  }

  const setLeadsSelected = (leads: {id: string; name: string; stage: string; user: string}[]) => {
    setLeadSelected(leads)
  }

  const research = () => {
    updateListLeads()
  }

  useEffect(() => {
    if (errorMembers) toast.error(`Error: ${errorMembers.message}`)
  }, [errorMembers])

  if (loadingPipelines || !dataPipelines || !permissions) {
    return <LoadingLeadsPage />
  }

  return (
    <>
      <div className='d-flex flex-column w-100'>
        <div className='w-100 mb-4 d-flex justify-content-between flex-column flex-md-row gap-4'>
          <div className='d-flex align-items-center gap-4 col-md-10'>
            <div>
              <Form.Select
                aria-label='Default select example'
                value={pipelineId}
                onChange={(e) => {
                  const {value} = e.target
                  if (value) {
                    setPipelineId(value)
                  }
                }}
              >
                <option value=''>Select Pipeline</option>
                {dataPipelines.allPipelines.edges.map(({node}) => (
                  <option key={node.id} value={node.id}>
                    {node.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            {flagView === 'card' ? (
              <div>
                <Form.Select
                  value={filterStage}
                  aria-label='Search stages'
                  onChange={(e) => setFilterStage(e.target.value as IFilterStage)}
                >
                  <option value='all'>View all</option>
                  <option value='soldDecline'>View Sold & Declined</option>
                  <option value='others'>View Active Stages</option>
                </Form.Select>
              </div>
            ) : loadingPipeline ? (
              <span>
                <Spinner animation='border' size='sm' />
              </span>
            ) : (
              (!loadingPipeline || dataPipelineSelected) && (
                <div>
                  <Form.Select
                    aria-label='Default select example'
                    onChange={(e) => {
                      const {value} = e.target
                      if (value) {
                        setStagePipelineId(value)
                        refreshList()
                      }
                      if (e.target.value === '') {
                        setStagePipelineId('')
                      }
                    }}
                  >
                    <option value=''>View all stage</option>
                    {dataPipelineSelected?.allPipelines.edges[0]?.node.stagePipelineSet?.edges
                      .sort((a, b) => a.node.order - b.node.order)
                      .map(({node}) => (
                        <option key={node.id} value={node.id}>
                          {node.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              )
            )}
            <div className='d-flex flex-column flex-sm-row align-items-start gap-3 w-100 w-md-auto'>
              <DatePicker
                aria-label='Select Fecha'
                style={{
                  padding: '1.55em 1em',
                  textAlign: 'center',
                  flex: '1',
                  width: '100%',
                  minWidth: '215px',
                }}
                placeholder='Select date range'
                containerStyle={{width: '100%'}}
                value={[dateFrom, dateTo]}
                onChange={(d) => {
                  if (d === undefined || d === null || d.length <= 1) {
                    setDateFrom(null)
                    setDateTo(null)
                    return
                  }
                  const [d1, d2] = d
                  const date1 = d1.toDate()
                  if (date1) {
                    const dateF = new Date(date1) // fechaPrevia es la fecha que ya tienes
                    dateF.setHours(0, 0, 0, 0)
                    setDateFrom(dateF)
                  }
                  const date2 = d2.toDate()
                  if (date2) {
                    const date = new Date(date2) // fechaPrevia es la fecha que ya tienes
                    date.setHours(23, 59, 59, 999)
                    setDateTo(date)
                  }
                }}
                range
                numberOfMonths={isMobile ? 1 : 2}
                format='DD-MMM-YYYY'
              />
            </div>
            <div>
              <Form.Control
                aria-label='Example text with button addon'
                aria-describedby='basic-addon1'
                name='search'
                type='search'
                value={searchNameText}
                onChange={(e) => {
                  setSearchNameText(e.target.value)
                  if (e.target.value === '') {
                    setSearchName('')
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSearchName(searchNameText)
                  }
                }}
                placeholder='Lead name'
              />
            </div>
            <div>
              <Form.Control
                aria-label='Example text with button addon'
                aria-describedby='basic-addon1'
                name='search'
                type='search'
                value={searchPhoneText}
                onChange={(e) => {
                  setSearchPhoneText(e.target.value)
                  if (e.target.value === '') {
                    setSearchPhone('')
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSearchPhone(searchPhoneText)
                  }
                }}
                placeholder='Lead phone number'
              />
            </div>
            {permissions && hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_USUARIOS) && (
              <div>
                <Form.Select
                  aria-label='Lead source'
                  name='leadSource'
                  onChange={(e) => {
                    const {value} = e.target
                    if (value) {
                      setValueUser(value)
                    }
                    if (e.target.value === '') {
                      setValueUser('')
                    }
                  }}
                >
                  <option value=''>Please select</option>
                  {dataMembers?.allUsers.edges.map(({node}) => (
                    <option key={node.id} value={node.id}>
                      {node.firstName + ' ' + node.lastName}
                    </option>
                  ))}
                </Form.Select>
              </div>
            )}

            <Button variant='secondary' onClick={() => research()} type='submit' id='button-addon1'>
              <i className='bi bi-search text-dark' />
            </Button>
          </div>
          {isLoadingChangeState && (
            <span>
              <Spinner animation='border' size='sm' />
            </span>
          )}
          <div>
            {permissions && hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_USUARIOS) ? (
              <DropdownButton
                id='dropdown-basic-button'
                title='Actions'
                className='btn-actions-group'
              >
                {hasPermission(permissions, ALL_PERMISSIONS.PUEDE_CREAR_LEADS) && (
                  <Dropdown.Item
                    className='px-4 mx-1 my-2 py-2 d-flex align-items-center gap-1'
                    onClick={onOpenAdd}
                  >
                    <i className='bi bi-person-vcard'></i>
                    Add Lead
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() => {
                    const userNode = dataMembers?.allUsers.edges.find(
                      ({node}) => node.id === valueUser
                    )?.node

                    const stageSelectedNode = stageByPipeline?.find(
                      ({node}) => node.id === statePipeline
                    )?.node.name

                    const fullName = userNode ? `${userNode.firstName} ${userNode.lastName}` : ''
                    let assingType = 'by-user'
                    if (leadsSelected && leadsSelected.length > 0) {
                      assingType = 'by-leads-selected'
                    } else if (
                      (statePipeline != null && statePipeline !== '') ||
                      dateFrom != null ||
                      dateTo != null ||
                      (searchName != null && searchName !== '') ||
                      (searchPhone != null && searchPhone !== '')
                    )
                      assingType = 'by-filters'

                    setInfoReAssign({
                      fromUserId: valueUser,
                      fromUserName: fullName,
                      pipelineId: pipelineId,
                      statePipeline: statePipeline,
                      stateNamePipeline: stageSelectedNode ?? '',
                      dateFrom: dateFrom,
                      dateTo: dateTo,
                      searchName: searchName,
                      searchPhone: searchPhone,
                      assignType: assingType,
                      leadsSelected: leadsSelected,
                    })
                    onOpenReAssign()
                  }}
                >
                  <i className='bi bi-person-up' />
                  Reassign Leads
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    const pipelineAux = dataPipelines?.allPipelines?.edges.find(
                      ({node}) => node.id === pipelineId
                    )
                    setInfoAssignPipeline({
                      pipelineName: pipelineAux?.node.name ?? '',
                      pipelineId: pipelineId,
                    })
                    onOpenAssignPipeline()
                  }}
                >
                  <i className='bi bi-person-check' />
                  Assign Revisor
                </Dropdown.Item>
              </DropdownButton>
            ) : (
              <Button
                variant='primary'
                onClick={onOpenAdd}
                disabled={!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_CREAR_LEADS)}
              >
                <i className='bi bi-person-vcard'></i>
                Add Lead
              </Button>
            )}
          </div>
        </div>
        <div className='w-100 mb-1 d-flex justify-content-between flex-column flex-md-row gap-4'>
          <div></div>
          <div className='d-flex justify-content-end gap-4 col-md-1'>
            <ButtonGroup className='btn-sm'>
              <Button
                variant='secondary'
                className='btn-sm'
                active={flagView === 'card'}
                onClick={() => setFlagView('card')}
              >
                <i className='bi bi-postcard' />
              </Button>
              <Button
                variant='secondary'
                className='btn-sm'
                active={flagView === 'list'}
                onClick={() => setFlagView('list')}
              >
                <i className='bi bi-card-list'></i>
              </Button>
            </ButtonGroup>
          </div>
        </div>

        {flagView === 'card' ? (
          <div
            className='d-flex gap-2 container-draggs'
            style={{maxWidth: '100%', overflowX: 'auto'}}
          >
            <ContentDragDropLeads
              pipelineId={pipelineId}
              pipelines={dataPipelines.allPipelines.edges}
              onOpenEdit={onOpenEdit}
              setInfoLead={setInfoLead}
              updateListLeads={updateListLeads}
              flagLeads={flag}
              setLoadingState={(b: boolean) => setIsLoadingChangeState(b)}
              filterStage={filterStage}
              searchName={searchName}
              searchPhone={searchPhone}
              valueUser={valueUser}
              datesFrom={dateFrom}
              datesTo={dateTo}
              permissions={permissions}
              isRingIntegration={isRingIntegration}
              stagesDeclined={stagesDeclined}
            />
          </div>
        ) : (
          <div className='lead-list' style={{maxWidth: '100%', overflowX: 'auto'}}>
            <ContentListLeads
              pipelineId={pipelineId}
              onOpenEdit={onOpenEdit}
              setInfoLead={setInfoLead}
              flagLeads={flag}
              updateListLeads={updateListLeads}
              setLoadingState={(b: boolean) => setIsLoadingChangeState(b)}
              statePipeline={statePipeline}
              searchName={searchName}
              searchPhone={searchPhone}
              datesFrom={dateFrom}
              datesTo={dateTo}
              valueUser={valueUser}
              permissions={permissions}
              pageRefresh={pageRefresh}
              isRingIntegration={isRingIntegration}
              stagesDeclined={stagesDeclined}
              leadsSelected={leadsSelected}
              setLeadsSelected={setLeadsSelected}
            />
          </div>
        )}
      </div>

      {isOpenAdd && (
        <DrawerAddLead isOpen={isOpenAdd} onClose={onCloseAdd} updateListLeads={updateListLeads} />
      )}
      {isOpenEdit && infoLead && (
        <DrawerEditLead
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          info={infoLead}
          updateListLeads={updateListLeads}
        />
      )}
      {isOpenReAssign && infoReAssign && (
        <ModalReasingLeads
          isOpen={isOpenReAssign}
          onClose={onCloseReAssign}
          info={infoReAssign}
          updateListLeads={updateListLeads}
          iStagePipeline={stageByPipeline}
        />
      )}
      {isOpenAssignPipeline && infoAssignPipeline && (
        <ModalAssignAgentsToPipeline
          isOpen={isOpenAssignPipeline}
          onClose={onCloseAssignPipeline}
          info={infoAssignPipeline}
        />
      )}
    </>
  )
}
