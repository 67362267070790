import {Badge, Button, Card, Spinner} from 'react-bootstrap'
import {Draggable, DroppableProvided} from 'react-beautiful-dnd'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Tooltip} from 'react-tooltip'
import {AllLeadStageEdge, IEdgesStagePipe, IInfoLead, IResAllLeadStage} from '../leadTypes'
import {useLazyQuery} from '@apollo/client'
import {GET_ALL_LEADS_BY_STAGE} from '../../../gql/queries/leadsQuery'
import {toast} from 'react-toastify'
import {formatPhoneNumber, hasPermission, parseId} from '../../../helpers'
import Lottie from 'lottie-react'
import noLeads from '../../../assets/lotties/no-leads.json'
import moment from 'moment'
import {useDisclourse} from '../../../hooks/useDisclourse'
import {ModalSumCall} from '../../oneLead/components/ModalSumCall'
import {ALL_PERMISSIONS} from '../../../helpers/consts'
import {ModalDeadLead} from '../../oneLead/components/ModalDeadLead'
import {getClassTraffic, STATUS_LEADS} from '../LeadsPage'

const FIRST = 20

const getItemStyle = (isDragging: boolean, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 8 * 2,
  margin: `0 0 ${8}px 0`,

  // change background colour if dragging
  background: isDragging ? '#71C8F5' : '#f4f7f9',

  // styles we need to apply on draggables
  ...draggableStyle,
})

interface IBodyLeadsProps {
  providedParent: DroppableProvided
  setInfoLead: Dispatch<SetStateAction<IInfoLead | null>>
  onOpenEdit: () => void
  updateOneLead: ({idStage, leads}: {idStage: string; leads: AllLeadStageEdge[]}) => void
  initTotal: ({idStage, total}: {idStage: string; total: number}) => void
  updateListLeads: () => void
  registerRefresh?: (stageId: string, fn: () => void) => void
  stageId: string
  stageName: string
  flagLeads: number
  leads: AllLeadStageEdge[]
  total: number | null
  permissions: string[]
  valueUser?: string | null
  datesFrom?: Date | null
  datesTo?: Date | null
  searchName?: string
  searchPhone?: string
  isRingIntegration: boolean
  declinedStage?: IEdgesStagePipe | null
}

export const BodyLeadsDragDrop: React.FC<IBodyLeadsProps> = ({
  providedParent,
  onOpenEdit,
  setInfoLead,
  updateOneLead,
  initTotal,
  updateListLeads,
  registerRefresh,
  stageId,
  stageName,
  flagLeads,
  leads,
  total,
  permissions,
  datesFrom,
  datesTo,
  searchName,
  searchPhone,
  valueUser,
  isRingIntegration,
  declinedStage,
}) => {
  const [gqlGetLeads, {data, loading, error: errorTotal, refetch}] = useLazyQuery<IResAllLeadStage>(
    GET_ALL_LEADS_BY_STAGE,
    {fetchPolicy: 'no-cache'}
  )

  const [offset, setOffset] = useState(0)

  const {isOpen: isOpenCall, onClose: onCloseCall, onOpen: onOpenCall} = useDisclourse()
  const {isOpen: isOpenDeadLead, onClose: onCloseDeadLead, onOpen: onOpenDeadLead} = useDisclourse()

  const [idLead, setIdLead] = useState<null | string>(null)
  const [infoLeadDead, setInfoLeadDead] = useState({
    idDeadLead: '',
    idNameLead: '',
  })

  useEffect(() => {
    gqlGetLeads({
      variables: {
        stagePipelineId: parseId(stageId),
        first: FIRST,
        offset: offset,
        userId: parseId(valueUser ?? ''),
        name_Icontains: searchName,
        phone_Icontains: searchPhone,
        fromDate: datesFrom != null ? moment(datesFrom).format('YYYY-MM-DD') : null,
        toDate: datesTo != null ? moment(datesTo).format('YYYY-MM-DD') : null,
      },
    })
  }, [
    gqlGetLeads,
    stageId,
    flagLeads,
    offset,
    datesFrom,
    datesTo,
    searchName,
    searchPhone,
    valueUser,
  ])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerRefresh, flagLeads])

  // useEffect(() => {
  //   if (error) toast.error(`Error: ${error.message}`)
  // }, [error])
  useEffect(() => {
    if (errorTotal) toast.error(`Error: ${errorTotal.message}`)
  }, [errorTotal])

  useEffect(() => {
    if (!data) return
    if (isNaN(data.totalLeads)) return
    initTotal({
      idStage: stageId,
      total: Number(data.totalLeads),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!data) return
    const {edges} = data.allLeads
    if (edges.length === 0) return
    updateOneLead({idStage: stageId, leads: edges})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (loading || !data) {
    return (
      <Card.Body
        className='placeholder-glow p-0 mb-1'
        style={{overflowY: 'auto', minHeight: 'calc(100vh - 200px)'}}
      >
        <div style={{borderBottom: '1px solid #f1f1f2'}} className='px-3 py-2 pb-2 mb-4'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div>
              <div className='d-flex align-items-center gap-3'>
                <button className={`m-0 p-0 border-0 bg-transparent`} disabled>
                  <i className='bi bi-arrow-left' style={{color: 'inherit'}} />
                </button>
                <span className='text-secondary'>
                  {offset / FIRST + 1}/
                  {total ? (
                    Math.ceil(total / FIRST)
                  ) : total == null ? (
                    <Spinner animation='grow' size='sm' />
                  ) : (
                    total
                  )}
                </span>
                <button className={`m-0 p-0 border-0 bg-transparent`} disabled>
                  <i className='bi bi-arrow-right' style={{color: 'inherit'}} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {[1, 2, 3, 4].map((ff) => (
          <Card
            key={ff}
            className='placeholder'
            style={{
              padding: 8 * 2,
              margin: `0 0 ${8}px 0`,
              background: '#c4c4c4',
              height: `${80 + (Math.floor(Math.random() * 10) % 2 === 0 ? 20 : 0)}px`,
              display: 'block',
            }}
          />
        ))}
      </Card.Body>
    )
  }

  const {edges} = data.allLeads

  if (edges.length === 0) {
    return (
      <Card.Body className='p-0 mb-1' style={{maxHeight: '100%', overflowY: 'auto'}}>
        <Card.Body>
          <Card.Text>This stage does not contain any LEAD assigned</Card.Text>
          <div className='d-flex align-items-center justify-content-center w-100'>
            <div style={{maxWidth: '200px'}}>
              <Lottie animationData={noLeads} autoPlay style={{maxWidth: '200px'}} />
            </div>
          </div>
          {providedParent.placeholder}
        </Card.Body>
      </Card.Body>
    )
  }

  return (
    <>
      <Card.Body className='p-0 mb-1' style={{minHeight: 'calc(100vh - 200px)', overflowY: 'auto'}}>
        <div style={{borderBottom: '1px solid #f1f1f2'}} className='px-3 py-2 pb-2 mb-4'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div>
              {/* <Form.Select size='sm' className='border-0 text-primary'>
                      <option>Select option</option>
                      <option>Select one</option>
                      <option>Third select</option>
                    </Form.Select> */}
            </div>
            <div>
              <div className='d-flex align-items-center gap-3'>
                <button
                  className={`m-0 p-0 border-0 bg-transparent ${offset > 0 ? 'text-primary' : ''}`}
                  disabled={offset === 0}
                  onClick={() => {
                    setOffset((p) => p - FIRST)
                  }}
                >
                  <i className='bi bi-arrow-left' style={{color: 'inherit'}} />
                </button>
                <span className='text-secondary'>
                  {offset / FIRST + 1}/
                  {total ? (
                    Math.ceil(total / FIRST)
                  ) : total == null ? (
                    <Spinner animation='grow' size='sm' />
                  ) : (
                    total
                  )}
                </span>
                <button
                  className={`m-0 p-0 border-0 bg-transparent ${
                    data.allLeads.pageInfo.hasNextPage ? 'text-primary' : ''
                  }`}
                  disabled={!data.allLeads.pageInfo.hasNextPage}
                  onClick={() => {
                    setOffset((p) => p + FIRST)
                  }}
                >
                  <i className='bi bi-arrow-right' style={{color: 'inherit'}} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {leads.map(({node}, idx) => (
          <Draggable key={node.id} draggableId={`${parseId(node.id)}`} index={idx}>
            {(provided, snapshot) => (
              <Card
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                onClick={() => {
                  setInfoLead({name: node.name, id: node.id})
                  onOpenEdit()
                }}
              >
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between w-100'>
                    <div className='ms-2 c-details'>
                      <h6 className='mb-0 text-capitalize' style={{maxWidth: '17ch'}}>
                        {node.name}
                      </h6>{' '}
                      <div className='d-flex flex-column'>
                        <span style={{fontSize: '10px'}}>Entered on:</span>{' '}
                        <span style={{fontSize: '10px'}}>
                          {moment(node.created).format('DD MMM YYYY HH:mm')}
                        </span>
                      </div>
                      <small style={{display: 'block'}} className='text-muted'>
                        <i className='bi bi-phone' />
                        {formatPhoneNumber(node.phone)}
                      </small>
                    </div>
                    {node.user.edges.length > 0 && (
                      <>
                        <div
                          style={{
                            padding: '3px 2px',
                            height: '50x',
                            backgroundColor: 'transparent',
                            borderRadius: '5px',
                            border: `1px solid ${
                              node.tagInsurance?.edges?.[0]?.node?.color || 'gray'
                            }`,
                            color: node.tagInsurance?.edges?.[0]?.node?.color || 'gray',
                            overflow: 'hidden',
                            maxWidth: '75px',
                            fontSize: '10px',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordSpacing: '1',
                          }}
                          data-tooltip-id={`${node.user.edges[0].node.firstName}-${idx}-${parseId(
                            node.id
                          )}`}
                          data-tooltip-content={`${
                            node.personalLead
                              ? '[Personal]'
                              : node.comercialLead
                              ? '[Commercial]'
                              : '-'
                          } - ${node.user.edges[0].node.firstName} ${
                            node.user.edges[0].node.lastName
                          }`}
                          /* title={`${
                            node.personalLead ? 'Personal' : node.comercialLead ? 'Commercial' : '-'
                          } - ${
                            node.user.edges[0].node.firstName
                          } ${node.user.edges[0].node.lastName[0]?.toUpperCase()}`} */
                        >
                          {node.user.edges[0].node.firstName}{' '}
                          {node.user.edges[0].node.lastName[0]?.toUpperCase()}
                        </div>
                        <Tooltip
                          id={`${node.user.edges[0].node.firstName}-${idx}-${parseId(node.id)}`}
                          place='right'
                        />
                      </>
                    )}
                  </div>
                </div>
                {node.tagInsurance.edges.length > 0 && (
                  <>
                    {stageName === STATUS_LEADS.Sold || stageName === STATUS_LEADS.Declined ? (
                      <hr style={{color: '#7c8392'}} />
                    ) : (
                      <div
                        className={getClassTraffic(new Date(node.created)).class}
                        title={getClassTraffic(new Date(node.created)).title}
                      ></div>
                    )}

                    <div className='d-flex align-items-md-center align-items-start justify-content-between gap-2 flex-column flex-md-row'>
                      <div className='d-flex align-items-center gap-2' style={{flexWrap: 'wrap'}}>
                        {node.tagInsurance.edges.map(({node: nodeTag}) => (
                          <div
                            style={{
                              padding: '3px 5px',
                              borderRadius: '30px',
                              backgroundColor: nodeTag.color,
                              color: 'white',
                              fontWeight: 'bolder',
                            }}
                            key={nodeTag.id}
                          >
                            {nodeTag.name}
                          </div>
                        ))}
                      </div>
                      {declinedStage &&
                        declinedStage.node.name === STATUS_LEADS.Declined &&
                        hasPermission(permissions, ALL_PERMISSIONS.PUEDE_ELIMINAR_LEADS) &&
                        stageName !== 'Declined' && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              setInfoLeadDead({
                                idDeadLead: node.id,
                                idNameLead: node.name,
                              })
                              onOpenDeadLead()
                            }}
                            className='fs-2 btn btn-danger btn-very-smaller'
                            variant='secondary'
                            style={{zIndex: 100}}
                            type='button'
                            id='button-addon1'
                          >
                            <i className='bi bi-trash' />
                          </Button>
                        )}
                      <button
                        style={{
                          flex: 0,
                          backgroundColor: 'transparent',
                          border: 0,
                          padding: 0,
                          margin: 0,
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (isRingIntegration) {
                            ;(window as any).RCAdapter.clickToCall(
                              formatPhoneNumber(node.phone),
                              true,
                              onOpenCall()
                            )
                          } else onOpenCall()

                          setIdLead(node.id)
                        }}
                      >
                        <Badge bg='success' pill className='fs-2 d-flex align-items-center gap-1'>
                          <i className='bi bi-telephone-plus' style={{color: 'inherit'}} />
                          {node.callCounter <= 10 ? node.callCounter : '10+'}
                        </Badge>
                      </button>
                    </div>
                  </>
                )}
              </Card>
            )}
          </Draggable>
        ))}
        {leads.length === 0 && (
          <Draggable
            draggableId='none-23231'
            index={-1}
            isDragDisabled
            disableInteractiveElementBlocking
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              >
                <Card.Text>This stage does not contain any LEAD assigned</Card.Text>
                <div className='d-flex align-items-center justify-content-center w-100'>
                  <div style={{maxWidth: '200px'}}>
                    <Lottie animationData={noLeads} autoPlay style={{maxWidth: '200px'}} />
                  </div>
                </div>
              </div>
            )}
          </Draggable>
        )}
        {providedParent.placeholder}
      </Card.Body>

      {idLead && (
        <ModalSumCall id={idLead} isOpen={isOpenCall} onClose={onCloseCall} refetch={refetch} />
      )}
      {isOpenDeadLead && (
        <ModalDeadLead
          isOpen={isOpenDeadLead}
          onClose={() => {
            setInfoLeadDead({
              idDeadLead: '',
              idNameLead: '',
            })

            onCloseDeadLead()
          }}
          info={{id: infoLeadDead.idDeadLead, name: infoLeadDead.idNameLead}}
        />
      )}
    </>
  )
}
