import {gql} from '@apollo/client'

export const GET_ROLES_GROUP = gql`
  query allGroups($offset: Int, $first: Int) {
    allGroups(offset: $offset, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
