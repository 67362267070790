import {Accordion, Badge, Button, Card, Col, Form, Modal, Row, Table} from 'react-bootstrap'
import {Node} from '../../../types/policiesApi'
import moment from 'moment'
import {InputCurrency} from '../../../components/InputCurrency'
import {Link} from 'react-router-dom'
import {parseId} from '../../../helpers'

const METALS_INFO = {
  '5': 'Platinum',
  '4': 'Gold',
  '3': 'Silver',
  '2': 'Bronze',
  '1': 'Catastrophic',
}

export const ModalPreview: React.FC<{
  onClose: () => void
  isOpen: boolean
  info: Node
  isQuote?: boolean
}> = ({info, isOpen, onClose, isQuote}) => {
  return (
    <Modal show={isOpen} onHide={onClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>{isQuote ? 'Quote' : 'Policy'} preview</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{backgroundColor: '#f2f3f8'}}>
        <Card>
          <Card.Body>
            <Row>
              <Col sm={12} md={8}>
                <div className='kt-heading kt-heading--md custom-border-brand'>
                  Specific information
                </div>
                <Row>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>Policy Name</Form.Label>
                      <Form.Control defaultValue={info.policyName} readOnly />
                    </Form.Group>
                  </Col>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>Type Policy</Form.Label>
                      <Form.Select defaultValue={info.typePolicy.typePolicy}>
                        <option value={info.typePolicy.typePolicy}>
                          {info.typePolicy.typePolicy}
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>Type of Sale</Form.Label>
                      <Form.Select defaultValue={info.typeSale}>
                        <option value={info.typeSale}>{info.typeSale}</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>Plan Name</Form.Label>
                      <Form.Control defaultValue={info.planName} readOnly />
                    </Form.Group>
                  </Col>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>Metal Level</Form.Label>
                      <Form.Select defaultValue={info.metalLevel}>
                        <option value={METALS_INFO[info.metalLevel]}>
                          {METALS_INFO[info.metalLevel]}
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>FFM</Form.Label>
                      <Form.Control defaultValue={info.FFM} />
                    </Form.Group>
                  </Col>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>NPN</Form.Label>
                      <Form.Control defaultValue={info.NPN} />
                    </Form.Group>
                  </Col>
                  <Col className='mb-4'>
                    <Form.Group>
                      <Form.Label>Premium</Form.Label>
                      <InputCurrency readOnly defaultValue={info.premium} />
                    </Form.Group>
                  </Col>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>Marketplace ID</Form.Label>
                      <Form.Control readOnly defaultValue={info.marketplaceId} />
                    </Form.Group>
                  </Col>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>CMS Plan</Form.Label>
                      <Form.Control readOnly defaultValue={info.CMSPlanId} />
                    </Form.Group>
                  </Col>

                  <Col className='mb-4' md={12}>
                    <Form.Group>
                      <Form.Label>Policy Total Cost</Form.Label>
                      <InputCurrency readOnly defaultValue={info.policyTotalCost} />
                    </Form.Group>
                  </Col>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>Percentage Value</Form.Label>
                      <Form.Control readOnly defaultValue={info.percentageValue} />
                    </Form.Group>
                  </Col>
                  <Col className='mb-4' md={6}>
                    <Form.Group>
                      <Form.Label>Nominal Value</Form.Label>
                      <InputCurrency defaultValue={info.nominalValue} readOnly />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={4}>
                <div className='kt-heading kt-heading--md custom-border-brand mb-2'>
                  Basic information
                </div>
                <div>
                  <Accordion defaultActiveKey='0'>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Policy information</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className='mt-2'>
                          <Form.Label>Status Documents</Form.Label>
                          <Form.Select defaultValue={info.stateDocs}>
                            <option value={info.stateDocs}>{info.stateDocs}</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className='mt-2'>
                          <Form.Label>Status Payment</Form.Label>
                          <Form.Select defaultValue={info.paymentStatus}>
                            <option>{info.paymentStatus}</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className='mt-2'>
                          <Form.Label>Effective date</Form.Label>
                          <Form.Control
                            type='date'
                            defaultValue={new Date(info.effectiveDate).toISOString().split('T')[0]}
                            readOnly
                          />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                          <Form.Label>Carrier</Form.Label>
                          <Form.Select defaultValue={info.carrier.name}>
                            <option value={info.carrier.name}>{info.carrier.name}</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className='mt-2'>
                          <Form.Label>States</Form.Label>
                          <Form.Select defaultValue={info.stateEU.name}>
                            <option value={info.stateEU.name}>{info.stateEU.name}</option>
                          </Form.Select>
                        </Form.Group>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* <Card>
          <Card.Body>
            <Row>
              <Col md={4} lg={3} sm={6}>
                <Form.Label>Product type:</Form.Label>
                <p>N/R</p>
              </Col>
              <Col md={4} lg={3} sm={6}>
                <Form.Label>Carrier:</Form.Label>
                <p>{info.carrier ? info.carrier.name : 'N/R'}</p>
              </Col>
              <Col md={4} lg={3} sm={6}>
                <Form.Label>Plan:</Form.Label>
                <p>{info.planName}</p>
              </Col>
              <Col md={4} lg={3} sm={6}>
                <Form.Label>Plan ID:</Form.Label>
                <p>PLAN_ID</p>
              </Col>
              <Col md={4} lg={3} sm={6}>
                <Form.Label>Member ID:</Form.Label>
                <p>{info.memberId || 'N/R'}</p>
              </Col>
              <Col md={4} lg={3} sm={6}>
                <Form.Label>Monthly premium:</Form.Label>
                <p>---</p>
              </Col>
              <Col md={4} lg={3} sm={6}>
                <Form.Label>Effective date:</Form.Label>
                <p>{moment(info.effectiveDate).format('DD MMM, YY')}</p>
              </Col>
              <Col md={4} lg={3} sm={6}>
                <Form.Label>Cancellation date:</Form.Label>
                <p>---</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className='mt-4'>
          <Card.Header>
            <Card.Title>Addresses</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={4}>
                <Form.Label>Physical address</Form.Label>
                <p>...</p>
              </Col>
              <Col md={4}>
                <Form.Label>Mailing address</Form.Label>
                <p>...</p>
              </Col>
            </Row>
          </Card.Body>
        </Card> */}
        {!isQuote && (
          <Card className='mt-4'>
            <Card.Header>
              <Card.Title>Members</Card.Title>
            </Card.Header>
            <Card.Body>
              {/* <Row className='my-4'>
              <Col md={6}>
                <Form.Label>Total applicants:</Form.Label>
                <p>{info.applicants}</p>
              </Col>
              <Col md={4}>
                <Form.Label>Total Members:</Form.Label>
                <p>{info.members}</p>
              </Col> 
              <Col md={6}>
                <Form.Label>Total dependents:</Form.Label>
                <p>{info.dependents}</p>
              </Col>
            </Row> */}
              <Table>
                <thead>
                  <tr style={{backgroundColor: '#ebedf2'}}>
                    <th className='ps-2'>Applicant</th>
                    <th>Role</th>
                    <th>Member</th>
                    <th>Date of birth </th>
                    <th>Immigration</th>
                  </tr>
                </thead>
                <tbody>
                  {info.memberPolicySet.edges.map(({node}) => (
                    <tr key={node.id}>
                      <td className='ps-2'>
                        <Badge bg={node.applicant ? 'success' : 'danger'}>
                          {node.applicant ? 'Yes' : 'No'}{' '}
                        </Badge>
                      </td>
                      <td>{node.rol}</td>
                      <td>
                        {node.firstName} {node.middleName} {node.lastName} {node.secondLastName}
                      </td>
                      <td>{moment(node.dateBirth).format('DD MMM, YY')}</td>
                      <td>{node.immigrationStatus || 'N/R'}</td>
                    </tr>
                  ))}
                  {info.memberPolicySet.edges.length === 0 && (
                    <tr>
                      <td colSpan={5}>
                        <div className='d-flex flex-center' style={{height: '120px'}}>
                          <p className='text-center'>There is not members</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
        <Link
          className='btn btn-primary'
          to={isQuote ? `/one-quote/${parseId(info.id)}` : `/sold?policy=${parseId(info.id)}`}
        >
          View {isQuote ? 'quote' : 'policy'}
        </Link>
      </Modal.Footer>
    </Modal>
  )
}
