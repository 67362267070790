import {Card, Spinner} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {useQuery} from '@apollo/client'
import {GET_ALL_REASON_LOSS} from '../../gql/queries/reasonLossQuery'
import {IResAllReasons} from '../../types/reasonLoss'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {ModalAddReason} from './components/ModalAddReason'
import {useDisclourse} from '../../hooks/useDisclourse'
import {COLORS} from '../../helpers/consts'

export const LeadLossReasonPage = () => {
  const {data, loading, error, refetch} = useQuery<IResAllReasons>(GET_ALL_REASON_LOSS, {
    fetchPolicy: 'no-cache',
  })

  const {isOpen, onClose, onOpen} = useDisclourse()
  const [infoEdit, setInfoEdit] = useState<null | {reason: string; id: string}>(null)

  useEffect(() => {
    if (error) {
      toast.error('Error: ' + error.message)
    }
  }, [error])

  const getColor = (idx: number) => {
    const i = idx % COLORS.length

    return COLORS[i]
  }

  if (!data || loading)
    return (
      <div>
        <Card>
          <Card.Header className='border-0'>
            <div className='d-flex align-items-center justify-content-between w-100'>
              <h2 className='py-4'>Lead Loss Reasons</h2>
              <button
                className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'
                title='Add Reason'
              >
                <i className='bi bi-plus fw-bold m-0 p-0 fs-1' />
              </button>
            </div>
          </Card.Header>
          <Card.Body className='pt-0'>
            <div
              className='d-flex align-items-center justify-content-center'
              style={{height: '40vh'}}
            >
              <Spinner animation='border' />
            </div>
          </Card.Body>
        </Card>
      </div>
    )

  return (
    <>
      <div>
        <Card>
          <Card.Header className='border-0'>
            <div className='d-flex align-items-center justify-content-between w-100'>
              <h2 className='py-4'>Lead Loss Reasons</h2>
              <button
                className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'
                title='Add Reason'
                onClick={() => {
                  onOpen()
                  setInfoEdit(null)
                }}
              >
                <i className='bi bi-plus fw-bold m-0 p-0 fs-1' />
              </button>
            </div>
          </Card.Header>
          <Card.Body className='pt-0'>
            {data.allReasonLeadLoss.edges.length > 0 ? (
              <>
                {data.allReasonLeadLoss.edges.map(({node}, i) => (
                  <div
                    key={node.id}
                    className={`d-flex align-items-center bg-light-${getColor(i)} rounded p-5 mb-7`}
                  >
                    {/* begin::Icon */}
                    <span className={`text-${getColor(i)} me-5`}>
                      <KTIcon iconName='information' className={`text-${getColor(i)} fs-1 me-5`} />
                    </span>
                    {/* end::Icon */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                      <h5 className='fw-bold text-gray-800 fs-6'>{node.reason}</h5>
                      <span className=' fw-semibold d-block'>
                        <span>Created: </span>
                        <span className='text-muted'>
                          {moment(node.created).format('DD MMM YYYY')}
                        </span>
                      </span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    <button
                      className={`bg-transparent border-0 fw-bold text-${getColor(i)} py-1`}
                      onClick={() => {
                        onOpen()
                        setInfoEdit({
                          id: node.id,
                          reason: node.reason,
                        })
                      }}
                    >
                      <i className={`bi bi-pencil fs-2 text-${getColor(i)}`} />
                    </button>
                    {/* end::Lable */}
                  </div>
                ))}
              </>
            ) : (
              <div
                className='d-flex align-items-center justify-content-center w-100'
                style={{height: '30vh'}}
              >
                <h3>
                  <i className='bi bi-exclamation-octagon fs-3 me-1' style={{color: 'inherit'}} />
                  There are no reasons created
                </h3>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>

      {isOpen && (
        <ModalAddReason isOpen={isOpen} onClose={onClose} refetch={refetch} infoEdit={infoEdit} />
      )}
    </>
  )
}
