import {Link, useNavigate, useParams} from 'react-router-dom'

import {useEffect, useState} from 'react'
import {useLazyQuery} from '@apollo/client'
import {GET_ONE_LEAD, GET_REQUEST_CONSENT_LASTED} from '../../gql/queries/leadsQuery'
import {IResOneLead} from '../leads/leadTypes'
import {toast} from 'react-toastify'
import {Alert, Badge, Button, Card, Col, Dropdown, ListGroup, Row} from 'react-bootstrap'
import {LoadingOneLead} from './components/LoadingOneLead'
import {StagesWizard} from './components/StagesWizard'
import {CardRightInfo} from './components/CardRightInfo'
import {DrawerEditLead} from '../leads/components/DrawerEditLead'
import {useDisclourse} from '../../hooks/useDisclourse'
import {CardInfoPersonalCommercial} from './components/CardInfoPersonalCommercial'
import {ModalAddQoute} from './components/ModalAddQoute'
import {ModalDeadLead} from './components/ModalDeadLead'
import {useGetUser} from '../../store/userStore'
import {hasPermission} from '../../helpers'
import {ALL_PERMISSIONS} from '../../helpers/consts'
import {ModalSumCall} from './components/ModalSumCall'
import {ModalRequestCons} from './components/ModalRequestCons'
import {TimeLineRequestConset} from './components/TimeLineRequestConset'
import {NodeDocusign} from '../../types/leads'
import {ModalAddPolice} from '../policies/components/ModalAddPolice'

export const OneLeadPage = () => {
  const permissions = useGetUser((s) => s.user?.permissions)
  const [gqlGetLead, {data, loading, error, refetch}] = useLazyQuery<IResOneLead>(GET_ONE_LEAD, {
    fetchPolicy: 'no-cache',
  })
  const [gqlGetLastedReq, {data: dataLatestReq}] = useLazyQuery<{
    latestDocusignEventsByLead: NodeDocusign[]
  }>(GET_REQUEST_CONSENT_LASTED)

  const [infoEdit, setInfoEdit] = useState<null | {id: string; name: string}>(null)
  const [infoAddQuote, setInfoAddQuote] = useState<null | {
    id: string
    dateBirthLead: string | null | undefined
  }>(null)

  const [alertShow, setAlertShow] = useState<string | null>(null)

  const navigate = useNavigate()
  const {id} = useParams()

  const {isOpen, onClose, onOpen} = useDisclourse()
  const {isOpen: isOpenAddQuote, onClose: onCloseAddQuote, onOpen: onOpenAddQuote} = useDisclourse()
  const {isOpen: isOpenDeadLead, onClose: onCloseDeadLead, onOpen: onOpenDeadLead} = useDisclourse()
  const {isOpen: isOpenSumCall, onClose: onCloseSumCall, onOpen: onOpenSumCall} = useDisclourse()
  const {
    isOpen: isOpenRequestCons,
    onClose: onCloseRequestCons,
    onOpen: onOpenRequestCons,
  } = useDisclourse()
  const {
    isOpen: isOpenAddPolicy,
    onClose: onCloseAddPolicy,
    onOpen: onOpenAddPolicy,
  } = useDisclourse()

  useEffect(() => {
    if (!id) {
      return navigate('/')
    }
    if (Number(id)) {
      gqlGetLead({
        variables: {
          id,
        },
      })
      return
    }
    navigate('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (!data || !data.leadById) return
    gqlGetLastedReq({
      variables: {
        leadId: Number(data.leadById.id),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!dataLatestReq || !dataLatestReq.latestDocusignEventsByLead) return
    if (dataLatestReq.latestDocusignEventsByLead.length === 0) {
      setAlertShow('This lead has not requested a consent signature')
      return
    }

    const filters = dataLatestReq.latestDocusignEventsByLead.filter(
      (last) => last.event === 'envelope-completed'
    )
    setAlertShow(
      `For this lead, the signing of ${dataLatestReq.latestDocusignEventsByLead.length} document(s) has been requested. ${filters.length} of them has been completed`
    )
  }, [dataLatestReq])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data || !permissions) {
    return <LoadingOneLead />
  }

  if (data.leadById == null) {
    return <div>No data</div>
  }

  return (
    <div>
      <header>
        <div className='d-flex align-items-center gap-3'>
          <div>
            <Link to='/leads' className='d-flex align-items-center gap-2'>
              <i className='bi bi-chevron-left text-primary fs-3' />
              Pipelines
            </Link>
          </div>
          <div
            className='d-flex flex-column w-100 py-1 ps-3'
            style={{borderLeft: '1px solid #e9e9e9'}}
          >
            <div>
              <h2 className='text-uppercase'>{data.leadById.name}</h2>
            </div>
            <div className='d-flex algin-items-center flex-column flex-md-row gap-3 text-muted'>
              <span>
                <i className='bi bi-envelope' /> {data.leadById.email}
              </span>
              <span className='ps-3' style={{borderLeft: '1px solid #c9c9c9'}}>
                <i className='bi bi-phone-vibrate' /> {data.leadById.phone}
              </span>
              <span className='ps-3' style={{borderLeft: '1px solid #c9c9c9'}}>
                Identifier: {data.leadById.id}
              </span>
            </div>
          </div>
          <div>
            <div style={{flex: 0}}>
              <Badge bg='success' pill className='fs-2 d-flex align-items-center gap-1'>
                <i className='bi bi-telephone-plus' style={{color: 'inherit'}} />
                {data.leadById.callCounter <= 10 ? data.leadById.callCounter : '10+'}
              </Badge>
            </div>
          </div>
        </div>

        <Row>
          <Col lg={6} />
          <Col
            lg={6}
            className='mt-4 d-block d-md-flex algin-items-center justify-content-start justify-content-md-end'
          >
            <div className='d-flex algin-items-md-center algin-items-start gap-3 flex-column flex-md-row'>
              <Button
                /* to={`/sold?lead=${data.leadById.id}`} */ variant='primary'
                onClick={onOpenAddPolicy}
              >
                <i className='bi bi-check-lg fs-2' />
                Sold
              </Button>
              {hasPermission(permissions, ALL_PERMISSIONS.PUEDE_ELIMINAR_LEADS) && (
                <Button variant='danger' onClick={onOpenDeadLead}>
                  <i className='bi bi-x-lg fs-2' />
                  Dead
                </Button>
              )}
              <Dropdown>
                <Dropdown.Toggle variant='white' className='border' id='dropdown-basic'>
                  Actions
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      onOpenAddQuote()
                      setInfoAddQuote({
                        id: data.leadById.id,
                        dateBirthLead: data.leadById.personalLead?.birthDay,
                      })
                    }}
                    className='d-flex align-items-center gap-1'
                  >
                    <i className='bi bi-card-checklist fs-4' /> Add Quote
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      onOpenSumCall()
                    }}
                    className='d-flex align-items-center gap-1'
                  >
                    <i className='bi bi-telephone-plus fs-4' /> Sum Call
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      onOpenRequestCons()
                    }}
                    className='d-flex align-items-center gap-1'
                  >
                    <i className='bi bi-vector-pen fs-4' />
                    Request Consent
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {hasPermission(permissions, ALL_PERMISSIONS.PUEDE_EDITAR_LEADS) && (
                <div className='d-flex align-items-center justify-content-center'>
                  <button
                    style={{
                      border: 0,
                      padding: '0px 10px',
                      borderRadius: '15px',
                      width: '85px',
                      height: '30px',
                      color: '#fff',
                      backgroundColor: '#0062cc',
                      borderColor: '#005cbf',
                    }}
                    className='d-flex align-items-center gap-2 justify-content-center'
                    onClick={() => {
                      setInfoEdit({id: data.leadById.id, name: data.leadById.name})
                      onOpen()
                    }}
                  >
                    <i className='bi bi-pencil text-white' />
                    Edit
                  </button>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <StagesWizard
          stagePipelineId={data.leadById.stagePipeline.id}
          stages={data.leadById?.stagePipeline?.pipeline?.stagePipelineSet?.edges}
          refetch={refetch}
          leadId={data.leadById.id}
        />
      </header>
      {alertShow && (
        <div className=''>
          <Alert variant='info' onClose={() => setAlertShow(null)} dismissible>
            {alertShow}
          </Alert>
        </div>
      )}
      <div className='pt-20'>
        <Row>
          <Col lg={5}>
            <div className='d-flex flex-column gap-4'>
              <Card>
                <Card.Header>
                  <Card.Title>Lead Information</Card.Title>
                </Card.Header>
                <ListGroup variant='flush' className='px-5 py-3'>
                  <ListGroup.Item className='d-flex align-items-center justify-content-between py-4'>
                    <div className='text-muted'>Identifier</div>
                    <div className='fw-bold'>{data.leadById.id}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex align-items-center justify-content-between py-4'>
                    <div className='text-muted'>Name</div>
                    <div className='fw-bold'>{data.leadById.name}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex align-items-center justify-content-between py-4'>
                    <div className='text-muted'>Phone</div>
                    <div className='fw-bold'>{data.leadById.phone}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex align-items-center justify-content-between py-4'>
                    <div className='text-muted'>Email</div>
                    <div className='fw-bold'>{data.leadById.email}</div>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex align-items-center justify-content-between py-4'>
                    <div className='text-muted'>Lead Source</div>
                    <div className='fw-bold'>{data.leadById.leadSource.name}</div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>

              <Card>
                <Card.Header>
                  <Card.Title>Tags</Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className='d-flex align-items-center gap-2' style={{flexWrap: 'wrap'}}>
                    {data.leadById.tagInsurance.edges.map(({node}) => (
                      <div
                        style={{
                          padding: '3px 8px',
                          borderRadius: '30px',
                          backgroundColor: node.color,
                          color: 'white',
                          fontWeight: 'bolder',
                        }}
                        key={node.id}
                      >
                        {node.name}
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header>
                  <Card.Title>Members</Card.Title>
                </Card.Header>
                <Card.Body style={{maxHeight: '200px', overflowY: 'auto'}}>
                  <div className='d-flex flex-column gap-3'>
                    {data.leadById.user.edges.map(({node}) => (
                      <div
                        key={node.id}
                        style={{borderRadius: '12px', backgroundColor: '#f4f7f9'}}
                        className='px-4 py-5 d-flex align-items-start gap-4'
                      >
                        <div
                          style={{
                            height: '40px',
                            width: '40px',
                            backgroundColor: 'blue',
                            borderRadius: '100%',
                          }}
                          className='d-flex align-items-center justify-content-center'
                        >
                          <i className='bi bi-person-circle fs-1 text-white' />
                        </div>
                        <div>
                          <h6 className='text-primary'>
                            {node.firstName} {node.lastName}
                          </h6>
                          <div className='d-flex flex-column m-0'>
                            <div className='d-flex align-items-center gap-1'>
                              <i className='bi bi-envelope' />
                              {node.email}
                            </div>
                            <div className='d-flex align-items-center gap-1'>
                              <i className='bi bi-phone-vibrate' />
                              {node.cellphone}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {data.leadById.user.edges.length === 0 && (
                      <div
                        style={{borderRadius: '12px', backgroundColor: '#f4f7f9'}}
                        className='px-4 py-5 d-flex align-items-start gap-4'
                      >
                        <div
                          style={{
                            height: '40px',
                            width: '40px',
                            backgroundColor: 'blue',
                            borderRadius: '100%',
                          }}
                          className='d-flex align-items-center justify-content-center'
                        >
                          <i className='bi bi-exclamation fs-1 text-white' />
                        </div>
                        <div>
                          <h6 className='text-primary'>There are no members</h6>
                          <div className='d-flex flex-column m-0'>
                            <div className='d-flex align-items-center gap-1 text-muted'>
                              This lead does not have registered members
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col lg={7}>
            <div className='mb-4'>
              <CardInfoPersonalCommercial
                leadId={data.leadById.id}
                commercial={data.leadById.comercialLead}
                personal={data.leadById.personalLead}
              />
            </div>
            <div className='mb-4'>
              <CardRightInfo lead={data.leadById} refetch={refetch} />
            </div>
            <div>
              <Card>
                <Card.Header>
                  <Card.Title>Consent Requests</Card.Title>
                </Card.Header>
                <Card.Body>
                  <TimeLineRequestConset leadId={data.leadById.id} />
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </div>

      {infoEdit && (
        <DrawerEditLead
          info={infoEdit}
          isOpen={isOpen}
          onClose={onClose}
          updateListLeads={() => {
            refetch()
          }}
        />
      )}

      {infoAddQuote && (
        <ModalAddQoute isOpen={isOpenAddQuote} info={infoAddQuote} onClose={onCloseAddQuote} />
      )}

      {isOpenDeadLead && (
        <ModalDeadLead
          isOpen={isOpenDeadLead}
          onClose={onCloseDeadLead}
          info={{id: data.leadById.id, name: data.leadById.name}}
        />
      )}

      {isOpenSumCall && (
        <ModalSumCall
          isOpen={isOpenSumCall}
          onClose={onCloseSumCall}
          id={data.leadById.id}
          refetch={refetch}
        />
      )}
      {isOpenRequestCons && (
        <ModalRequestCons
          isOpen={isOpenRequestCons}
          info={{
            email: data.leadById.email,
            id: data.leadById.id,
            name: data.leadById.name,
          }}
          onClose={onCloseRequestCons}
          refetch={refetch}
        />
      )}
      {isOpenAddPolicy && (
        <ModalAddPolice
          isOpen={isOpenAddPolicy}
          onClose={onCloseAddPolicy}
          refetch={refetch}
          leadId={data.leadById.id}
        />
      )}
    </div>
  )
}
