import {useMutation} from '@apollo/client'
import React, {useEffect} from 'react'
import {Button, Form, Modal, Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {parseId} from '../../helpers'
import {ADD_EDIT_STAGE} from '../../gql/mutations/pipelineMutations'
import {IResAddStage} from '../../types/pipelines'

interface IModalAddStages {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  infoEdit?: null | {id: string; name: string; order: number}
  pipelineId: string
  maxOrder: number
}

export const ModalAddStage: React.FC<IModalAddStages> = ({
  isOpen,
  onClose,
  refetch,
  infoEdit,
  pipelineId,
  maxOrder,
}) => {
  const [gqlAddEditStage, {data, loading, error}] = useMutation<IResAddStage>(ADD_EDIT_STAGE)

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.stagePipelineCreateupdateMutation?.stagePipeline?.id) {
      toast.success(`Success: Stage ${infoEdit ? 'edited correctly' : 'successfully added'}`)
      onClose()
      refetch()
    } else if (data.stagePipelineCreateupdateMutation?.errors?.length > 0) {
      toast.error(
        `Error: ${
          data.stagePipelineCreateupdateMutation?.errors?.[0]?.messages?.[0] || 'Something happened'
        }`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const {name} = Object.fromEntries(new FormData(e.currentTarget))
    gqlAddEditStage({
      variables: {
        input: {
          id: infoEdit ? parseId(infoEdit.id) : undefined,
          name,
          pipeline: pipelineId,
          order: infoEdit ? infoEdit.order : maxOrder + 1,
        },
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{infoEdit ? 'Edit' : 'Add'} Stage</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
            <Form.Label>Stage name</Form.Label>
            <Form.Control
              type='name'
              name='name'
              defaultValue={infoEdit ? infoEdit.name : ''}
              required
              placeholder='Enter name'
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' variant='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button
            type='submit'
            variant='primary'
            className='d-flex align-items-center'
            disabled={loading}
          >
            {loading && <Spinner animation='border' size='sm' />}
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
