import React from 'react'
import {Table, Spinner, Col, Form, Pagination, Row, Badge, Button} from 'react-bootstrap'
import {IEdgesStagePipe, IInfoLead, IResAllListLead} from '../leadTypes'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import Lottie from 'lottie-react'
import noDataLottie from '../../../assets/lotties/no-data.json'
import {GET_ALL_LIST_LEADS} from '../../../gql/queries/leadsQuery'
import moment from 'moment'
import {useLazyQuery} from '@apollo/client'
import {formatPhoneNumber, hasPermission, parseId} from '../../../helpers'
import {useDisclourse} from '../../../hooks/useDisclourse'
import {ModalSumCall} from '../../oneLead/components/ModalSumCall'
import {ALL_PERMISSIONS} from '../../../helpers/consts'
import {ModalDeadLead} from '../../oneLead/components/ModalDeadLead'
import {getClassTraffic, STATUS_LEADS} from '../LeadsPage'
// import {toast} from 'react-toastify'

interface ContentListLeadsProps {
  pipelineId: string
  onOpenEdit: () => void
  setInfoLead: Dispatch<SetStateAction<IInfoLead | null>>
  updateListLeads: () => void
  flagLeads: number
  setLoadingState: (b: boolean) => void
  statePipeline?: string | null
  searchName?: string
  searchPhone?: string
  valueUser?: string | null
  datesFrom?: Date | null
  datesTo?: Date | null
  permissions: string[]
  pageRefresh: number
  isRingIntegration: boolean
  stagesDeclined?: IEdgesStagePipe | null
  leadsSelected?: {id: string; name: string; stage: string; user: string}[] | undefined
  setLeadsSelected: (l: {id: string; name: string; stage: string; user: string}[]) => void
}

export const ContentListLeads: React.FC<ContentListLeadsProps> = ({
  pipelineId,
  onOpenEdit,
  setInfoLead,
  updateListLeads,
  flagLeads,
  setLoadingState,
  statePipeline,
  searchName,
  searchPhone,
  valueUser,
  datesFrom,
  datesTo,
  permissions,
  pageRefresh,
  isRingIntegration,
  stagesDeclined,
  leadsSelected,
  setLeadsSelected,
}) => {
  const [first, setFirst] = useState(20)
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(1)

  const [gqlGetLeads, {data: allDataLeads, loading: loadingChange, refetch}] =
    useLazyQuery<IResAllListLead>(GET_ALL_LIST_LEADS, {fetchPolicy: 'no-cache'})

  const {isOpen: isOpenDeadLead, onClose: onCloseDeadLead, onOpen: onOpenDeadLead} = useDisclourse()
  const {isOpen: isOpenCall, onClose: onCloseCall, onOpen: onOpenCall} = useDisclourse()
  const [idLead, setIdLead] = useState<null | string>(null)

  const [infoLeadDead, setInfoLeadDead] = useState({
    idDeadLead: '',
    idNameLead: '',
  })
  // const [selected, setSelected] = useState<
  //   {id: string; name: string; stage: string; user: string}[]
  // >([])

  const isAllSelected = leadsSelected?.length === allDataLeads?.allLeads?.edges?.length
  // .filter(
  //   (e) => ![STATUS_LEADS.Declined, STATUS_LEADS.Sold].includes(e.node.stagePipeline.name)
  // ).length

  const toggleAll = () => {
    if (isAllSelected) {
      setLeadsSelected([])
    } else {
      if (allDataLeads?.allLeads?.edges?.length) {
        const allIds = allDataLeads.allLeads.edges
          // .filter(
          //   (e) => ![STATUS_LEADS.Declined, STATUS_LEADS.Sold].includes(e.node.stagePipeline.name)
          // )
          .map((edge) => ({
            id: edge.node.id,
            name: edge.node.name,
            stage: edge.node.stagePipeline.name,
            user: edge.node.user?.edges
              ? edge.node.user?.edges[0]?.node?.firstName ??
                '' + ' ' + edge.node.user?.edges[0]?.node?.lastName ??
                ''
              : '',
          }))
        setLeadsSelected(allIds)
      }
    }
  }
  const toggleOne = (id: string) => {
    if (!id || !leadsSelected) return

    const exists = leadsSelected.find((x) => x.id === id)

    if (exists) {
      setLeadsSelected(leadsSelected.filter((x) => x.id !== id))
    } else {
      const found = allDataLeads?.allLeads?.edges.find((e) => e.node?.id === id)

      if (found?.node) {
        setLeadsSelected([
          ...leadsSelected,
          {
            id: found.node.id,
            name: found.node.name,
            stage: found.node.stagePipeline?.name ?? '',
            user: found.node.user?.edges?.[0]?.node
              ? `${found.node.user.edges[0].node.firstName} ${found.node.user.edges[0].node.lastName}`
              : '',
          },
        ])
      }
    }
  }

  // useEffect(() => {
  //   setLeadsSelected(selected ?? [])
  // }, [selected, setLeadsSelected])

  useEffect(() => {
    setOffset(0)
    setPage(1)
  }, [pageRefresh])

  useEffect(() => {
    if (!pipelineId) return
    const auxSt = parseId(statePipeline ?? '')
    gqlGetLeads({
      variables: {
        pipelineId: parseId(pipelineId),
        first: first,
        offset: offset,
        userId: parseId(valueUser ?? ''),
        stagePipelineId: (auxSt as number) > 0 ? (auxSt as number) : null,
        name_Icontains: searchName,
        phone_Icontains: searchPhone,
        fromDate: datesFrom != null ? moment(datesFrom).format('YYYY-MM-DD') : null,
        toDate: datesTo != null ? moment(datesTo).format('YYYY-MM-DD') : null,
      },
    })
  }, [
    gqlGetLeads,
    flagLeads,
    first,
    statePipeline,
    pipelineId,
    offset,
    datesFrom,
    datesTo,
    searchName,
    searchPhone,
    valueUser,
    page,
  ])

  useEffect(() => {
    setLoadingState(loadingChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingChange])

  const positionCursor = (cursor: string) => {
    if (cursor === null) return 0
    const au = parseId(cursor)
    if (typeof au === 'string') return parseInt(au) + 1
  }

  if (loadingChange || !allDataLeads) {
    return (
      <>
        <div style={{width: '100%', overflowX: 'auto'}}>
          <Row className='mt-5'>
            <Col sm={12} md={6} xl={4}>
              <div className='d-flex align-items-center gap-1'>
                <span style={{flex: 0}}>Show</span>
                <Form.Select
                  value={first}
                  onChange={(e) => {
                    setFirst(Number(e.target.value))
                    setPage(1)
                    setOffset(0)
                  }}
                  style={{flex: 0, flexBasis: '80px'}}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>50</option>
                </Form.Select>
                <span style={{flex: 1}}> entries</span>
              </div>
            </Col>
            <Col className='d-none d-xl-block' xl={4}>
              <div className='d-flex align-items-center justify-content-center h-100 w-100'>
                <Spinner animation='grow' size='sm' />
              </div>
            </Col>
            <Col sm={12} md={6} xl={4}>
              <div className='d-flex align-items-center justify-content-end'>
                <Pagination size='sm'>
                  <Pagination.Prev
                    disabled={offset === 0}
                    onClick={() => {
                      setOffset((p) => p - first)
                    }}
                  />
                  {loadingChange && (
                    <Pagination.Item>
                      <Spinner animation='grow' size='sm' />
                    </Pagination.Item>
                  )}
                  <Pagination.Next
                    disabled={!allDataLeads?.allLeads.pageInfo.hasNextPage}
                    onClick={() => {
                      setOffset((p) => p + first)
                    }}
                  />
                </Pagination>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{width: '100%', overflowX: 'auto'}}>
          <Table hover size='sm' className='compact dataTable no-footer dtr-inline'>
            <thead>
              <tr role='row'>
                <th className='fw-bold'>Name</th>
                {/* <th className='fw-bold'>Lead Source</th> */}
                <th className='fw-bold'>Entered on</th>
                <th className='fw-bold'>Phone</th>
                <th className='fw-bold'>Stage</th>
                <th className='fw-bold'>User</th>
                <th className='fw-bold'>Tag</th>
                <th className='fw-bold'>Calls</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={6} className='text-center'>
                  <Spinner animation='border' size='sm' />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    )
  }

  if (allDataLeads?.allLeads.edges.length === 0) {
    return (
      <div
        className='d-flex align-items-center justify-content-center w-100 flex-column'
        style={{height: '100%', minHeight: '400px'}}
      >
        <h4>This pipeline has no stages</h4>
        <div>
          <Lottie animationData={noDataLottie} autoplay style={{maxWidth: '400px'}} />
        </div>
      </div>
    )
  }

  let totalLeads = allDataLeads.totalLeads

  const filteredLeads = allDataLeads.allLeads.edges

  return (
    <>
      <div style={{width: '100%', overflowX: 'auto'}}>
        <Row className='mt-5'>
          <Col sm={12} md={6} xl={4}>
            <div className='d-flex align-items-center gap-1'>
              <span style={{flex: 0}}>Show</span>

              <Form.Select
                value={first}
                onChange={(e) => {
                  setFirst(Number(e.target.value))
                  setPage(1)
                  setOffset(0)
                }}
                style={{flex: 0, flexBasis: '80px'}}
              >
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>50</option>
              </Form.Select>

              <span style={{flex: 1}}> entries</span>
            </div>
          </Col>
          <Col className='d-none d-xl-block' xl={4}>
            <p className='d-flex align-items-center justify-content-center h-100 w-100'>
              {allDataLeads?.allLeads.pageInfo.startCursor !== null &&
              allDataLeads?.allLeads.pageInfo.endCursor !== null
                ? `Showing ${positionCursor(
                    allDataLeads?.allLeads.pageInfo.startCursor
                  )} to ${positionCursor(
                    allDataLeads?.allLeads.pageInfo.endCursor
                  )} of ${totalLeads} entries`
                : ''}
            </p>
          </Col>
          <Col sm={12} md={6} xl={4}>
            <div className='d-flex align-items-center justify-content-end'>
              <Pagination size='sm'>
                <Pagination.Prev
                  disabled={offset === 0}
                  onClick={() => {
                    setOffset((p) => p - first)
                  }}
                />
                {
                  <span className='d-flex align-items-center text-secondary'>
                    {offset / first + 1}/
                    {totalLeads ? (
                      Math.ceil(totalLeads / first)
                    ) : totalLeads == null ? (
                      <Spinner animation='grow' size='sm' />
                    ) : (
                      totalLeads
                    )}
                  </span>
                }
                {loadingChange && (
                  <Pagination.Item>
                    <Spinner animation='grow' size='sm' />
                  </Pagination.Item>
                )}
                <Pagination.Next
                  disabled={!allDataLeads?.allLeads.pageInfo.hasNextPage}
                  onClick={() => {
                    setOffset((p) => p + first)
                  }}
                />
              </Pagination>
            </div>
          </Col>
        </Row>
      </div>
      <div className='list-leads' style={{width: '100%', overflowX: 'auto'}}>
        <Table hover size='sm' className=' table table-striped table-hover  '>
          <thead>
            <tr role='row'>
              {permissions && hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_USUARIOS) && (
                <th className='td-lead-checbox'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={isAllSelected}
                    onChange={toggleAll}
                  />
                </th>
              )}
              <th className='fw-bold'>Name</th>
              {/* <th className='fw-bold'>Lead Source</th> */}
              <th className='fw-bold'>Entered on</th>
              <th className='fw-bold'>Phone</th>
              <th className='fw-bold'>Stage</th>
              <th className='fw-bold'>User</th>
              <th className='fw-bold'>Tag</th>
              <th className='fw-bold'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredLeads
              .sort((a, b) => a.node.stagePipeline.order - b.node.stagePipeline.order)
              .map((lead) => (
                <tr
                  className='cursor-pointer'
                  key={lead.node.id}
                  onClick={() => {
                    setInfoLead(lead.node)
                    onOpenEdit()
                  }}
                >
                  {permissions && hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_USUARIOS) && (
                    <td
                      className={getClassTraffic(new Date(lead.node.created)).class}
                      title={getClassTraffic(new Date(lead.node.created)).title}
                    >
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={leadsSelected?.some((item) => item.id === lead.node.id)}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        onChange={(e) => {
                          e.stopPropagation()
                          toggleOne(lead.node?.id)
                        }}
                      />
                    </td>
                  )}
                  {/* {lead.node.stagePipeline.name === STATUS_LEADS.Sold ||
                  lead.node.stagePipeline.name === STATUS_LEADS.Declined ? (
                    <td className='td-lead-declined'>{''}</td>
                  ) : (
                    <td
                      className={getClassTraffic(new Date(lead.node.created)).class}
                      title={getClassTraffic(new Date(lead.node.created)).title}
                    >
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={selected?.some((item) => item.id === lead.node.id)}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        onChange={(e) => {
                          e.stopPropagation()
                          toggleOne(lead.node.id)
                        }}
                      />
                    </td>
                  )} */}
                  {/* <td>
                    {lead.node.leadSource.name}{' '}
                    {lead.node.leadSource.name === 'Facebook' ? (
                      <i className='bi bi-facebook' style={{color: 'inherit'}} />
                    ) : lead.node.leadSource.name === 'Instagram' ? (
                      <i className='bi bi-instagram' style={{color: 'inherit'}} />
                    ) : lead.node.leadSource.name === 'Google' ? (
                      <i className='bi bi-google' style={{color: 'inherit'}} />
                    ) : lead.node.leadSource.name === 'WhatsApp' ? (
                      <i className='bi bi-whatsapp' style={{color: 'inherit'}} />
                    ) : (
                      ''
                    )}{' '}
                  </td> */}
                  <td>{lead.node.name}</td>
                  <td>{moment(lead.node.created).format('DD MMM YYYY HH:mm')}</td>
                  <td>{formatPhoneNumber(lead.node.phone)}</td>
                  <td>{lead.node?.stagePipeline?.name}</td>
                  <td>
                    {lead.node?.user?.edges[0]?.node.firstName}{' '}
                    {lead.node?.user?.edges[0]?.node.lastName}
                  </td>
                  <td>
                    <div className='d-flex align-items-center gap-2' style={{flexWrap: 'wrap'}}>
                      {lead.node.tagInsurance.edges.map(({node: nodeTag}) => (
                        // <div className='tag-insurance-list' key={nodeTag.id}>
                        <div
                          className='tag-insurance-list'
                          style={{
                            backgroundColor: nodeTag.color,
                            color: 'white',
                            fontWeight: 'bolder',
                          }}
                          key={nodeTag.id}
                        >
                          {nodeTag.name}
                        </div>
                      ))}
                    </div>
                    {/* {lead.node.tagInsurance.edges.toString()} */}
                  </td>
                  <td className='d-flex align-items-center gap-2'>
                    <button
                      style={{
                        flex: 0,
                        backgroundColor: 'transparent',
                        border: 0,
                        padding: 0,
                        margin: 0,
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (isRingIntegration) {
                          ;(window as any).RCAdapter.clickToCall(
                            formatPhoneNumber(lead.node.phone),
                            true,
                            onOpenCall()
                          )
                        } else onOpenCall()
                        setIdLead(lead.node.id)
                      }}
                    >
                      <Badge bg='success' pill className='fs-2 d-flex align-items-center gap-1'>
                        <i className='bi bi-telephone-plus' style={{color: 'inherit'}} />
                        {lead.node.callCounter <= 10 ? lead.node.callCounter : '10+'}
                      </Badge>
                    </button>
                    {stagesDeclined &&
                      stagesDeclined.node.name === STATUS_LEADS.Declined &&
                      hasPermission(permissions, ALL_PERMISSIONS.PUEDE_ELIMINAR_LEADS) &&
                      lead.node.stagePipeline.name !== STATUS_LEADS.Declined && (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation()
                            setInfoLeadDead({
                              idDeadLead: lead.node.id,
                              idNameLead: lead.node.name,
                            })
                            onOpenDeadLead()
                          }}
                          className='ms-2 btn btn-danger btn-very-smaller'
                          variant='danger'
                          style={{zIndex: 100}}
                          type='button'
                        >
                          <i className='bi bi-trash' />
                        </Button>
                      )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {filteredLeads.length === 0 && (
          <div className='text-center'>
            <Spinner animation='border' size='sm' />
            {/* <p>No leads found</p> */}
          </div>
        )}
      </div>
      {idLead && (
        <ModalSumCall id={idLead} isOpen={isOpenCall} onClose={onCloseCall} refetch={refetch} />
      )}
      {isOpenDeadLead && (
        <ModalDeadLead
          isOpen={isOpenDeadLead}
          onClose={() => {
            setInfoLeadDead({
              idDeadLead: '',
              idNameLead: '',
            })
            onCloseDeadLead()
            updateListLeads()
          }}
          info={{id: infoLeadDead.idDeadLead, name: infoLeadDead.idNameLead}}
        />
      )}
    </>
  )
}
