import {Alert} from 'react-bootstrap'
import {DeactivateAccount} from '../../../../modules/accounts/components/settings/cards/DeactivateAccount'
import {IAllMembersNode} from '../../../types/members'
import {ProfileDetailsSetting} from './ProfileDetailsSetting'
import {ChangePassword} from './ChangePassword'

interface ISettingProps {
  node: IAllMembersNode
  refetch: () => void
}

export const SettingsTab: React.FC<ISettingProps> = ({node, refetch}) => {
  return (
    <>
      <ProfileDetailsSetting node={node} refetch={refetch} />
      <Alert variant='danger' className='my-5'>
        <Alert.Heading className='d-flex flex-center gap-2'>
          <i className='bi bi-exclamation-triangle fs-1' style={{color: 'inherit'}} />
          Dangerous zone
        </Alert.Heading>
      </Alert>
      <ChangePassword id={node.id} refetch={refetch} />
      <DeactivateAccount />
    </>
  )
}
