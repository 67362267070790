import {useFormik} from 'formik'
import {Card, Dropdown, Spinner, SplitButton} from 'react-bootstrap'
import ReactQuill from 'react-quill'
import * as Yup from 'yup'
import {SEND_MAIL_STR} from '../../../gql/mutations/integrationsMutations'
import {useUploadFile} from '../../../hooks/useUploadFile'
import {useEffect} from 'react'
import {toast} from 'react-toastify'

interface ISendMsgProps {
  onBack: () => void
  ButtonMenu: JSX.Element
}

const MAX_SIZE = 1000000

const validationSchema = Yup.object().shape({
  toEmail: Yup.string().email().required(),
  subject: Yup.string().required(),
})

interface TUpload {
  errorMessage?: string
  success: boolean
}

export const SendMessageContent: React.FC<ISendMsgProps> = ({ButtonMenu}) => {
  const [uploadFile, {data, error, loading}] = useUploadFile<TUpload>()
  const formik = useFormik({
    initialValues: {
      toEmail: '',
      subject: '',
      message: '',
      attachments: [] as File[],
    },
    validationSchema,
    onSubmit: (v) => {
      const formdata = new FormData()
      const map = {}
      v.attachments.forEach((file, i) => {
        formdata.append(`${i}`, file, file.name)
        map[`${i}`] = [`variables.attachments.${i}`]
        // formdata.append('map', `{"${i}": ["variables.attachments"]}`)
      })
      const obj = {
        query: SEND_MAIL_STR,
        variables: {
          attachments: v.attachments.map(() => null),
          message: v.message,
          subject: v.subject,
          toEmail: v.toEmail,
        },
      }
      formdata.append('map', JSON.stringify(map))
      formdata.append('operations', JSON.stringify(obj))
      uploadFile(formdata)
    },
  })

  useEffect(() => {
    if (error) toast.error(`${error.toString()}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.errorMessage) toast.error(`Error: ${data.errorMessage}`)
  }, [data])

  const handleUploadFile = () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.style.display = 'none'

    // Adjuntar el input al documento
    document.body.appendChild(fileInput)

    // Simular clic en el input
    fileInput.click()

    // Cuando se seleccione un archivo, hacer algo con él
    fileInput.addEventListener('change', function () {
      const file = fileInput?.files?.[0]
      if (!file) return
      const preFiles = [...formik.values.attachments]

      const find = preFiles.find((f) => file.name === f.name)

      if (find) return

      formik.setFieldValue('attachments', [...preFiles, file])
    })

    // Eliminar el input después de seleccionar el archivo
    fileInput.remove()
  }

  return (
    <>
      <Card.Header>
        <Card.Title>Compose Message</Card.Title>
        <div className='card-title h5'>{ButtonMenu}</div>
      </Card.Header>
      <div
        className='d-flex align-items-center border-bottom px-8 min-h-50px'
        style={{position: 'relative'}}
      >
        To:
        <input
          name='toEmail'
          value={formik.values.toEmail}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type='text'
          className='position-absolute'
          style={{
            inset: 0,
            border: formik.errors.toEmail && formik.touched.toEmail ? '1px solid red' : 0,
            backgroundColor: 'transparent',
            paddingLeft: '50px',
          }}
        />
      </div>
      <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>Cc:</div>
      <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>Bcc:</div>
      <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
        <input
          type='text'
          name='subject'
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='Subject'
          className='w-100 h-100'
          style={{border: formik.errors.subject && formik.touched.subject ? '1px solid red' : 0}}
        />
      </div>
      <ReactQuill
        theme='snow'
        value={formik.values.message}
        onChange={(v) => formik.setFieldValue('message', v)}
        className='w-100'
        style={{border: 0}}
      />
      <Card.Body></Card.Body>
      {formik.values.attachments.length > 0 && (
        <div className='d-flex flex-wrap gap-2 px-3 my-2 mb-4'>
          {formik.values.attachments.map((file) => (
            <div
              key={file.name}
              className='p-0 d-flex gap-1 justify-content-between'
              style={{
                border: file.size > MAX_SIZE ? '2px dashed red' : '1px dashed #aaa',
                color: file.size > MAX_SIZE ? 'red' : undefined,
                borderRadius: '7px',
              }}
            >
              <p
                className='p-0 m-0 d-flex flex-center px-3'
                style={{
                  maxWidth: '230px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <span className='w-100'>{file.name}</span>
              </p>
              <button
                className='m-0 p-0'
                style={{
                  border: 0,
                  backgroundColor: '#ff7e7e',
                  borderTopRightRadius: '7px',
                  borderBottomRightRadius: '7px',
                }}
                onClick={() => {
                  const prevFiles = [...formik.values.attachments]
                  formik.setFieldValue(
                    'attachments',
                    prevFiles.filter((f) => f.name !== file.name)
                  )
                }}
              >
                <i className='bi bi-x fs-2' style={{color: 'white'}} />
              </button>
            </div>
          ))}
        </div>
      )}
      <Card.Footer>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-4'>
            <SplitButton
              id={`dropdown-split-variants-Primary`}
              onClick={(e) => formik.handleSubmit()}
              variant={'primary'}
              title={
                <div>
                  {loading && <Spinner animation='border' className='me-2' size='sm' />}
                  <span>Send</span>
                </div>
              }
              disabled={loading}
            >
              <Dropdown.Item eventKey='1'>Action</Dropdown.Item>
              <Dropdown.Item eventKey='2'>Another action</Dropdown.Item>
              <Dropdown.Item eventKey='3' active>
                Active Item
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey='4'>Separated link</Dropdown.Item>
            </SplitButton>

            <button style={{backgroundColor: 'transparent', border: 0}} onClick={handleUploadFile}>
              <span>
                <i className='bi bi-paperclip fs-2' />
              </span>
            </button>
          </div>
          <div>
            <button style={{backgroundColor: 'transparent', border: 0}}>
              <i className='bi bi-trash fs-2' />
            </button>
          </div>
        </div>
      </Card.Footer>
    </>
  )
}
