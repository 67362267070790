import {useEffect, useState} from 'react'
import {Badge, Button, Col, FloatingLabel, Form, Offcanvas, Row, Spinner} from 'react-bootstrap'
import {FormPersonalLead} from '../FormPersonalLead'
import {FormComercialLead} from '../FormComercialLead'
import Select, {MultiValue} from 'react-select'
import {IResCatLeadSource} from '../../../leadSource/leadSourceTypes'
import {IResPipelines} from '../../../../types/pipelines'
import {IResTags} from '../../../../types/tags'
import {IResCampus} from '../../../../types/campus'
import {hasPermission, parseId} from '../../../../helpers'
import {IResAllMembers} from '../../../../types/members'
import {ILeadData, IResUpdateLead, LeadByID} from '../../leadTypes'
import {IResAllReasons} from '../../../../types/reasonLoss'
import {useMutation} from '@apollo/client'
import {UPDATE_LEAD} from '../../../../gql/mutations/leadMutations'
import {toast} from 'react-toastify'
import {useGetUser} from '../../../../store/userStore'
import {ALL_PERMISSIONS} from '../../../../helpers/consts'

interface IFormEditProps {
  dataLeadSource: IResCatLeadSource
  dataPipelines: IResPipelines
  dataTags: IResTags
  dataCampus: IResCampus
  dataMembers: IResAllMembers
  onClose: () => void
  updateListLeads: () => void
  lead: LeadByID
  dataReasonLoss: IResAllReasons
  children: JSX.Element
}

export const FormEditLead = ({
  dataCampus,
  dataLeadSource,
  dataPipelines,
  dataTags,
  dataMembers,
  onClose,
  lead,
  dataReasonLoss,
  updateListLeads,
  children,
}: IFormEditProps) => {
  const permissions = useGetUser((s) => s.user?.permissions)
  const [gqlEdit, {data: dataEdit, loading: loadingEdit, error: errorEdit}] =
    useMutation<IResUpdateLead>(UPDATE_LEAD)

  const [leadType, setLeadType] = useState<'PERSONAL' | 'COMERCIAL'>('PERSONAL')
  const [valuePipelines, setValuePipelines] = useState('')
  const [stageValue, setStageValue] = useState('')
  const [valueTags, setValueTags] = useState<
    MultiValue<{
      value: string
      label: string
      color: string
    }>
  >([])
  const [valueMembers, setValueMembers] = useState<
    MultiValue<{
      value: string
      label: string
    }>
  >([])

  useEffect(() => {
    if (!lead) return
    const tLead = lead.comercialLead ? 'COMERCIAL' : lead.personalLead ? 'PERSONAL' : null
    if (!tLead) return
    setLeadType(tLead)
    const tags = lead.tagInsurance.edges.map(({node}) => ({
      value: node.id,
      label: node.name,
      color: node.color,
    }))
    setValueTags(tags)
    setValuePipelines(`${parseId(lead.stagePipeline.pipeline.id)}`)
    const mems = lead.user.edges.map(({node}) => ({
      value: node.id,
      label: `${node.firstName} ${node.lastName}`,
    }))
    setValueMembers(mems)
    setStageValue(`${parseId(lead.stagePipeline.id)}`)
  }, [lead])

  useEffect(() => {
    if (errorEdit) toast.error(`Error: ${errorEdit.message}`)
  }, [errorEdit])

  useEffect(() => {
    if (!dataEdit) return
    if (dataEdit.updateLead.success) {
      toast.success('Lead updated successfully')
      onClose()
      updateListLeads()
    } else if (dataEdit.updateLead.errors) {
      toast.error(`Error: ${dataEdit.updateLead.errors?.[0]?.message || 'something happened'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const {
      name,
      email,
      phone,
      leadSource,
      pipeline,
      campus,
      reasonLeadLoss,
      tags,
      observations,
      stagePipeline,
      ...rest
    } = Object.fromEntries(new FormData(e.currentTarget))
    const leadData: ILeadData = {
      name,
      email,
      phone,
      typeLead: leadType,
      leadSource,
      pipeline,
      campus,
      reasonLeadLoss: reasonLeadLoss.toString() ? reasonLeadLoss : undefined,
      tags,
      observations,
      stagePipeline,
    }
    if (valueTags.length > 0) {
      leadData.tagInsurance = valueTags.map(({value}) => parseId(value))
    }
    if (valueMembers.length > 0) {
      leadData.user = valueMembers.map(({value}) => parseId(value))
    }
    if (leadType === 'PERSONAL') {
      leadData.personalLead = {...rest}
      if (!leadData.personalLead.birthDay) {
        delete leadData.personalLead.birthDay
      }
      delete leadData.comercialLead
    }
    if (leadType === 'COMERCIAL') {
      leadData.comercialLead = {...rest}
      delete leadData.personalLead
    }
    delete leadData.tags
    delete leadData.pipeline
    console.log('leadData', leadData)
    gqlEdit({
      variables: {
        id: lead.id,
        leadData,
      },
    })
  }

  return (
    <>
      <Offcanvas.Header closeButton className='bg-light'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Offcanvas.Title className='d-flex align-items-center gap-4'>{lead.name}</Offcanvas.Title>
          <div style={{flex: 0}}>
            <Badge bg='success' pill className='fs-2 d-flex align-items-center gap-1'>
              <i className='bi bi-telephone-plus' style={{color: 'inherit'}} />
              {lead.callCounter}
            </Badge>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <button
              className=''
              onClick={() => {
                setLeadType('PERSONAL')
              }}
              style={{
                border: 'none',
                padding: '4px 10px',
                borderRadius: '14px',
                color: leadType === 'PERSONAL' ? 'white' : 'gray',
                backgroundColor: leadType === 'PERSONAL' ? '#3e97ff' : '#e4e4e4',
              }}
            >
              Personal
            </button>
            <button
              className=''
              onClick={() => {
                setLeadType('COMERCIAL')
              }}
              style={{
                border: 'none',
                padding: '4px 10px',
                borderRadius: '14px',
                color: leadType === 'COMERCIAL' ? 'white' : 'gray',
                backgroundColor: leadType === 'COMERCIAL' ? '#3e97ff' : '#e4e4e4',
              }}
            >
              Commercial
            </button>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {children}
        <form onSubmit={handleSubmit}>
          <div className='w-100 h-100'>
            <div>
              <Row>
                <Col className='col-lg-4 col-md-4 col-12 mb-4'>
                  <FloatingLabel
                    controlId='LeadSource'
                    label={
                      <span>
                        Lead source <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select
                      aria-label='Lead source'
                      name='leadSource'
                      required
                      defaultValue={parseId(lead.leadSource.id)}
                    >
                      <option value=''>Please select</option>
                      {dataLeadSource.allCategoryLeadSource.edges.map(({node}) => (
                        <optgroup label={node.name} key={node.id}>
                          {node.leadSourceSet.edges.map(({node: nodeLead}) => (
                            <option key={nodeLead.id} value={parseId(nodeLead.id)}>
                              {nodeLead.name}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-4 col-md-4 col-12 mb-4'>
                  <FloatingLabel
                    controlId='pipeline'
                    label={
                      <span>
                        Pipeline <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select
                      aria-label='Pipeline'
                      name='pipeline'
                      required
                      value={valuePipelines}
                      onChange={(e) => setValuePipelines(e.target.value)}
                    >
                      <option value=''>Nothing selected</option>
                      {dataPipelines.allPipelines.edges.map(({node}) => (
                        <option value={parseId(node.id)} key={node.id}>
                          {node.name}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-4 col-md-4 col-12 mb-4'>
                  <FloatingLabel
                    label={
                      <span>
                        Stage <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select
                      aria-label='stagePipeline'
                      name='stagePipeline'
                      required
                      value={stageValue}
                      onChange={(e) => setStageValue(e.target.value)}
                    >
                      <option value=''>Nothing selected</option>
                      {dataPipelines.allPipelines.edges
                        .find(({node}) => parseId(node.id) === valuePipelines)
                        ?.node.stagePipelineSet.edges.map(({node}) => (
                          <option key={node.id} value={parseId(node.id)}>
                            {node.name}
                          </option>
                        ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                {/* two */}
                <Col className='col-lg-6 col-md-6 col-12 mb-4 z-999'>
                  <Select
                    classNames={{
                      control: () => 'h-100',
                      container: () => 'h-100',
                    }}
                    placeholder={
                      <span>
                        Tags <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    id='tags-cont'
                    required
                    inputId='tags'
                    name='tags'
                    options={dataTags.allTagInsurances.edges.map(({node}) => ({
                      value: node.id,
                      label: node.name,
                      color: node.color,
                    }))}
                    isMulti
                    value={valueTags}
                    onChange={(e) => {
                      setValueTags(e)
                    }}
                    styles={{
                      option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                        const color = data.color
                        return {
                          ...styles,
                          zIndex: 9999,
                          backgroundColor: isDisabled
                            ? undefined
                            : isSelected
                            ? data.color
                            : isFocused
                            ? color
                            : undefined,
                          color: isDisabled ? '#ccc' : isSelected ? 'white' : 'black',
                          cursor: isDisabled ? 'not-allowed' : 'default',

                          ':active': {
                            ...styles[':active'],
                            backgroundColor: !isDisabled
                              ? isSelected
                                ? data.color
                                : color
                              : undefined,
                          },
                        }
                      },
                      multiValue: (styles, {data}) => {
                        const color = data.color
                        return {
                          ...styles,
                          backgroundColor: color,
                        }
                      },
                      multiValueLabel: (styles) => ({
                        ...styles,
                        color: 'white',
                      }),
                      multiValueRemove: (styles, {data}) => ({
                        ...styles,
                        color: 'white',
                        ':hover': {
                          backgroundColor: data.color,
                          color: 'white',
                        },
                      }),
                      control: (styles, {isDisabled, isFocused}) => {
                        return {
                          ...styles,
                          border: isDisabled
                            ? '1px solid #ccc'
                            : isFocused
                            ? '1px solid white'
                            : '1px solid #dbdfe9',
                          cursor: isDisabled ? 'not-allowed' : 'default',
                        }
                      },
                    }}
                  />
                  {/* <FloatingLabel
                    controlId='tag'
                    label={
                      <span>
                        Tag <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select aria-label='Tag' name='tag' required multiple>
                      <option value=''>Nothing selected</option>
                      {dataTags.allTagInsurances.edges.map(({node}) => (
                        <option
                          value={parseId(node.id)}
                          key={node.id}
                          style={{
                            backgroundColor: node.color,
                            fontWeight: 'bolder',
                            textTransform: 'uppercase',
                            color: 'white',
                          }}
                        >
                          {node.name}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel> */}
                </Col>
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='campus'
                    label={
                      <span>
                        Location <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select
                      aria-label='Location'
                      name='campus'
                      required
                      defaultValue={parseId(lead.campus.id)}
                    >
                      <option value=''>Nothing selected</option>
                      {dataCampus.allCampus.edges.map(({node}) => (
                        <option value={parseId(node.id)} key={node.id}>
                          {node.name}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                {/* two */}
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='reasonLeadLoss'
                    label={
                      <span>
                        Reason for loss <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select
                      aria-label='Location'
                      name='reasonLeadLoss'
                      defaultValue={
                        lead?.reasonLeadLoss?.id ? parseId(lead.reasonLeadLoss.id) : undefined
                      }
                    >
                      <option value=''>Nothing selected</option>
                      {dataReasonLoss.allReasonLeadLoss.edges.map(({node}) => (
                        <option value={parseId(node.id)} key={node.id}>
                          {node.reason}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-6 col-md-6 col-12 mb-4 z-999'>
                  <Select
                    classNames={{
                      container: () => 'h-100',
                    }}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        height: 'calc(3.75rem + 2px)',
                      }),
                    }}
                    placeholder='Members'
                    options={dataMembers.allUsers.edges.map(({node}) => ({
                      value: node.id,
                      label: `${node.firstName} ${node.lastName}`,
                    }))}
                    isMulti
                    value={valueMembers}
                    onChange={(e) => {
                      setValueMembers(e)
                    }}
                  />
                </Col>
                {/*ONE*/}
                <Col className='col-12 mb-4'>
                  <FloatingLabel
                    controlId='name'
                    label={
                      <span>
                        Lead Name <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control
                      placeholder='Lead Name'
                      name='name'
                      required
                      defaultValue={lead.name}
                    />
                  </FloatingLabel>
                </Col>
                {/* two */}
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='phone'
                    label={
                      <span>
                        Phone <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control
                      placeholder='Phone'
                      name='phone'
                      required
                      defaultValue={lead.phone}
                    />
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='email'
                    label={
                      <span>
                        Email <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control
                      placeholder='Email'
                      name='email'
                      required
                      defaultValue={lead.email}
                    />
                  </FloatingLabel>
                </Col>
                {leadType === 'PERSONAL' && <FormPersonalLead info={lead.personalLead} />}
                {leadType === 'COMERCIAL' && <FormComercialLead info={lead.comercialLead} />}

                {/* Grupo 12 */}
                <Col className='col-12 mb-4'>
                  <FloatingLabel controlId='observations' label='Observations'>
                    <Form.Control
                      defaultValue={lead?.observations || ''}
                      name='observations'
                      as='textarea'
                      rows={4}
                      placeholder='Observations'
                      className='h-100'
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <div
                className='d-flex align-items-center justify-content-start gap-2 mt-8 pt-3 pb-8'
                style={{borderTop: '1px solid #f1f1f1'}}
              >
                <Button type='button' variant='light' onClick={onClose}>
                  Cancel
                </Button>
                {permissions && hasPermission(permissions, ALL_PERMISSIONS.PUEDE_EDITAR_LEADS) && (
                  <Button type='submit' disabled={loadingEdit}>
                    {loadingEdit && <Spinner animation='border' size='sm' />}
                    Update
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </>
  )
}
