import {Button, Col, Form, Row, Spinner} from 'react-bootstrap'
import {TFormKeys} from '../policiesTypes'
// import {InputCurrency} from '../../../components/InputCurrency'
import {useQuery} from '@apollo/client'
import {GET_STATES_EU} from '../../../gql/queries/statesEUQuery'
import {IResStates} from '../../../types/statesEU'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {GET_ONE_CARRIER} from '../../../gql/queries/carriersQuery'
import {IResCarriers} from '../../../types/carriers'

export const BasicPolicyInfoTab: React.FC<{
  onPrev: () => void
  onSubmit: () => void
  handleChange: (field: TFormKeys, value: string) => void
  handleBlur: (field: TFormKeys) => void
  loading: boolean
  values: {
    state: string
    stateDocs: string
    paymentStatus: string
    effectiveDate: string
    stateEU: string
    carrier: string
  }
  isQuote?: boolean
}> = ({onPrev, onSubmit, isQuote, handleChange, values, loading}) => {
  const {data: dataStates, error: errorStates} = useQuery<IResStates>(GET_STATES_EU)
  const {
    data: dataCarriers,
    loading: loadingCarriers,
    error: errorCarriers,
  } = useQuery<IResCarriers>(GET_ONE_CARRIER)
  useEffect(() => {
    if (errorStates) {
      toast.error(`Error: ${errorStates.message}`)
    }
  }, [errorStates])

  useEffect(() => {
    if (errorCarriers) toast.error(`Error: ${errorCarriers.message}`)
  }, [errorCarriers])

  return (
    <div className='ps-2'>
      <h6 className='d-flex align-items-center gap-2'>
        Basic {isQuote ? 'quote' : 'policy'} information{' '}
        {(loading || loadingCarriers) && <Spinner animation='border' size='sm' />}
      </h6>
      <Row>
        {/* <Col md={6} className='mb-5'>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Select
              onChange={(e) => handleChange('state', e.target.value)}
              value={values.state}
            >
              <option value=''>Select one</option>
              <option value='ACTIVE'>Active</option>
              <option value='PENDING'>Pending</option>
              <option value='SIGNED'>Signed</option>
              <option value='PAID'>Paid</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6} className='mb-5'>
          <Form.Group>
            <Form.Label>
              Status Documents <small className='text-danger'>(required)</small>
            </Form.Label>
            <Form.Select
              onChange={(e) => handleChange('stateDocs', e.target.value)}
              value={values.stateDocs}
            >
              <option value=''>Select one</option>
              <option value='ACTIVE'>Active</option>
              <option value='PENDING'>Pending</option>
              <option value='SIGNED'>Signed</option>
              <option value='PAID'>Paid</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6} className='mb-5'>
          <Form.Group>
            <Form.Label>
              Status Payment <small className='text-danger'>(required)</small>
            </Form.Label>
            <Form.Select
              onChange={(e) => handleChange('paymentStatus', e.target.value)}
              value={values.paymentStatus}
            >
              <option value=''>Select one</option>
              <option value='ACTIVE'>Active</option>
              <option value='PENDING'>Pending</option>
              <option value='SIGNED'>Signed</option>
              <option value='PAID'>Paid</option>
            </Form.Select>
          </Form.Group>
        </Col> */}
        <Col md={6} className='mb-5'>
          <Form.Group>
            <Form.Label>
              Effective date <small className='text-danger'>(required)</small>
            </Form.Label>
            <Form.Control
              type='date'
              onChange={(e) => handleChange('effectiveDate', e.target.value)}
              value={values.effectiveDate}
            />
          </Form.Group>
        </Col>
        <Col md={6} className='mb-5'>
          <Form.Group>
            <Form.Label>Carrier</Form.Label>
            <Form.Select
              onChange={(e) => handleChange('carrier', e.target.value)}
              value={values.carrier}
            >
              <option value=''>Select one</option>
              {dataCarriers?.allInsuranceOperators.edges.map(({node}) => (
                <option key={node.id} value={node.id}>
                  {node.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        {/*  <Col md={12} className='mb-5'>
          <Form.Group>
            <Form.Label>Premium</Form.Label>
            <InputCurrency
              value={values.premium}
              onValueChange={(v) => handleChange('premium', v || '')}
            />
          </Form.Group>
        </Col> */}
        {/* <Col md={12} className='mb-5'>
          <Form.Group>
            <Form.Label>
              Who is the agent on record for this client?{' '}
              <small className='text-danger'>(required)</small>
            </Form.Label>
            <Select />
          </Form.Group>
        </Col> */}
        <Col md={12} className='mb-5'>
          <Form.Group>
            <Form.Label>
              States {/* <small className='text-danger'>(required)</small> */}
            </Form.Label>
            <Form.Select
              onChange={(e) => handleChange('stateEU', e.target.value)}
              value={values.stateEU}
            >
              <option value=''>Select one</option>
              {dataStates?.allStates?.edges.map(({node}) => (
                <option key={node.id} value={node.id}>
                  {node.name} ({node.code})
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <div className='pt-6'>
        <div className='pt-6 mt-6 d-flex align-items-center justify-content-between mb-4'>
          <Button onClick={onPrev} variant='outline' disabled={loading}>
            PREVIUS
          </Button>
          <Button onClick={onSubmit} disabled={loading}>
            SAVE & CONTINUE
          </Button>
        </div>
      </div>
    </div>
  )
}
