import {useMutation} from '@apollo/client'
import {Alert, Button, Modal, Spinner} from 'react-bootstrap'

import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {REQUEST_CONSENT} from '../../../gql/mutations/leadMutations'
import {IResReqCons} from '../../../types/leads'
import {TEMPLATES} from '../../../helpers/consts'

interface IModalDeadProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  info: {
    id: string
    name: string
    email: string
  }
}

export const ModalRequestCons = ({isOpen, onClose, info, refetch}: IModalDeadProps) => {
  const [gqlRequest, {data, loading, error}] = useMutation<IResReqCons>(REQUEST_CONSENT)

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.createDocusignEnvelopeWithTemplate.success) {
      toast.success('Request processed successfully.')
      refetch()
      onClose()
    } else {
      toast.error(`Error: request could not be processed`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>REQUEST CONSENT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant='info' className='fs-3 mb-5 d-flex align-items-center gap-1'>
          <span style={{lineHeight: 0}}>
            <i className='bi bi-exclamation-circle fs-1' style={{color: 'inherit'}} />
          </span>
          Are you sure you want to request consent for this lead?
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant='light'>
          Cancel
        </Button>
        <Button
          onClick={() => {
            gqlRequest({
              variables: {
                template: TEMPLATES.AGENT,
                args: {
                  leadId: info.id,
                  fullName: info.name,
                  email: info.email,
                },
              },
            })
          }}
          variant='danger'
          disabled={loading}
        >
          {loading && <Spinner animation='border' size='sm' className='me-1' />}
          Request
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
