import {gql} from '@apollo/client'

export const GET_ALL_CARRIERS = gql`
  query allInsuranceOperators(
    $offset: Int
    $after: String
    $first: Int
    $id: Float
    $name: String
  ) {
    allInsuranceOperators(offset: $offset, after: $after, first: $first, id: $id, name: $name) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          created
          updated
          policyInsuranceThroughSet {
            edges {
              node {
                id
              }
            }
          }
          policies {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`
export const GET_ONE_CARRIER = gql`
  query allInsuranceOperators($id: Float) {
    allInsuranceOperators(id: $id) {
      edges {
        node {
          id
          name
          created
          updated
        }
      }
    }
  }
`

export const GET_COMISSIONS_ID = gql`
  query commissionsByOperatorId($operatorId: String) {
    commissionsByOperatorId(operatorId: $operatorId) {
      id
      created
      percentageValue
      nominalValue
      state {
        id
        name
      }
      policyType {
        id
        typePolicy
      }
    }
  }
`
