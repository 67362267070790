import {Button, Col, Form, Row} from 'react-bootstrap'
import {InputCurrency} from '../../../components/InputCurrency'
import {TFormKeys} from '../policiesTypes'
import {useQuery} from '@apollo/client'
import {GET_ALL_POLICIES_TYPES} from '../../../gql/queries/policiesQueries'
import {IResPoliciesType} from '../../../types/policies'

export const AddressTab: React.FC<{
  onPrev: () => void
  onNext: () => void
  handleChange: (field: TFormKeys, value: string) => void
  handleBlur: (field: TFormKeys) => void
  values: {
    policyName: string
    planName: string
    typeSale: string
    typePolicy: string
    FFM: string
    NPN: string
    premium: string
    marketplaceId: string
    CMSPlanId: string
    metalLevel: string
    policyTotalCost: string
    percentageValue: string
    nominalValue: string
  }
}> = ({onNext, onPrev, handleBlur, handleChange, values}) => {
  const {data} = useQuery<IResPoliciesType>(GET_ALL_POLICIES_TYPES, {fetchPolicy: 'no-cache'})
  return (
    <div className='ps-2'>
      <h6>Specific information </h6>
      <Row>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>Policy Name</Form.Label>
            <Form.Control
              onChange={(e) => handleChange('policyName', e.target.value)}
              value={values.policyName}
            />
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>Type Policy</Form.Label>
            <Form.Select
              onChange={(e) => handleChange('typePolicy', e.target.value)}
              value={values.typePolicy}
            >
              <option value=''>Select one</option>
              {data &&
                data.allPoliciesType.edges.map(({node}) => (
                  <option key={node.id} value={node.id}>
                    {node.typePolicy}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>Type of Sale</Form.Label>
            <Form.Select
              onChange={(e) => handleChange('typeSale', e.target.value)}
              value={values.typeSale}
            >
              <option value=''>Select one</option>
              <option value='New sale'>New sale</option>
              <option value='Renewal'>Renewal</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>Plan Name</Form.Label>
            <Form.Control
              onChange={(e) => handleChange('planName', e.target.value)}
              value={values.planName}
            />
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>
              Metal Level <small className='text-danger'>(required)</small>
            </Form.Label>
            <Form.Select
              onChange={(e) => handleChange('metalLevel', e.target.value)}
              value={values.metalLevel}
            >
              <option value=''>Select one</option>
              <option value='5'>Platinum</option>
              <option value='4'>Gold</option>
              <option value='3'>Silver</option>
              <option value='2'>Bronze</option>
              <option value='1'>Catastrophic</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>FFM</Form.Label>
            <Form.Control
              onChange={(e) => handleChange('FFM', e.target.value)}
              value={values.FFM}
            />
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>NPN</Form.Label>
            <Form.Control
              onChange={(e) => handleChange('NPN', e.target.value)}
              value={values.NPN}
            />
          </Form.Group>
        </Col>
        <Col className='mb-4'>
          <Form.Group>
            <Form.Label>Premium</Form.Label>
            <InputCurrency
              value={values.premium}
              onValueChange={(v) => handleChange('premium', v || '')}
            />
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>Marketplace ID</Form.Label>
            <Form.Control
              onChange={(e) => handleChange('marketplaceId', e.target.value)}
              value={values.marketplaceId}
            />
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>CMS Plan</Form.Label>
            <Form.Control
              onChange={(e) => handleChange('CMSPlanId', e.target.value)}
              value={values.CMSPlanId}
            />
          </Form.Group>
        </Col>

        <Col className='mb-4' md={12}>
          <Form.Group>
            <Form.Label>Policy Total Cost</Form.Label>
            <InputCurrency
              value={values.policyTotalCost}
              onValueChange={(v) => handleChange('policyTotalCost', v || '')}
            />
          </Form.Group>
        </Col>
        {/*  <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>Percentage Value</Form.Label>
            <InputCurrency
              value={values.percentageValue}
              onValueChange={(v) => handleChange('percentageValue', v || '')}
            />
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>Nominal Value</Form.Label>
            <InputCurrency
              value={values.nominalValue}
              onValueChange={(v) => handleChange('nominalValue', v || '')}
            />
          </Form.Group>
        </Col> */}
      </Row>

      <div className='pt-6'>
        <div className='pt-6 mt-6 d-flex align-items-center justify-content-between mb-4'>
          <div />
          {/* <Button onClick={onPrev} variant='outline'>
            PREVIUS
          </Button> */}
          <Button onClick={onNext}>NEXT STEP</Button>
        </div>
      </div>
    </div>
  )
}
