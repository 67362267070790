import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {toAbsoluteUrl} from '../_metronic/helpers'

import 'react-quill/dist/quill.snow.css'

const App = () => {
  return (
    <Suspense
      fallback={
        <div
          className='d-flex align-items-center justify-content-center bg-dark'
          style={{width: '100vw', height: '100vh'}}
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/tfi/logoN.jpg')}
            className='h-75px tfi-blink-logo'
          />
        </div>
      }
    >
      <ToastContainer />
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
