import {IFormSold} from '../pages/soldLead/soldTypes'

export const VERSION_APP = 'v0.0.15-beta'

export const STORAGE_NAMES = {
  TOKEN: 'TOKEN',
  USER: 'USER',
  PERMISSIONS: 'PERMISSIONS',
  ID_USER: 'USER_ID',
  TOKEN_GOOGLE: 'google_token',
  EMAIL_GOOGLE: 'google_email',
  RING_CENTRAL: 'ringcentral',
} as const

export const EVENT_SERVICE_WORKER_NAME = 'swUpdated' as const
export const MSG_SKIP_WAITING = 'SKIP_WAITING' as const

export const INIT_FORM: IFormSold = {
  policyholder: {
    isApplicant: 'yes',
    firstName: '',
    lastName: '',
    dateBirth: '',
    email: '',
    maritalStatus: '',
    weight: '',
    language: 'Spanish',
    middleName: '',
    secondLastName: '',
    gender: 'Male',
    phone: '',
    countryBirth: '',
    height: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateProvince: '',
    zipcode: '',
    country: '',
    mailingAddressLine1: '',
    mailingAddressLine2: '',
    mailingCity: '',
    mailingStateProvince: '',
    mailingZipCode: '',
    mailingCounty: '',
    immigrationStatus: '',
    uscis: '',
    ssn: '',
    greenCard: '',
    workPermitCard: '',
    driverLicense: '',
    immigrationStatusCategory: '',
    passport: '',
    ssnIssuedDate: '',
    greenCardIssueDate: '',
    greenCardExpirationDate: '',
    workPermitIssueDate: '',
    workPermitExpirationDate: '',
    driverLicenseExpirationDate: '',
    employerNameSelfEmployed: '',
    employerPhoneNumber: '',
    occupation: '',
    annualIncome: '',
    employerNameSelfEmployed2: '',
    employerPhoneNumber2: '',
    occupation2: '',
    annualIncome2: '',
    employerNameSelfEmployed3: '',
    employerPhoneNumber3: '',
    occupation3: '',
    annualIncome3: '',
  },
  familyMembers: {
    numberApplicants: '',
    numberMembers: '',
    numberDependents: '',
    familyMember: [],
  },
  insurancePayments: {
    ffmUsedMarketplace: '',
    npnUsedMarketplace: '',
    typeSale: '',
    effectiveDate: '',
    marketplaceID: '',
    memberID: '',
    cmsPlanID: '',
    Carrier: '',
    planName: '',
    metalLevel: '',
    policyTotalCost: '',
    taxCreditSubsidy: '',
    premium: '',
    recurrentPayment: '',
    firstPaymentDate: '',
    preferredPaymentDayEachMonth: '',
    creditDebit: '',
    bankEFT: '',
    cardCompany: '',
    bankName: '',
    paymentMethodHolder: '',
    cardNumber: '',
    abaRouting: '',
    addressLine1: '',
    addressLine2: '',
    expirationMonth: '',
    expirationYear: '',
    accountNumber: '',
    city: '',
    cvvCsv: '',
    stateProvince: '',
    zipCode: '',
  },
  extras: {
    primaryDoctorInformation: '',
    medicinesNeeded: '',
    extraNotesComments: '',
    cancellationDate: '',
    specialEnrollmentDate: '',
    specialEnrollmentReason: '',
  },
}

export const COUNTRIES = [
  {label: 'Argentina', value: 'AR'},
  {label: 'Australia', value: 'AU'},
  {label: 'Brazil', value: 'BR'},
  {label: 'Canada', value: 'CA'},
  {label: 'China', value: 'CN'},
  {label: 'Colombia', value: 'CO'},
  {label: 'Denmark', value: 'DK'},
  {label: 'Egypt', value: 'EG'},
  {label: 'Finland', value: 'FI'},
  {label: 'France', value: 'FR'},
  {label: 'Germany', value: 'DE'},
  {label: 'Greece', value: 'GR'},
  {label: 'India', value: 'IN'},
  {label: 'Indonesia', value: 'ID'},
  {label: 'Ireland', value: 'IE'},
  {label: 'Israel', value: 'IL'},
  {label: 'Italy', value: 'IT'},
  {label: 'Japan', value: 'JP'},
  {label: 'Mexico', value: 'MX'},
  {label: 'Netherlands', value: 'NL'},
  {label: 'New Zealand', value: 'NZ'},
  {label: 'Norway', value: 'NO'},
  {label: 'Pakistan', value: 'PK'},
  {label: 'Peru', value: 'PE'},
  {label: 'Philippines', value: 'PH'},
  {label: 'Poland', value: 'PL'},
  {label: 'Portugal', value: 'PT'},
  {label: 'Russia', value: 'RU'},
  {label: 'Saudi Arabia', value: 'SA'},
  {label: 'South Africa', value: 'ZA'},
  {label: 'South Korea', value: 'KR'},
  {label: 'Spain', value: 'ES'},
  {label: 'Sweden', value: 'SE'},
  {label: 'Switzerland', value: 'CH'},
  {label: 'Thailand', value: 'TH'},
  {label: 'Turkey', value: 'TR'},
  {label: 'Ukraine', value: 'UA'},
  {label: 'United Arab Emirates', value: 'AE'},
  {label: 'United Kingdom', value: 'GB'},
  {label: 'United States', value: 'US'},
  {label: 'Venezuela', value: 'VE'},
  {label: 'Vietnam', value: 'VN'},
]

export const COLORS = ['primary', 'success', 'warning', 'danger', 'info', 'dark'] as const

export const ALL_PERMISSIONS = {
  PUEDE_VER_USUARIOS: 'view_user',
  PUEDE_CREAR_USUARIOS: 'add_user',
  PUEDE_EDITAR_USUARIOS: 'change_user',
  PUEDE_VER_LEADS: 'view_lead',
  PUEDE_CREAR_LEADS: 'add_lead',
  PUEDE_ELIMINAR_LEADS: 'delete_lead',
  PUEDE_EDITAR_LEADS: 'change_lead',
  PUEDE_VER_POLIZAS: 'view_policy',
  PUEDE_CREAR_POLIZAS: 'add_policy',
  PUEDE_EDITAR_POLIZAS: 'change_policy',

  PUEDE_VER_SETTINGS: 'view_setting',
} as const

export const RING_CENTRAL_CLIENT_ID = 'Z9YGOPXDJkVfVDDW7RM8ll'
export const RING_CENTRAL_APP_SERVER =
  process.env.NODE_ENV === 'production'
    ? 'https://platform.ringcentral.com'
    : 'https://platform.devtest.ringcentral.com'

export const TEMPLATES = {
  AGENT: 'AGENT_OF_RECORD_CONSENTIMIENTO_CONSUMIDOR',
  CONTRATO: 'CONTRATO_CONSENTIMIENTO',
  CARTA: 'CARTA_INGRESOS_CUENTA_PROPIA',
} as const

export const STATUS_POLICY = {
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
}

export const INIT_PEOPLE = {
  age: '',
  dob: '',
  gender: 'Male',
  isPregnant: false,
  pregnantWith: '',
  isParent: false,
  usesTobacco: false,
  lastTobaccoUseDate: '',
  hasMec: false,
  aptcEligible: false,
  utilizationLevel: '',
  relationship: '',
  doesNotCohabitate: '',
  currentEnrollment: null,
}
