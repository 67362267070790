import {useEffect, useState} from 'react'
import {Button, Form, InputGroup, Modal} from 'react-bootstrap'
import {ModalBodySearchHeader} from './ModalBodySearchHeader'
import {useLazyQuery} from '@apollo/client'
import {IResAllLeadStage} from '../../pages/leads/leadTypes'
import {GET_ALL_LEADS_SEARCH} from '../../gql/queries/leadsQuery'
import {toast} from 'react-toastify'

export const ModalSearchMovile: React.FC<{isOpen: boolean; onClose: () => void}> = ({
  isOpen,
  onClose,
}) => {
  const [gqlGet, {data, loading, error}] = useLazyQuery<IResAllLeadStage>(GET_ALL_LEADS_SEARCH, {
    fetchPolicy: 'no-cache',
  })

  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  const searchLeads = (str: string) => {
    if (str.length < 3) return
    const variables: {name_Icontains?: string; phone_Icontains?: string} = {}

    if (isNaN(Number(str))) {
      variables.name_Icontains = str
    } else {
      variables.phone_Icontains = str
    }
    gqlGet({
      variables,
    })
  }
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <form
          autoComplete='off'
          className='w-100'
          onSubmit={(e) => {
            e.preventDefault()
            const {search} = Object.fromEntries(new FormData(e.currentTarget))
            searchLeads(search.toString())
          }}
        >
          <InputGroup className='w-100' size='sm'>
            <Button variant='transparent' type='submit' id='button-addon1'>
              <i className='bi bi-search text-info fs-2 ' />
            </Button>
            <Form.Control
              autoFocus
              aria-label='Example text with button addon'
              aria-describedby='basic-addon1'
              name='search'
              className='border-0'
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value)
                if (e.target.value === '') onClose()
              }}
              placeholder='Enter Lead name or phone number'
              minLength={3}
            />
          </InputGroup>
        </form>
      </Modal.Header>
      <ModalBodySearchHeader
        data={data}
        loading={loading}
        onClose={onClose}
        keyword={searchInput}
      />
    </Modal>
  )
}
