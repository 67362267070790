import clsx from 'clsx'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {useLazyQuery} from '@apollo/client'
import {GET_ALL_MEMBERS} from '../../gql/queries/membersQuery'
import {IResAllMembers} from '../../types/members'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {Button, Spinner} from 'react-bootstrap'
import {useDisclourse} from '../../hooks/useDisclourse'
import {ModalAddMember} from './components/ModalAddMember'
import {IInfoUser} from './typeMembers'
import {useSearchParams} from 'react-router-dom'
import {getColorMember} from '../../helpers'

export const MembersPage = () => {
  const [gqlGetMembers, {data, loading, error, refetch}] = useLazyQuery<IResAllMembers>(
    GET_ALL_MEMBERS,
    {
      fetchPolicy: 'no-cache',
    }
  )
  const [search] = useSearchParams()

  const p = search.get('open')

  const {isOpen, onClose, onOpen} = useDisclourse()
  const [infoEdit, setInfoEdit] = useState<null | IInfoUser>(null)

  useEffect(() => {
    gqlGetMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (error) {
      toast.error('Error: ' + error.message)
    }
  }, [error])

  useEffect(() => {
    if (p && p === 'true') {
      onOpen()
      setInfoEdit(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p])

  if (!data || loading) {
    return (
      <div>
        <div className='py-4 d-flex align-items-center justify-content-between'>
          <h2>Members</h2>
          <Button className='d-flex align-items-center gap-2' disabled>
            <i className='bi bi-plus fs-2' style={{color: 'inherit'}} />
            Add Member
          </Button>
        </div>
        <KTCard>
          <KTCardBody className='py-4'>
            <div
              className='d-flex align-items-center justify-content-center'
              style={{height: '40vh'}}
            >
              <Spinner animation='border' />
            </div>
          </KTCardBody>
        </KTCard>
      </div>
    )
  }

  return (
    <>
      <div>
        <div className='py-4 d-flex align-items-center justify-content-between flex-column flex-md-row gap-2'>
          <h2>Members</h2>
          <Button
            className='d-flex align-items-center gap-2'
            onClick={() => {
              onOpen()
              setInfoEdit(null)
            }}
          >
            <i className='bi bi-plus fs-2' style={{color: 'inherit'}} />
            Add Member
          </Button>
        </div>
        <KTCard>
          <KTCardBody className='py-4'>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th>Member</th>
                    <th>Rol</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th />
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold'>
                  {data.allUsers.edges.map(({node}, i) => (
                    <tr key={node.id}>
                      <td
                        className={clsx({
                          'text-end min-w-100px': false /* cell.column.id === 'actions' */,
                        })}
                      >
                        <div className='d-flex align-items-center'>
                          {/* begin:: Avatar */}
                          <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                            <div
                              className={clsx(
                                'symbol-label fs-3 text-uppercase',
                                `bg-light-${getColorMember(i)}`,
                                `text-${getColorMember(i)}`
                              )}
                            >
                              {node.firstName?.charAt(0)}
                              {node.lastName?.charAt(0)}
                            </div>
                          </div>
                          <div className='d-flex flex-column'>
                            <a href='/#' className='text-gray-800 text-hover-primary mb-1'>
                              {node.lastName}
                            </a>
                            <span>{node.firstName}</span>
                          </div>
                        </div>
                      </td>
                      <th>{node.rol || 'No Rol'}</th>
                      <th>{node.email || 'N/R'}</th>
                      <th>{node.cellphone || 'N/R'}</th>
                      <th>
                        <div>
                          <button
                            title='Edit'
                            className='rounded-circle bg-transparent text-warning bg-warning-subtle border border-1'
                            onClick={() => {
                              setInfoEdit({
                                email: node.email,
                                id: node.id,
                                lastName: node.lastName,
                                name: node.firstName,
                                phone: node.cellphone,
                                rol: node.rol,
                              })
                              onOpen()
                            }}
                          >
                            <i className='bi bi-pencil' style={{color: 'inherit'}} />
                          </button>
                        </div>
                      </th>
                    </tr>
                  ))}
                  {data.allUsers.edges.length === 0 && (
                    <tr>
                      <td colSpan={6}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          There are no registered members
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </KTCardBody>
        </KTCard>
      </div>

      {isOpen && (
        <ModalAddMember isOpen={isOpen} onClose={onClose} infoEdit={infoEdit} refetch={refetch} />
      )}
    </>
  )
}
