import {Dropdown} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {ROUTES_SETTINGS} from './routesSetting'

// const ROUTES_LEFT = ROUTES_SETTINGS.filter(({side}) => side === 'left')
// const ROUTES_RIGHT = ROUTES_SETTINGS.filter(({side}) => side === 'right')

export const NavSettingMobile = () => {
  return (
    <Dropdown drop='up'>
      <Dropdown.Toggle size='sm' variant='secondary' id='dropdown-basic' className='w-100'>
        My agency
      </Dropdown.Toggle>

      <Dropdown.Menu
        className='w-100 drop-scrollbar'
        style={{maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden'}}
      >
        {ROUTES_SETTINGS.map((info) => {
          if (info.title || !info.route) {
            return (
              <Dropdown.Item key={info.id} className='text-center' style={{color: '#c4c4c4'}}>
                {info.title}
              </Dropdown.Item>
            )
          }
          return (
            <Dropdown.Item
              key={info.id}
              as={Link}
              to={info.route}
              style={{color: '#133159', marginLeft: info.isChildren ? '8px' : undefined}}
            >
              {info.name}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}
