import {useRef} from 'react'
import {Modal} from 'react-bootstrap'

interface IModalProps {
  isOpen: boolean
  onClose: () => void
}

const YOUR_RINGCENTRAL_CLIENT_ID = 'Z9YGOPXDJkVfVDDW7RM8ll'

export const ModalRingCentralConnect: React.FC<IModalProps> = ({isOpen, onClose}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  /* useEffect(() => {
    if (!divRef.current) return
    const rcs = document.createElement('script')
    rcs.src = `https://apps.ringcentral.com/integration/ringcentral-embeddable/2.0.0/adapter.js?clientId=${YOUR_RINGCENTRAL_CLIENT_ID}`
    var rcs0 = document.getElementsByTagName('script')[0]
    rcs0?.parentNode?.insertBefore(rcs, rcs0)
  }, []) */

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        {/* <Modal.Title>RingCentral integration</Modal.Title> */}
        <img
          src='/media/tfi/ringcentral2.png'
          alt='Integration - ringcentral'
          style={{
            maxWidth: '120px',
            // minHeight: '60px',
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <div id='content' className='d-flex flex-center'>
          <iframe
            ref={iframeRef}
            title='RingCentral'
            width='200'
            height='100'
            allow='microphone'
            src={`https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/app.html?clientId=${YOUR_RINGCENTRAL_CLIENT_ID}&appServer=https://platform.devtest.ringcentral.com&redirectUri=https://app.policytools.com/`}
            /* src={`https://apps.ringcentral.com/integration/ringcentral-embeddable/2.0.0/adapter.js?clientId=${YOUR_RINGCENTRAL_CLIENT_ID}&appServer=https://platform.devtest.ringcentral.com&redirectUri=https://app.policytools.com/`} */
          ></iframe>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer> */}
    </Modal>
  )
}
