import {useLazyQuery} from '@apollo/client'
import {GET_ALL_QUOTES} from '../../gql/queries/quotesQuery'
import {IResQuotes} from './quotesTypes'
import {useEffect, useState} from 'react'
import {LoadingQuotes} from './components/LoadingQuotes'
import {toast} from 'react-toastify'
import {
  Breadcrumb,
  Button,
  // ButtonGroup,
  Col,
  // Dropdown,
  Form,
  InputGroup,
  ListGroup,
  Pagination,
  Row,
  Spinner,
} from 'react-bootstrap'
import {Tooltip} from 'react-tooltip'
import {InfoClientPolicy} from '../policies/components/InfoClientPolicy'
import {parseId} from '../../helpers'
import {BadgeStatePolicy} from '../soldLead/components/BadgeStatePolicy'
import moment from 'moment'
import {useDisclourse} from '../../hooks/useDisclourse'
import {ModalAddQuote} from './components/ModalAddQuote'
import {ModalPreview} from '../policies/components/ModalPreview'
import {Node} from '../../types/policiesApi'

const FILTERS_NAME_ARR = [
  /* {
    name: 'OE 2024 Obamacare (ACA)',
    icon: 'heart-fill',
  },
  {
    name: 'OE 2024 Medicare',
    icon: 'heart-fill',
  }, */
  {
    name: 'Important',
    icon: 'star-fill',
  },
]

const METALS_NAME = {
  1: 'Catastrophic',
  2: 'Bronze',
  3: 'Silver',
  4: 'Gold',
  5: 'Platinum',
}

export const AllQuotesPage = () => {
  const [gqlGetPolicies, {data, loading, error, refetch}] = useLazyQuery<IResQuotes>(
    GET_ALL_QUOTES,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const [offset, setOffset] = useState(0)
  const [first, setFirst] = useState(10)

  const [isPag, setIsPag] = useState(false)
  const [page, setPage] = useState(1)

  const {isOpen, onClose, onOpen} = useDisclourse()

  const [tabFilters, setTabFilters] = useState('Policies')

  const [dataArr, setDataArr] = useState<IResQuotes['allQuotes']['edges']>([])

  const {isOpen: isOpenPreview, onClose: onClosePreview, onOpen: onOpenPreview} = useDisclourse()
  const [infoPreview, setInfoPreview] = useState<null | Node>(null)

  useEffect(() => {
    const variables = {
      offset,
      first,
    }

    gqlGetPolicies({
      variables,
    })
  }, [first, gqlGetPolicies, offset])

  useEffect(() => {
    if (!data) return
    const {edges} = data.allQuotes
    if (edges.length === 0) return
    if (isPag) {
      setDataArr((p) => [...p, ...edges])
    } else {
      setDataArr(edges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
    }
  }, [error])

  if ((loading || !data) && !isPag) {
    return <LoadingQuotes />
  }
  return (
    <div>
      <header
        className='py-4 d-flex align-items-center justify-content-between'
        style={{width: '100%', marginTop: '-10px'}}
      >
        <Breadcrumb>
          <Breadcrumb.Item className='d-blok d-md-none' /* onClick={() => setShowAside(true)} */>
            <span>
              <i className='bi bi-list fs-1' />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='fs-3'>Quotes</Breadcrumb.Item>
          {/* <Breadcrumb.Item active>Quote </Breadcrumb.Item> */}
        </Breadcrumb>

        <div className='d-flex align-items-center gap-2'>
          <Button size='sm' onClick={onOpen}>
            <i className='bi bi-plus fs-3' /> New Quote
          </Button>
          {/* <Dropdown>
            <Dropdown.Toggle variant='primary' size='sm' id='dropdown-basic'>
              Export
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
              <Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
              <Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </header>
      <Row>
        <Col md={4} lg={4} sm={0} xl={3} className='d-none d-md-block'>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
              backgroundColor: '#fff',
              marginBottom: '20px',
              borderRadius: '4px',
              padding: '8px 10px',
            }}
          >
            <div
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
                paddingBottom: '7px',
              }}
            >
              <h5 style={{fontSize: '13px'}}>Menu</h5>
            </div>

            <div>
              <ListGroup className='mt-5' variant='flush'>
                <ListGroup.Item
                  action
                  variant={tabFilters === 'Policies' ? 'primary' : 'light'}
                  className='my-2 py-4'
                  onClick={() => setTabFilters('Policies')}
                >
                  <i className='bi bi-person-fill fs-3' style={{color: 'inherit'}} /> Policies
                </ListGroup.Item>
                {FILTERS_NAME_ARR.map((lis) => (
                  <ListGroup.Item
                    key={lis.name}
                    action
                    variant={tabFilters === lis.name ? 'primary' : 'light'}
                    className='my-1 py-4 d-flex align-items-center justify-content-between'
                    onClick={() => setTabFilters(lis.name)}
                  >
                    <span className='d-flex align-items-center justify-content-between gap-1'>
                      <i className={`bi bi-${lis.icon} fs-3`} style={{color: 'inherit'}} />{' '}
                      {lis.name}
                    </span>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item
                  action
                  variant={'light'}
                  className='my-2 py-4'
                  // onClick={() => setTabFilters('Policies')}
                >
                  <i className='bi bi-clock fs-3' style={{color: 'inherit'}} /> Reminders
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  variant={'light'}
                  className='my-2 py-4'
                  // onClick={() => setTabFilters('Policies')}
                >
                  <i className='bi bi-folder-fill fs-3' style={{color: 'inherit'}} /> Archived
                </ListGroup.Item>
              </ListGroup>
            </div>

            <div
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
              }}
              className='d-flex align-items-center justify-content-between'
            >
              <h5 className='m-0 p-0'>Agency Folders</h5>
              <Button variant='link'>
                <i className='bi bi-pencil-square' style={{color: 'inherit'}} /> Manage
              </Button>
            </div>
            <p className='my-4'>Your agency has no folders.</p>
            <div
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
              }}
              className='d-flex align-items-center justify-content-between mt-5'
            >
              <h5 className='m-0 p-0'>
                Agency Folders
                <span>
                  <span data-tooltip-id='asdf'>
                    <i className='bi bi-info-circle-fill fs-5 ms-1' style={{color: '#5867dd'}} />
                  </span>
                  <Tooltip
                    id='asdf'
                    place='right'
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                      opacity: 1,
                    }}
                  >
                    <div className='tooltip-tfi'>
                      <h6 className='text-center'>English</h6>
                      <p className='text-muted'>
                        Only policies created by you can be stored in your personal folders.
                      </p>
                      <h6 className='text-center'>Español</h6>
                      <p className='text-muted'>
                        Una dirección de facturación es la dirección relacionada con una forma de
                        pago específica.
                      </p>
                    </div>
                  </Tooltip>
                </span>
              </h5>
              <Button variant='link'>
                <i className='bi bi-pencil-square' style={{color: 'inherit'}} /> Manage
              </Button>
            </div>
            <p className='my-4'>You have no personal folders.</p>
          </div>
        </Col>
        <Col md={8} lg={8} sm={12} xl={9}>
          {/* <div
            style={{
              backgroundColor: 'white',
              boxShadow: '0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)',
              borderRadius: '6px',
            }}
            className='p-4'
          >
            <div className='row row-no-padding row-col-separator-lg g-4'>
              <div className='col-sm-12 col-md-6 col-xxl-3'>
                <div className='kt-widget24'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='kt-widget24__info'>
                      <h4 className='m-0 p-0'>POLICIES</h4>
                      <span className='text-muted'>Number of policies</span>
                    </div>
                    <span
                      className='text-primary'
                      style={{
                        fontSize: '1.75rem',
                        fontWeight: 500,
                        paddingLeft: '.5rem',
                      }}
                      id='qty_policies_label'
                    >
                      0
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-xxl-3'>
                <div className='kt-widget24'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='kt-widget24__info'>
                      <h4 className='m-0 p-0'>APPLICANTS</h4>
                      <span className='text-muted'>Number of applicants</span>
                    </div>
                    <span
                      className='text-primary'
                      style={{
                        fontSize: '1.75rem',
                        fontWeight: 500,
                        paddingLeft: '.5rem',
                      }}
                      id='qty_applicants_label'
                    >
                      0
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-xxl-3'>
                <div className='kt-widget24'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='kt-widget24__info'>
                      <h4 className='m-0 p-0'>CANCELED POLICIES</h4>
                      <span className='text-muted'>Canceled policies</span>
                    </div>
                    <span
                      className='text-primary'
                      style={{
                        fontSize: '1.75rem',
                        fontWeight: 500,
                        paddingLeft: '.5rem',
                      }}
                      id='qty_canceled_policies_label'
                    >
                      0
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-xxl-3'>
                <div className='kt-widget24'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='kt-widget24__info'>
                      <h4 className='m-0 p-0'>CANCELED APPLICANTS</h4>
                      <span className='text-muted'>Canceled applicants</span>
                    </div>
                    <span
                      className='text-primary'
                      style={{
                        fontSize: '1.75rem',
                        fontWeight: 500,
                        paddingLeft: '.5rem',
                      }}
                      id='qty_canceled_applicants_label'
                    >
                      0
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
              backgroundColor: '#fff',
              marginBottom: '20px',
              borderRadius: '4px',
              padding: '8px 10px',
            }}
            className='mt-0'
          >
            <div
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
                paddingBottom: '7px',
              }}
              className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between'
            >
              <h3 style={{fontSize: '13px'}}>All policies</h3>
              <div>
                <span className='fw-bold me-4'>Effective year:</span>
                <Form.Check inline label='2024' />
                <Form.Check inline label='2023' />
                <Form.Check inline label='2022' />
              </div>
            </div>

            <div className='my-4'>
              <InputGroup>
                <Form.Control
                  placeholder='Type your query here...'
                  aria-label="Recipient's username with two button addons"
                />
                <Button variant='outline-success' style={{border: '1px solid #dbdfe9'}}>
                  Search
                </Button>
                <Button variant='outline-primary' style={{border: '1px solid #dbdfe9'}}>
                  Filter
                </Button>
              </InputGroup>
            </div>
            <div className='m-0'>
              <Form.Check
                type='checkbox'
                label={
                  <span>
                    Search by members:{' '}
                    <span className='ms-2 ' data-tooltip-id='my-tooltip-info-1'>
                      <i className='bi bi-question-circle-fill text-primary' />
                    </span>
                    <Tooltip
                      id='my-tooltip-info-1'
                      place='bottom'
                      style={{
                        zIndex: 99999,
                        backgroundColor: 'white',
                        color: 'black',
                        boxShadow:
                          'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                      }}
                    >
                      <div className='tooltip-tfi'>
                        <p style={{color: '#222'}}>
                          Check this box if you need to search for policies by the name of the
                          members.
                        </p>
                      </div>
                    </Tooltip>
                  </span>
                }
              />
            </div>

            <Row className='mt-5'>
              <Col sm={12} md={6} xl={4}>
                <div className='d-flex align-items-center gap-1'>
                  <span style={{flex: 0}}>Show</span>
                  <Form.Select
                    value={first}
                    onChange={(e) => {
                      setIsPag(false)
                      setOffset(0)
                      setFirst(Number(e.target.value))
                      setPage(1)
                    }}
                    style={{flex: 0, flexBasis: '80px'}}
                  >
                    <option>2</option>
                    <option>5</option>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>50</option>
                  </Form.Select>
                  <span style={{flex: 0}}>entries</span>
                </div>
              </Col>
              <Col className='d-none d-xl-block' xl={4}>
                <p className='d-flex align-items-center justify-content-center h-100 w-100'>
                  Showing {page} to {Math.ceil(dataArr.length / first)} of 100 entries
                </p>
              </Col>
              <Col sm={12} md={6} xl={4}>
                <div className='d-flex align-items-center justify-content-end'>
                  <Pagination size='sm'>
                    <Pagination.Prev
                      disabled={page <= 1}
                      onClick={() => {
                        if (page <= 1) return
                        setPage((p) => p - 1)
                      }}
                    />
                    {Array.from({length: Math.ceil(dataArr.length / first)})
                      .map((_, i) => i + 1)
                      .map((n) => (
                        <Pagination.Item
                          key={n}
                          active={n === page}
                          onClick={() => {
                            setPage(n)
                          }}
                        >
                          {n}
                        </Pagination.Item>
                      ))}
                    {loading && (
                      <Pagination.Item>
                        <Spinner animation='grow' size='sm' />
                      </Pagination.Item>
                    )}
                    <Pagination.Next
                      disabled={!data?.allQuotes.pageInfo.hasNextPage}
                      onClick={() => {
                        setIsPag(true)
                        setOffset((p) => p + first)
                        setPage((p) => p + 1)
                      }}
                    />
                  </Pagination>
                </div>
              </Col>
            </Row>
            <div style={{width: '100%', overflowX: 'auto'}} className='mt-5'>
              <table
                className='table table-hover table-sm dataTable no-footer dtr-inline w-100'
                style={{whiteSpace: 'nowrap'}}
              >
                <thead>
                  <tr role='row'>
                    <th></th>
                    <th className='fw-bold'>Agent</th>
                    <th className='fw-bold'>Client</th>
                    <th className='fw-bold'>Policy</th>
                    <th className='fw-bold'>Status</th>
                    <th className='fw-bold'>Effective</th>
                    {/* <th className='fw-bold'>Assigned to</th> */}
                    <th className='fw-bold'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {dataArr.length === 0 && (
                    <tr>
                      <td colSpan={8}>
                        <div className='d-flex flex-center' style={{height: '30vh'}}>
                          There are no quotes currently added
                        </div>
                      </td>
                    </tr>
                  )}
                  {loading && (
                    <tr>
                      <td colSpan={8}>
                        <div className='d-flex flex-center' style={{height: '30vh'}}>
                          <Spinner animation='border' />
                        </div>
                      </td>
                    </tr>
                  )}
                  {dataArr.slice(first * page - first, first * page).map(({node}) => (
                    <tr key={node.id}>
                      <td />
                      <td>
                        <div>
                          <span
                            className='d-flex align-items-center justify-content-center fs-2'
                            style={{
                              borderRadius: '100%',
                              background: 'rgba(85, 120, 235, .1)',
                              color: '#5578eb',
                              height: '50px',
                              width: '50px',
                              textTransform: 'uppercase',
                            }}
                            data-tooltip-id={`myt${node.id}`}
                          >
                            {node.user.firstName[0]}
                            {node.user.lastName[0]}
                          </span>
                          <Tooltip
                            id={`myt${node.id}`}
                            place='bottom'
                            style={{
                              zIndex: 99999,
                              backgroundColor: 'white',
                              color: 'black',
                              boxShadow:
                                'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                            }}
                          >
                            <div className='tooltip-tfi' style={{color: '#222'}}>
                              <h6 className='text-center'>Agent information:</h6>
                              <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='fw-bold'>Name</p>
                                {/* <p>{node.user_name}</p> */}
                                <p>
                                  {node.user.firstName} {node.user.lastName}
                                </p>
                              </div>
                              {/* <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='fw-bold'>NPN</p>
                                <p>{node.user_npn || 'N/R'}</p>
                                <p>user_npn </p>
                              </div> */}
                              <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='fw-bold'>Email</p>
                                <p style={{maxWidth: '140px', wordBreak: 'break-all'}}>
                                  {node.user.email}
                                </p>
                              </div>
                              <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='fw-bold'>Phone</p>
                                <p>{node.user.cellphone || 'N/R'}</p>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                      <td>
                        <InfoClientPolicy
                          isQuote
                          id={node.id}
                          members={node.memberPolicySet.edges}
                        />
                      </td>
                      <td>
                        <div>
                          <span
                            className='text-primary'
                            style={{cursor: 'pointer'}}
                            data-tooltip-id={`myt2${parseId(node.id)}`}
                          >
                            {node.planName || 'N/R'}
                          </span>
                          <p className='m-0 p-0'>{node.memberPolicySet.edges.length} members</p>
                          {node.carrier && <p className='m-0 p-0'>ID:{node.carrier.name}</p>}
                          <Tooltip
                            id={`myt2${parseId(node.id)}`}
                            place='top'
                            style={{
                              zIndex: 99999,
                              backgroundColor: 'white',
                              color: 'black',
                              boxShadow:
                                'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                            }}
                          >
                            <div className='tooltip-tfi' style={{color: '#222', maxWidth: '220px'}}>
                              <h6 className='text-center'>More information:</h6>
                              <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='fw-bold m-0 p-0'>Product:</p>
                                <p className='m-0 p-0'>program_name</p>
                              </div>
                              <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='fw-bold m-0 p-0'>Plan:</p>
                                <p className='m-0 p-0'>{node.planName || 'N/R'}</p>
                              </div>
                              <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='fw-bold m-0 p-0'>Metal:</p>
                                <p
                                  className='m-0 p-0'
                                  style={{maxWidth: '140px', wordBreak: 'break-all'}}
                                >
                                  {METALS_NAME[node.metalLevel]}
                                </p>
                              </div>
                              <div className='m-0 p-0 gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='p-0 m-0 fw-bold'>Premium:</p>
                                <p>${node.premium}</p>
                              </div>
                              <div className='m-0 p-0 gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='m-0 p-0 fw-bold'>NPN:</p>
                                <p>{node.NPN}</p>
                              </div>
                              <div className='m-0 p-0 gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='m-0 p-0 fw-bold'>Blocked:</p>
                                <p className='m-0 p-0'>
                                  {/* {node.blocked ? (
                                    <>
                                      <i className='bi bi-x' style={{color: 'red'}} /> No
                                    </>
                                  ) : (
                                    <>
                                      <i className='bi bi-x' style={{color: 'green'}} /> Yes
                                    </>
                                  )} */}
                                  N/R
                                </p>
                              </div>
                              <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='m-0 p-0 fw-bold'>is this a duplicate?</p>
                                <p className='m-0 p-0'>
                                  {/* {node.duplicated ? (
                                    <>
                                      <i className='bi bi-x' style={{color: 'red'}} /> No
                                    </>
                                  ) : (
                                    <>
                                      <i className='bi bi-x' style={{color: 'green'}} /> Yes
                                    </>
                                  )} */}
                                  N/R
                                </p>
                              </div>
                              <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='m-0 p-0 fw-bold'>Last duplicate:</p>
                                {/* <p>{node.duplicated?.display}</p> */}
                                <p className='m-0 p-0'></p>
                              </div>
                              <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='m-0 p-0 fw-bold'>Submitted:</p>
                                <p className='m-0 p-0'>node.submitted?.display</p>
                              </div>
                              <div className='gap-2 d-flex align-items-center justify-content-between mb-3'>
                                <p className='fw-bold m-0 p-0'>Last update:</p>
                                <p className='m-0 p-0'>node.last_update?.display</p>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                      <td>
                        <div>
                          <BadgeStatePolicy state={node.status} />
                          <span className='d-block'>
                            Documents:{' '}
                            <span
                              style={{
                                color:
                                  node.stateDocs === 'COMPLETE'
                                    ? 'green'
                                    : node.stateDocs === 'PENDING'
                                    ? 'orange'
                                    : 'blue',
                              }}
                            >
                              {node.stateDocs}
                            </span>
                          </span>
                          <span>
                            Payment: <span className='fw-bold'>{node.paymentStatus}</span>
                          </span>
                        </div>
                      </td>
                      {/* <td>
                        
                      </td> */}
                      <td>{moment(node.effectiveDate).format('DD MMM, YY')}</td>
                      {/* <td>node.assigned_to_name</td> */}
                      <td>
                        <Button
                          variant='outline-primary'
                          size='sm'
                          style={{border: '1px solid #3e97ff'}}
                          onClick={() => {
                            onOpenPreview()
                            setInfoPreview(node)
                          }}
                        >
                          Preview
                        </Button>
                        {/* <Dropdown as={ButtonGroup}>
                          <Dropdown.Toggle
                            split
                            variant='outline-primary'
                            size='sm'
                            id='dropdown-custom-2'
                            style={{border: '1px solid #3e97ff'}}
                          />
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey='1'>Action</Dropdown.Item>
                            <Dropdown.Item eventKey='2'>Another action</Dropdown.Item>
                            <Dropdown.Item eventKey='3' active>
                              Active Item
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey='4'>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>

      {isOpen && <ModalAddQuote refetch={refetch} isOpen={isOpen} onClose={onClose} />}
      {isOpenPreview && infoPreview && (
        <ModalPreview info={infoPreview} isOpen={isOpenPreview} onClose={onClosePreview} isQuote />
      )}
    </div>
  )
}
