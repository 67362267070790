import {useMutation} from '@apollo/client'
import {Button, Modal, Spinner} from 'react-bootstrap'
import {CANCEL_POLICY} from '../../../gql/mutations/policyMutation'
import {IResCancelPolicy} from '../soldTypes'
import {parseId} from '../../../helpers'
import {useEffect} from 'react'
import {toast} from 'react-toastify'

interface IModalProps {
  id: string
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}
export const ModalCancelPolicy: React.FC<IModalProps> = ({isOpen, onClose, id, refetch}) => {
  const [gqlCancel, {data, loading, error}] = useMutation<IResCancelPolicy>(CANCEL_POLICY)

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return

    if (data && data.cancelPolicy.success) {
      toast.success('Policy cancelled successfully')
      refetch()
      onClose()
    } else if (data.cancelPolicy.errors && data.cancelPolicy.errors.length > 0) {
      toast.error(`Error ${data.cancelPolicy.errors[0].message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleDelete = () => {
    gqlCancel({
      variables: {
        id: parseId(id),
      },
    })
  }
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel policy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='fs-2'>Are you sure you want to cancel this policy?</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button variant='danger' onClick={handleDelete} disabled={loading}>
          {loading && <Spinner animation='border' size='sm' className='me-2' />}
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
