import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useGetUser} from '../../store/userStore'
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useEffect, useState} from 'react'
import {useMutation} from '@apollo/client'
import {CHANGE_OWNER_PASSWORD} from '../../gql/mutations/userMutations'
import {IResUpdateOwnPass} from '../../types/membersPolicy'
import {toast} from 'react-toastify'

const getParseData = (str: string) => {
  try {
    const res = JSON.parse(str) as {name: string}
    return res
  } catch (_) {
    return {}
  }
}

export const ProfilePage = () => {
  const [gqlChangePass, {data, loading, error}] =
    useMutation<IResUpdateOwnPass>(CHANGE_OWNER_PASSWORD)
  const {user, logout} = useGetUser()

  const [showPass, setShowPass] = useState(false)
  const [showConfirmPass, setShowConfirmPass] = useState(false)
  const [showOldPass, setShowOldPass] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      oldPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .required('Password is require'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Password is require'),
      oldPassword: Yup.string().required('Old Password is require'),
    }),
    onSubmit: (v, o) => {
      if (v.password && v.password !== v.confirmPassword) {
        o.setFieldError('confirmPassword', 'Passwords must match')
        return
      }
      gqlChangePass({
        variables: {
          claveActual: v.oldPassword,
          nuevaClave: v.password,
        },
      })
    },
  })

  useEffect(() => {
    if (error) toast.error(`Error: ${error}`)
  }, [error])

  useEffect(() => {
    if (!data) return

    if (data.changeOwnPasswordMutation.success) {
      toast.success('Password updated successfully')
      logout()
    } else if (
      data.changeOwnPasswordMutation.errors &&
      data.changeOwnPasswordMutation.errors.length > 0
    ) {
      toast.error(
        `Error: ${data.changeOwnPasswordMutation.errors[0]?.message || 'Something wrong!'}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!user) return null

  const node = getParseData(user.settings?.[0]) as {name: string}

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/tfi/logo-icoN.png')} alt={node.name} />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {node.name}
                    </span>
                    <span>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='/#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {user.payload.email || 'N/R'}
                    </a>
                  </div>
                </div>

                <div className='d-flex my-4'></div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    {/* <InfoBalance id={`${parseId(node.id)}`} flagBalance={flagBalance} /> */}

                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                        <div className='fs-2 fw-bolder'>75</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>60%</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Success Rate</div>
                    </div> */}
                  </div>
                </div>
                {/* strat*/}{' '}
                {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                    <span className='fw-bolder fs-6'>50%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{width: '50%'}}
                    ></div>
                  </div>
                </div>{' '} */}
                {/* End*/}
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <button className={`nav-link text-active-primary me-6 active`}>Settings</button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Change Password</h3>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className='card-body border-top p-9'>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Form.Group>
                  <Form.Label>Old password</Form.Label>
                  <InputGroup className='mb-3'>
                    <Form.Control
                      type={showOldPass ? 'text' : 'password'}
                      placeholder='Insert old password'
                      aria-label='Insert oldPassword'
                      aria-describedby='oldPassword'
                      name='oldPassword'
                      value={formik.values.oldPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={Boolean(formik.errors.oldPassword && formik.touched.oldPassword)}
                    />
                    <Button
                      variant='outline-secondary'
                      id='button-addon3'
                      style={{border: '1px solid #dbdfe9'}}
                      onClick={() => setShowOldPass((p) => !p)}
                    >
                      <i
                        className={`bi bi-${showPass ? 'eye-slash' : 'eye'} fs-2`}
                        style={{color: 'inherit'}}
                      />
                    </Button>
                  </InputGroup>
                  {formik.errors.oldPassword && formik.touched.oldPassword && (
                    <small className='text-danger'>{formik.errors.oldPassword}</small>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <InputGroup className='mb-3'>
                    <Form.Control
                      type={showPass ? 'text' : 'password'}
                      placeholder='Insert password'
                      aria-label='Insert password'
                      aria-describedby='password'
                      name='password'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={Boolean(formik.errors.password && formik.touched.password)}
                    />
                    <Button
                      variant='outline-secondary'
                      id='button-addon2'
                      style={{border: '1px solid #dbdfe9'}}
                      onClick={() => setShowPass((p) => !p)}
                    >
                      <i
                        className={`bi bi-${showPass ? 'eye-slash' : 'eye'} fs-2`}
                        style={{color: 'inherit'}}
                      />
                    </Button>
                  </InputGroup>
                  {formik.errors.password && formik.touched.password && (
                    <small className='text-danger'>{formik.errors.password}</small>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group>
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup className='mb-3'>
                    <Form.Control
                      type={showConfirmPass ? 'text' : 'password'}
                      placeholder='Confirm password'
                      aria-label='Confirm password'
                      aria-describedby='Confirm password'
                      name='confirmPassword'
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={Boolean(
                        formik.errors.confirmPassword && formik.touched.confirmPassword
                      )}
                    />
                    <Button
                      variant='outline-secondary'
                      id='Confirm-pass'
                      onClick={() => setShowConfirmPass((p) => !p)}
                      style={{border: '1px solid #dbdfe9'}}
                    >
                      <i
                        className={`bi bi-${showConfirmPass ? 'eye-slash' : 'eye'} fs-2`}
                        style={{color: 'inherit'}}
                      />
                    </Button>
                  </InputGroup>
                  {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                    <small className='text-danger'>{formik.errors.confirmPassword}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-info'>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
