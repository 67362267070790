import {Route, Routes} from 'react-router-dom'
import {PipelinesPage} from '../tfi/pages/pipelines/PipelinesPage'
import NotFoundPage from '../tfi/pages/notFound/NotFoundPage'
import {LeadsPage} from '../tfi/pages/leads/LeadsPage'
import {StagePage} from '../tfi/pages/stages/StagePage'
import {LeadLossReasonPage} from '../tfi/pages/leadLossReason/LeadLossReasonPage'
import {TagManagerPage} from '../tfi/pages/tagManager/TagManagerPage'
import {MembersPage} from '../tfi/pages/members/MembersPage'
import {AssignmentGroupsPage} from '../tfi/pages/assignmentGroups/AssignmentGroupsPage'
import {LeadSourcePage} from '../tfi/pages/leadSource/LeadSourcePage'
import {PolicyTypePage} from '../tfi/pages/policyType/PolicyTypePage'
import {OneLeadPage} from '../tfi/pages/oneLead/OneLeadPage'
import {OneAddQuotePage} from '../tfi/pages/oneAddQuote/OneAddQuotePage'
import {SoldLeadPage} from '../tfi/pages/soldLead/SoldLeadPage'
import {AddUserPage} from '../tfi/pages/addUsers/AddUserPage'
import {PoliciesPage} from '../tfi/pages/policies/PoliciesPage'
import {CarriersPage} from '../tfi/pages/carriers/CarriersPage'
import {OneUserPage} from '../tfi/pages/oneUser/OneUserPage'
import {OneCarrierPage} from '../tfi/pages/oneCarrier/OneCarrierPage'
import {useGetUser} from '../tfi/store/userStore'
import {hasPermission} from '../tfi/helpers'
import {ALL_PERMISSIONS} from '../tfi/helpers/consts'
import {IntegrationsPage} from '../tfi/pages/integrations/IntegrationsPage'
import {InboxPage} from '../tfi/pages/inbox/InboxPage'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {ProfilePage} from '../tfi/pages/profile/ProfilePage'
import {HoomeReportPage} from '../tfi/pages/homeReports/HoomeReportPage'
import {HomePoliciesPage} from '../tfi/pages/homeReportsPolicies/HomePoliciesPage'
import {CommissionsPage} from '../tfi/pages/commissions/CommissionsPage'
import {AllQuotesPage} from '../tfi/pages/quotes/AllQuotesPage'
import {HomeQuotesPage} from '../tfi/pages/homeReportsQuotes/HomeQuotesPage'
import {OneQuotePage} from '../tfi/pages/oneQuote/OneQuotePage'

const CLIENT_ID = '1005174958588-ht8kplhn013evfjg2de734oc2upk6djp.apps.googleusercontent.com'

export const TFIRoutes = () => {
  const permissions = useGetUser((s) => s.user?.permissions)
  return (
    <Routes>
      <Route path='pipelines' element={<PipelinesPage />} />
      {permissions && hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_LEADS) && (
        <Route path='leads' element={<LeadsPage />} />
      )}
      <Route path='stages/:id' element={<StagePage />} />
      <Route path='/lead-loss-reasons' element={<LeadLossReasonPage />} />
      <Route path='/tag-manager' element={<TagManagerPage />} />
      <Route path='/members' element={<MembersPage />} />
      <Route path='/assignment-groups' element={<AssignmentGroupsPage />} />
      <Route path='/lead-sources' element={<LeadSourcePage />} />
      <Route path='/policy-type-setup' element={<PolicyTypePage />} />
      {permissions && hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_LEADS) && (
        <Route path='/lead/:id' element={<OneLeadPage />} />
      )}
      <Route path='/quote/:id' element={<OneAddQuotePage />} />
      <Route path='/sold' element={<SoldLeadPage />} />
      {permissions && hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_USUARIOS) && (
        <Route path='/users' element={<AddUserPage />} />
      )}
      {permissions && hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_USUARIOS) && (
        <Route path='/user/:id' element={<OneUserPage />} />
      )}
      {permissions && hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_POLIZAS) && (
        <Route path='/policies' element={<PoliciesPage />} />
      )}
      <Route path='/carriers' element={<CarriersPage />} />
      <Route path='/carrier/:id' element={<OneCarrierPage />} />

      <Route
        path='/integrations'
        element={
          <GoogleOAuthProvider clientId={CLIENT_ID}>
            <IntegrationsPage />
          </GoogleOAuthProvider>
        }
      />
      <Route path='/inbox' element={<InboxPage />} />
      <Route path='/profile' element={<ProfilePage />} />
      <Route path='/dashboard/lead' element={<HoomeReportPage />} />
      <Route path='/commissions' element={<CommissionsPage />} />
      <Route path='/dashboard/policy' element={<HomePoliciesPage />} />
      <Route path='/dashboard/quote' element={<HomeQuotesPage />} />
      <Route path='/one-quote/:id' element={<OneQuotePage />} />
      <Route path='/all-quotes' element={<AllQuotesPage />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  )
}
