import {Modal, Button, Spinner, Form, Table, Pagination} from 'react-bootstrap'
import {formatPhoneNumber, parseId} from '../../../helpers'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {IResAssignLeads} from '../../../types/leads'
import {REASSIGN_LEADS_USER} from '../../../gql/mutations/leadMutations'
import {useLazyQuery, useMutation, useQuery} from '@apollo/client'
import {IAllMembersEdges, IResAllMembers} from '../../../types/members'
import {GET_FILTERS_MEMBERS} from '../../../gql/queries/membersQuery'
import {GET_ALL_LIST_LEADS, GET_TOTAL_LEADS_BY_USER} from '../../../gql/queries/leadsQuery'
import {IResAllListLead, ITotalLeads} from '../leadTypes'
import moment from 'moment'
import {IStagePipelineSet} from '../../../types/pipelines'

interface ModalReasingLeadsProps {
  isOpen: boolean
  onClose: () => void
  updateListLeads: () => void
  info: {
    fromUserId: string
    fromUserName: string
    pipelineId: string
    statePipeline: string
    stateNamePipeline: string
    searchName: string
    searchPhone: string
    dateFrom: Date | null
    dateTo: Date | null
    assignType: string
    leadsSelected?: {id: string; name: string; stage: string; user: string}[]
  }
  iStagePipeline: IStagePipelineSet[] | null
}

export const ModalReasingLeads = ({
  isOpen,
  onClose,
  info,
  updateListLeads,
  iStagePipeline,
}: ModalReasingLeadsProps) => {
  const firstML = 20
  const [offsetML, setOffsetML] = useState(0)

  const [disabledAssign, setDisabledAssign] = useState(true)

  const [userFrom, setUserFrom] = useState('')
  const [userTo, setUserTo] = useState('')
  const [stageTo, setStageTo] = useState('')
  const [byFiltersApplied, setFiltersApplied] = useState('')

  const [filteredUsers, setFilteredUsers] = useState<IAllMembersEdges[] | undefined>(undefined)

  const {
    data: dataMembersFrom,
    loading: loadingMember,
    error: errorMembers,
  } = useQuery<IResAllMembers>(GET_FILTERS_MEMBERS, {
    fetchPolicy: 'no-cache',
  })

  const [getTotalLeads, {data: countTotalLeads, loading: loadingMemberLU, error: errorMembersLu}] =
    useLazyQuery<ITotalLeads>(GET_TOTAL_LEADS_BY_USER, {
      fetchPolicy: 'no-cache',
    })

  const [gqlGetLeads, {data: allDataLeads, loading: loadingAllLeads}] =
    useLazyQuery<IResAllListLead>(GET_ALL_LIST_LEADS, {fetchPolicy: 'no-cache'})

  const [gqlCall, {data, loading, error}] = useMutation<IResAssignLeads>(REASSIGN_LEADS_USER)

  useEffect(() => {
    const filteredUsersA = dataMembersFrom?.allUsers.edges.filter(
      (user) => user.node.id !== userFrom
    )
    setFilteredUsers(filteredUsersA)

    if (userFrom)
      getTotalLeads({variables: {pipelineId: parseId(info.pipelineId), userId: parseId(userFrom)}})
  }, [dataMembersFrom, getTotalLeads, info.pipelineId, userFrom])

  useEffect(() => {
    if (!info.pipelineId) return

    if (info.fromUserId) {
      const filteredUsersA = dataMembersFrom?.allUsers.edges.filter(
        (user) => user.node.id !== info.fromUserId
      )
      setFilteredUsers(filteredUsersA)
    } else {
      const filteredUsersA = dataMembersFrom?.allUsers.edges
      setFilteredUsers(filteredUsersA)
    }

    if (info.assignType === 'by-filters') {
      let filtersApplied = ''
      if (info.dateFrom && info.dateTo)
        filtersApplied +=
          'Dates from [' +
          moment(info.dateFrom).format('DD-MMM-YY') +
          '] to [' +
          moment(info.dateTo).format('DD-MMM-YY') +
          ']'
      else if (info.dateFrom)
        filtersApplied +=
          (filtersApplied.length > 0 ? ',' : '') +
          'Date from [' +
          moment(info.dateFrom).format('DD/MMM/YY') +
          '], '
      else if (info.dateTo)
        filtersApplied +=
          (filtersApplied.length > 0 ? ',' : '') +
          'Date to [' +
          moment(info.dateTo).format('DD/MMM/YY') +
          '], '

      if (info.statePipeline)
        filtersApplied +=
          (filtersApplied.length > 0 ? ', ' : '') + 'from stage [' + info.stateNamePipeline + ']'

      if (info.fromUserId && info.fromUserName)
        filtersApplied +=
          (filtersApplied.length > 0 ? ', ' : '') + 'from user [' + info.fromUserName + ']'

      if (info.searchName)
        filtersApplied +=
          (filtersApplied.length > 0 ? ', ' : '') + ' name contains "' + info.searchName + '"'

      if (info.searchPhone)
        filtersApplied +=
          (filtersApplied.length > 0 ? ', ' : '') + ' phone  contains "' + info.searchPhone + '"'

      setFiltersApplied(filtersApplied)
      // console.log('ByFilter', filtersApplied)
      gqlGetLeads({
        variables: {
          pipelineId: parseId(info.pipelineId),
          userId: parseId(info.fromUserId ?? ''),
          stagePipelineId:
            (parseId(info.statePipeline) as number) > 0
              ? (parseId(info.statePipeline) as number)
              : null,
          name_Icontains: info.searchName,
          phone_Icontains: info.searchPhone,
          fromDate: info.dateFrom != null ? moment(info.dateFrom).format('YYYY-MM-DD') : null,
          toDate: info.dateTo != null ? moment(info.dateTo).format('YYYY-MM-DD') : null,
          first: firstML,
          offset: offsetML,
        },
      })
    }
  }, [
    dataMembersFrom?.allUsers.edges,
    gqlGetLeads,
    info.assignType,
    info.dateFrom,
    info.dateTo,
    info.fromUserId,
    info.pipelineId,
    info.searchName,
    info.searchPhone,
    info.statePipeline,
    offsetML,
    firstML,
    info.stateNamePipeline,
    info.fromUserName,
  ])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
    if (errorMembers) toast.error(`Error: ${errorMembers.message}`)
    if (errorMembersLu) toast.error(`Error: ${errorMembersLu.message}`)
  }, [error, errorMembers, errorMembersLu])

  useEffect(() => {
    setUserFrom(info.fromUserId)
  }, [info.fromUserId])

  useEffect(() => {
    setDisabledAssign(true)
    if (userTo || stageTo) {
      if (byFiltersApplied || userFrom || (info.leadsSelected && info.leadsSelected.length > 0)) {
        setDisabledAssign(false)
      }
    }
  }, [userTo, stageTo, byFiltersApplied, info.leadsSelected, userFrom])

  useEffect(() => {
    if (!data) return
    if (data.assignLeadsToUser?.success) {
      toast.success(data.assignLeadsToUser?.message)
      updateListLeads()
      modalClose()
    } else if (data.assignLeadsToUser?.errors && data.assignLeadsToUser?.errors.length > 0) {
      toast.error(`Error: ${data.assignLeadsToUser?.errors[0]?.message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleReAssignLeads = () => {
    if (!info.pipelineId) return;
  
    // Función auxiliar para convertir a null si es 0 o NaN
    const safeParseId = (value: any): number | null => {
      const id = parseId(value) as number;
      return !id || isNaN(id) || id === 0 ? null : id;
    };
  
    const leadIds: (number | null)[] = (info.leadsSelected ?? [])
      .map((lead) => safeParseId(lead.id))
      .filter((id): id is number => id !== null); // eliminamos los nulls si no querés enviarlos
  
    gqlCall({
      variables: {
        pipelineId: safeParseId(info.pipelineId),
        fromUserId: safeParseId(userFrom),
        fromStagePipelineId: safeParseId(info.statePipeline),
        fromDate: info.dateFrom ? moment(info.dateFrom).format('YYYY-MM-DD') : null,
        toDate: info.dateTo ? moment(info.dateTo).format('YYYY-MM-DD') : null,
        name_Icontains: info.searchName,
        phone_Icontains: info.searchPhone,
        toUserId: safeParseId(userTo),
        toStagePipelineId: safeParseId(stageTo),
        leadIds,
      },
    });
  };
  

  const modalClose = (): void => {
    info = {
      fromUserId: '',
      fromUserName: '',
      pipelineId: '',
      statePipeline: '',
      stateNamePipeline: '',
      searchName: '',
      searchPhone: '',
      dateFrom: null,
      dateTo: null,
      assignType: '',
      leadsSelected: [],
    }
    onClose()
  }

  return (
    <Modal show={isOpen} onHide={modalClose} size='lg' className='modal-reassing-leads'>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className='d-flex flex-column gap-0'>
            <span className='fw-bold '>
              {info.assignType === 'by-user' ? 'Reassign leads by user ' : ''}
              {info.assignType === 'by-filters' ? 'Reassign leads by filters: ' : ''}
              {info.assignType === 'by-leads-selected' ? 'Reassign selected leads' : ''}
            </span>
            {info.assignType === 'by-filters' && (
              <span className='filters-applied'>{byFiltersApplied}</span>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column gap-4'>
          <div className='reassign-leads reassign-from'>
            {info.assignType === 'by-user' && <h2 className='fs-1'>From </h2>}
            {info.assignType === 'by-filters' && loadingAllLeads && (
              <h2 className='fs-1'>Leads lists </h2>
            )}
            {!loadingMember &&
              !allDataLeads &&
              dataMembersFrom &&
              info.assignType !== 'by-leads-selected' &&
              info.fromUserId && (
                <>
                  <div>
                    <Form.Select aria-label='Lead source' name='leadSource'>
                      <option value={info.fromUserId}>{info.fromUserName}</option>
                    </Form.Select>
                  </div>
                </>
              )}
            {!loadingMember &&
              !loadingAllLeads &&
              !allDataLeads &&
              dataMembersFrom &&
              !(info.leadsSelected && info.leadsSelected?.length > 0) &&
              !info.fromUserId && (
                <div>
                  <Form.Select
                    aria-label='Lead source'
                    name='leadSource'
                    value={userFrom}
                    onChange={(e) => {
                      const {value} = e.target
                      if (value) {
                        setUserFrom(value)
                      }
                      if (e.target.value === '') {
                        setUserFrom('')
                      }
                    }}
                  >
                    <option value=''>Please select</option>
                    {dataMembersFrom?.allUsers.edges
                      .filter(({node}) => node.id !== info.fromUserId)
                      .map(({node}) => (
                        <option key={node.id} value={node.id}>
                          {node.firstName + ' ' + node.lastName}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              )}
            {info.assignType === 'by-user' && loadingMemberLU && (
              <span>
                <Spinner animation='border' size='sm' />
              </span>
            )}
            {countTotalLeads && userFrom && userFrom !== '' && info.assignType === 'by-user' && (
              <div className='total-leads-assign-to'>
                {countTotalLeads.totalLeads ?? '0'} leads to Assign
              </div>
            )}
            {info.assignType === 'by-filters' && loadingAllLeads && (
              <div>
                <div className='d-flex justify-content-between mb-2'>
                  <Spinner animation='border' size='sm' />
                </div>
              </div>
            )}
            {!loadingAllLeads &&
              (allDataLeads || (info.leadsSelected && info.leadsSelected?.length > 0)) && (
                <div>
                  <div className='d-flex justify-content-between mb-2'>
                    <h2 className='fs-1'>Leads lists</h2>
                    {info.assignType === 'by-filters' && (
                      <div className='d-flex align-items-center justify-content-end'>
                        <Pagination size='sm'>
                          <Pagination.Prev
                            disabled={offsetML === 0}
                            onClick={() => {
                              setOffsetML((p) => p - firstML)
                            }}
                          />
                          {
                            <span className='d-flex align-items-center text-secondary'>
                              {offsetML / firstML + 1}/
                              {allDataLeads?.totalLeads ? (
                                Math.ceil(allDataLeads?.totalLeads / firstML)
                              ) : allDataLeads?.totalLeads == null ? (
                                <Spinner animation='grow' size='sm' />
                              ) : (
                                allDataLeads?.totalLeads
                              )}
                            </span>
                          }
                          <Pagination.Next
                            disabled={!allDataLeads?.allLeads.pageInfo.hasNextPage}
                            onClick={() => {
                              setOffsetML((p) => p + firstML)
                            }}
                          />
                        </Pagination>
                      </div>
                    )}
                    <span
                      className='d-flex align-items-center justify-content-center px-3 py-1 text-primary'
                      style={{
                        borderRadius: '20px',
                        backgroundColor: '#ddf1ff',
                        fontWeight: 'bold',
                      }}
                    >
                      {info.assignType === 'by-filters' && allDataLeads?.totalLeads}
                      {info.assignType === 'by-leads-selected' &&
                      info.leadsSelected &&
                      info.leadsSelected?.length > 0
                        ? info.leadsSelected?.length
                        : ''}
                    </span>
                  </div>
                  <div className='table-scrollable table-container'>
                    <Table
                      hover
                      size='sm'
                      className='table-scrollable table-scroll table-light table-striped table-bordered table-sm'
                    >
                      <thead>
                        <tr role='row'>
                          <th className='fw-bold text-center'>#</th>
                          <th className='fw-bold'>Name</th>
                          <th className='fw-bold'>Stage</th>
                          <th className='fw-bold'>User</th>
                        </tr>
                      </thead>
                      <tbody style={{maxHeight: '300px', overflowY: 'auto'}}>
                        {allDataLeads?.allLeads?.edges
                          .sort((a, b) => a.node.stagePipeline.order - b.node.stagePipeline.order)
                          .map((lead, index) => (
                            <tr className='cursor-pointer' key={lead.node.id}>
                              <td className='text-center'>{index + offsetML + 1}</td>
                              <td>
                                {lead.node.name}
                                {info.searchPhone ? (
                                  <span className='fst-italic fs-7 p-2'>
                                    <i className='bi bi-phone fs-8'></i>
                                    {formatPhoneNumber(lead.node.phone)}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td>{lead.node.stagePipeline.name}</td>
                              <td>
                                {lead.node.user?.edges[0]?.node.firstName}{' '}
                                {lead.node.user?.edges[0]?.node.lastName}
                              </td>
                            </tr>
                          ))}
                        {info.leadsSelected?.map((lead, index) => (
                          <tr className='cursor-pointer' key={lead.id}>
                            <td className='text-center'>{index + 1}</td>
                            <td>{lead.name}</td>
                            <td>{lead.stage}</td>
                            <td>{lead.user ?? ''}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
          </div>
          <div className='reassign-leads reassign-to'>
            <div className='d-flex justify-content-between'>
              <div className='reassign-to-user'>
                <h6 className='fs-4'>To user</h6>
                {!loadingMember &&
                  ((userFrom && userFrom !== '') || allDataLeads || info.leadsSelected) && (
                    <div>
                      <Form.Select
                        aria-label='Lead source'
                        name='leadSource'
                        onChange={(e) => {
                          const {value} = e.target
                          if (value) {
                            setUserTo(value)
                          }
                          if (e.target.value === '') {
                            setUserTo('')
                          }
                        }}
                      >
                        <option value=''>Please select</option>
                        {filteredUsers
                          ?.filter(({node}) => node.id !== info.fromUserId)
                          .map(({node}) => (
                            <option key={node.id} value={node.id}>
                              {node.firstName + ' ' + node.lastName}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  )}
              </div>
              <div className='reassign-to-stage'>
                <h4 className='fs-4'>To stage</h4>
                {!loadingMember &&
                  ((userFrom && userFrom !== '') || allDataLeads || info.leadsSelected) && (
                    <div>
                      <Form.Select
                        aria-label='Lead source'
                        name='leadSource'
                        onChange={(e) => {
                          const {value} = e.target
                          if (value) {
                            setStageTo(value)
                          }
                          if (e.target.value === '') {
                            setStageTo('')
                          }
                        }}
                      >
                        <option value=''>Please select</option>
                        {iStagePipeline
                          ?.sort((a, b) => a.node.order - b.node.order)
                          .map(({node}) => (
                            <option key={node.id} value={node.id}>
                              {node.name}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            modalClose()
          }}
          variant='light'
        >
          Cancel
        </Button>
        <Button onClick={handleReAssignLeads} variant='primary' disabled={disabledAssign}>
          {loading && <Spinner animation='border' className='me-2' size='sm' />}
          <i className='bi bi-person-up' />
          Reassign Leads
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
