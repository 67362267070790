import {useLazyQuery} from '@apollo/client'
import {GET_MARKET_PLACE_2} from '../../gql/queries/leadsQuery'
import {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {IResultsJson} from '../oneLead/oneleadTypes'
import {useParams} from 'react-router-dom'
import {validateJsonQuote} from '../../helpers'
import {AccordionForm} from './components/AccordionForm'
import {IForm, IPerson} from './oneQuoteTypes'
import {ResultsCards} from './components/ResultsCards'
import {LoadingOneAddQuote} from './components/LoadingOneAddQuote'

interface IResTest {
  marketplace2: string
}

export const OneAddQuotePage = () => {
  const [gqlGetMarket, {data, loading, error}] = useLazyQuery<IResTest>(GET_MARKET_PLACE_2, {
    fetchPolicy: 'no-cache',
  })

  const {id} = useParams()

  const [arrPeople, setArrPeople] = useState<IPerson[]>([])

  const [infoEnrollement, setInfoEnrollement] = useState({
    id: null as null | number,
    effectiveDate: '',
    planId: '',
    usesTobacco: false,
  })

  const [formData, setFormData] = useState<IForm>({
    queryType: 'BUSCAR_PLANES',
    year: 2024,
    householdIncome: '0',
    market: 'Individual',
    countyfips: '', //17033
    state: '', //IL
    zipCode: '', //62466
    limit: 10,
    offset: 0,
    order: 'asc',
    utilizationLevel: 'Low',
    personOne: {
      age: '',
      dob: '',
      gender: 'Male',
      isPregnant: false,
      pregnantWith: '',
      isParent: false,
      usesTobacco: false,
      lastTobaccoUseDate: '',
      hasMec: false,
      aptcEligible: false,
      utilizationLevel: '',
      relationship: '',
      doesNotCohabitate: '',
      currentEnrollment: {} as IForm['personOne']['currentEnrollment'],
    },
    people: [],
  })

  const [errorData, setErrorData] = useState(false)

  const [result, setResult] = useState<null | IResultsJson>(null)

  const moreArr = useRef(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [arrOffset, setArrOffset] = useState<number[]>([])
  const [infoPagination, setInfoPagination] = useState({hasNext: true, offset: 0, initialOffset: 0})

  useEffect(() => {
    if (!id) return

    try {
      const str = atob(id)
      const obj = JSON.parse(str) as IForm
      const isOk = validateJsonQuote(obj)
      if (!isOk) {
        return setErrorData(true)
      }
      setFormData((p) => ({...p, ...obj}))
      setArrPeople(obj.people)
      if (obj.personOne.currentEnrollment) {
        setInfoEnrollement({
          id: 1,
          effectiveDate: obj.personOne.currentEnrollment.effectiveDate,
          planId: obj.personOne.currentEnrollment.planId,
          usesTobacco: obj.personOne.currentEnrollment.useTobacco,
        })
      }
      console.log('obj', obj)
      const arrPe = [
        {
          ...obj.personOne,
          age: Number(obj.personOne.age),
          pregnantWith: obj.personOne.pregnantWith ? Number(obj.personOne.pregnantWith) : 0,
          currentEnrollment: obj.personOne.currentEnrollment
            ? {
                ...obj.personOne.currentEnrollment,
                id: undefined,
              }
            : null,
        },
        ...obj.people.map((inf) => {
          if (inf.currentEnrollment) {
            setInfoEnrollement({
              effectiveDate: inf.currentEnrollment.effectiveDate,
              id: inf.currentEnrollment.id,
              planId: inf.currentEnrollment.planId,
              usesTobacco: inf.currentEnrollment.useTobacco,
            })
          }
          return {
            ...inf,
            id: undefined,
            age: Number(inf.age),
            pregnantWith: inf.pregnantWith ? Number(inf.pregnantWith) : 0,
            currentEnrollment: inf.currentEnrollment
              ? {
                  effectiveDate: inf.currentEnrollment.effectiveDate,
                  planId: inf.currentEnrollment.planId,
                  useTobacco: inf.currentEnrollment.useTobacco,
                }
              : null,
          }
        }),
      ]
      gqlGetMarket({
        variables: {
          ...obj,
          householdIncome: Number(obj.householdIncome.replaceAll(',', '')),
          personOne: undefined,
          people: arrPe,
        },
      })
    } catch (error) {
      console.log('error', error)
      setErrorData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.marketplace2) {
      const json = JSON.parse(data.marketplace2) as IResultsJson

      if (json.error) {
        toast.error('Error: Not found Quote')
        return
      }
      if (json.validation_errors) {
        toast.error(`Error: ${json.validation_errors[0].error}`)
        return
      }
      setInfoPagination((p) => ({
        hasNext: json.plans.length > 0 && moreArr.current,
        offset: moreArr.current ? p.offset + json.plans.length : p.offset,
        initialOffset: moreArr.current ? json.plans.length : p.initialOffset,
      }))
      if (json.plans.length === 0) moreArr.current = false
      if (json.plans.length > 0 && moreArr.current) setArrOffset((p) => p.concat(p.length + 1))
      setResult(json)
    }
  }, [data])

  const loadMoreQuotes = (pag: number) => {
    gqlGetMarket({
      variables: {
        ...formData,
        householdIncome: formData.householdIncome.replaceAll(',', ''),
        // dob: formData?.dob || undefined,
        offset: infoPagination.offset * pag - infoPagination.offset,
      },
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    console.log('formData', formData)
    console.log('infoEnrollement', infoEnrollement)
    console.log('arrPeople', arrPeople)

    const arrPe = [
      {
        ...formData.personOne,
        age: Number(formData.personOne.age),
        pregnantWith: formData.personOne.pregnantWith ? Number(formData.personOne.pregnantWith) : 0,
        currentEnrollment:
          infoEnrollement.id === 1
            ? {
                ...infoEnrollement,
                id: undefined,
              }
            : null,
      },
      ...formData.people.map((inf) => ({
        ...inf,
        id: undefined,
        age: Number(inf.age),
        pregnantWith: inf.pregnantWith ? Number(inf.pregnantWith) : 0,
        currentEnrollment:
          inf.currentEnrollment?.id === infoEnrollement?.id
            ? {
                effectiveDate: inf.currentEnrollment.effectiveDate,
                planId: inf.currentEnrollment.planId,
                useTobacco: inf.currentEnrollment.useTobacco,
              }
            : null,
      })),
    ]
    gqlGetMarket({
      variables: {
        ...formData,
        householdIncome: Number(formData.householdIncome.replaceAll(',', '')),
        personOne: undefined,
        people: arrPe,
      },
    })

    /* gqlGetMarket({
      variables: {
        ...formData,
        householdIncome: Number(formData.householdIncome.replaceAll(',', '')),
        // dob: formData.dob || undefined,
      },
    }) */
  }

  const plusPage = () => {
    setCurrentPage((p) => p + 1)
  }

  const updatePage = (n: number) => {
    setCurrentPage(n)
  }

  if (!id) {
    return <div>NOT FOUND</div>
  }

  if (errorData) {
    return <div>Error</div>
  }

  if (loading) return <LoadingOneAddQuote arrOffset={arrOffset} currentPage={currentPage} />

  return (
    <div>
      <AccordionForm
        handleSubmit={handleSubmit}
        formData={formData}
        handleChange={handleChange}
        setFormData={setFormData}
        arrPeople={arrPeople}
        setArrPeople={setArrPeople}
        infoEnrollement={infoEnrollement}
        setInfoEnrollement={setInfoEnrollement}
      />
      {result && (
        <ResultsCards
          result={result}
          arrOffset={arrOffset}
          infoPagination={infoPagination}
          loadMoreQuotes={loadMoreQuotes}
          currentPage={currentPage}
          plusPage={plusPage}
          updatePage={updatePage}
        />
      )}
    </div>
  )
}
