import {Modal, Button, Spinner} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useLazyQuery, useMutation} from '@apollo/client'
import {
  IPipelineMembersPipeline,
  IResAllMembers,
  IRespAddUserPipeline,
  IRespDelUserPipeline,
} from '../../../types/members'
import {GET_FILTERS_MEMBERS} from '../../../gql/queries/membersQuery'
import {GET_MEMBERS_PIPELINE} from '../../../gql/queries/pipelines'
import {ADD_USER_TO_PIPELINE, REMOVE_USER_TO_PIPELINE} from '../../../gql/mutations/userMutations'
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd'
import {parseId} from '../../../helpers'
import Lottie from 'lottie-react'
import noLeads from '../../../assets/lotties/no-leads.json'

interface ModalAssignAgentsToPipelineProps {
  isOpen: boolean
  onClose: () => void
  info: {
    pipelineName: string
    pipelineId: string
  }
}

export const ModalAssignAgentsToPipeline = ({
  isOpen,
  onClose,
  info,
}: ModalAssignAgentsToPipelineProps) => {
  const [userUpdates, setUserUpdates] = useState(0)

  const [
    gqlGetMembersPipeline,
    {data: dataMembersPipeline, loading: loadingMemberPipelin, error: errorMembersPipline},
  ] = useLazyQuery<IPipelineMembersPipeline>(GET_MEMBERS_PIPELINE, {fetchPolicy: 'no-cache'})

  const [gqlAllMembers, {data: allMembers, loading: loadingMember, error: errorMembersFilter}] =
    useLazyQuery<IResAllMembers>(GET_FILTERS_MEMBERS, {
      fetchPolicy: 'no-cache',
    })

  const [
    gqlAddMembers,
    {data: addUserToPipeline, loading: loadingAddUserToPipeline, error: errorAddUserToPipeline},
  ] = useMutation<IRespAddUserPipeline>(ADD_USER_TO_PIPELINE)
  const [
    gqlDelMembers,
    {data: delUserToPipeline, loading: loadingDelUserToPipeline, error: errorDelUserToPipeline},
  ] = useMutation<IRespDelUserPipeline>(REMOVE_USER_TO_PIPELINE)

  useEffect(() => {
    gqlGetMembersPipeline({
      variables: {
        id: parseId(info.pipelineId),
      },
    })
  }, [gqlGetMembersPipeline, info, userUpdates])

  useEffect(() => {
    gqlAllMembers()
  }, [gqlAllMembers, userUpdates])

  useEffect(() => {
    if (errorAddUserToPipeline) toast.error(`Error: ${errorAddUserToPipeline.message}`)
    if (errorDelUserToPipeline) toast.error(`Error: ${errorDelUserToPipeline.message}`)
    if (errorMembersFilter) toast.error(`Error: ${errorMembersFilter.message}`)
    if (errorMembersPipline) toast.error(`Error: ${errorMembersPipline.message}`)
  }, [errorAddUserToPipeline, errorDelUserToPipeline, errorMembersPipline, errorMembersFilter])

  useEffect(() => {
    if (!addUserToPipeline && !delUserToPipeline) return
    if (addUserToPipeline?.addUserToPipeline?.success) {
      toast.success('User assgined to pipeline successfully')
      setUserUpdates((p) => p + 1)
    } else if (delUserToPipeline?.removeUserFromPipeline?.success) {
      toast.success('User assgined to pipeline successfully')
      setUserUpdates((p) => p + 1)
    } else {
      toast.error(
        `Error: ${addUserToPipeline?.addUserToPipeline?.errors} ${delUserToPipeline?.removeUserFromPipeline?.errors}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delUserToPipeline, addUserToPipeline])

  const handleAssignPipelines = async (result: DropResult) => {
    const {source, destination, draggableId} = result

    // Cancelado o no cambió de lista
    if (!destination || source.droppableId === destination.droppableId) {
      return
    }

    try {
      if (source.droppableId === 'disponibles' && destination.droppableId === 'conPermiso') {
        // Agregar permiso
        await gqlAddMembers({
          variables: {
            userId: parseId(draggableId),
            pipelineId: parseId(info.pipelineId), // o el ID que corresponda
          },
        })
      } else if (source.droppableId === 'conPermiso' && destination.droppableId === 'disponibles') {
        // Quitar permiso
        await gqlDelMembers({
          variables: {
            userId: parseId(draggableId),
            pipelineId: parseId(info.pipelineId),
          },
        })
      }
    } catch (error) {
      console.error('Error en la mutación de drag & drop:', error)
    }
  }

  // if (allMembers) {
  //   console.log('allUsers:', allMembers.allUsers.edges?.length)
  // }
  // if (!loadingMemberPipelin && dataMembersPipeline) {
  //   console.log('Pipeline:', JSON.stringify(dataMembersPipeline))
  //   console.log('dataMembersPipeline:', dataMembersPipeline?.pipelineById?.users?.edges?.length)
  // }

  return (
    <Modal show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>
          Assign revisor agent to pipeline{' '}
          <span className='fw-bold text-uppercase'>{info.pipelineName}</span>{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-start h-100 flex-column'>
          {/* <h2 className='fs-1'>Management revisor agents to pipeline: {info.pipelineName}</h2> */}
          <h2 >The assigned agents can view all leads on the pipeline</h2>
          <div className='d-flex w-100 flex-row '>
            <DragDropContext onDragEnd={handleAssignPipelines}>
              <Droppable droppableId='disponibles'>
                {(provided) => (
                  <div className='list-user-available w-50 p-3 border border-secondary rounded'>
                    <div className='title'>Agents available</div>
                    {(loadingMember || loadingAddUserToPipeline || loadingDelUserToPipeline) && (
                      <span>
                        <Spinner animation='border' size='sm' />
                      </span>
                    )}
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{maxHeight: 400}}
                    >
                      <div style={{maxHeight: '400px', overflowY: 'auto'}}>
                        {allMembers?.allUsers.edges
                          .filter(
                            (u) =>
                              !dataMembersPipeline?.pipelineById.users.edges.find(
                                (m) => m.node.id === u.node.id
                              )
                          )
                          .map(({node}, index) => (
                            <Draggable key={node.id} draggableId={node.id} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className='user-available'
                                >
                                  {node.firstName} {node.lastName}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
              <Droppable droppableId='conPermiso'>
                {(provided) => (
                  <div className='list-user-assigned h-400 w-50 p-3 border border-secondary rounded'>
                    <div className='title'>Agents assigned to pipeline</div>
                    {(loadingMemberPipelin ||
                      loadingAddUserToPipeline ||
                      loadingDelUserToPipeline) && (
                      <span>
                        <Spinner animation='border' size='sm' />
                      </span>
                    )}
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{maxHeight: 400}}
                    >
                      <div style={{minHeight: '390px', maxHeight: '400px', overflowY: 'auto'}}>
                        {dataMembersPipeline?.pipelineById?.users?.edges?.length === 0 && (
                          <div className='d-flex align-items-center justify-content-center w-100'>
                            <div style={{maxWidth: '200px'}}>
                              <Lottie
                                animationData={noLeads}
                                autoPlay
                                style={{maxWidth: '200px'}}
                              />
                            </div>
                          </div>
                        )}
                        {dataMembersPipeline?.pipelineById?.users?.edges?.map(({node}, index) => (
                          <Draggable key={node.id} draggableId={node.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className='user-available'
                              >
                                {node.firstName} {node.lastName}
                              </div>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          {!loadingMember && allMembers && <div></div>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant='secondary'>
          Close
        </Button>
        {/* <Button
          onClick={handleAssignPipelines}
          variant='primary'
          disabled={loadingMemberPipelin || loadingMember || valueUser === ''}
        >
          {loadingMemberPipelin || loadingMember && <Spinner animation='border' className='me-2' size='sm' />}
          <i className='bi bi-person-up' />
          Assign
        </Button> */}
      </Modal.Footer>
    </Modal>
  )
}
