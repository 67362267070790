import {useQuery} from '@apollo/client'
import {IResPoliciesType} from '../../types/policies'
import {GET_ALL_POLICIES_TYPES} from '../../gql/queries/policiesQueries'
import {IResCarriers} from '../../types/carriers'
import {GET_ONE_CARRIER} from '../../gql/queries/carriersQuery'
import {IResStates} from '../../types/statesEU'
import {GET_STATES_EU} from '../../gql/queries/statesEUQuery'
import {isMobileDevice} from '../../../../_metronic/assets/ts/_utils'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {Col, Form, Row, Spinner} from 'react-bootstrap'
import DatePicker from 'react-multi-date-picker'
import {STATUS_POLICY} from '../../helpers/consts'
import Select from 'react-select'
import {QuoteCount} from './components/QuoteCount'
import {QuoteReportUsers} from './components/QuoteReportUsers'

export const HomeQuotesPage = () => {
  const {
    data: dataPolicyTypes,
    loading: loadingPolicyTypes,
    error: errorPolicyTypes,
  } = useQuery<IResPoliciesType>(GET_ALL_POLICIES_TYPES, {fetchPolicy: 'no-cache'})
  const {
    data: dataCarriers,
    loading: loadingCarriers,
    error: errorCarriers,
  } = useQuery<IResCarriers>(GET_ONE_CARRIER, {fetchPolicy: 'no-cache'})
  const {
    data: dataStates,
    loading: loadingStates,
    error: errorStates,
  } = useQuery<IResStates>(GET_STATES_EU, {fetchPolicy: 'no-cache'})

  const isMobile = isMobileDevice()

  const [dates, setDates] = useState([
    new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)),
    new Date(new Date().setHours(23, 59, 59, 999)),
  ])

  const [policyStatus, setPolicyStatus] = useState('')

  const [policyTypeSelect, setPolicyTypeSelect] = useState<null | {value: string; label: string}>(
    null
  )
  const [carrierSelect, setCarrierSelect] = useState<null | {value: string; label: string}>(null)
  const [stateSelect, setStateSelect] = useState<null | {value: string; label: string}>(null)

  useEffect(() => {
    if (errorCarriers) toast.error(`Error: ${errorCarriers.message}`)
  }, [errorCarriers])

  useEffect(() => {
    if (errorPolicyTypes) toast.error(`Error: ${errorPolicyTypes.message}`)
  }, [errorPolicyTypes])

  useEffect(() => {
    if (errorStates) toast.error(`Error: ${errorStates.message}`)
  }, [errorStates])

  /* useEffect(() => {
    if (!dataCarriers) return
    if (dataCarriers.allInsuranceOperators.edges.length > 0) {
      const node = dataCarriers.allInsuranceOperators.edges[0].node
      setCarrierSelect({
        label: node.name,
        value: node.id,
      })
    }
  }, [dataCarriers])
  useEffect(() => {
    if (!dataPolicyTypes) return
    if (dataPolicyTypes.allPoliciesType.edges.length > 0) {
      const node = dataPolicyTypes.allPoliciesType.edges[0].node
      setPolicyTypeSelect({
        label: node.typePolicy,
        value: node.id,
      })
    }
  }, [dataPolicyTypes])
  useEffect(() => {
    if (!dataStates) return
    if (dataStates.allStates.edges.length > 0) {
      const node = dataStates.allStates.edges[0].node
      setStateSelect({
        label: node.name,
        value: node.id,
      })
    }
  }, [dataStates]) */

  return (
    <div>
      <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between'>
        <h1 className='d-flex align-items-center'>
          QUOTES REPORTS
          {(loadingCarriers || loadingPolicyTypes || loadingStates) && (
            <Spinner animation='border' size='sm' className='ms-1' />
          )}
        </h1>
        <div className='d-flex flex-column flex-sm-row align-items-start gap-3 w-100 w-md-auto'>
          <DatePicker
            style={{
              padding: '1.55em 1em',
              textAlign: 'center',
              flex: '1',
              width: '100%',
              minWidth: '215px',
            }}
            containerStyle={{width: '100%'}}
            value={dates}
            onChange={(d) => {
              if (d.length <= 1) return
              const [d1, d2] = d
              const date1 = d1.toDate()
              const date2 = d2.toDate()
              date1.setHours(0, 0, 0, 0)
              date2.setHours(23, 59, 59, 99)
              setDates([date1, date2])
            }}
            range
            numberOfMonths={isMobile ? 1 : 2}
            format='DD-MMM-YYYY'
          />
          <Form.Select value={policyStatus} onChange={(v) => setPolicyStatus(v.target.value)}>
            <option value=''>Select status</option>
            <option value={STATUS_POLICY.PENDING}>Pending</option>
            <option value={STATUS_POLICY.COMPLETED}>Completed</option>
            <option value={STATUS_POLICY.CANCELED}>Canceled</option>
          </Form.Select>
        </div>
      </div>
      <Row className='mt-3 g-3'>
        <Col sm={12} md={4}>
          <small>Policy Type</small>
          <Select
            options={
              dataPolicyTypes?.allPoliciesType.edges.map(({node}) => ({
                label: node.typePolicy,
                value: node.id,
              })) || []
            }
            placeholder='Select Policy Type'
            noOptionsMessage={() => 'Not found'}
            value={policyTypeSelect}
            onChange={setPolicyTypeSelect}
            isClearable
          />
        </Col>
        <Col sm={12} md={4}>
          <small>Carriers</small>
          <Select
            options={
              dataCarriers?.allInsuranceOperators.edges.map(({node}) => ({
                label: node.name,
                value: node.id,
              })) || []
            }
            placeholder='Select carrier'
            noOptionsMessage={() => 'Not found'}
            value={carrierSelect}
            onChange={setCarrierSelect}
            isClearable
          />
        </Col>
        <Col sm={12} md={4}>
          <small>States</small>
          <Select
            options={
              dataStates?.allStates.edges.map(({node}) => ({
                label: node.name,
                value: node.id,
              })) || []
            }
            placeholder='Select state'
            noOptionsMessage={() => 'Not found'}
            value={stateSelect}
            onChange={setStateSelect}
            isClearable
          />
        </Col>
      </Row>

      <QuoteCount
        start={dates[0]}
        end={dates[1]}
        policyType={policyTypeSelect?.value}
        carriers={carrierSelect?.value}
        states={stateSelect?.value}
        status={policyStatus}
      />

      <div className='mt-4'>
        <QuoteReportUsers start={dates[0]} end={dates[1]} />
      </div>
    </div>
  )
}
