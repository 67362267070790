/* import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub' */
import {SidebarMenuItem} from './SidebarMenuItem'
import {useGetUser} from '../../../../../app/tfi/store/userStore'
import {hasPermission} from '../../../../../app/tfi/helpers'
import {ALL_PERMISSIONS} from '../../../../../app/tfi/helpers/consts'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const permissions = useGetUser((user) => user?.user?.permissions)

  if (!permissions) return null

  return (
    <>
      <SidebarMenuItemWithSub
        to='/dashboard'
        title='Dashboard'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem
          to='/dashboard/lead'
          // icon='element-11'
          title='Leads'
          hasBullet
        />
        <SidebarMenuItem to='/dashboard/policy' title='Policies' hasBullet={true} />
        <SidebarMenuItem to='/dashboard/quote' title='Quotes' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_LEADS) && (
        <SidebarMenuItem
          to='/leads'
          // icon='simcard'
          // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          title='Leads'
          fontIcon='bi-person-vcard'
        />
      )}
      {hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_USUARIOS) && (
        <SidebarMenuItem
          to='/users'
          // icon='simcard'
          // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          title='Users'
          fontIcon='bi-people'
        />
      )}
      {hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_POLIZAS) && (
        <SidebarMenuItem
          to='/policies'
          // icon='simcard'
          // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          title='Policies'
          fontIcon='bi-file-lock2'
        />
      )}
      <SidebarMenuItem
        to='/integrations'
        // icon='simcard'
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title='Integrations'
        fontIcon='bi-diagram-3'
      />
      <SidebarMenuItem
        to='/all-quotes'
        // icon='simcard'
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title='Quotes'
        fontIcon='bi-wallet'
      />
      <SidebarMenuItem
        to='/commissions'
        // icon='simcard'
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title='Commissions'
        fontIcon='bi-piggy-bank'
      />
      {/*  <SidebarMenuItem
        to='/inbox'
        // icon='simcard'
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title='Email'
        fontIcon='bi-envelope-open-fill'
      /> */}
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
