import {gql} from '@apollo/client'

export const ADD_EDIT_LEAD_SOURCES = gql`
  mutation leadSourceCreateupdateMutation($input: lead_source_createUpdate_mutationInput!) {
    leadSourceCreateupdateMutation(input: $input) {
      leadSource {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
