import {gql} from '@apollo/client'

export const GET_ALL_TAGS = gql`
  query allTagInsurances(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $name: String
    $name_Icontains: String
    $name_Istartswith: String
  ) {
    allTagInsurances(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      name: $name
      name_Icontains: $name_Icontains
      name_Istartswith: $name_Istartswith
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          color
        }
      }
    }
  }
`

export const GET_TAGS_FILTER = gql`
  query allTagInsurances($offset: Int, $id: Float, $name: String) {
    allTagInsurances(offset: $offset, id: $id, name: $name) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          color
        }
      }
    }
  }
`
