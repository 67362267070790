const WIDTH_IMAGE = 35

export const RenderFilePreview = ({file}: {file: string}) => {
  const extension = file.split('/').pop()?.split?.('?')?.[0].split('.')?.[1]?.toLowerCase()
  if (!extension) return null
  // Word
  const fileWordArr = ['doc', 'docx', 'docm', 'dot', 'dotx', 'dotm', 'rtf']

  // Excel
  const fileExcelArr = ['xls', 'xlsx', 'xlsm', 'xlsb', 'xlt', 'xltx', 'xltm', 'csv']

  // PowerPoint
  const filePptArr = ['ppt', 'pptx', 'pptm', 'pot', 'potx', 'potm', 'pps', 'ppsx', 'ppsm']

  // Imágenes
  const fileImageArr = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp', 'ico', 'heic']

  // Videos
  const fileVideoArr = ['mp4', 'mov', 'avi', 'wmv', 'flv', 'mkv', 'webm', 'mpeg', '3gp', 'ogg']

  // PDF
  const filePdfArr = ['pdf']

  // Audio
  const fileAudioArr = ['mp3', 'wav', 'aac', 'flac', 'ogg', 'wma', 'm4a', 'aiff', 'alac']

  // Otros documentos de texto (por si los necesitas)
  const fileTextArr = ['txt', 'md', 'log', 'json', 'xml', 'csv']

  // Archivos comprimidos (opcional)
  const fileCompressedArr = ['zip', 'rar', '7z', 'tar', 'gz']

  if (fileWordArr.includes(extension))
    return <img src='/media/tfi/files/word.webp' alt={extension} width={WIDTH_IMAGE} />
  if (fileExcelArr.includes(extension))
    return <img src='/media/tfi/files/excel.webp' alt={extension} width={WIDTH_IMAGE} />
  if (filePptArr.includes(extension))
    return <img src='/media/tfi/files/powerpoint.webp' alt={extension} width={WIDTH_IMAGE} />
  if (fileImageArr.includes(extension)) return <img src={file} alt={extension} width={40} />
  if (fileVideoArr.includes(extension))
    return <img src='/media/tfi/files/video.webp' alt={extension} width={WIDTH_IMAGE} />
  if (filePdfArr.includes(extension))
    return <img src='/media/tfi/files/pdf.png' alt={extension} width={WIDTH_IMAGE} />
  if (fileAudioArr.includes(extension))
    return <img src='/media/tfi/files/audio.webp' alt={extension} width={WIDTH_IMAGE} />
  if (fileTextArr.includes(extension))
    return <img src='/media/tfi/files/txt.png' alt={extension} width={WIDTH_IMAGE} />
  if (fileCompressedArr.includes(extension))
    return <img src='/media/tfi/files/rar.webp' alt={extension} width={WIDTH_IMAGE} />

  return <img src='/media/tfi/files/txt.png' alt={extension} width={WIDTH_IMAGE} />
}
