import {Form, InputGroup} from 'react-bootstrap'

export const RemindersTab = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
        backgroundColor: '#fff',
        marginBottom: '20px',
        borderRadius: '4px',
        padding: '8px 10px',
      }}
    >
      <div className='pt-0'>
        <div className='kt-heading pb-2 custom-border-brand d-flex align-items-center justify-content-between'>
          <span>Reminders</span>
          <div className='pull-right'>
            <div className='btn-toolbar gap-2'>
              <button
                type='button'
                className='btn btn-sm btn-sm btn-primary'
                // onclick="document.getElementById('uppy_upload_btn').click();"
              >
                <i className='fa fa-plus' /> New Reminder
              </button>
              <button
                type='button'
                className='btn btn-sm ml-2'
                style={{backgroundColor: '#d0e5fe', color: '#3e97ff'}}
              >
                <i className='fa fa-redo' style={{color: 'inherit'}} /> Refresh
              </button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4'>
            <div className='form-group form-group-sm'>
              <label htmlFor='contact_documents_datatable_search'> Search reminders:</label>
              <div style={{position: 'relative'}}>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Type here to search...'
                  id='contact_documents_datatable_search'
                  name='contact_documents_datatable_search'
                />
                <span style={{position: 'absolute', right: '4px', top: '12px'}}>
                  <span>
                    <i className='bi bi-search fs-2' style={{color: 'inherit'}} />
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='form-group form-group-sm'>
              <label htmlFor='contact_documents_datatable_tag'> Filter by date range::</label>
              <InputGroup className='mb-3'>
                <Form.Control placeholder='from' type='date' />
                <InputGroup.Text id='basic-addon1'>···</InputGroup.Text>
                <Form.Control placeholder='to' type='date' />
              </InputGroup>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='form-group form-group-sm'>
              <label htmlFor='contact_documents_datatable_family_member'>Filter by status:</label>
              <Form.Select aria-label='Default select example'>
                <option>Filter by status</option>
                <option value='1'>One</option>
                <option value='2'>Two</option>
                <option value='3'>Three</option>
              </Form.Select>
            </div>
          </div>
        </div>
        {/*begin: Datatable */}
        <div
          id='contact_documents_datatable_wrapper'
          className='dataTables_wrapper dt-bootstrap4 no-footer'
        >
          <div className='row'>
            <div className='col-sm-12 col-lg-6 '>
              <div
                className='d-flex align-items-center gap-1 mt-2'
                id='contact_documents_datatable_length'
              >
                <label className='d-flex align-items-center gap-1'>
                  Show{' '}
                  <select
                    name='contact_documents_datatable_length'
                    aria-controls='contact_documents_datatable'
                    className='custom-select custom-select-sm form-control form-control-sm'
                  >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={-1}>All</option>
                  </select>{' '}
                  entries
                </label>
              </div>
            </div>

            <div className='col-sm-12 col-lg-6 '>
              <div
                className='d-flex align-items-center justify-content-end w-100'
                id='contact_documents_datatable_paginate'
              >
                <ul className='pagination'>
                  <li
                    className='paginate_button page-item previous disabled'
                    id='contact_documents_datatable_previous'
                  >
                    <button
                      aria-controls='contact_documents_datatable'
                      data-dt-idx={0}
                      tabIndex={0}
                      className='page-link'
                    >
                      <i className='la la-angle-left' />
                    </button>
                  </li>
                  <li
                    className='paginate_button page-item next disabled'
                    id='contact_documents_datatable_next'
                  >
                    <button
                      aria-controls='contact_documents_datatable'
                      data-dt-idx={1}
                      tabIndex={0}
                      className='page-link'
                    >
                      <i className='la la-angle-right' />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <table
                className='table table-hover table-sm dataTable no-footer dtr-inline'
                id='policy_reminders_datatable'
                aria-describedby='policy_reminders_datatable_info'
                role='grid'
                style={{width: 739}}
              >
                <thead>
                  <tr role='row'>
                    <th
                      className='sorting_disabled'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '30.1px'}}
                      aria-label='ID'
                    >
                      <label className='kt-checkbox kt-checkbox--single kt-checkbox--solid kt-checkbox--tick kt-checkbox--brand'>
                        <input type='checkbox' defaultValue='' className='kt-group-checkable' />
                        <span />
                      </label>
                    </th>
                    <th
                      className='details-control sorting_disabled'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '15.1px'}}
                      aria-label='&nbsp;'
                    >
                      &nbsp;
                    </th>
                    <th
                      className='sorting'
                      tabIndex={0}
                      aria-controls='policy_reminders_datatable'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '45.1px'}}
                      aria-label='User: activate to sort column ascending'
                    >
                      User
                    </th>
                    <th
                      className='sorting'
                      tabIndex={0}
                      aria-controls='policy_reminders_datatable'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '127.1px'}}
                      aria-label='Reminder Title: activate to sort column ascending'
                    >
                      Reminder Title
                    </th>
                    <th
                      className='sorting'
                      tabIndex={0}
                      aria-controls='policy_reminders_datatable'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '56.1px'}}
                      aria-label='Client: activate to sort column ascending'
                    >
                      Client
                    </th>
                    <th
                      className='sorting'
                      tabIndex={0}
                      aria-controls='policy_reminders_datatable'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '56.1px'}}
                      aria-label='Policy: activate to sort column ascending'
                    >
                      Policy
                    </th>
                    <th
                      className='sorting_asc'
                      tabIndex={0}
                      aria-controls='policy_reminders_datatable'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '65.1px'}}
                      aria-label='Due on: activate to sort column ascending'
                    >
                      Due on
                    </th>
                    <th
                      className='text-right sorting'
                      tabIndex={0}
                      aria-controls='policy_reminders_datatable'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '60.1px'}}
                      aria-label='Status: activate to sort column ascending'
                    >
                      Status
                    </th>
                    <th
                      className='text-right sorting'
                      tabIndex={0}
                      aria-controls='policy_reminders_datatable'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '69.1px'}}
                      aria-label='Actions: activate to sort column ascending'
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='odd'>
                    <td valign='top' colSpan={9} className='dataTables_empty'>
                      <div
                        className='d-flex align-items-center justify-content-center flex-column'
                        style={{padding: '3% 0', backgroundColor: '#f0f3ff'}}
                      >
                        <svg
                          style={{width: 100, height: 100}}
                          xmlns='http://www.w3.org/2000/svg'
                          xmlnsXlink='http://www.w3.org/1999/xlink'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                          version='1.1'
                          className='kt-svg-icon'
                        >
                          <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                            <rect id='bound' x={0} y={0} width={24} height={24} />
                            <path
                              d='M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z'
                              id='Combined-Shape'
                              fill='#000000'
                              opacity='0.3'
                            />
                            <path
                              d='M13,5.06189375 C12.6724058,5.02104333 12.3386603,5 12,5 C11.6613397,5 11.3275942,5.02104333 11,5.06189375 L11,4 L10,4 C9.44771525,4 9,3.55228475 9,3 C9,2.44771525 9.44771525,2 10,2 L14,2 C14.5522847,2 15,2.44771525 15,3 C15,3.55228475 14.5522847,4 14,4 L13,4 L13,5.06189375 Z'
                              id='Combined-Shape'
                              fill='#000000'
                            />
                            <path
                              d='M16.7099142,6.53272645 L17.5355339,5.70710678 C17.9260582,5.31658249 18.5592232,5.31658249 18.9497475,5.70710678 C19.3402718,6.09763107 19.3402718,6.73079605 18.9497475,7.12132034 L18.1671361,7.90393167 C17.7407802,7.38854954 17.251061,6.92750259 16.7099142,6.53272645 Z'
                              id='Combined-Shape'
                              fill='#000000'
                            />
                            <path
                              d='M11.9630156,7.5 L12.0369844,7.5 C12.2982526,7.5 12.5154733,7.70115317 12.5355117,7.96165175 L12.9585886,13.4616518 C12.9797677,13.7369807 12.7737386,13.9773481 12.4984096,13.9985272 C12.4856504,13.9995087 12.4728582,14 12.4600614,14 L11.5399386,14 C11.2637963,14 11.0399386,13.7761424 11.0399386,13.5 C11.0399386,13.4872031 11.0404299,13.4744109 11.0414114,13.4616518 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z'
                              id='Path-107'
                              fill='#000000'
                            />
                          </g>
                        </svg>
                        <h4 className='kt-font-dark'>
                          There are no reminders created or that match your search criteria.
                        </h4>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-5'>
              <div className='dataTables_info'>Showing 0 to 0 of 0 entries</div>
            </div>
            <div className='col-sm-12 col-md-7'>
              <div className='dataTables_paginate paging_simple_numbers'>
                <ul className='pagination'>
                  <li className='paginate_button page-item previous disabled'>
                    <a
                      href='/#'
                      aria-controls='contact_documents_datatable'
                      data-dt-idx={0}
                      tabIndex={0}
                      className='page-link'
                    >
                      <i className='la la-angle-left' />
                    </a>
                  </li>
                  <li className='paginate_button page-item next disabled'>
                    <a
                      href='/#'
                      aria-controls='contact_documents_datatable'
                      data-dt-idx={1}
                      tabIndex={0}
                      className='page-link'
                    >
                      <i className='la la-angle-right' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*end: Datatable */}
      </div>
    </div>
  )
}
