import {Button, Modal, Spinner} from 'react-bootstrap'
import {ICommissions, IResDeleteCommission} from '../../../types/carriers'
import {useMutation} from '@apollo/client'
import {DELETE_COMMISION} from '../../../gql/mutations/carriersMutations'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {parseId} from '../../../helpers'

interface IModalDeleteProps {
  info: ICommissions
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}

export const ModalDelete: React.FC<IModalDeleteProps> = ({info, isOpen, onClose, refetch}) => {
  const [gqlDelete, {data, loading, error}] = useMutation<IResDeleteCommission>(DELETE_COMMISION)

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.deleteCommission.success) {
      toast.success('Commission deleted successfully')
      onClose()
      refetch()
    } else if (data.deleteCommission.errors && data.deleteCommission.errors?.length > 0) {
      toast.error(`Error: ${data.deleteCommission.errors?.[0]?.message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleDelete = () => {
    gqlDelete({
      variables: {
        id: parseId(info.id),
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Commission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='fs-2'>Are you sure you want to eliminate this commission?</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button variant='danger' onClick={handleDelete} disabled={loading}>
          {loading && <Spinner animation='border' size='sm' className='me-2' />}
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
