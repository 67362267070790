import {Button, Spinner} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useLazyQuery} from '@apollo/client'
import {GET_ALL_CARRIERS} from '../../gql/queries/carriersQuery'
import {IResCarriers, NodeOperators} from '../../types/carriers'
import {LoadingCarriers} from './components/LoadingCarriers'
import {Link} from 'react-router-dom'
import {parseId} from '../../helpers'
import {useDisclourse} from '../../hooks/useDisclourse'
import {ModalAddCarrier} from './components/ModalAddCarrier'

export const CarriersPage = () => {
  const [gqlGet, {data, loading, error, refetch}] = useLazyQuery<IResCarriers>(GET_ALL_CARRIERS, {
    fetchPolicy: 'no-cache',
  })

  const [dataArr, setDataArr] = useState<IResCarriers['allInsuranceOperators']['edges']>([])
  const [offset, setOffset] = useState(0)
  const [isPag, setIsPag] = useState(false)

  const [infoEdit, setInfoEdit] = useState<null | NodeOperators>(null)

  const {isOpen, onClose, onOpen} = useDisclourse()

  useEffect(() => {
    gqlGet({variables: {offset}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  useEffect(() => {
    if (!data) return
    const {edges} = data.allInsuranceOperators
    if (isPag) {
      setDataArr((p) => [...p, ...edges])
    } else {
      setDataArr(edges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
    }
  }, [error])

  if (dataArr.length === 0 && !isPag) {
    return <LoadingCarriers />
  }

  return (
    <div>
      <header className='d-flex align-items-center justify-content-between pb-4'>
        <h2>Manage Carriers</h2>
        <div>
          <Button onClick={onOpen}>
            <i className='bi bi-plus fs-3' />
            Carrier
          </Button>
        </div>
      </header>

      <div className='my-5 card shadow' style={{overflowX: 'hidden'}}>
        <div className='table-responsive'>
          <table className='table align-items-center table-hover'>
            <thead className='bg-dark text-light h-100'>
              <tr>
                <th className='p-4 fw-bold' scope='col'>
                  Name
                </th>
                <th className='p-4 fw-bold' scope='col'>
                  Commissions
                </th>
                <th className='p-4 fw-bold' scope='col'>
                  Policies
                </th>
                <th className='p-4 fw-bold' scope='col'>
                  Created
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className=''>
              {dataArr.map(({node}) => (
                <tr key={node.id}>
                  <td className='px-4'>{node.name}</td>
                  <td className='px-2'>{node.policyInsuranceThroughSet.edges.length}</td>
                  <td className='px-2'>{node.policies.edges.length}</td>
                  <td className='px-2'>{moment(node.created).format('DD MMM, YYYY')}</td>
                  <td className='px-2'>
                    <div className='d-flex align-items-center gap-2'>
                      <Link
                        title='View carrier'
                        to={`/carrier/${parseId(node.id)}`}
                        className='text-info'
                      >
                        <i className='bi bi-eye fs-2' style={{color: 'inherit'}} />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
              {data?.allInsuranceOperators.pageInfo.hasNextPage && (
                <tr>
                  <td colSpan={3}>
                    <div className='d-flex flex-center'>
                      <Button
                        size='sm'
                        onClick={() => {
                          setOffset((p) => p + 10)
                          setIsPag(true)
                        }}
                        disabled={loading}
                      >
                        {loading && <Spinner animation='border' size='sm' className='me-2' />}
                        Load more
                      </Button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isOpen && <ModalAddCarrier refetch={refetch} isOpen={isOpen} onClose={onClose} />}
    </div>
  )
}

/*
<Row className='gy-2 mb-5'>
        {dataArr.map(({node}) => (
          <Col sm={12} md={6} lg={5} key={node.id}>
            <Card>
              <Card.Header>
                <Card.Title>
                  <Link to={`/carrier/${parseId(node.id)}`} className='text-info'>
                    {node.name}
                  </Link>
                </Card.Title>
                <div className='card-title'>
                  <div className='d-flex align-items-center gap-2'>
                    <button
                      className='text-primary'
                      style={{border: 0, backgroundColor: 'transparent'}}
                    >
                      <i className='bi bi-pencil-square fs-2' style={{color: 'inherit'}} />
                    </button>
                    <button
                      className='text-danger'
                      style={{border: 0, backgroundColor: 'transparent'}}
                    >
                      <i className='bi bi-trash fs-2' style={{color: 'inherit'}} />
                    </button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div className='d-flex flex-column gap-4'>
                  <div className='d-flex justify-content-between align-items-start border-bottom pb-4'>
                    <div className=' me-auto'>
                      <div className='fw-bold'>Commisions</div>
                    </div>
                    <Badge bg='primary' style={{color: 'white'}} pill>
                      {node.policyInsuranceThroughSet.edges.length}
                    </Badge>
                  </div>
                  <div className='d-flex justify-content-between align-items-start'>
                    <div className=' me-auto'>
                      <div className='fw-bold'>Policies</div>
                    </div>
                    <Badge bg='info' style={{color: 'white'}} pill>
                      {node.policies.edges.length}
                    </Badge>
                  </div>
                </div>
                <div className='mt-4 d-flex justify-content-end'>
                  <small>
                    <span className='fw-bold'>Created: </span>
                    {moment(node.created).format('DD MMM, YYYY')}
                  </small>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div>
        {dataArr.map(({node}, i) => (
          <div
            key={node.id}
            className={`d-flex align-items-center bg-light-${getColor(i)} rounded p-5 mb-7`}
          >
            begin::Icon
            <span className={`text-${getColor(i)} me-5`}>
              <KTIcon iconName='information' className={`text-${getColor(i)} fs-1 me-5`} />
            </span>
            end::Icon
            begin::Title
            <div className='flex-grow-1 me-2'>
              <h5 className='fw-bold text-gray-800 fs-6'>{node.name}</h5>
              <span className=' fw-semibold d-block'>
                <span>Created: </span>
                <span className='text-muted'>{moment(node.created).format('DD MMM YYYY')}</span>
              </span>
            </div>
             end::Title 
             begin::Lable 
            <button
              className={`bg-transparent border-0 fw-bold text-${getColor(i)} py-1`}
              onClick={() => {
                onOpen()
                setInfoEdit(node)
              }}
            >
              <i className={`bi bi-pencil fs-2 text-${getColor(i)}`} />
            </button>
            end::Lable 
          </div>
        ))}
      </div>
*/
