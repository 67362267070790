import {useMutation, useQuery} from '@apollo/client'
import {Alert, Button, FloatingLabel, Form, Modal, Spinner} from 'react-bootstrap'
import {GET_ALL_REASON_LOSS} from '../../../gql/queries/reasonLossQuery'
import {IResAllReasons} from '../../../types/reasonLoss'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {DEAD_LEAD} from '../../../gql/mutations/leadMutations'
import {parseId} from '../../../helpers'
import {IResDeadLead} from '../../../types/leads'
import {useNavigate} from 'react-router-dom'

interface IModalDeadProps {
  isOpen: boolean
  onClose: () => void
  info: {
    id: string
    name: string
  }
}

export const ModalDeadLead = ({isOpen, onClose, info}: IModalDeadProps) => {
  const [gqlDeadLead, {data, loading, error}] = useMutation<IResDeadLead>(DEAD_LEAD)
  const {
    data: dataReason,
    loading: loadingReason,
    error: errorReason,
  } = useQuery<IResAllReasons>(GET_ALL_REASON_LOSS, {fetchPolicy: 'no-cache'})

  const navigate = useNavigate()

  const [reasonLeadLossId, setReasonLeadLossId] = useState('')

  useEffect(() => {
    if (errorReason) toast.error(`Error: ${errorReason.message}`)
  }, [errorReason])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.declineLeadMutation.success) {
      toast.success('Lead has been removed')
      navigate('/leads')
      onClose()
    } else if (data.declineLeadMutation.errors && data.declineLeadMutation.errors.length > 0) {
      toast.error(`Error: ${data.declineLeadMutation.errors?.[0]?.message || 'Something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Modal show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>
          Dead <span className='fw-bold text-uppercase'>{info.name}</span>{' '}
          {loadingReason && <Spinner animation='border' size='sm' />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant='danger' className='fs-3 mb-5 d-flex align-items-center gap-1'>
          <span>
            <i className='bi bi-exclamation-circle fs-1' style={{color: 'inherit'}} />
          </span>
          Are you sure you want to close this lead? If so, please select the reason for closing the
          lead below
        </Alert>
        <div>
          <FloatingLabel controlId='floatingSelect' label='Reason Loss'>
            <Form.Select
              aria-label='reason loss'
              disabled={loadingReason || !dataReason}
              value={reasonLeadLossId}
              onChange={(e) => setReasonLeadLossId(e.target.value)}
            >
              <option value=''>Select one</option>
              {dataReason?.allReasonLeadLoss?.edges?.map(({node}) => (
                <option key={node.id} value={node.id}>
                  {node.reason}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant='light'>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!reasonLeadLossId) return
            gqlDeadLead({
              variables: {
                leadId: parseId(info.id),
                reasonLeadLossId: parseId(reasonLeadLossId),
              },
            })
          }}
          variant='danger'
          disabled={loading}
        >
          Dead
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
