import {useLazyQuery} from '@apollo/client'
import {KTIcon} from '../../../../../_metronic/helpers'
import {GET_POLICY_REPORTS_GLOBAL} from '../../../gql/queries/reportsQuery'
import {IResPolicyReportsGlobal} from '../../../types/reportsTypes'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {parseId, parsePrice} from '../../../helpers'

interface IPolicyProps {
  start: Date
  end: Date
  policyType?: string | null
  carriers?: string | null
  states?: string | null
  status: string
}

export const PolicyCount: React.FC<IPolicyProps> = ({
  carriers,
  end,
  policyType,
  start,
  states,
  status,
}) => {
  const [gqlGet, {data, loading, error}] = useLazyQuery<IResPolicyReportsGlobal>(
    GET_POLICY_REPORTS_GLOBAL,
    {fetchPolicy: 'no-cache'}
  )

  useEffect(() => {
    if (!end || !start) return

    gqlGet({
      variables: {
        createdAfter: start.toISOString(),
        createdBefore: end.toISOString(),
        carrier: carriers ? parseId(carriers) : undefined,
        stateEU: states ? parseId(states) : undefined,
        policyType: policyType ? parseId(policyType) : undefined,
        status: status || undefined,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers, end, policyType, start, states, status])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (!end || !start || !data || loading) {
    return (
      <section className='mt-8'>
        <small>Policy Summary</small>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-4'>
            <div className={`card bg-danger hoverable card-xl-stretch mb-xl-8`}>
              <div className='card-body'>
                <KTIcon iconName='cheque' className={`text-white fs-3x ms-n1`} />

                <div className={`text-white fw-bold fs-1 mb-2 mt-5 placeholder-glow`}>
                  <span className='placeholder col-8' />
                </div>

                <div className={`fw-semibold text-white placeholder-glow`}>
                  <span className='placeholder col-5' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className='mt-8'>
      <small>Policy Summary</small>
      <div className='row'>
        <div className='col-12 col-md-6 col-lg-4'>
          <div className={`card bg-danger hoverable card-xl-stretch mb-xl-8`}>
            <div className='card-body'>
              <KTIcon iconName='cheque' className={`text-white fs-3x ms-n1`} />

              <div className={`text-white fw-bold fs-1 mb-2 mt-5`}>
                {parsePrice(data.policyStatistics.totalCost)}
              </div>

              <div className={`fw-semibold text-white`}>
                Policies: <span className='fs-2 fw-bold'>{data.policyStatistics.count}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
