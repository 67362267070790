import {Badge, Card, Form, InputGroup, Table} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useLazyQuery} from '@apollo/client'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {GET_EMAILS} from '../../../gql/queries/emailsQuery'
import {LoadingMsgs} from './LoadingMsgs'
import Lottie from 'lottie-react'
import lottieEmptyMsg from '../../../assets/lotties/empty-msg.json'

interface Item {
  id: number
  name: string
  urlImg: string
  title: string
  lastDate: string
  badge:
    | null
    | {
        text: string
        color: string
      }[]
}

const DATA_TABLE: Item[] = [
  {
    id: 1,
    name: 'Ana Simmons',
    urlImg: '/media/avatars/300-14.jpg',
    title: 'Digital PPV Customer Confirmation',
    lastDate: '8:30 PM',
    badge: [
      {text: 'inbox', color: 'info'},
      {text: 'task', color: 'warning'},
    ],
  },
  {
    id: 2,
    name: 'Max Smith',
    urlImg: '/media/avatars/300-2.jpg',
    title: 'Your iBuy.com grocery shopping confirmation',
    lastDate: 'day ago',
    badge: null,
  },
  {
    id: 3,
    name: 'Sean Bean',
    urlImg: '/media/avatars/300-5.jpg',
    title: 'Your Order #224820998666029 has been Confirmed',
    lastDate: '11:20 PM',
    badge: null,
  },
  {
    id: 4,
    name: 'Brian Cox',
    urlImg: '/media/avatars/300-20.jpg',
    title: 'Payment Notification DLOP2329KD',
    lastDate: '2 days ago',
    badge: [{text: 'new', color: 'danger'}],
  },
  {
    id: 5,
    name: 'Mikaela Collins',
    urlImg: '/media/avatars/300-14.jpg',
    title: 'Congratulations on your iRun Coach subscription',
    lastDate: 'July 25',
    badge: null,
  },
  {
    id: 6,
    name: 'Francis Mitcham',
    urlImg: '/media/avatars/300-5.jpg',
    title: 'Pay bills & win up to 600$ Cashback!',
    lastDate: 'July 24',
    badge: null,
  },
]

interface IInitInboxProps {
  ButtonMenu: JSX.Element
  updateInfo: (info: Item) => void
}

// const t = true

export const InitInboxContent: React.FC<IInitInboxProps> = ({ButtonMenu, updateInfo}) => {
  const [gqlGetEmails, {data, loading, error}] = useLazyQuery<{loadEmails: []}>(GET_EMAILS)

  useEffect(() => {
    gqlGetEmails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data) {
    return <LoadingMsgs />
  }

  return (
    <>
      <Card.Header>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <div>
            {data.loadEmails.length > 0 && (
              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
              </div>
            )}
          </div>
          <div className='d-flex gap-2 align-items center'>
            <InputGroup className=''>
              <InputGroup.Text id='basic-addon1'>
                <i className='bi bi-search' />
              </InputGroup.Text>
              <Form.Control
                placeholder='Username'
                aria-label='Username'
                aria-describedby='basic-addon1'
              />
            </InputGroup>
            {ButtonMenu}
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        {data.loadEmails.length > 0 ? (
          <div className='table-responsive'>
            <Table className='w-100 table-hover table-row-dashed fs-6 gy-5 my-0 dataTable'>
              <tbody>
                {DATA_TABLE.map((info) => (
                  <tr key={info.id}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <button style={{backgroundColor: 'transparent', border: 0}}>
                          <i className='bi bi-star-fill fs-3' />
                        </button>
                        <button style={{backgroundColor: 'transparent', border: 0}}>
                          <i className='bi bi-chat-left fs-3' />
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={toAbsoluteUrl(info.urlImg)} alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {info.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <button
                        style={{border: 0, backgroundColor: 'transparent', textAlign: 'left'}}
                        className='text-dark fw-bold text-hover-primary d-block fs-6'
                        onClick={() => {
                          updateInfo(info)
                        }}
                      >
                        {info.title}
                      </button>
                      {info.badge &&
                        info.badge.map((bg) => (
                          <Badge key={bg.text} bg={bg.color} className='mx-1'>
                            {bg.text}
                          </Badge>
                        ))}
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-semibold'>{info.lastDate}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className='d-flex flex-center h-100 flex-column'>
            <h3>You have no messages</h3>
            <div style={{maxWidth: '300px'}}>
              <Lottie animationData={lottieEmptyMsg} loop autoPlay />
            </div>
          </div>
        )}
      </Card.Body>
    </>
  )
}
