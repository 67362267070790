import {
  Breadcrumb,
  Button,
  Col,
  // Dropdown,
  Form,
  ListGroup,
  Pagination,
  Row,
  Spinner,
} from 'react-bootstrap'
// import Select from 'react-select'

export const LoadingUsersPage = () => {
  return (
    <>
      <header
        className='py-4 d-flex align-items-center justify-content-between'
        style={{width: '100%', marginTop: '-10px'}}
      >
        <Breadcrumb>
          <Breadcrumb.Item className='d-blok d-md-none'>
            <span>
              <i className='bi bi-list fs-1' />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Users</Breadcrumb.Item>
          <Breadcrumb.Item active>View users</Breadcrumb.Item>
        </Breadcrumb>

        <Button size='sm' disabled>
          <i className='bi bi-plus fs-3' /> New User
        </Button>
      </header>

      <Row>
        <Col md={4} lg={4} sm={0} xl={3} className='d-none d-md-block'>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
              backgroundColor: '#fff',
              marginBottom: '20px',
              borderRadius: '4px',
              padding: '8px 10px',
            }}
          >
            <div
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
                paddingBottom: '7px',
              }}
            >
              <h5 style={{fontSize: '13px'}}>New agent application form URL:</h5>
              <a href='#/'>coming soon</a>
            </div>

            <div className='my-4'>
              <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
                <span className='fw-bold'>Active users</span>
                <span className='badge badge-success'>
                  <Spinner animation='grow' size='sm' />
                </span>
              </div>
              <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
                <span className='fw-bold'>Inctive users</span>
                <span className='badge badge-danger'>
                  <Spinner animation='grow' size='sm' />
                </span>
              </div>
              <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
                <span className='fw-bold'>Candidates</span>
                <span className='badge badge-primary'>
                  <Spinner animation='grow' size='sm' />
                </span>
              </div>
            </div>

            <div>
              <div
                style={{
                  borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
                }}
                className='d-flex align-items-center justify-content-between'
              >
                <h5>User Groups</h5>
                <button className='btn btn-link'>
                  <i className='bi bi-pencil-square' style={{color: 'inherit'}} /> Manage
                </button>
              </div>
              <ListGroup className='mt-5' variant='flush'>
                <ListGroup.Item action variant={'light'} className='my-2 py-4'>
                  <i className='bi bi-person-fill fs-3' style={{color: 'inherit'}} /> All Users
                </ListGroup.Item>
                <div className='d-flex flex-center' style={{height: '220px'}}>
                  <Spinner animation='border' />
                </div>
              </ListGroup>
            </div>
          </div>
        </Col>
        <Col md={8} lg={8} sm={12} xl={9}>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
              backgroundColor: '#fff',
              marginBottom: '20px',
              borderRadius: '4px',
              padding: '8px 10px',
            }}
          >
            <div
              className='d-flex align-items-center justify-content-between'
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
                paddingBottom: '10px',
              }}
            >
              <h3>List of users</h3>
              {/* <Dropdown>
                <Dropdown.Toggle
                  variant='outline-primary'
                  id='dropdown-basic'
                  style={{border: '1px solid #3e97ff'}}
                >
                  <i className='bi bi-download fs-3' style={{color: 'inherit'}} /> Export
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>

            {/* <Row className='mt-4'>
              <Col sm={12} md={12} lg={4}>
                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label>Search users:</Form.Label>
                  <Form.Control placeholder='Type here to search...' />
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={4}>
                <Form.Group className='mb-3' controlId='exampleFotrolInput1'>
                  <Form.Label>Filter by status:</Form.Label>
                  <Select
                    styles={{
                      control: (base) => ({...base, padding: '2px 4px'}),
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={4}>
                <Form.Group className='mb-3' controlId='exampleFotrolInput1'>
                  <Form.Label>Filter by status:</Form.Label>
                  <Form.Select aria-label='Default select example'>
                    <option>Open this select menu</option>
                    <option value='1'>One</option>
                    <option value='2'>Two</option>
                    <option value='3'>Three</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row> */}
            <Row>
              <Col sm={12} md={8} />
              {/* <Col sm={12} md={8}>
                <p className='fw-bold m-0 p-0'>Only show users without:</p>
                <div>
                  <Form.Check
                    inline
                    label='A group assigned'
                    name='group1'
                    type='checkbox'
                    id='inline-1'
                  />
                  <Form.Check inline label='Licenses' name='group2' type='checkbox' id='inline-2' />
                  <Form.Check inline label='NPN' name='group3' type='checkbox' id='inline-3' />
                  <Form.Check
                    inline
                    label='Free mark'
                    name='group4'
                    type='checkbox'
                    id='inline-4'
                  />
                </div>
              </Col> */}
              {/* <Col sm={12} md={4}>
                <div className='mt-1'>
                  <Button size='sm' variant='outline-primary' style={{border: '1px solid #3e97ff'}}>
                    <i className='bi bi-eraser-fill' style={{color: 'inherit'}} />
                    Reset Filters
                  </Button>
                  <Button
                    size='sm'
                    variant='outline-success'
                    style={{border: '1px solid #50cd89'}}
                    className='ms-1'
                  >
                    <i className='bi bi-search' style={{color: 'inherit'}} />
                    Search
                  </Button>
                </div>
              </Col> */}
            </Row>

            <Row className='mt-5'>
              <Col sm={12} md={6} xl={4}>
                <div className='d-flex align-items-center gap-1'>
                  <span style={{flex: 0}}>Show</span>
                  <Form.Select defaultValue={50} style={{flex: 0, flexBasis: '80px'}}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>50</option>
                  </Form.Select>
                  <span style={{flex: 0}}>entries</span>
                </div>
              </Col>
              <Col className='d-none d-xl-block' xl={4}>
                <p className='d-flex align-items-center justify-content-center h-100 w-100'>
                  Showing 1 to 50 of 100 entries
                </p>
              </Col>
              <Col sm={12} md={6} xl={4}>
                <div className='d-flex align-items-center justify-content-end'>
                  <Pagination size='sm'>
                    <Pagination.Prev />
                    <Pagination.Item active>1</Pagination.Item>
                    <Pagination.Item>2</Pagination.Item>
                    <Pagination.Next />
                  </Pagination>
                </div>
              </Col>
            </Row>
            <div style={{width: '100%', overflowX: 'auto'}}>
              <table className='table table-hover table-sm compact dataTable no-footer dtr-inline'>
                <thead>
                  <tr role='row'>
                    <th>
                      <Form.Check type='checkbox' />
                    </th>
                    <th className='fw-bold'>Avatar</th>
                    <th className='fw-bold'>Name</th>
                    <th className='fw-bold d-none d-lg-table-cell'>Contact information</th>
                    <th className='fw-bold d-none d-lg-table-cell'>Groups</th>
                    <th className='fw-bold d-none d-lg-table-cell'>Status</th>
                    <th className='fw-bold d-none d-lg-table-cell'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr role='row' className='odd parent'>
                    <td colSpan={7}>
                      <div className='d-flex flex-center' style={{height: '20vh'}}>
                        <Spinner animation='border' />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}
