import {useFormik} from 'formik'
import * as Yup from 'yup'
import {IAllMembersNode, IResEditMember} from '../../../types/members'
import {EDIT_USER} from '../../../gql/mutations/userMutations'
import {useMutation, useQuery} from '@apollo/client'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {Col, Form, Row} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {parseId} from '../../../helpers'
import {IResRolesGroups} from '../../../types/roles'
import {GET_ROLES_GROUP} from '../../../gql/queries/rolesGroupQuery'

interface IPropfileProps {
  node: IAllMembersNode
  refetch: () => void
}

const INIT_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  cellphone: '',
  applyDeductions: '',
  rol: '',
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  cellphone: Yup.string().required('Cellphone is required'),
  applyDeductions: Yup.string().required('Role is required'),
})

export const ProfileDetailsSetting: React.FC<IPropfileProps> = ({node, refetch}) => {
  const {
    data: dataRoles,
    loading: loadingRoles,
    error: errorRoles,
  } = useQuery<IResRolesGroups>(GET_ROLES_GROUP, {fetchPolicy: 'no-cache'})
  const [gqlEdit, {data, loading, error}] = useMutation<IResEditMember>(EDIT_USER)

  const formik = useFormik({
    initialValues: INIT_VALUES,
    validationSchema,

    onSubmit: (v, o) => {
      /* if (v.password && v.password !== v.confirmPassword) {
        o.setFieldError('confirmPassword', 'Passwords must match')
        return
      } */
      const userData = {
        ...v,
        applyDeductions: v.applyDeductions
          ? v.applyDeductions === 'yes'
            ? true
            : false
          : undefined,
        password: undefined,
        confirmPassword: undefined,
        isActive: true,
        groups: [parseId(v.rol)],
        rol: undefined,
      }
      gqlEdit({
        variables: {
          userData,
          userId: parseId(node.id),
        },
      })
    },
  })

  useEffect(() => {
    formik.setValues({
      applyDeductions: node.applyDeductions ? 'yes' : 'no',
      cellphone: node.cellphone,
      email: node.email,
      firstName: node.firstName,
      lastName: node.lastName,
      rol: node.groups.edges.length > 0 ? node.groups.edges[0].node.id : '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
    }
  }, [error])
  useEffect(() => {
    if (errorRoles) toast.error(`Error: ${errorRoles.message}`)
  }, [errorRoles])

  useEffect(() => {
    if (!data) return
    if (data?.updateUserMutation?.user?.id) {
      toast.success('user added successfully')
      refetch()
    } else if (data?.updateUserMutation.errors?.length > 0) {
      toast.error(`Error: ${data?.updateUserMutation.errors[0]?.message || 'Something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/tfi/logoNColorP.png')})`}}
                  ></div>
                </div>
              </div>
            </div>

            <Row className='g-2'>
              <Col md={6} sm={12}>
                <Form.Group>
                  <Form.Label>
                    First name <small className='text-danger'>(required)</small>
                  </Form.Label>
                  <Form.Control
                    placeholder='First name'
                    name='firstName'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    isInvalid={Boolean(formik.errors.firstName && formik.touched.firstName)}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group>
                  <Form.Label>
                    Last name <small className='text-danger'>(required)</small>
                  </Form.Label>
                  <Form.Control
                    placeholder='Last name'
                    name='lastName'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    isInvalid={Boolean(formik.errors.lastName && formik.touched.lastName)}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group>
                  <Form.Label>
                    Email address <small className='text-danger'>(required)</small>
                  </Form.Label>
                  <Form.Control
                    placeholder='Email address'
                    type='email'
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    isInvalid={Boolean(formik.errors.email && formik.touched.email)}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group>
                  <Form.Label>Cellphone number</Form.Label>
                  <Form.Control
                    placeholder='Cellphone'
                    type='tel'
                    name='cellphone'
                    value={formik.values.cellphone}
                    onChange={(e) => {
                      const {value} = e.target
                      if (value === '') {
                        formik.setFieldValue('cellphone', '')
                      }
                      if (Number(value)) {
                        formik.setFieldValue('cellphone', value)
                      }
                    }}
                    maxLength={10}
                    isInvalid={Boolean(formik.errors.cellphone && formik.touched.cellphone)}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.cellphone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6} sm={12}>
                <Form.Group>
                  <Form.Label>
                    ¿Apply deductions? <small className='text-danger'>(required)</small>
                  </Form.Label>
                  <Form.Select
                    name='applyDeductions'
                    value={formik.values.applyDeductions}
                    onChange={formik.handleChange}
                    isInvalid={Boolean(
                      formik.errors.applyDeductions && formik.touched.applyDeductions
                    )}
                  >
                    <option value=''>Select One</option>
                    {['yes', 'no'].map((text) => (
                      <option key={text} value={text}>
                        {text}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.applyDeductions}
                  </Form.Control.Feedback>
                </Form.Group>
                {/* <small>
                <span className='text-primary' style={{textDecoration: 'underline'}}>
                  Click here
                </span>{' '}
                to learn more about our access roles.
              </small> */}
              </Col>
              <Col md={6} sm={12}>
                <Form.Group>
                  <Form.Label>
                    Rol <small className='text-danger'>(required)</small>
                  </Form.Label>
                  <Form.Select
                    name='rol'
                    value={formik.values.rol}
                    onChange={formik.handleChange}
                    isInvalid={Boolean(formik.errors.rol && formik.touched.rol)}
                    disabled={loadingRoles}
                  >
                    {loading ? (
                      <option value=''>Loading...</option>
                    ) : (
                      <option value=''>Select One</option>
                    )}
                    {dataRoles?.allGroups.edges.map(({node}) => (
                      <option key={node.id} value={node.id}>
                        {node.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>{formik.errors.rol}</Form.Control.Feedback>
                </Form.Group>
                {/* <small>
                <span className='text-primary' style={{textDecoration: 'underline'}}>
                  Click here
                </span>{' '}
                to learn more about our access roles.
              </small> */}
              </Col>
              {/* <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>
                  Preferred language <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Select>
                  <option>Spanish</option>
                  <option>English</option>
                </Form.Select>
              </Form.Group>
            </Col> */}
              {/* <Col className='mt-5'>
                <Form.Check
                  type='checkbox'
                  label={
                    <span className='text-dark'>
                      Check this box to send this user an email invitation.
                    </span>
                  }
                  defaultChecked
                />
              </Col> */}
            </Row>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
