import {gql} from '@apollo/client'

export const ADD_EDIT_PIPELINE = gql`
  mutation pipelineCreateupdateMutation($input: pipeline_createUpdate_mutationInput!) {
    pipelineCreateupdateMutation(input: $input) {
      pipeline {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
export const ADD_EDIT_STAGE = gql`
  mutation stagePipelineCreateupdateMutation($input: stage_pipeline_createUpdate_mutationInput!) {
    stagePipelineCreateupdateMutation(input: $input) {
      stagePipeline {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_ORDER_STAGES = gql`
  mutation stageOrderPipelineMutation($stageOrderData: [stage_order_pipeline_input]!) {
    stageOrderPipelineMutation(stageOrderData: $stageOrderData) {
      stageOrderPipeline {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const DUPLICATE_PIPELINE = gql`
  mutation duplicatePipeline($pipelineId: Int!) {
    duplicatePipeline(pipelineId: $pipelineId) {
      pipeline {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

// DELETE STAGE PIPELINE

export const DELETE_STAGE_PIPELINE = gql`
  mutation deleteStageMutation($stageId: Int!) {
    deleteStageMutation(stageId: $stageId) {
      stage {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const DELETE_PIPELINE = gql`
  mutation deletePipeline($pipelineId: Int!) {
    deletePipeline(pipelineId: $pipelineId) {
      success
      errors {
        field
        message
      }
    }
  }
`
