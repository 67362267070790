// import {KTIcon} from '../../../helpers'

import {NavSettingsDesktop} from '../../../../app/tfi/components/Sidebar/NavSettingsDesktop'
import {NavSettingMobile} from '../../../../app/tfi/components/Sidebar/NavSettingMobile'
import {hasPermission} from '../../../../app/tfi/helpers'
import {ALL_PERMISSIONS} from '../../../../app/tfi/helpers/consts'
import {useGetUser} from '../../../../app/tfi/store/userStore'

const SidebarFooter = () => {
  const permissions = useGetUser((s) => s.user?.permissions)
  if (!permissions) return null
  return (
    <div
      className='menu-item app-sidebar-footer flex-column-auto pt-2 pb-6 px-6'
      id='kt_app_sidebar_footer'
    >
      {hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_SETTINGS) && (
        <>
          <NavSettingsDesktop />
          <div className='my-0 menu-item d-block d-md-none'>
            <NavSettingMobile />
          </div>
        </>
      )}
    </div>
  )
}

export {SidebarFooter}
