import React, {Dispatch, SetStateAction, useEffect} from 'react'
import {Card, Offcanvas, Spinner} from 'react-bootstrap'
import {LeadByID} from '../../leadTypes'
import {useQuery} from '@apollo/client'
import {GET_ALL_NOTES_BY_LEAD} from '../../../../gql/queries/leadsQuery'
import {toast} from 'react-toastify'
import {IResNotesList, NodeNotes} from './sectionNavTypes'
import Lottie from 'lottie-react'
import noNotesLottie from '../../../../assets/lotties/no-notes.json'
import moment from 'moment'

interface INotesListProps {
  lead: LeadByID
  children: JSX.Element
  setInfoEditNote: Dispatch<SetStateAction<Omit<NodeNotes, 'created' | 'user' | 'date'> | null>>
  onOpenEdit: () => void
}

export const NotesListLead: React.FC<INotesListProps> = ({
  children,
  lead,
  onOpenEdit,
  setInfoEditNote,
}) => {
  const {data, loading, error} = useQuery<IResNotesList>(GET_ALL_NOTES_BY_LEAD, {
    variables: {
      id: lead.id,
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data) {
    return (
      <>
        <Offcanvas.Header closeButton className='bg-light'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <Offcanvas.Title className='d-flex align-items-center gap-4'>
              {lead.name}
            </Offcanvas.Title>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {children}
          <div className='mt-6'>
            <Spinner animation='border' />
          </div>
        </Offcanvas.Body>
      </>
    )
  }
  if (!data.leadById) {
    return (
      <>
        <Offcanvas.Header closeButton className='bg-light'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <Offcanvas.Title className='d-flex align-items-center gap-4'>
              {lead.name}
            </Offcanvas.Title>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {children}
          <div className='mt-6'>
            <h3>Error loading notes</h3>
          </div>
        </Offcanvas.Body>
      </>
    )
  }
  if (data.leadById.noteSet.edges.length === 0) {
    return (
      <>
        <Offcanvas.Header closeButton className='bg-light'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <Offcanvas.Title className='d-flex align-items-center gap-4'>
              {lead.name}
            </Offcanvas.Title>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {children}
          <div className='mt-6'>
            <h4 className='text-center mt-8'>There are no notes recorded for this Lead</h4>
            <div style={{margin: '0 auto'}}>
              <Lottie
                animationData={noNotesLottie}
                autoplay
                style={{maxWidth: '40%', margin: '30px auto'}}
              />
            </div>
          </div>
        </Offcanvas.Body>
      </>
    )
  }
  return (
    <>
      <Offcanvas.Header closeButton className='bg-light'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Offcanvas.Title className='d-flex align-items-center gap-4'>{lead.name}</Offcanvas.Title>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {children}
        {/* <div className='mt-6'>
          <pre>{JSON.stringify(data.leadById.noteSet.edges, null, 2)}</pre>
        </div> */}
        <div className='d-flex flex-column gap-4'>
          {data.leadById.noteSet.edges.map(({node}) => (
            <Card key={node.id}>
              <Card.Header className='d-flex align-items-center justify-content-between bg-warning bg-gradient'>
                <Card.Title>
                  <span className='me-4'>
                    <i className='bi bi-sticky fs-1 text-primary' />
                  </span>{' '}
                  {node.tittle}
                </Card.Title>
                <span>{moment(node.created).format('DD MMM YYYY, hh:mm A')}</span>
              </Card.Header>
              <Card.Body>{node.description}</Card.Body>
              <Card.Footer className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center gap-2'>
                  <button
                    className='btn  btn-sm'
                    onClick={() => {
                      setInfoEditNote({
                        description: node.description,
                        id: node.id,
                        tittle: node.tittle,
                      })
                      onOpenEdit()
                    }}
                  >
                    <i className='bi bi-pencil fs-3' style={{color: 'blue'}} />
                  </button>
                  <button className='btn  btn-sm'>
                    <i className='bi bi-trash fs-3' style={{color: 'red'}} />
                  </button>
                </div>
                <div>
                  <span
                    style={{
                      display: 'inline-block',
                      padding: '2px 6px',
                      border: '1px solid purple',
                      color: 'purple',
                    }}
                    className='text-uppercase me-3 rounded-circle'
                  >
                    {node.user.firstName?.[0] || 'N'}
                  </span>
                  {node.user.firstName || 'N/'} {node.user.lastName || 'A'}
                </div>
              </Card.Footer>
            </Card>
          ))}
        </div>
      </Offcanvas.Body>
    </>
  )
}
