import {gql} from '@apollo/client'

export const CREATE_QUOTE = gql`
  mutation createQuote($policyData: policy_input!) {
    createQuote(policyData: $policyData) {
      success
      policy {
        id
      }
      errors {
        message
      }
    }
  }
`

export const CONVERT_TO_POLICY = gql`
  mutation convertQuoteToPolicy($quoteId: ID!) {
    convertQuoteToPolicy(quoteId: $quoteId) {
      policy {
        id
      }
      success
      errors {
        message
      }
    }
  }
`
