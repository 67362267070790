import {useQuery} from '@apollo/client'
import {Button, Card, Spinner} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useDisclourse} from '../../hooks/useDisclourse'
import {IResPolicy} from './policyTypeTypes'
import {GET_ALL_POLICY_TYPES} from '../../gql/queries/policyTypesQuery'
import {ModalAddEditPolicyType} from './components/ModalAddEditPolicyType'

export const PolicyTypePage = () => {
  const {data, loading, error, refetch} = useQuery<IResPolicy>(GET_ALL_POLICY_TYPES, {
    fetchPolicy: 'no-cache',
  })

  const {isOpen, onClose, onOpen} = useDisclourse()
  const [info, setInfo] = useState<{id?: string; typePolicy?: string; idCat?: string}>({})

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data) {
    return (
      <div>
        <Spinner animation='border' className='ms-4' />
      </div>
    )
  }

  if (data.allCategoryPolicy.edges.length === 0) {
    return (
      <div>
        <div>There are no policy categories currently added</div>
      </div>
    )
  }

  return (
    <div>
      <div>
        <div className='d-flex align-items-center justify-content-between py-2 mb-5'>
          <Card.Title>Manage Policy Type</Card.Title>
          {/* <Button variant='primary'>+ Policy Type</Button> */}
        </div>
        <div
          style={{
            minHeight: '300px',
          }}
        >
          <div
            className='d-flex align-items-center gap-4 justify-content-center justify-content-md-start'
            style={{flexWrap: 'wrap'}}
          >
            {data.allCategoryPolicy.edges.map(({node}) => (
              <div
                key={node.id}
                style={{
                  minWidth: '250px',
                  maxWidth: '250px',
                  minHeight: '290px',
                  border: '1px solid #f4f4f4',
                }}
                className='d-flex flex-column rounded bg-white shadow-sm'
              >
                <h6
                  style={{
                    flex: 0,
                    borderBottom: '2px solid #f6f5f6',
                    padding: '14px 14px',
                    marginBottom: 0,
                  }}
                >
                  {node.name}
                </h6>
                <div
                  className='d-flex align-items-center gap-2 flex-column'
                  style={{flex: 1, padding: '12px 10px', maxHeight: '100%', overflowY: 'auto'}}
                >
                  {node.policyTypeSet.edges.map(({node: nodePolicy}) => (
                    <div
                      key={nodePolicy.id}
                      className='d-flex align-items-center justify-content-between rounded-2 w-100 py-4 px-3'
                      style={{border: '1px solid #e9e9e9'}}
                    >
                      <div>{nodePolicy.typePolicy}</div>
                      <div>
                        <button
                          title='Edit Policy Types'
                          style={{border: 0, backgroundColor: 'transparent', padding: 0, margin: 0}}
                          onClick={() => {
                            onOpen()
                            setInfo({
                              id: nodePolicy.id,
                              typePolicy: nodePolicy.typePolicy,
                              idCat: node.id,
                            })
                          }}
                        >
                          <i className='bi bi-pencil-fill text-primary' />
                        </button>
                      </div>
                    </div>
                  ))}
                  {node.policyTypeSet.edges.length === 0 && (
                    <div
                      className='d-flex align-items-center justify-content-center text-muted'
                      style={{height: '160px'}}
                    >
                      No Policy Types added
                    </div>
                  )}
                </div>
                <div
                  style={{flex: 0}}
                  className='d-flex align-items-center justify-content-between py-4 px-4'
                >
                  <Button
                    className='w-100'
                    size='sm'
                    onClick={() => {
                      onOpen()
                      setInfo({
                        idCat: node.id,
                      })
                    }}
                  >
                    + Policy Type
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isOpen && info.idCat && (
        <ModalAddEditPolicyType isOpen={isOpen} onClose={onClose} info={info} refetch={refetch} />
      )}
    </div>
  )
}
