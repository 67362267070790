import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  /* Search, */ ThemeModeSwitcher,
} from '../../../partials'
import {Alert, Button, Offcanvas, Spinner} from 'react-bootstrap'
import {useDisclourse} from '../../../../app/tfi/hooks/useDisclourse'
import {getNameAvatar, handleUpdateApp} from '../../../../app/tfi/helpers'
import {useState} from 'react'
import {ModalSearchMovile} from '../../../../app/tfi/components/header/ModalSearchMovile'
import {ModalAddQoute} from '../../../../app/tfi/pages/oneLead/components/ModalAddQoute'
import {Tooltip} from 'react-tooltip'
import {useGetUser} from '../../../../app/tfi/store/userStore'
// import {useLayout} from '../../core'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {user} = useGetUser()
  const {isOpen, onClose, onOpen} = useDisclourse()
  const {isOpen: isOpenSearch, onClose: onCloseSearch, onOpen: onOpenSearch} = useDisclourse()
  const {isOpen: isOpenAddQuote, onClose: onCloseAddQuote, onOpen: onOpenAddQuote} = useDisclourse()
  const [loading, setLoading] = useState(false)

  const onLoading = (bool: boolean) => {
    setLoading(bool)
  }

  if (!user) return null

  const strAvatar = getNameAvatar(user.settings[0])
  // const {config} = useLayout()
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass, 'd-none-search-header')}>
        <Button
          size='sm'
          className='px-2 text-center'
          variant='transparent'
          title='Search'
          onClick={onOpenSearch}
        >
          <i className='bi bi-search fs-3 m-0 p-0 text-primary' />
        </Button>
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <Button
          size='sm'
          className='px-2 text-center'
          variant='light'
          // title='Add Quote'
          data-tooltip-id='my-tooltip-1'
          data-tooltip-content='Add Quote'
          onClick={onOpenAddQuote}
        >
          <i className='bi bi-file-earmark-medical fs-3 m-0 p-0' />
        </Button>
        <Tooltip id='my-tooltip-1' place='bottom' />
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <Button
          size='sm'
          className='px-2 text-center'
          variant='light'
          title='Settings'
          onClick={onOpen}
        >
          <i className='bi bi-gear-fill fs-3 m-0 p-0' />
        </Button>
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='notification' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>

      {/*  <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <span className='avatar' style={{textTransform: 'uppercase', fontWeight: 'bolder'}}>
            {strAvatar}
          </span>
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}

      <Offcanvas show={isOpen} onHide={onClose} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Settings</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Alert variant='danger'>
            <h4>Force update</h4>
            <p>This action will update the app to the latest version</p>
            <Button onClick={() => handleUpdateApp(onLoading)} disabled={loading} variant='danger'>
              {loading && <Spinner animation='border' size='sm' className='me-2' />}
              Update
            </Button>
          </Alert>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSearchMovile isOpen={isOpenSearch} onClose={onCloseSearch} />
      {isOpenAddQuote && <ModalAddQoute isOpen={isOpenAddQuote} onClose={onCloseAddQuote} />}
    </div>
  )
}

export {Navbar}
