import {gql} from '@apollo/client'

export const ADD_EDIT_POLICY_TYPE = gql`
  mutation policyTypeCreateupdateMutation($input: policy_type_createUpdate_mutationInput!) {
    policyTypeCreateupdateMutation(input: $input) {
      policyType {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
