import {Button, Modal, Spinner} from 'react-bootstrap'
import {INodeAttachments} from '../../../../types/policiesApi'
import {useMutation} from '@apollo/client'
import {DELETE_FILE_POLICY} from '../../../../gql/mutations/policyMutation'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {parseId} from '../../../../helpers'

interface IResDeleteFilePolicy {
  deleteAttachments: {success: boolean; errors?: {message: string}[]}
}

interface IModalProps {
  id: string
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  infoDoc: INodeAttachments
}
export const ModalDeleteDoc: React.FC<IModalProps> = ({id, infoDoc, isOpen, onClose, refetch}) => {
  const [gqlDelete, {data, loading, error}] = useMutation<IResDeleteFilePolicy>(DELETE_FILE_POLICY)

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.deleteAttachments.success) {
      onClose()
      refetch()
      toast.success('File deleted successfully')
    } else if (data.deleteAttachments.errors && data.deleteAttachments.errors.length > 0) {
      toast.error(`Error: ${data.deleteAttachments.errors[0].message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleDelete = () => {
    gqlDelete({
      variables: {
        id: parseId(infoDoc.id),
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete attachment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='fs-2'>
          Are you sure you want to eliminate this attachment "
          <span className='text-danger text-uppercase fw-bold'>{infoDoc.name}</span>"?
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button variant='danger' onClick={handleDelete} disabled={loading}>
          {loading && <Spinner animation='border' size='sm' className='me-2' />}
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
