import {Button, Col, FloatingLabel, Form, Offcanvas, Row, Spinner} from 'react-bootstrap'
import {FormPersonalLead} from './FormPersonalLead'
import {useEffect, useState} from 'react'
import {FormComercialLead} from './FormComercialLead'
import {useMutation, useQuery} from '@apollo/client'
import {GET_CAMPUS_FILTER} from '../../../gql/queries/campusQuery'
import {GET_PIPELINES_FILTERS} from '../../../gql/queries/pipelines'
import {GET_ALL_LEAD_SOURCE} from '../../../gql/queries/leadSourceQuery'
import {GET_FILTERS_MEMBERS} from '../../../gql/queries/membersQuery'
import {GET_TAGS_FILTER} from '../../../gql/queries/tagsQuery'
import {toast} from 'react-toastify'
import {IResPipelines} from '../../../types/pipelines'
import {IResTags} from '../../../types/tags'
import {IResAllMembers} from '../../../types/members'
import {IResCampus} from '../../../types/campus'
import {IResCatLeadSource} from '../../leadSource/leadSourceTypes'
import Select, {MultiValue} from 'react-select'
import {ILeadData, IResCreateLead} from '../leadTypes'
import {parseId} from '../../../helpers'
import {CREATE_LEAD} from '../../../gql/mutations/leadMutations'

interface IDrawerProps {
  isOpen: boolean
  onClose: () => void
  updateListLeads: () => void
}

export const DrawerAddLead = ({isOpen, onClose, updateListLeads}: IDrawerProps) => {
  const [gqlCreate, {data: dataCreate, loading: loadingCreate, error: errorCreate}] =
    useMutation<IResCreateLead>(CREATE_LEAD)
  const {
    data: dataCampus,
    error: errorCampus,
    loading: loadingCampus,
  } = useQuery<IResCampus>(GET_CAMPUS_FILTER, {fetchPolicy: 'no-cache'})
  const {
    data: dataPipelines,
    error: errorPipelines,
    loading: loadingPipelines,
  } = useQuery<IResPipelines>(GET_PIPELINES_FILTERS, {fetchPolicy: 'no-cache'})
  const {
    data: dataLeadSource,
    error: errorLeadSource,
    loading: loadingLeadSource,
  } = useQuery<IResCatLeadSource>(GET_ALL_LEAD_SOURCE, {fetchPolicy: 'no-cache'})
  const {
    data: dataMembers,
    error: errorMembers,
    loading: loadingMembers,
  } = useQuery<IResAllMembers>(GET_FILTERS_MEMBERS, {fetchPolicy: 'no-cache'})
  const {
    data: dataTags,
    error: errorTags,
    loading: loadingTags,
  } = useQuery<IResTags>(GET_TAGS_FILTER, {fetchPolicy: 'no-cache'})

  const [leadType, setLeadType] = useState<'PERSONAL' | 'COMERCIAL'>('PERSONAL')
  const [valuePipelines, setValuePipelines] = useState('')

  const [valueTags, setValueTags] = useState<
    MultiValue<{
      value: string
      label: string
      color: string
    }>
  >([])
  const [valueMembers, setValueMembers] = useState<
    MultiValue<{
      value: string
      label: string
    }>
  >([])

  useEffect(() => {
    if (errorCampus) toast.error(`Error: ${errorCampus.message}`)
  }, [errorCampus])
  useEffect(() => {
    if (errorLeadSource) toast.error(`Error: ${errorLeadSource.message}`)
  }, [errorLeadSource])
  useEffect(() => {
    if (errorMembers) toast.error(`Error: ${errorMembers.message}`)
  }, [errorMembers])
  useEffect(() => {
    if (errorPipelines) toast.error(`Error: ${errorPipelines.message}`)
  }, [errorPipelines])
  useEffect(() => {
    if (errorTags) toast.error(`Error: ${errorTags.message}`)
  }, [errorTags])

  useEffect(() => {
    if (errorCreate) toast.error(`Error: ${errorCreate.message}`)
  }, [errorCreate])

  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate.createLead?.lead?.id) {
      toast.success('Lead created successfully')
      onClose()
      updateListLeads()
    } else if (dataCreate.createLead.errors) {
      toast.error(`Error: ${dataCreate.createLead.errors?.[0]?.message || 'something happened'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreate])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const {
      name,
      email,
      phone,
      leadSource,
      pipeline,
      campus,
      tags,
      observations,
      stagePipeline,
      ...rest
    } = Object.fromEntries(new FormData(e.currentTarget))
    const leadData: ILeadData = {
      name,
      email,
      phone,
      typeLead: leadType,
      leadSource,
      pipeline,
      campus,
      tags,
      observations,
      stagePipeline,
    }
    if (valueTags.length > 0) {
      leadData.tagInsurance = valueTags.map(({value}) => parseId(value))
    }
    if (valueMembers.length > 0) {
      leadData.user = valueMembers.map(({value}) => parseId(value))
    }
    if (leadType === 'PERSONAL') {
      leadData.personalLead = {...rest}
      if (!leadData.personalLead.birthDay) {
        delete leadData.personalLead.birthDay
      }
    }
    if (leadType === 'COMERCIAL') {
      leadData.comercialLead = {...rest}
    }
    delete leadData.tags
    delete leadData.pipeline
    gqlCreate({
      variables: {
        leadData,
      },
    })
  }

  const isLoading =
    loadingCampus ||
    !dataCampus ||
    loadingLeadSource ||
    !dataLeadSource ||
    loadingMembers ||
    !dataMembers ||
    loadingPipelines ||
    !dataPipelines ||
    loadingTags ||
    !dataTags

  if (isLoading) {
    return (
      <Offcanvas show={isOpen} onHide={onClose} placement='end' className='w-100 w-md-75 w-lg-75'>
        <Offcanvas.Header closeButton className='bg-light'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <Offcanvas.Title className='d-flex align-items-center gap-4'>Add Lead</Offcanvas.Title>
            <div className='d-flex align-items-center gap-1'>
              <button
                className=''
                onClick={() => {
                  setLeadType('PERSONAL')
                }}
                style={{
                  border: 'none',
                  padding: '4px 10px',
                  borderRadius: '14px',
                  color: leadType === 'PERSONAL' ? 'white' : 'gray',
                  backgroundColor: leadType === 'PERSONAL' ? '#3e97ff' : '#e4e4e4',
                }}
              >
                Personal
              </button>
              <button
                className=''
                onClick={() => {
                  setLeadType('COMERCIAL')
                }}
                style={{
                  border: 'none',
                  padding: '4px 10px',
                  borderRadius: '14px',
                  color: leadType === 'COMERCIAL' ? 'white' : 'gray',
                  backgroundColor: leadType === 'COMERCIAL' ? '#3e97ff' : '#e4e4e4',
                }}
              >
                Commercial
              </button>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='d-flex p-6 h-100'>
            <Spinner animation='border' />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    )
  }

  return (
    <Offcanvas show={isOpen} onHide={onClose} placement='end' className='w-100 w-md-75 w-lg-75'>
      <Offcanvas.Header closeButton className='bg-light'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Offcanvas.Title className='d-flex align-items-center gap-4'>Add Lead</Offcanvas.Title>
          <div className='d-flex align-items-center gap-1'>
            <button
              className=''
              onClick={() => {
                setLeadType('PERSONAL')
              }}
              style={{
                border: 'none',
                padding: '4px 10px',
                borderRadius: '14px',
                color: leadType === 'PERSONAL' ? 'white' : 'gray',
                backgroundColor: leadType === 'PERSONAL' ? '#3e97ff' : '#e4e4e4',
              }}
            >
              Personal
            </button>
            <button
              className=''
              onClick={() => {
                setLeadType('COMERCIAL')
              }}
              style={{
                border: 'none',
                padding: '4px 10px',
                borderRadius: '14px',
                color: leadType === 'COMERCIAL' ? 'white' : 'gray',
                backgroundColor: leadType === 'COMERCIAL' ? '#3e97ff' : '#e4e4e4',
              }}
            >
              Commercial
            </button>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <form onSubmit={handleSubmit}>
          <div className='w-100 h-100'>
            <div>
              <Row>
                <Col className='col-lg-4 col-md-4 col-12 mb-4'>
                  <FloatingLabel
                    controlId='LeadSource'
                    label={
                      <span>
                        Lead source <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select aria-label='Lead source' name='leadSource' required>
                      <option value=''>Please select</option>
                      {dataLeadSource.allCategoryLeadSource.edges.map(({node}) => (
                        <optgroup label={node.name} key={node.id}>
                          {node.leadSourceSet.edges.map(({node: nodeLead}) => (
                            <option key={nodeLead.id} value={parseId(nodeLead.id)}>
                              {nodeLead.name}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-4 col-md-4 col-12 mb-4'>
                  <FloatingLabel
                    controlId='pipeline'
                    label={
                      <span>
                        Pipeline <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select
                      aria-label='Pipeline'
                      name='pipeline'
                      required
                      value={valuePipelines}
                      onChange={(e) => setValuePipelines(e.target.value)}
                    >
                      <option value=''>Nothing selected</option>
                      {dataPipelines.allPipelines.edges.map(({node}) => (
                        <option value={parseId(node.id)} key={node.id}>
                          {node.name}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-4 col-md-4 col-12 mb-4'>
                  <FloatingLabel
                    label={
                      <span>
                        Stage <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select aria-label='stagePipeline' name='stagePipeline' required>
                      <option value=''>Nothing selected</option>
                      {dataPipelines.allPipelines.edges
                        .find(({node}) => parseId(node.id) === valuePipelines)
                        ?.node.stagePipelineSet.edges.map(({node}) => (
                          <option key={node.id} value={parseId(node.id)}>
                            {node.name}
                          </option>
                        ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                {/* two */}
                <Col className='col-lg-6 col-md-6 col-12 mb-4 z-999'>
                  <Select
                    classNames={{
                      control: () => 'h-100',
                      container: () => 'h-100',
                    }}
                    placeholder={
                      <span>
                        Tags <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    id='tags-cont'
                    required
                    inputId='tags'
                    name='tags'
                    options={dataTags.allTagInsurances.edges.map(({node}) => ({
                      value: node.id,
                      label: node.name,
                      color: node.color,
                    }))}
                    isMulti
                    value={valueTags}
                    onChange={(e) => {
                      setValueTags(e)
                    }}
                    styles={{
                      option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                        const color = data.color
                        return {
                          ...styles,
                          zIndex: 9999,
                          backgroundColor: isDisabled
                            ? undefined
                            : isSelected
                            ? data.color
                            : isFocused
                            ? color
                            : undefined,
                          color: isDisabled ? '#ccc' : isSelected ? 'white' : '#949494',
                          cursor: isDisabled ? 'not-allowed' : 'default',

                          ':active': {
                            ...styles[':active'],
                            backgroundColor: !isDisabled
                              ? isSelected
                                ? data.color
                                : color
                              : undefined,
                          },
                        }
                      },
                      multiValue: (styles, {data}) => {
                        const color = data.color
                        return {
                          ...styles,
                          backgroundColor: color,
                        }
                      },
                      multiValueLabel: (styles) => ({
                        ...styles,
                        color: 'white',
                      }),
                      multiValueRemove: (styles, {data}) => ({
                        ...styles,
                        color: 'white',
                        ':hover': {
                          backgroundColor: data.color,
                          color: 'white',
                        },
                      }),
                      control: (styles, {isDisabled, isFocused}) => {
                        return {
                          ...styles,
                          border: isDisabled
                            ? '1px solid #ccc'
                            : isFocused
                            ? '1px solid white'
                            : '1px solid #dbdfe9',
                          cursor: isDisabled ? 'not-allowed' : 'default',
                        }
                      },
                    }}
                  />
                  {/* <FloatingLabel
                    controlId='tag'
                    label={
                      <span>
                        Tag <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select aria-label='Tag' name='tag' required multiple>
                      <option value=''>Nothing selected</option>
                      {dataTags.allTagInsurances.edges.map(({node}) => (
                        <option
                          value={parseId(node.id)}
                          key={node.id}
                          style={{
                            backgroundColor: node.color,
                            fontWeight: 'bolder',
                            textTransform: 'uppercase',
                            color: 'white',
                          }}
                        >
                          {node.name}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel> */}
                </Col>
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='campus'
                    label={
                      <span>
                        Location <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select aria-label='Location' name='campus' required>
                      <option value=''>Nothing selected</option>
                      {dataCampus.allCampus.edges.map(({node}) => (
                        <option value={parseId(node.id)} key={node.id}>
                          {node.name}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                {/* two */}
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='name'
                    label={
                      <span>
                        Lead Name <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control placeholder='Lead Name' name='name' required />
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-6 col-md-6 col-12 mb-4 z-999'>
                  <Select
                    classNames={{
                      container: () => 'h-100',
                    }}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        height: 'calc(3.75rem + 2px)',
                      }),
                    }}
                    placeholder='Members'
                    options={dataMembers.allUsers.edges.map(({node}) => ({
                      value: node.id,
                      label: `${node.firstName} ${node.lastName}`,
                    }))}
                    isMulti
                    value={valueMembers}
                    onChange={(e) => {
                      setValueMembers(e)
                    }}
                  />
                </Col>
                {/*ONE*/}

                {/* two */}
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='phone'
                    label={
                      <span>
                        Phone <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control placeholder='Phone' name='phone' required />
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='email'
                    label={
                      <span>
                        Email <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control placeholder='Email' name='email' required />
                  </FloatingLabel>
                </Col>
                {leadType === 'PERSONAL' && <FormPersonalLead />}
                {leadType === 'COMERCIAL' && <FormComercialLead />}

                {/* Grupo 12 */}
                <Col className='col-12 mb-4'>
                  <FloatingLabel controlId='observations' label='Observations'>
                    <Form.Control
                      name='observations'
                      as='textarea'
                      rows={4}
                      placeholder='Observations'
                      className='h-100'
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <div
                className='d-flex align-items-center justify-content-start gap-2 mt-8 pt-3 pb-8'
                style={{borderTop: '1px solid #f1f1f1'}}
              >
                <Button type='button' variant='light' onClick={onClose}>
                  Cancel
                </Button>
                <Button type='submit' disabled={loadingCreate}>
                  {loadingCreate && <Spinner animation='border' size='sm' />}
                  Create
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
