import {useEffect, useState} from 'react'
import {Col, Modal, Row} from 'react-bootstrap'
/* import {TFormKeys, TTabForm} from '../policiesTypes'
import {PolicyHolderTab} from './PolicyHolderTab'
import {AddressTab} from './AddressTab'
import {BasicPolicyInfoTab} from './BasicPolicyInfoTab'
import {HouseholdInfoTab} from './HouseholdInfoTab' */
import {useFormik} from 'formik'
import {useMutation} from '@apollo/client'
import {toast} from 'react-toastify'
import {IResCreateQuoteApi} from '../../../types/policies'
import {parseId} from '../../../helpers'
import {useNavigate} from 'react-router-dom'
import {TFormKeys, TTabForm} from '../../policies/policiesTypes'
import {AddressTab} from '../../policies/components/AddressTab'
import {BasicPolicyInfoTab} from '../../policies/components/BasicPolicyInfoTab'
import {CREATE_QUOTE} from '../../../gql/mutations/quotesMutations'

const TABS_FORM: {name: string; description: string; tabName: TTabForm; icon: string}[] = [
  /* {
    name: 'Policyholder',
    description: 'New or existing contact',
    icon: 'bi bi-person-vcard',
    tabName: 'policyholder',
  }, */
  {
    name: 'Quote information',
    description: 'Specific information',
    icon: 'bi bi-list-check',
    tabName: 'address',
  },
  /* {
    name: 'Household information',
    description: 'Household size and income',
    icon: 'bi bi-people',
    tabName: 'householdInfo',
  }, */
  {
    name: 'Basic quote information',
    description: 'Complete',
    icon: 'bi bi-stars',
    tabName: 'basicPolicyInfo',
  },
]

const INIT_FORM = {
  policyName: '',
  state: 'PENDING',
  applicants: '',
  members: '',
  dependents: '',
  stateDocs: 'PENDING',
  paymentStatus: 'PENDING',
  FFM: '',
  NPN: '',
  typeSale: '',
  effectiveDate: '',
  marketplaceId: '',
  memberId: '',
  CMSPlanId: '',
  planName: '',
  metalLevel: '',
  policyTotalCost: '',
  taxCredit: '',
  premium: '',
  typePolicy: '',
  stateEU: '',
  carrier: '',
  percentageValue: '',
  nominalValue: '',
}

interface IModalProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  leadId?: string
}

export const ModalAddQuote: React.FC<IModalProps> = ({isOpen, onClose, refetch, leadId}) => {
  const [gqlCreateP, {data: dataCreate, loading: loadingCreate, error: errorCreate}] =
    useMutation<IResCreateQuoteApi>(CREATE_QUOTE)

  const navigate = useNavigate()

  const [tabForm, setTabForm] = useState<TTabForm>('address')
  const formik = useFormik({
    initialValues: INIT_FORM,
    onSubmit: (v) => {
      // validadciones
      const policyData = {
        ...v,
        stateEU: Number(parseId(v.stateEU)),
        typePolicy: Number(parseId(v.typePolicy)),
        policyTotalCost: Number(v.policyTotalCost),
        percentageValue: Number(v.percentageValue),
        nominalValue: Number(v.nominalValue),
        carrier: parseId(v.carrier),
        premium: Number(v.premium),
        metalLevel: Number(v.metalLevel),
        taxCredit: Number(v.taxCredit),
        memberId: v.memberId ? Number(v.memberId) : undefined,
        applicants: Number(v.applicants) || 1,
        dependents: Number(v.dependents),
        members: Number(v.members),
        lead: leadId ? Number(leadId) : undefined,
        state: undefined,
        // assignedTo: null,
      }
      console.log('policyInput', policyData)
      gqlCreateP({
        variables: {
          policyData,
        },
      })
    },
  })

  useEffect(() => {
    if (errorCreate) {
      toast.error(`Error: ${errorCreate.message}`)
    }
  }, [errorCreate])

  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate.createQuote?.policy?.id) {
      toast.success('Policy added successfully')
      onClose()
      navigate(`/one-quote/${dataCreate.createQuote?.policy?.id}`)
    } else if (dataCreate.createQuote?.errors?.length > 0) {
      toast.error(`Error: ${dataCreate.createQuote.errors[0]?.message || 'Something wrong'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreate])

  const handleChange = (field: TFormKeys, value: string) => {
    formik.setFieldValue(field, value)
  }
  const handleBlur = (field: TFormKeys) => {
    formik.setFieldTouched(field, true)
  }

  return (
    <Modal show={isOpen} onHide={onClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Create a new quote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col
            xl={3}
            lg={4}
            className='d-none d-md-none d-lg-block'
            style={{
              borderRight: '1px solid #eeeef4',
            }}
          >
            <div>
              {TABS_FORM.map((tb) => (
                <div
                  key={tb.tabName}
                  className='d-flex align-items-center gap-2 p-5 rounded'
                  style={{
                    backgroundColor: tb.tabName === tabForm ? '#e7e7ff' : undefined,
                    position: 'relative',
                    maxWidth: '330px',
                  }}
                  role='button'
                  onClick={() => {
                    if (loadingCreate) return
                    setTabForm(tb.tabName)
                  }}
                >
                  {tb.tabName === tabForm && (
                    <div
                      style={{
                        left: '100%',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        content: ' ',
                        height: 0,
                        width: 0,
                        border: 'solid transparent',
                        position: 'absolute',
                        borderLeftColor: '#e7e7ff',
                        borderWidth: '1rem',
                      }}
                    />
                  )}
                  <div>
                    <i className={`${tb.icon} fs-1`} style={{color: 'inherit'}} />
                  </div>
                  <div>
                    <h6 style={{fontSize: '11px'}} className='m-0'>
                      {tb.name}
                    </h6>
                    <p style={{fontSize: '10px'}} className='m-0'>
                      {tb.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
          <Col lg={8} xl={9}>
            {tabForm === 'address' && (
              <AddressTab
                // onNext={() => setTabForm('householdInfo')}
                // onPrev={() => setTabForm('policyholder')}
                onNext={() => setTabForm('basicPolicyInfo')}
                onPrev={() => setTabForm('policyholder')}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={formik.values}
              />
            )}
            {/*  {tabForm === 'householdInfo' && (
              <HouseholdInfoTab
                onPrev={() => setTabForm('address')}
                onNext={() => setTabForm('basicPolicyInfo')}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={formik.values}
              />
            )} */}
            {tabForm === 'basicPolicyInfo' && (
              <BasicPolicyInfoTab
                onSubmit={formik.handleSubmit}
                // onPrev={() => setTabForm('householdInfo')}
                onPrev={() => setTabForm('address')}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={formik.values}
                loading={loadingCreate}
                isQuote
              />
            )}
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
        <Button variant='primary' onClick={onClose}>
          Save Changes
        </Button>
      </Modal.Footer> */}
    </Modal>
  )
}
