import {useMutation} from '@apollo/client'
import {GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google'
import {useEffect, useState} from 'react'
import {Button, Card, Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {CREATE_INTEGRATION} from '../../gql/mutations/integrationsMutations'
import {IResCreateIntegration} from '../../types/integrationsTypes'
import {RING_CENTRAL_APP_SERVER, RING_CENTRAL_CLIENT_ID, STORAGE_NAMES} from '../../helpers/consts'
import {useNavigate} from 'react-router-dom'
import {useDisclourse} from '../../hooks/useDisclourse'
import {ModalRingCentralConnect} from './components/ModalRingCentralConnect'

const INTEGRATIONS = [
  {
    id: 1,
    title: 'Google',
    description: 'Manage your emails efficiently, send, receive, and organize messages directly.',
    link: 'https://www.gmail.com/',
    urlImg: 'gmail.png',
  },
  {
    id: 2,
    title: 'RingCentral',
    description:
      'Seamlessly manage calls and messages, collaborate with your team, and stay connected',
    link: 'https://www.ringcentral.com',
    urlImg: 'ringcentral.png',
  },
]

const CLIENT_ID = '1005174958588-ht8kplhn013evfjg2de734oc2upk6djp.apps.googleusercontent.com'

/* const CLIENT_ID_RINGCENTRAL =
  process.env.REACT_APP_CLIENT_ID_RINGCENTRAL || 'Z9YGOPXDJkVfVDDW7RM8ll' */

const INTEGRATION_RING_ID = 'ring_init_id'

export const IntegrationsPage = () => {
  const [gqlCreateIntegration, {data, loading, error}] =
    useMutation<IResCreateIntegration>(CREATE_INTEGRATION)

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isConnectRing, setIsConnectRing] = useState(
    Boolean(localStorage.getItem(STORAGE_NAMES.RING_CENTRAL))
  )
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const {access_token} = tokenResponse
      console.log('tokenResponse', tokenResponse)
      setIsLoading(true)
      try {
        const res = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {Authorization: `Bearer ${access_token}`},
        })
        const resData = (await res.json()) as {email: string}
        gqlCreateIntegration({
          variables: {
            fromEmail: resData.email,
            integrationId: 1,
            token: access_token,
          },
        })
        localStorage.setItem(STORAGE_NAMES.TOKEN_GOOGLE, access_token)
        localStorage.setItem(STORAGE_NAMES.EMAIL_GOOGLE, resData.email)
      } catch (error) {
        toast.error('Error performing integration')
      } finally {
        setIsLoading(false)
      }
    },
    onError: (e) => {
      toast.error(`Error: ${e.error}`)
    },
  })

  const {
    isOpen: isOpenRingCentral,
    onClose: onCloseRingCentral,
    // onOpen: onOpenRingCentral,
  } = useDisclourse()

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`)
      setIsLoading(false)
      localStorage.removeItem(STORAGE_NAMES.TOKEN_GOOGLE)
      localStorage.removeItem(STORAGE_NAMES.EMAIL_GOOGLE)
    }
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.createUserIntegration?.userIntegration?.id) {
      toast.success(`Integration successfully completed`)
      navigate('/inbox')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  /* useEffect(() => {
    const loadScript = () => {
      const nWindow = window as unknown as IWindow

      if (nWindow.RingCentralC2D) {
        const clickToDial = new nWindow.RingCentralC2D()
        clickToDial.on(nWindow.RingCentralC2D.events.call, (phoneNumber) => {
          nWindow.RCAdapter.clickToCall(phoneNumber, true)
        })
        clickToDial.on(nWindow.RingCentralC2D.events.text, (phoneNumber) => {
          nWindow.RCAdapter.clickToSMS(phoneNumber)
        })
        console.log('RingCentralC2D cargado:', clickToDial)
      } else {
        console.error('RingCentralC2D no está disponible')
      }
    }

    window.addEventListener('focus', loadScript)
    window.addEventListener('message', (e) => {
      const data = e.data
      console.log('data', data)
    })

    return () => {
      window.removeEventListener('focus', loadScript)
    }
  }, []) */

  const handleDisconnect = () => {
    /* document.getElementById(INTEGRATION_RING_ID)?.remove()
    document.getElementById(STORAGE_NAMES.RING_CENTRAL)?.remove()
    document.querySelector('rc-c2d-menu')?.remove()
    document.querySelector('#rc-widget')?.remove()
    document.getElementById('ring_init_id')?.remove() */
    localStorage.removeItem(STORAGE_NAMES.RING_CENTRAL)
    setIsConnectRing(false)
    window.location.reload()
  }

  const handleClick = () => {
    const isRingIntegration = localStorage.getItem(STORAGE_NAMES.RING_CENTRAL)
    if (isRingIntegration) return /* eslint-disable */
    ;(function () {
      var rcs = document.createElement('script')

      rcs.src = `https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/adapter.js?clientId=${RING_CENTRAL_CLIENT_ID}&appServer=${RING_CENTRAL_APP_SERVER}`
      rcs.id = INTEGRATION_RING_ID
      var rcs0 = document.getElementsByTagName('script')[0]
      rcs0?.parentNode?.insertBefore(rcs, rcs0)
    })()
    const script2 = document.createElement('script')
    script2.src = 'https://unpkg.com/ringcentral-c2d@1.0.0/build/index.js'
    script2.id = STORAGE_NAMES.RING_CENTRAL

    // Agregar el script al body
    document.body.appendChild(script2)

    // Confirmar que el script fue cargado
    script2.onload = () => {
      var clickToDial = new (window as any).RingCentralC2D()
      clickToDial.on((window as any).RingCentralC2D.events.call, (phoneNumber) => {
        ;(window as any).RCAdapter.clickToCall(phoneNumber, true)
      })
      clickToDial.on((window as any).RingCentralC2D.events.text, (phoneNumber) => {
        ;(window as any).RCAdapter.clickToSMS(phoneNumber)
      })
    }

    script2.onerror = () => {
      console.log('Error al cargar el script.')
    }
    localStorage.setItem(STORAGE_NAMES.RING_CENTRAL, STORAGE_NAMES.RING_CENTRAL)
    setIsConnectRing(true)
    /* ;(function () {
      var rcs = document.createElement('script')
      rcs.src = 'https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/adapter.js'
      var rcs0 = document.getElementsByTagName('script')[0]
      rcs0?.parentNode?.insertBefore(rcs, rcs0)
    })() */
    // Interact with RingCentral C2D
  }

  return (
    <>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <div>
          <h3>Integrations</h3>
          <span>Enhance Workflows with Advanced Integrations.</span>

          <div className='d-flex gap-2 flex-wrap mt-5'>
            {INTEGRATIONS.map((info) => (
              <Card key={info.id} style={{minWidth: '290px', width: '100%', flexBasis: 1}}>
                <Card.Body>
                  <div className='d-flex align-items-center justify-content-between'>
                    <img
                      src={`/media/tfi/${info.urlImg}`}
                      alt={`Integration - ${info.title}`}
                      style={{
                        maxWidth: info.title === 'RingCentral' ? '80px' : '4rem',
                        minHeight: '60px',
                      }}
                    />
                    <Card.Link href={info.link} target='_blank' rel='noopener noreferrer'>
                      <i className='bi bi-box-arrow-up-right' style={{color: 'inherit'}} />
                    </Card.Link>
                  </div>
                  <Card.Title>{info.title}</Card.Title>
                  <Card.Text>{info.description}</Card.Text>
                </Card.Body>
                <Card.Footer>
                  <div className='d-flex align-items-center justify-content-between'>
                    {info.title === 'Google' ? (
                      <Button
                        variant='outline'
                        className='btn btn-transparent border d-flex align-items-center gap-2'
                        onClick={() => login()}
                      >
                        <i className='bi bi-file-play' style={{color: 'inherit'}} />
                        Connect
                      </Button>
                    ) : info.title === 'RingCentral' ? (
                      <>
                        {isConnectRing ? (
                          <Button
                            variant='outline'
                            className='btn btn-transparent border d-flex align-items-center gap-2 text-danger'
                            onClick={handleDisconnect}
                          >
                            <i className='bi bi-power' style={{color: 'inherit'}} />
                            Disconnect
                          </Button>
                        ) : (
                          <Button
                            variant='outline'
                            className='btn btn-transparent border d-flex align-items-center gap-2'
                            /* onClick={() => {
                          const YOUR_RINGCENTRAL_CLIENT_ID = 'Z9YGOPXDJkVfVDDW7RM8ll'

                          const rcs = document.createElement('script')
                          // rcs.src = 'https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/adapter.js?clientId=Z9YGOPXDJkVfVDDW7RM8ll&appServer=https://platform.devtest.ringcentral.com&redirectUri=https://app.policytools.com/'

                          const src = `https://apps.ringcentral.com/integration/ringcentral-embeddable/2.0.0/adapter.js?clientId=${YOUR_RINGCENTRAL_CLIENT_ID}&appServer=https://platform.devtest.ringcentral.com&enablePopup=1&multipleTabsSupport=1&redirectUri=${REDIRECT_URL}`
                          console.log('src', src)
                          rcs.src = src
                          const rcs0 = document.getElementsByTagName('script')[0]
                          rcs0?.parentNode?.insertBefore(rcs, rcs0)
                          rcs0.onload = () => {
                            ;(window as unknown as any).RCAdapterInit()
                          }

                          // onOpenRingCentral()
                        }} */
                            onClick={handleClick}
                            // onClick={onOpenRingCentral}
                          >
                            <i className='bi bi-file-play' style={{color: 'inherit'}} />
                            Connect
                          </Button>
                        )}
                      </>
                    ) : (
                      <Card.Link
                        className='btn btn-transparent border d-flex align-items-center gap-2'
                        href='#'
                      >
                        <i className='bi bi-file-play' style={{color: 'inherit'}} />
                        Connect
                      </Card.Link>
                    )}
                  </div>
                </Card.Footer>
              </Card>
            ))}
          </div>
        </div>
      </GoogleOAuthProvider>
      {(isLoading || loading) && (
        <div
          className='d-flex flex-center'
          style={{
            inset: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000',
            color: 'white',
            opacity: 0.5,
            position: 'absolute',
            zIndex: 10000000,
          }}
        >
          <div>
            <Spinner animation='border' style={{height: '3.5em', width: '3.5em'}} />
          </div>
        </div>
      )}

      {isOpenRingCentral && (
        <ModalRingCentralConnect isOpen={isOpenRingCentral} onClose={onCloseRingCentral} />
      )}
    </>
  )
}
