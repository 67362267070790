import {
  FC,
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useGetUser} from '../../../tfi/store/userStore'
import {useMutation} from '@apollo/client'
import {VERIFY_TOKEN} from '../../../tfi/gql/mutations/authMutations'
import {STORAGE_NAMES} from '../../../tfi/helpers/consts'
import {TokenAuth} from '../../../tfi/types/auth'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const [gqlVerify, {data, loading, error}] = useMutation(VERIFY_TOKEN)

  const render = useRef(0)

  const status = useGetUser((s) => s.status)
  const logout = useGetUser((s) => s.logout)
  const setLoading = useGetUser((s) => s.setLoading)
  const login = useGetUser((s) => s.login)

  useEffect(() => {
    const token = window.localStorage.getItem(STORAGE_NAMES.TOKEN)
    if (!token) {
      return logout()
    }

    if (render.current === 0) {
      render.current = 1
      gqlVerify({
        variables: {
          token,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loading) {
      setLoading()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (data) {
      if (data.verifyToken && data.verifyToken.payload) {
        const date = data.verifyToken.payload.exp

        const fechaActual = Date.now()

        // Obtiene la fecha de vencimiento del token en milisegundos (suponiendo que el valor proporcionado es en segundos)
        const fechaVencimientoToken = date * 1000

        // Calcula la diferencia en milisegundos entre la fecha de vencimiento y la fecha actual
        const diferencia = fechaVencimientoToken - fechaActual

        // Convierte la diferencia en minutos
        const minutosRestantes = Math.floor(diferencia / (1000 * 60))

        if (minutosRestantes <= 30) {
          return logout()
        }

        // const rol = window.localStorage.getItem('rol');
        // const permissions = window.localStorage.getItem('permissions');
        const user = window.localStorage.getItem(STORAGE_NAMES.USER)
        if (!user) return
        const userObj = JSON.parse(user) as TokenAuth
        login({tokenAuth: userObj})

        /* const infoZoneOrAgencie =
          window.localStorage.getItem('infoZoneOrAgency'); */
      } else {
        logout()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (error) {
      console.log('error', error)
      logout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return status === 'loading' ? (
    <div
      className='d-flex align-items-center justify-content-center bg-dark'
      style={{width: '100vw', height: '100vh'}}
    >
      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/tfi/logoN.png')}
        className='h-75px tfi-blink-logo'
      />
    </div>
  ) : (
    <>{children}</>
  )
}

export {AuthProvider, AuthInit, useAuth}
