import {Alert, Offcanvas, Placeholder, Spinner} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {useLazyQuery, useQuery} from '@apollo/client'
import {GET_CAMPUS_FILTER} from '../../../gql/queries/campusQuery'
import {GET_PIPELINES_FILTERS} from '../../../gql/queries/pipelines'
import {GET_ALL_LEAD_SOURCE} from '../../../gql/queries/leadSourceQuery'
import {GET_FILTERS_MEMBERS} from '../../../gql/queries/membersQuery'
import {GET_TAGS_FILTER} from '../../../gql/queries/tagsQuery'
import {toast} from 'react-toastify'
import {IResPipelines} from '../../../types/pipelines'
import {IResTags} from '../../../types/tags'
import {IResAllMembers} from '../../../types/members'
import {IResCampus} from '../../../types/campus'
import {IResCatLeadSource} from '../../leadSource/leadSourceTypes'
import {Tooltip} from 'react-tooltip'

import 'react-tooltip/dist/react-tooltip.css'

import {IInfoLead, IResFile, IResOneLead, TTabs} from '../leadTypes'
import {FormEditLead} from './editLead/FormEditLead'
import {FormAppointment} from './editLead/FormAppointment'
import {FormNote} from './editLead/FormNote'
import {Link} from 'react-router-dom'
import {FormSMS} from './editLead/FormSMS'
import {FormSendMail} from './editLead/FormSendMail'
import {GET_ONE_LEAD, GET_REQUEST_CONSENT_LASTED} from '../../../gql/queries/leadsQuery'
import {GET_ALL_REASON_LOSS_FILTER} from '../../../gql/queries/reasonLossQuery'
import {IResAllReasons} from '../../../types/reasonLoss'
import {parseId} from '../../../helpers'
import {CREATE_FILE_STR} from '../../../gql/mutations/leadMutations'
import {useUploadFile} from '../../../hooks/useUploadFile'
import {NavMainEditLead} from './NavMainEditLead'
import {AppointmentsListLead} from './sectionsTopNav/AppointmentsListLead'
import {NotesListLead} from './sectionsTopNav/NotesListLead'
import {FilessListLead} from './sectionsTopNav/FilesListLead'
import {NodeAppointmentSet, NodeNotes} from './sectionsTopNav/sectionNavTypes'
import {FormSendCall} from './editLead/FormSendCall'
import {RequesConcentDrawer} from './sectionsTopNav/RequesConcentDrawer'
import {NodeDocusign} from '../../../types/leads'

const FORM_TYPES = {
  EDIT: 'EDIT',
  APPOINTMENT: 'APPOINTMENT',
  NOTE: 'NOTE',
  SMS: 'SMS',
  MAIL: 'MAIL',
} as const

interface IDrawerProps {
  isOpen: boolean
  onClose: () => void
  info: IInfoLead
  updateListLeads: () => void
}

const LIST_NAV = [
  {
    id: 1,
    name: 'More information',
    icon: 'bi-exclamation-circle',
    isLink: true,
    isNav: true,
    to: '/more-info?lead=',
  },
  {
    id: 2,
    name: 'Create an appointment',
    icon: 'bi-calendar-check',
    isLink: false,
    isNav: true,
    onClick: (set: (type: keyof typeof FORM_TYPES) => void) => {
      set('APPOINTMENT')
    },
  },
  {
    id: 3,
    name: 'Create a note',
    icon: 'bi-sticky',
    isLink: false,
    isNav: true,
    onClick: (set: (type: keyof typeof FORM_TYPES) => void) => {
      set('NOTE')
    },
  },
  {
    id: 4,
    name: 'Send SMS',
    icon: 'bi-chat-left-text',
    isLink: false,
    isNav: true,
    onClick: (set: (type: keyof typeof FORM_TYPES) => void) => {
      set('SMS')
    },
  },
  {
    id: 5,
    name: 'Send Email',
    icon: 'bi-send',
    isLink: false,
    isNav: true,
    onClick: (set: (type: keyof typeof FORM_TYPES) => void) => {
      set('MAIL')
    },
  },
  {
    id: 6,
    name: 'Upload file',
    icon: 'bi-paperclip',
    isLink: false,
    isNav: false,
    onClick: (callback: (file: File) => void) => {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.style.display = 'none'

      // Adjuntar el input al documento
      document.body.appendChild(fileInput)

      // Simular clic en el input
      fileInput.click()

      // Cuando se seleccione un archivo, hacer algo con él
      fileInput.addEventListener('change', function () {
        const file = fileInput?.files?.[0]
        if (!file) return
        // Aquí puedes realizar acciones con el archivo, como subirlo a un servidor, etc.
        console.log('Archivo seleccionado:', file?.name)
        callback(file)
        // Aquí puedes agregar lógica para guardar el archivo
        // Por ejemplo, podrías usar el objeto File y enviarlo a un servidor a través de AJAX
      })

      // Eliminar el input después de seleccionar el archivo
      fileInput.remove()
    },
  },
] as const

export const DrawerEditLead = ({isOpen, onClose, info, updateListLeads}: IDrawerProps) => {
  const [uploadFile, {data: dataFile, loading: loadingFile, error: errorFile}] =
    useUploadFile<IResFile>()
  const {data, loading, error, refetch} = useQuery<IResOneLead>(GET_ONE_LEAD, {
    variables: {id: parseId(info.id)},
    fetchPolicy: 'no-cache',
  })
  const [gqlGetLastedReq, {data: dataLatestReq}] = useLazyQuery<{
    latestDocusignEventsByLead: NodeDocusign[]
  }>(GET_REQUEST_CONSENT_LASTED)
  const {
    data: dataCampus,
    error: errorCampus,
    loading: loadingCampus,
  } = useQuery<IResCampus>(GET_CAMPUS_FILTER, {fetchPolicy: 'no-cache'})
  const {
    data: dataPipelines,
    error: errorPipelines,
    loading: loadingPipelines,
  } = useQuery<IResPipelines>(GET_PIPELINES_FILTERS, {fetchPolicy: 'no-cache'})
  const {
    data: dataLeadSource,
    error: errorLeadSource,
    loading: loadingLeadSource,
  } = useQuery<IResCatLeadSource>(GET_ALL_LEAD_SOURCE, {fetchPolicy: 'no-cache'})
  const {
    data: dataMembers,
    error: errorMembers,
    loading: loadingMembers,
  } = useQuery<IResAllMembers>(GET_FILTERS_MEMBERS, {fetchPolicy: 'no-cache'})
  const {
    data: dataTags,
    error: errorTags,
    loading: loadingTags,
  } = useQuery<IResTags>(GET_TAGS_FILTER, {fetchPolicy: 'no-cache'})
  const {
    data: dataReasonLoss,
    error: errorReasonLoss,
    loading: loadingReasonLoss,
  } = useQuery<IResAllReasons>(GET_ALL_REASON_LOSS_FILTER, {fetchPolicy: 'no-cache'})

  const [formType, setFormType] = useState<TTabs>(FORM_TYPES.EDIT)

  const [infoEditAppointment, setInfoEditAppointment] = useState<null | Omit<
    NodeAppointmentSet,
    'created'
  >>(null)

  const [infoNoteEdit, setInfoNoteEdit] = useState<Omit<
    NodeNotes,
    'created' | 'user' | 'date'
  > | null>(null)

  const [alertShow, setAlertShow] = useState<string | null>(null)

  useEffect(() => {
    if (errorCampus) toast.error(`Error: ${errorCampus.message}`)
  }, [errorCampus])
  useEffect(() => {
    if (errorLeadSource) toast.error(`Error: ${errorLeadSource.message}`)
  }, [errorLeadSource])
  useEffect(() => {
    if (errorMembers) toast.error(`Error: ${errorMembers.message}`)
  }, [errorMembers])
  useEffect(() => {
    if (errorPipelines) toast.error(`Error: ${errorPipelines.message}`)
  }, [errorPipelines])
  useEffect(() => {
    if (errorTags) toast.error(`Error: ${errorTags.message}`)
  }, [errorTags])
  useEffect(() => {
    if (errorReasonLoss) toast.error(`Error: ${errorReasonLoss.message}`)
  }, [errorReasonLoss])
  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (errorFile) toast.error(`Error: ${errorFile.toString()}`)
  }, [errorFile])

  useEffect(() => {
    if (!dataFile) return
    if (dataFile.createArchive.success) {
      toast.success('File added successfully')
    } else if (dataFile.createArchive.errors && dataFile.createArchive.errors?.length > 0) {
      toast.error(`Error: ${dataFile.createArchive.errors[0].message}`)
    }
  }, [dataFile])

  useEffect(() => {
    if (!data || !data.leadById) return
    gqlGetLastedReq({
      variables: {
        leadId: Number(data.leadById.id),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!dataLatestReq || !dataLatestReq.latestDocusignEventsByLead) return
    if (dataLatestReq.latestDocusignEventsByLead.length === 0) {
      setAlertShow('This lead has not requested a consent signature')
      return
    }

    const filters = dataLatestReq.latestDocusignEventsByLead.filter(
      (last) => last.event === 'envelope-completed'
    )
    setAlertShow(
      `For this lead, the signing of ${dataLatestReq.latestDocusignEventsByLead.length} document(s) has been requested. ${filters.length} of them has been completed`
    )
  }, [dataLatestReq])

  const changeForm = (type: keyof typeof FORM_TYPES | 'SEND_CALL') => {
    setFormType(type)
  }

  const handleFile = (file: File) => {
    if (!data?.leadById.id) return

    const formdata = new FormData()
    formdata.append('0', file, file.name)
    formdata.append('map', '{"0": ["variables.archiveData.archivo"]}')
    const obj = {
      query: CREATE_FILE_STR,
      variables: {
        archiveData: {
          archivo: null,
          name: file.name,
          leadId: data?.leadById.id,
        },
      },
    }
    formdata.append('operations', JSON.stringify(obj))
    uploadFile(formdata)
  }

  const handleUploadFile = () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.style.display = 'none'

    // Adjuntar el input al documento
    document.body.appendChild(fileInput)

    // Simular clic en el input
    fileInput.click()

    // Cuando se seleccione un archivo, hacer algo con él
    fileInput.addEventListener('change', function () {
      const file = fileInput?.files?.[0]
      if (!file) return
      // Aquí puedes realizar acciones con el archivo, como subirlo a un servidor, etc.
      handleFile(file)
      // Aquí puedes agregar lógica para guardar el archivo
      // Por ejemplo, podrías usar el objeto File y enviarlo a un servidor a través de AJAX
    })

    // Eliminar el input después de seleccionar el archivo
    fileInput.remove()
  }

  const isLoading =
    loadingCampus ||
    !dataCampus ||
    loadingLeadSource ||
    !dataLeadSource ||
    loadingMembers ||
    !dataMembers ||
    loadingPipelines ||
    !dataPipelines ||
    loadingTags ||
    !dataTags ||
    loadingReasonLoss ||
    !dataReasonLoss ||
    loading ||
    !data

  if (isLoading) {
    return (
      <Offcanvas show={isOpen} onHide={onClose} placement='end' className='w-100 w-md-75 w-lg-75'>
        <div
          className='bg-primary'
          style={{
            position: 'absolute',
            left: '-70px',
            top: '18%',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
          }}
        >
          <ul className='list-group' style={{maxWidth: '70px', width: '70px'}}>
            {LIST_NAV.map((nav) => (
              <li
                key={nav.id}
                className='list-group-item bg-transparent text-light py-6 d-flex align-items-center justify-content-center w-100'
              >
                <i className={'bi ' + nav.icon} style={{color: 'inherit', fontSize: '16px'}} />
              </li>
            ))}
            <li className='list-group-item bg-danger text-light py-6 d-flex align-items-center justify-content-center w-100'>
              <i className={'bi bi-telephone-plus'} style={{color: 'inherit', fontSize: '16px'}} />
            </li>
          </ul>
        </div>
        <Offcanvas.Header closeButton className='bg-light'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <Offcanvas.Title className='' style={{width: '120px'}}>
              <Placeholder animation='wave'>
                <Placeholder xs={12} />
              </Placeholder>
            </Offcanvas.Title>
            <div className='d-flex align-items-center gap-1'>
              <button
                className=''
                style={{
                  border: 'none',
                  padding: '4px 10px',
                  borderRadius: '14px',
                  color: 'gray',
                  backgroundColor: '#e4e4e4',
                }}
              >
                Personal
              </button>
              <button
                className=''
                style={{
                  border: 'none',
                  padding: '4px 10px',
                  borderRadius: '14px',
                  color: 'gray',
                  backgroundColor: '#e4e4e4',
                }}
              >
                Commercial
              </button>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='d-flex p-6 h-100'>
            <Spinner animation='border' />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    )
  }

  if (!data.leadById) {
    return (
      <Offcanvas show={isOpen} onHide={onClose} placement='end' className='w-100 w-md-75 w-lg-75'>
        <div
          className='bg-primary'
          style={{
            position: 'absolute',
            left: '-70px',
            top: '18%',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
          }}
        >
          <ul className='list-group' style={{maxWidth: '70px', width: '70px'}}>
            {LIST_NAV.map((nav) => (
              <li
                key={nav.id}
                className='list-group-item bg-transparent text-light py-6 d-flex align-items-center justify-content-center w-100'
              >
                <i className={'bi ' + nav.icon} style={{color: 'inherit', fontSize: '16px'}} />
              </li>
            ))}
          </ul>
        </div>
        <Offcanvas.Header closeButton className='bg-light'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <Offcanvas.Title className='d-flex align-items-center gap-4'>Edit Lead</Offcanvas.Title>
            <div className='d-flex align-items-center gap-1'>
              <button
                className=''
                style={{
                  border: 'none',
                  padding: '4px 10px',
                  borderRadius: '14px',
                  color: 'gray',
                  backgroundColor: '#e4e4e4',
                }}
              >
                Personal
              </button>
              <button
                className=''
                style={{
                  border: 'none',
                  padding: '4px 10px',
                  borderRadius: '14px',
                  color: 'gray',
                  backgroundColor: '#e4e4e4',
                }}
              >
                Commercial
              </button>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='d-flex p-6 h-100 text-danger'>Error finding Lead</div>
        </Offcanvas.Body>
      </Offcanvas>
    )
  }

  return (
    <Offcanvas show={isOpen} onHide={onClose} placement='end' className='w-100 w-md-75 w-lg-75'>
      {alertShow && (
        <div className='' style={{position: 'absolute', left: '10px', top: '50px', zIndex: 999999}}>
          <Alert variant='info' onClose={() => setAlertShow(null)} dismissible>
            {alertShow}
          </Alert>
        </div>
      )}
      {loadingFile && (
        <div
          className='d-flex align-items-center justify-content-center'
          style={{
            position: 'absolute',
            inset: 0,
            backgroundColor: '#000',
            opacity: 0.42,
            zIndex: 9999999,
          }}
        >
          <Spinner animation='border' style={{color: 'white'}} />
        </div>
      )}
      <div
        className='bg-transparent'
        style={{
          position: 'absolute',
          left: '-70px',
          top: '18%',
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
        }}
      >
        <ul className='list-group' style={{maxWidth: '70px', width: '70px'}}>
          <>
            <li
              role='button'
              className='list-group-item bg-primary text-light py-6 d-flex align-items-center justify-content-center w-100'
              data-tooltip-id='my-tooltip-11'
              data-tooltip-content='More information'
              id=''
            >
              <Link to={'/lead/' + data.leadById.id}>
                <i
                  className='bi bi-exclamation-circle'
                  style={{color: 'white', width: '100%', height: '100%', fontSize: '16px'}}
                />
              </Link>
            </li>
            <Tooltip id='my-tooltip-11' place='left' />
          </>
          <>
            <li
              role='button'
              className='list-group-item bg-primary text-light py-6 d-flex align-items-center justify-content-center w-100'
              data-tooltip-id='my-tooltip2'
              data-tooltip-content='Create an appointment'
              id=''
              onClick={() => {
                changeForm('APPOINTMENT')
                setInfoEditAppointment(null)
              }}
            >
              <i className='bi bi-calendar-check' style={{color: 'inherit', fontSize: '16px'}} />
            </li>
            <Tooltip id='my-tooltip2' place='left' />
          </>
          <>
            <li
              role='button'
              className='list-group-item bg-primary text-light py-6 d-flex align-items-center justify-content-center w-100'
              data-tooltip-id='my-tooltip3'
              data-tooltip-content='Create a note'
              id=''
              onClick={() => {
                changeForm('NOTE')
                setInfoNoteEdit(null)
              }}
            >
              <i className='bi bi-sticky' style={{color: 'inherit', fontSize: '16px'}} />
            </li>
            <Tooltip id='my-tooltip3' place='left' />
          </>
          <>
            <li
              role='button'
              className='list-group-item bg-primary text-light py-6 d-flex align-items-center justify-content-center w-100'
              data-tooltip-id='my-tooltip4'
              data-tooltip-content='Send SMS'
              id=''
              onClick={() => changeForm('SMS')}
            >
              <i className='bi bi-chat-left-text' style={{color: 'inherit', fontSize: '16px'}} />
            </li>
            <Tooltip id='my-tooltip4' place='left' />
          </>
          <>
            <li
              role='button'
              className='list-group-item bg-primary text-light py-6 d-flex align-items-center justify-content-center w-100'
              data-tooltip-id='my-tooltip5'
              data-tooltip-content='Send Email'
              id=''
              onClick={() => changeForm('MAIL')}
            >
              <i className='bi bi-send' style={{color: 'inherit', fontSize: '16px'}} />
            </li>
            <Tooltip id='my-tooltip5' place='left' />
          </>
          <>
            <li
              role='button'
              className='list-group-item bg-primary text-light py-6 d-flex align-items-center justify-content-center w-100'
              data-tooltip-id='my-tooltip'
              data-tooltip-content='Upload file'
              id=''
              onClick={handleUploadFile}
            >
              <i className='bi bi-paperclip' style={{color: 'inherit', fontSize: '16px'}} />
            </li>
            <Tooltip id='my-tooltip' place='left' />
          </>
          <>
            <li
              role='button'
              className='list-group-item bg-danger text-light py-6 d-flex align-items-center justify-content-center w-100'
              data-tooltip-id='my-tooltip22'
              data-tooltip-content='Sum Call'
              id=''
              onClick={() => {
                changeForm('SEND_CALL')
              }}
            >
              <i className='bi bi-telephone-plus' style={{color: 'inherit', fontSize: '16px'}} />
            </li>
            <Tooltip id='my-tooltip22' place='left' />
          </>
        </ul>
      </div>
      {(formType === 'EDIT' || formType === 'Main') && (
        <FormEditLead
          lead={data.leadById}
          dataCampus={dataCampus}
          dataLeadSource={dataLeadSource}
          dataPipelines={dataPipelines}
          dataTags={dataTags}
          dataMembers={dataMembers}
          dataReasonLoss={dataReasonLoss}
          onClose={onClose}
          updateListLeads={updateListLeads}
        >
          <NavMainEditLead setFormType={setFormType} formType={formType} />
        </FormEditLead>
      )}
      {formType === 'APPOINTMENT' && (
        <FormAppointment
          onBack={() => setFormType('EDIT')}
          leadId={data.leadById.id}
          members={dataMembers.allUsers.edges}
          infoEdit={infoEditAppointment}
          setInfoEditApp={setInfoEditAppointment}
        />
      )}
      {formType === 'NOTE' && (
        <FormNote
          onBack={() => setFormType('EDIT')}
          leadId={data.leadById.id}
          leadName={data.leadById.name}
          infoEdit={infoNoteEdit}
        />
      )}
      {formType === 'SMS' && <FormSMS onBack={() => setFormType('EDIT')} />}
      {formType === 'MAIL' && <FormSendMail onBack={() => setFormType('EDIT')} />}
      {formType === 'SEND_CALL' && (
        <FormSendCall
          leadId={data.leadById.id}
          leadName={data.leadById.name}
          onBack={() => setFormType('EDIT')}
          callCounter={data.leadById.callCounter}
          refetch={refetch}
          updateListLeads={updateListLeads}
        />
      )}

      {formType === 'Appointments' && (
        <AppointmentsListLead
          lead={data.leadById}
          setInfoEditApp={setInfoEditAppointment}
          onOpenEdit={() => setFormType('APPOINTMENT')}
        >
          <NavMainEditLead setFormType={setFormType} formType={formType} />
        </AppointmentsListLead>
      )}
      {formType === 'Notes' && (
        <NotesListLead
          lead={data.leadById}
          setInfoEditNote={setInfoNoteEdit}
          onOpenEdit={() => setFormType('NOTE')}
        >
          <NavMainEditLead setFormType={setFormType} formType={formType} />
        </NotesListLead>
      )}
      {formType === 'Files' && (
        <FilessListLead lead={data.leadById}>
          <NavMainEditLead setFormType={setFormType} formType={formType} />
        </FilessListLead>
      )}
      {formType === 'Consent' && (
        <RequesConcentDrawer lead={data.leadById} refetch={refetch}>
          <NavMainEditLead setFormType={setFormType} formType={formType} />
        </RequesConcentDrawer>
      )}
    </Offcanvas>
  )
}
