import {useEffect, useState} from 'react'
import {Card, Spinner} from 'react-bootstrap'
import {IResFile, LeadByID} from '../../leads/leadTypes'
import {NotesLead} from './NotesLead'
import {AddEditNoteDrawer} from './AddEditNoteDrawer'
import {useDisclourse} from '../../../hooks/useDisclourse'
import {NodeAppointmentSet, NodeNotes} from '../../leads/components/sectionsTopNav/sectionNavTypes'
import {AppointmentsLead} from './AppointmentsLead'
import {AddEditAppointmentDrawer} from './AddEditAppointmentDrawer'
import {FilesLead} from './FilesLead'
import {CREATE_FILE_STR} from '../../../gql/mutations/leadMutations'
import {useUploadFile} from '../../../hooks/useUploadFile'
import {toast} from 'react-toastify'

type TState = 'Activities' | 'Notes' | 'Appointments' | 'Files'

export const CardRightInfo: React.FC<{lead: LeadByID; refetch: () => void}> = ({lead, refetch}) => {
  const [uploadFile, {data: dataFile, loading: loadingFile, error: errorFile}] =
    useUploadFile<IResFile>()

  const [navInfoLead, setNavInfoLead] = useState<TState>('Activities')

  const [refetchRefNote, setRefetchRefNote] = useState(0)
  const [refetchRefAppoint, setRefetchRefAppoint] = useState(0)
  const [refRefetchFile, setRefRefetchFile] = useState(0)

  const [infoEditNote, setInfoEditNote] = useState<Omit<
    NodeNotes,
    'date' | 'user' | 'created'
  > | null>(null)

  const [infoEditAppointments, setInfoEditAppointments] = useState<Omit<
    NodeAppointmentSet,
    'created'
  > | null>(null)

  const {
    isOpen: isOpenAddEditNote,
    onClose: onCloseAddEditNote,
    onOpen: onOpenAddEditNote,
  } = useDisclourse()
  const {
    isOpen: isOpenAppointment,
    onClose: onCloseAppointment,
    onOpen: onOpenAppointment,
  } = useDisclourse()

  useEffect(() => {
    if (errorFile) toast.error(`Error: ${errorFile.toString()}`)
  }, [errorFile])

  useEffect(() => {
    if (!dataFile) return
    if (dataFile.createArchive.success) {
      toast.success('File added successfully')
      setRefRefetchFile((p) => p + 1)
    } else if (dataFile.createArchive.errors && dataFile.createArchive.errors?.length > 0) {
      toast.error(`Error: ${dataFile.createArchive.errors[0].message}`)
    }
  }, [dataFile])

  const handleFile = (file: File) => {
    if (!lead.id) return

    const formdata = new FormData()
    formdata.append('0', file, file.name)
    formdata.append('map', '{"0": ["variables.archiveData.archivo"]}')
    const obj = {
      query: CREATE_FILE_STR,
      variables: {
        archiveData: {
          archivo: null,
          name: file.name,
          leadId: lead.id,
        },
      },
    }
    formdata.append('operations', JSON.stringify(obj))
    uploadFile(formdata)
  }

  const handleUploadFile = () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.style.display = 'none'

    document.body.appendChild(fileInput)

    fileInput.click()

    fileInput.addEventListener('change', function () {
      const file = fileInput?.files?.[0]
      if (!file) return
      handleFile(file)
    })

    // Eliminar el input después de seleccionar el archivo
    fileInput.remove()
  }

  return (
    <>
      <Card>
        <Card.Header className='d-flex align-items-center justify-content-between'>
          <div>
            <div
              style={{
                borderBottom: 0,
                display: 'flex',
                background: '#f6f7fb',
                padding: '6px',
                borderRadius: '60px',
              }}
              className='d-flex align-items-center'
            >
              {(
                [
                  {title: 'Activities'},
                  {title: 'Notes'},
                  {title: 'Appointments'},
                  {title: 'Files'},
                ] as const
              ).map((nav) => (
                <button
                  key={nav.title}
                  style={{
                    flex: 1,
                    borderRadius: '20px',
                    color:
                      navInfoLead === nav.title
                        ? 'white'
                        : nav.title === navInfoLead
                        ? 'white'
                        : undefined,
                  }}
                  className={`border-0 ${
                    navInfoLead === nav.title
                      ? 'bg-primary'
                      : nav.title === navInfoLead
                      ? 'bg-primary'
                      : 'bg-transparent'
                  }`}
                  onClick={() => {
                    setNavInfoLead(nav.title)
                  }}
                >
                  {nav.title}
                </button>
              ))}
            </div>
          </div>
          <div style={{display: 'inline-block'}} className='ms-4'>
            {loadingFile && <Spinner animation='border' size='sm' />}
          </div>
          <div className='d-flex align-items-center gap-1'>
            <button
              style={{
                border: '1px solid gray',
                backgroundColor: 'white',
                padding: '4px 4px',
                borderRadius: '100%',
              }}
              title='Add note'
              className='d-flex align-items-center justify-content-center'
              onClick={() => {
                setInfoEditNote(null)
                onOpenAddEditNote()
              }}
            >
              <i className='bi bi-sticky fs-4' />
            </button>
            <button
              style={{
                border: '1px solid gray',
                backgroundColor: 'white',
                padding: '4px 4px',
                borderRadius: '100%',
              }}
              className='d-flex align-items-center justify-content-center'
              title='Add appointment'
              onClick={() => {
                onOpenAppointment()
                setInfoEditAppointments(null)
              }}
            >
              <i className='bi bi-plus fs-4' />
            </button>
            <button
              style={{
                border: '1px solid gray',
                backgroundColor: 'white',
                padding: '4px 4px',
                borderRadius: '100%',
              }}
              className='d-flex align-items-center justify-content-center'
              title='Upload'
              onClick={handleUploadFile}
            >
              <i className='bi bi-paperclip fs-4' />
            </button>
          </div>
        </Card.Header>
        <Card.Body style={{minHeight: '380px', maxHeight: '400px', overflowY: 'auto'}}>
          {navInfoLead === 'Activities' && (
            <div>
              <h6 className='text-center py-2 bg-secondary'>Upcoming</h6>
              <div className='p-4'>
                <div
                  style={{border: '1px solid #e1e1e1', borderRadius: '12px', minHeight: '280px'}}
                  className='d-flex align-items-center justify-content-center gap-2 flex-column'
                >
                  <span
                    style={{
                      display: 'block',
                      height: '90px',
                      width: '90px',
                      borderRadius: '100%',
                      backgroundColor: '#f4f7f9',
                    }}
                    className='d-flex align-items-center justify-content-center'
                  >
                    <i className='bi bi-calendar-minus-fill' style={{fontSize: '50px'}} />
                  </span>
                  <span>Clean slate! There are no scheduled tasks.</span>
                </div>
              </div>
            </div>
          )}
          {navInfoLead === 'Notes' && (
            <NotesLead
              leadId={lead.id}
              onOpenEdit={onOpenAddEditNote}
              setInfoEditNote={setInfoEditNote}
              refetchRefNote={refetchRefNote}
            />
          )}
          {navInfoLead === 'Appointments' && (
            <AppointmentsLead
              leadId={lead.id}
              onOpenEdit={onOpenAppointment}
              setInfoEditApp={setInfoEditAppointments}
              refetchRefAppoint={refetchRefAppoint}
            />
          )}
          {navInfoLead === 'Files' && (
            <FilesLead leadId={lead.id} refRefetchFile={refRefetchFile} />
          )}
        </Card.Body>
      </Card>

      <AddEditNoteDrawer
        isOpen={isOpenAddEditNote}
        leadId={lead.id}
        onClose={onCloseAddEditNote}
        infoEdit={infoEditNote}
        refetch={() => setRefetchRefNote((p) => p + 1)}
      />

      <AddEditAppointmentDrawer
        infoEdit={infoEditAppointments}
        isOpen={isOpenAppointment}
        leadId={lead.id}
        onClose={onCloseAppointment}
        refetch={() => setRefetchRefAppoint((p) => p + 1)}
      />
    </>
  )
}
