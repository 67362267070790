import {useQuery} from '@apollo/client'
import {IResFilesList} from '../../leads/components/sectionsTopNav/sectionNavTypes'
import {GET_ALL_FILES_BY_LEAD} from '../../../gql/queries/leadsQuery'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {Spinner, Table} from 'react-bootstrap'
import Lottie from 'lottie-react'
import noFilesLottie from '../../../assets/lotties/no-files.json'

export const FilesLead = ({leadId, refRefetchFile}: {leadId: string; refRefetchFile: number}) => {
  const {data, loading, error, refetch} = useQuery<IResFilesList>(GET_ALL_FILES_BY_LEAD, {
    variables: {
      id: leadId,
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (refRefetchFile === 0) return
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refRefetchFile])

  const handleClickDown = ({name, file}: {name: string; file: string}) => {
    const link = document.createElement('a')
    link.href = file
    link.download = name
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  if (loading || !data) {
    return (
      <div className='mt-6'>
        <Spinner animation='border' />
      </div>
    )
  }
  if (!data.leadById) {
    return (
      <div className='mt-6'>
        <h3>Error loading files</h3>
      </div>
    )
  }
  if (data.leadById.archiveSet.edges.length === 0) {
    return (
      <div className='mt-6'>
        <h4 className='text-center mt-8'>There are no files recorded for this Lead</h4>
        <div style={{margin: '0 auto'}}>
          <Lottie
            animationData={noFilesLottie}
            autoplay
            style={{maxWidth: '50%', margin: '0 auto'}}
          />
        </div>
      </div>
    )
  }
  return (
    <div className=''>
      {/* <pre>{JSON.stringify(data.leadById.archiveSet.edges, null, 2)}</pre> */}
      <Table className='table-borderless align-middle mb-0'>
        <thead className='table-light'>
          <tr>
            <th scope='col' style={{width: '80px'}} className='text-center'>
              #
            </th>
            <th scope='col'>Name</th>
            <th scope='col' />
          </tr>
        </thead>
        <tbody>
          {data.leadById.archiveSet.edges.map(({node}, idx) => (
            <tr key={node.id}>
              <td className='text-center'>{idx + 1 < 10 ? `0${idx + 1}` : idx}</td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='avatar-sm'>
                    <div className='avatar bg-danger rounded fs-36'>
                      <i
                        className='bi bi-file-earmark fs-2'
                        style={{color: 'white', fontWeight: 'bold'}}
                      />
                    </div>
                  </div>
                  <div className='ms-3 flex-grow-1'>
                    <h6 className='fs-15 mb-0'>{node.name}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <button
                    className='p-2 bg-transparent'
                    style={{borderRadius: '8px', border: '1px solid blue', margin: '0 auto'}}
                    onClick={() => {
                      handleClickDown({file: node.archivo, name: node.name})
                    }}
                  >
                    <i className='bi bi-download fs-2' style={{color: 'blue'}} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
