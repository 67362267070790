import {useQuery} from '@apollo/client'
import {IResReqConsList, NodeDocusign} from '../../../types/leads'
import {GET_REQUEST_CONSET} from '../../../gql/queries/leadsQuery'
import {Spinner} from 'react-bootstrap'
import {Fragment, useEffect} from 'react'
import {toast} from 'react-toastify'
import moment from 'moment'

export const TimeLineRequestConset = ({
  leadId,
  flagRefetch = 0,
}: {
  leadId: string
  flagRefetch?: number
}) => {
  const {data, error, loading, refetch} = useQuery<IResReqConsList>(GET_REQUEST_CONSET, {
    variables: {
      leadId,
    },
  })

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (flagRefetch > 0) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagRefetch])

  if (loading || !data) {
    return (
      <div className='flex flex-center' style={{height: '300px'}}>
        <Spinner animation='border' />
      </div>
    )
  }

  const {allDocusignEvents} = data

  if (allDocusignEvents.edges.length === 0) {
    return (
      <div className='flex flex-center' style={{height: '300px'}}>
        <p>There is not consent request</p>
      </div>
    )
  }

  const groups = allDocusignEvents.edges.reduce((acc, current) => {
    const envelopeId = current.node.envelopeId

    if (!acc[envelopeId]) {
      acc[envelopeId] = []
    }

    acc[envelopeId].push(current.node)

    acc[envelopeId].sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )

    return acc
  }, {} as {[k: string]: NodeDocusign[]})

  return (
    <div>
      <div className='timeline-tfi timeline-left-tfi mx-lg-10'>
        {Object.keys(groups).map((key) => (
          <Fragment key={key}>
            <div className='timeline-breaker-tfi timeline-breaker-middle-tfi'>{key}</div>
            {groups[key].map((info) => (
              <div key={info.id} className='timeline-item-tfi mt-3 row text-center p-2'>
                <div className='col-12 font-weight-bold text-md-right'>
                  <span>{info.templateDescription}</span>
                </div>
                <div className='col font-weight-bold text-md-left'>
                  <strong>Event </strong>
                  <span>{info.event}</span>
                </div>
                <div className='col-12 text-xs text-muted'>
                  Created: {moment(info.createdAt).format('DD MMM YYYY [at] hh:mm A')}
                </div>
              </div>
            ))}
          </Fragment>
        ))}

        <div className='timeline-breaker-tfi timeline-breaker-bottom-tfi'>End</div>
      </div>
    </div>
  )
}
