import {useMutation} from '@apollo/client'
import React, {useEffect} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {CONVERT_TO_POLICY} from '../../../gql/mutations/quotesMutations'
import {useNavigate} from 'react-router-dom'
interface IModalDeleteProps {
  id: number
  isOpen: boolean
  onClose: () => void
}

interface IResConvert {
  convertQuoteToPolicy: {
    policy: {
      id: string
    }
    success: boolean
    errors?: {message: string}[]
  }
}

export const ModalToPolicy: React.FC<IModalDeleteProps> = ({id, isOpen, onClose}) => {
  const [gqlConvert, {data, loading, error}] = useMutation<IResConvert>(CONVERT_TO_POLICY)
  const navigate = useNavigate()

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.convertQuoteToPolicy.success) {
      toast.success('Quotation to policy successfully')
      navigate(`/sold?policy=${data.convertQuoteToPolicy.policy.id}`)
      onClose()
    } else if (data.convertQuoteToPolicy.errors && data.convertQuoteToPolicy.errors?.length > 0) {
      toast.error(`Error: ${data.convertQuoteToPolicy.errors?.[0]?.message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Convert to Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='fs-2'>Are you sure you want to convert the quotation to a policy?</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={() => {
            gqlConvert({
              variables: {
                quoteId: id,
              },
            })
          }}
          disabled={loading}
        >
          {loading && <Spinner animation='border' size='sm' className='me-2' />}
          Convert
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
