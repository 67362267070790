import {Badge} from 'react-bootstrap'

const COLORS = {
  PENDING: 'warning',
  CANCELED: 'danger',
  COMPLETED: 'success',
  QUOTED: 'info',
  QUOTE_CONVERTED: 'danger',
}

export const BadgeStatePolicy = ({state}: {state: string}) => {
  return <Badge bg={COLORS[state]}>{state}</Badge>
}
