import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {DragDropContext, DraggableLocation, Droppable, DropResult} from 'react-beautiful-dnd'
import {Card, Spinner} from 'react-bootstrap'
import {AllLeadStageEdge, IEdgesStagePipe, IFilterStage, IInfoLead, IResChangeState} from '../leadTypes'
import {EdgePipelines, IStagePipelineSet} from '../../../types/pipelines'
import Lottie from 'lottie-react'
import noDataLottie from '../../../assets/lotties/no-data.json'
import {parseId} from '../../../helpers'
import {BodyLeadsDragDrop} from './BodyLeadsDragDrop'
import {useMutation} from '@apollo/client'
import {CHANGE_STATE_LEAD} from '../../../gql/mutations/leadMutations'
import {toast} from 'react-toastify'
import {useDisclourse} from '../../../hooks/useDisclourse'
import {ModalDeadLead} from '../../oneLead/components/ModalDeadLead'
import { STATUS_LEADS } from '../LeadsPage'

/* const getItems = (count: number, offset = 0) =>
  Array.from({length: count}, (v, k) => k).map((k) => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`,
  }))
*/

const reorder = (list: AllLeadStageEdge[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getListStyle = (isDraggingOver: boolean, nodeName?: string) => ({
  background:
    isDraggingOver && nodeName === 'Declined'
      ? 'var(--bs-danger)'
      : isDraggingOver
      ? '#4C86CD'
      : 'white',
  padding: grid,
  width: 250,
})

const move = (
  source: AllLeadStageEdge[],
  destination: AllLeadStageEdge[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  /* const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone */

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  }
}

interface ILeadsArr extends IStagePipelineSet {
  leads: AllLeadStageEdge[]
  total: null | number
}

interface IContentDragProps {
  setInfoLead: Dispatch<SetStateAction<IInfoLead | null>>
  onOpenEdit: () => void
  updateListLeads: () => void
  setLoadingState: (b: boolean) => void
  pipelines: EdgePipelines[]
  pipelineId: string
  flagLeads: number
  filterStage: IFilterStage
  permissions: string[]
  searchName?: string
  searchPhone?: string
  valueUser?: string | null
  datesFrom?: Date |null
  datesTo?: Date|null
  isRingIntegration: boolean
  stagesDeclined?: IEdgesStagePipe|null
}

export const ContentDragDropLeads: React.FC<IContentDragProps> = ({
  setInfoLead,
  onOpenEdit,
  updateListLeads,
  setLoadingState,
  pipelines,
  pipelineId,
  flagLeads,
  filterStage,
  permissions,
  searchName,
  searchPhone,
  valueUser,
  datesFrom,
  datesTo,
  isRingIntegration,
  stagesDeclined
}) => {
  const [gqlChange, {data: dataChange, loading: loadingChange, error: errorChange}] =
    useMutation<IResChangeState>(CHANGE_STATE_LEAD)

  const refreshFns = useRef<{[stageId: string]: () => void}>({})

  const [stagesLeadsArr, setStagesLeadsArr] = useState<ILeadsArr[]>([])
  
  const [isDragging, setIsDragging] = useState(false)
  const {isOpen: isOpenDeadLead, onClose: onCloseDeadLead, onOpen: onOpenDeadLead} = useDisclourse()
  const [infoLeadDead, setInfoLeadDead] = useState({
    sourceDroppableId: '',
    leadsSource: {} as AllLeadStageEdge[],
    idDeadLead: '',
    idNameLead: '',
  })

  const registerRefresh = (stageId: string, fn: () => void) => {
    refreshFns.current[stageId] = fn
  }

  useEffect(() => {
    updateListLeads()
    if (errorChange) {
      toast.error(`Error: ${errorChange.message}`)
      updateListLeads()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorChange])

  useEffect(() => {
    if (!dataChange) return
    if (dataChange.changeLeadStage.success) {
      toast.success('Lead has changed state successfully')
    } else if (dataChange.changeLeadStage.errors && dataChange.changeLeadStage.errors.length > 0) {
      toast.error(`Error: ${dataChange.changeLeadStage.errors[0].message}`)
      updateListLeads()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChange])

  useEffect(() => {
    if (!pipelines) return
    const pipeFound = pipelines.find(({node}) => node.id === pipelineId)
    if (!pipeFound) return
    const stateP = pipeFound.node.stagePipelineSet.edges
    if (stateP.length === 0) {
      setStagesLeadsArr([])
      return
    }
    setStagesLeadsArr(stateP.map(({node}) => ({node, leads: [], total: null})))
    
  }, [pipelines, pipelineId])

  useEffect(() => {
    setLoadingState(loadingChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingChange])

  const updateOneLead = ({idStage, leads = []}: {idStage: string; leads: AllLeadStageEdge[]}) => {
    setStagesLeadsArr((p) => {
      const nArr = [...p]
      const findI = nArr.findIndex(({node}) => node.id === idStage)
      if (findI < 0) return nArr
      nArr[findI].leads = leads
      return nArr
    })
  }

  const initTotal = ({idStage, total}: {idStage: string; total: number}) => {
    setStagesLeadsArr((p) => {
      const nArr = [...p]
      const findI = nArr.findIndex(({node}) => node.id === idStage)
      if (findI < 0) return nArr
      nArr[findI].total = total
      return nArr
    })
  }

  const onDragEnd = (result: DropResult) => {
    const {destination, source, draggableId} = result
    console.log('result', result)
    let destinationTrash = false
    if (!destination) return

    if (destination.droppableId === 'Declined-Trash' && stagesDeclined) {
      destination.droppableId = `${parseId(stagesDeclined.node.id)}`
      destinationTrash = true
    }

    const sInd = +source.droppableId
    const dInd = +destination.droppableId
    if (sInd === dInd) {
      const arr = [...stagesLeadsArr]
      const find = arr.find(({node}) => parseId(node.id) === source.droppableId)
      if (!find) return
      const nLeads = reorder(find.leads, source.index, destination.index)
      find.leads = nLeads
      setStagesLeadsArr(arr)
      return
    }
    const arr = [...stagesLeadsArr]
    const findFrom = arr.find(({node}) => parseId(node.id) === source.droppableId)
    const findTo = arr.find(({node}) => parseId(node.id) === destination.droppableId)

    if (!findFrom || !findTo) return

    if (destinationTrash) {
      const aux = findFrom.leads.find((e) => parseId(e.node.id) === draggableId)
      setInfoLeadDead({
        sourceDroppableId: findFrom.node.id,
        leadsSource: findFrom.leads,
        idDeadLead: draggableId,
        idNameLead: aux?.node.name ?? '',
      })
      onOpenDeadLead()
      return
    } else {
      const resMove = move(findFrom.leads, findTo.leads, source, destination)
      findFrom.leads = resMove[sInd]
      if (findFrom.total != null) findFrom.total -= 1
      findTo.leads = resMove[dInd]
      if (findTo.total != null) findTo.total += 1
      setStagesLeadsArr(arr)
      // console.log('result', result)
      gqlChange({
        variables: {
          leadId: draggableId,
          stageId: dInd,
        },
      })
    }
  }

  const pipeFound = pipelines.find(({node}) => node.id === pipelineId)

  if (!pipeFound) return null

  const stateP = pipeFound.node.stagePipelineSet.edges

  if (
    stateP.length === 0 ||
    stagesLeadsArr.filter(({node}) => {
      return filterStage === 'all'
        ? true
        : filterStage === 'others'
        ? node.name !== STATUS_LEADS.Sold && node.name !== STATUS_LEADS.Declined
        : node.name === STATUS_LEADS.Sold || node.name === STATUS_LEADS.Declined
    }).length === 0
  ) {
    return (
      <div
        className='d-flex align-items-center justify-content-center w-100 flex-column'
        style={{height: '100%', minHeight: '400px'}}
      >
        <h4>This pipeline has no stages</h4>
        <div>
          <Lottie animationData={noDataLottie} autoplay style={{maxWidth: '400px'}} />
        </div>
      </div>
    )
  }

  return (
    <>
      <DragDropContext
        onDragStart={() => setTimeout(() => setIsDragging(true), 0)}
        onDragEnd={(result) => {
          setIsDragging(false)
          onDragEnd(result)
        }}
      >
        {stagesLeadsArr
          .sort((a, b) => a.node.order - b.node.order)
          .filter(({node}) => {
            return filterStage === 'all'
              ? true
              : filterStage === 'others'
              ? node.name !== STATUS_LEADS.Sold && node.name !== STATUS_LEADS.Declined
              : node.name === STATUS_LEADS.Sold || node.name === STATUS_LEADS.Declined
          })
          .map(({node, leads, total}) => (
            <Droppable key={node.id} droppableId={`${parseId(node.id)}`}>
              {(provided, snapshot) => (
                <Card
                  ref={provided.innerRef}
                  style={{...getListStyle(snapshot.isDraggingOver, node.name), flex: '1 0 250px'}}
                  {...provided.droppableProps}
                >
                  <div
                    style={{borderBottom: '1px solid #f1f1f2'}}
                    className='px-3 py-2 d-flex align-items-center justify-content-between'
                  >
                    <Card.Title>{node.name}</Card.Title>
                    <div>
                      <span
                        className='d-flex align-items-center justify-content-center px-3 py-1 text-primary'
                        style={{
                          borderRadius: '20px',
                          backgroundColor: '#ddf1ff',
                          fontWeight: 'bold',
                        }}
                      >
                        {total ?? <Spinner animation='grow' size='sm' />}
                      </span>
                    </div>
                  </div>
                  <BodyLeadsDragDrop
                    providedParent={provided}
                    setInfoLead={setInfoLead}
                    onOpenEdit={onOpenEdit}
                    initTotal={initTotal}
                    updateOneLead={updateOneLead}
                    updateListLeads={updateListLeads}
                    registerRefresh={registerRefresh}
                    stageId={node.id}
                    stageName={node.name}
                    flagLeads={flagLeads}
                    leads={leads}
                    total={total}
                    searchName={searchName}
                    searchPhone={searchPhone}
                    valueUser={valueUser}
                    datesFrom={datesFrom}
                    datesTo={datesTo}
                    permissions={permissions}
                    isRingIntegration={isRingIntegration}
                    declinedStage={stagesDeclined}
                  />
                </Card>
              )}
            </Droppable>
          ))}
        {(stagesDeclined && stagesDeclined.node.name!=='') && (
          <Droppable key={'Declined-Trash'} droppableId='Declined-Trash'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className='trash-zone'
                style={{
                  position: 'fixed',
                  bottom: 0,
                  width: '88%',
                  right: 0,
                  height: '120px',
                  backgroundColor: snapshot.isDraggingOver
                    ? 'var(--bs-danger)'
                    : isDragging
                    ? 'var(--bs-gray-300)'
                    : 'var(--bs-gray-500)',
                  textAlign: 'center',
                  lineHeight: '100px',
                  fontWeight: 'bold',
                  zIndex: 100,
                  transition: 'transform 0.3s ease, opacity 0.3s ease',
                  transform: isDragging ? 'translateY(0)' : 'translateY(110%)',
                  opacity: isDragging ? 1 : 0,
                  pointerEvents: isDragging ? 'auto' : 'none',
                }}
              >
                <div className='div-bi-trash '>
                  <h4 className='bi bi-trash '>{''}</h4>
                  <span>Arrastra aquí para eliminar </span>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
      {isOpenDeadLead && (
        <ModalDeadLead
          isOpen={isOpenDeadLead}
          onClose={() => {
            refreshFns.current[infoLeadDead.sourceDroppableId]?.() // Re
            setInfoLeadDead({
              sourceDroppableId: '',
              leadsSource: {} as AllLeadStageEdge[],
              idDeadLead: '',
              idNameLead: '',
            })
            onCloseDeadLead()
          }}
          info={{id: infoLeadDead.idDeadLead, name: infoLeadDead.idNameLead}}
        />
      )}
    </>
  )
}
