import {gql} from '@apollo/client'

export const CREATE_INTEGRATION = gql`
  mutation createUserIntegration($fromEmail: String!, $integrationId: ID!, $token: String!) {
    createUserIntegration(fromEmail: $fromEmail, integrationId: $integrationId, token: $token) {
      userIntegration {
        id
        idUser {
          id
        }
        data
      }
    }
  }
`

export const SEND_MAIL_STR = `
  mutation sendEmail($attachments: [Upload], $message: String!, $subject: String!, $toEmail: String!) {
    sendEmail(attachments: $attachments, message: $message, subject: $subject, toEmail: $toEmail) {
      success
      errorMessage
    }
  }
`
