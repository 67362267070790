import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap'
import {InputCurrency} from '../../../components/InputCurrency'
import {TFormKeys} from '../policiesTypes'

export const HouseholdInfoTab: React.FC<{
  onPrev: () => void
  onNext: () => void
  handleChange: (field: TFormKeys, value: string) => void
  handleBlur: (field: TFormKeys) => void
  values: {
    applicants: string
    members: string
    dependents: string
    taxCredit: string
  }
}> = ({onNext, onPrev, handleBlur, handleChange, values}) => {
  return (
    <div className='ps-2'>
      <h6>Household information</h6>
      <Row>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>
              Number of applicants <small className='text-danger'>(required)</small>
            </Form.Label>
            <Form.Control
              onChange={(e) => handleChange('applicants', e.target.value)}
              value={values.applicants}
              type='number'
              min={0}
            />
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>
              Number of members <small className='text-danger'>(required)</small>
            </Form.Label>
            <Form.Control
              onChange={(e) => handleChange('members', e.target.value)}
              value={values.members}
            />
          </Form.Group>
        </Col>
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>
              Dependents <small className='text-danger'>(required)</small>
            </Form.Label>
            <Form.Control
              onChange={(e) => handleChange('dependents', e.target.value)}
              value={values.dependents}
            />
          </Form.Group>
        </Col>

        {/* <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>
              Number of applicants <small className='text-danger'>(required)</small>
            </Form.Label>
            <Form.Control />
          </Form.Group>
        </Col> */}
        <Col className='mb-4' md={6}>
          <Form.Group>
            <Form.Label>Tax credit</Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text id='basic-addon1'>$</InputGroup.Text>
              <InputCurrency
                value={values.taxCredit}
                onValueChange={(v) => handleChange('taxCredit', v || '')}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <div className='pt-6'>
        <div className='pt-6 mt-6 d-flex align-items-center justify-content-between mb-4'>
          <Button onClick={onPrev} variant='outline'>
            PREVIUS
          </Button>
          <Button onClick={onNext}>NEXT STEP</Button>
        </div>
      </div>
    </div>
  )
}
