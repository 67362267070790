import {Accordion, Button, Col, Form, /* InputGroup, */ Row, Spinner} from 'react-bootstrap'
// import Select from 'react-select'
import {IValuesForm} from '../../soldTypes'
import {TFormKeys} from '../../../policies/policiesTypes'
import {useMutation, useQuery} from '@apollo/client'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {IResPoliciesType} from '../../../../types/policies'
import {GET_ALL_POLICIES_TYPES} from '../../../../gql/queries/policiesQueries'
import {InputCurrency} from '../../../../components/InputCurrency'
import {IResStates} from '../../../../types/statesEU'
import {GET_STATES_EU} from '../../../../gql/queries/statesEUQuery'
import {FormikErrors, FormikTouched} from 'formik'
import {GET_ALL_CARRIERS} from '../../../../gql/queries/carriersQuery'
import {IResCarriers} from '../../../../types/carriers'
import {EDIT_POLICY} from '../../../../gql/mutations/policyMutation'
import {IResEditPolicy} from '../../../../types/policiesApi'
import {useSearchParams} from 'react-router-dom'
import {parseId} from '../../../../helpers'

interface IPolicyHolderProps {
  onNextStep: () => void
  values: IValuesForm
  handleBlur: (field: TFormKeys) => void
  handleChange: (field: TFormKeys, value: string) => void
  handleSubmit: () => void
  onReturnInit: () => void
  errors: FormikErrors<IValuesForm>
  touched: FormikTouched<IValuesForm>
  loadingCreate: boolean
  refetch: () => void
  isEdit?: boolean
}

export const PolicyHolderTab: React.FC<IPolicyHolderProps> = ({
  onNextStep,
  handleBlur,
  handleChange,
  values,
  handleSubmit,
  errors,
  touched,
  loadingCreate,
  refetch,
  isEdit = false,
}) => {
  const [gqlEdit, {data: dataEdit, loading: loadingEdit, error: errorEdit}] =
    useMutation<IResEditPolicy>(EDIT_POLICY)

  const {data, error} = useQuery<IResPoliciesType>(GET_ALL_POLICIES_TYPES, {
    fetchPolicy: 'no-cache',
  })
  const {data: dataCarriers, error: errorCarriers} = useQuery<IResCarriers>(GET_ALL_CARRIERS, {
    fetchPolicy: 'no-cache',
  })
  const {data: dataStates, error: errorStates} = useQuery<IResStates>(GET_STATES_EU)

  const [searchParams] = useSearchParams()
  const policyId = searchParams.get('policy')

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
    }
  }, [error])
  useEffect(() => {
    if (errorEdit) toast.error(`Error: ${errorEdit.message}`)
  }, [errorEdit])

  useEffect(() => {
    if (errorCarriers) toast.error(`Error: ${errorCarriers.message}`)
  }, [errorCarriers])

  useEffect(() => {
    if (!dataEdit) return
    if (dataEdit.updatePolicy.success) {
      toast.success('Policy updated successfully')
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit])

  useEffect(() => {
    if (errorStates) toast.error(`Error: ${errorStates.message}`)
  }, [errorStates])

  const onEditPolicy = () => {
    const idP = Number(policyId)
    if (!idP || isNaN(idP)) return
    const policyData = {
      policyName: values.policyName,
      // status: values,
      applicants: Number(values.applicants),
      members: Number(values.members),
      dependents: Number(values.dependents),
      stateDocs: values.stateDocs,
      paymentStatus: values.paymentStatus,
      FFM: values.FFM,
      NPN: values.NPN,
      typeSale: values.typeSale,
      effectiveDate: values.effectiveDate,
      marketplaceId: values.marketplaceId,
      memberId: values.memberId,
      CMSPlanId: values.CMSPlanId,
      planName: values.planName,
      metalLevel: Number(values.metalLevel),
      policyTotalCost: Number(values.policyTotalCost),
      taxCredit: Number(values.taxCredit),
      premium: Number(values.premium),
      percentageValue: Number(values.percentageValue),
      nominalValue: Number(values.nominalValue),
      stateEU: parseId(values.stateEU),
      carrier: parseId(values.carrier),
      typePolicy: parseId(values.typePolicy),
      lead: values.lead ? Number(values.lead) : undefined,
    }

    gqlEdit({
      variables: {
        id: idP,
        policyData,
      },
    })
  }
  /* const form = useFormPolicy((s) => s.form.policyholder)
  const onChange = useFormPolicy((s) => s.onChange) */

  if (!policyId) return null

  return (
    <div>
      <Row>
        <Col sm={12} md={8}>
          <div className='kt-heading kt-heading--md custom-border-brand'>Specific information</div>
          <Row>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>
                  Policy Name <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Control
                  onChange={(e) => handleChange('policyName', e.target.value)}
                  value={values.policyName}
                  onBlur={() => handleBlur('policyName')}
                  isInvalid={Boolean(errors.policyName && touched.policyName)}
                />
                {errors.policyName && touched.policyName && (
                  <Form.Control.Feedback type='invalid'>{errors.policyName}</Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>
                  Type Policy <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Select
                  onChange={(e) => handleChange('typePolicy', e.target.value)}
                  value={values.typePolicy}
                  onBlur={() => handleBlur('typePolicy')}
                  isInvalid={Boolean(errors.typePolicy && touched.typePolicy)}
                >
                  <option value=''>Select one</option>
                  {data &&
                    data.allPoliciesType.edges.map(({node}) => (
                      <option key={node.id} value={node.id}>
                        {node.typePolicy}
                      </option>
                    ))}
                </Form.Select>
                {errors.typePolicy && touched.typePolicy && (
                  <Form.Control.Feedback type='invalid'>{errors.typePolicy}</Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>Type of Sale</Form.Label>
                <Form.Select
                  onChange={(e) => handleChange('typeSale', e.target.value)}
                  value={values.typeSale}
                >
                  <option value=''>Select one</option>
                  <option value='New sale'>New sale</option>
                  <option value='Renewal'>Renewal</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>Plan Name</Form.Label>
                <Form.Control
                  onChange={(e) => handleChange('planName', e.target.value)}
                  value={values.planName}
                />
              </Form.Group>
            </Col>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>
                  Metal Level <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Select
                  onChange={(e) => handleChange('metalLevel', e.target.value)}
                  value={values.metalLevel}
                  onBlur={() => handleBlur('metalLevel')}
                  isInvalid={Boolean(errors.metalLevel && touched.metalLevel)}
                >
                  <option value=''>Select one</option>
                  <option value='5'>Platinum</option>
                  <option value='4'>Gold</option>
                  <option value='3'>Silver</option>
                  <option value='2'>Bronze</option>
                  <option value='1'>Catastrophic</option>
                </Form.Select>
                {errors.metalLevel && touched.metalLevel && (
                  <Form.Control.Feedback type='invalid'>{errors.metalLevel}</Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>FFM</Form.Label>
                <Form.Control
                  onChange={(e) => handleChange('FFM', e.target.value)}
                  value={values.FFM}
                />
              </Form.Group>
            </Col>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>NPN</Form.Label>
                <Form.Control
                  onChange={(e) => handleChange('NPN', e.target.value)}
                  value={values.NPN}
                />
              </Form.Group>
            </Col>
            <Col className='mb-4'>
              <Form.Group>
                <Form.Label>Premium</Form.Label>
                <InputCurrency
                  value={values.premium}
                  onValueChange={(v) => handleChange('premium', v || '')}
                />
              </Form.Group>
            </Col>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>Marketplace ID</Form.Label>
                <Form.Control
                  onChange={(e) => handleChange('marketplaceId', e.target.value)}
                  value={values.marketplaceId}
                />
              </Form.Group>
            </Col>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>CMS Plan</Form.Label>
                <Form.Control
                  onChange={(e) => handleChange('CMSPlanId', e.target.value)}
                  value={values.CMSPlanId}
                />
              </Form.Group>
            </Col>

            <Col className='mb-4' md={12}>
              <Form.Group>
                <Form.Label>Policy Total Cost</Form.Label>
                <InputCurrency
                  value={values.policyTotalCost}
                  onValueChange={(v) => handleChange('policyTotalCost', v || '')}
                />
              </Form.Group>
            </Col>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>Percentage Value</Form.Label>
                <Form.Control
                  type='number'
                  max={100}
                  min={0}
                  value={values.percentageValue}
                  onChange={(v) => handleChange('percentageValue', v.target.value || '')}
                />
              </Form.Group>
            </Col>
            <Col className='mb-4' md={6}>
              <Form.Group>
                <Form.Label>Nominal Value</Form.Label>
                <InputCurrency
                  value={values.nominalValue}
                  onValueChange={(v) => handleChange('nominalValue', v || '')}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={4}>
          <div className='kt-heading kt-heading--md custom-border-brand mb-2'>
            Basic information
          </div>
          <div>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Policy information</Accordion.Header>
                <Accordion.Body>
                  {/* <Form.Group className='mt-2'>
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      onChange={(e) => handleChange('state', e.target.value)}
                      value={values.state}
                    >
                      <option value=''>Select one</option>
                      <option value='ACTIVE'>Active</option>
                      <option value='PENDING'>Pending</option>
                      <option value='SIGNED'>Signed</option>
                      <option value='PAID'>Paid</option>
                    </Form.Select>
                  </Form.Group> */}

                  <Form.Group className='mt-2'>
                    <Form.Label>
                      Status Documents <small className='text-danger'>(required)</small>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => handleChange('stateDocs', e.target.value)}
                      value={values.stateDocs}
                    >
                      <option value=''>Select one</option>
                      <option value='ACTIVE'>Active</option>
                      <option value='PENDING'>Pending</option>
                      <option value='SIGNED'>Signed</option>
                      <option value='PAID'>Paid</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className='mt-2'>
                    <Form.Label>
                      Status Payment <small className='text-danger'>(required)</small>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => handleChange('paymentStatus', e.target.value)}
                      value={values.paymentStatus}
                    >
                      <option value=''>Select one</option>
                      <option value='ACTIVE'>Active</option>
                      <option value='PENDING'>Pending</option>
                      <option value='SIGNED'>Signed</option>
                      <option value='PAID'>Paid</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className='mt-2'>
                    <Form.Label>
                      Effective date <small className='text-danger'>(required)</small>
                    </Form.Label>
                    <Form.Control
                      type='date'
                      onChange={(e) => handleChange('effectiveDate', e.target.value)}
                      value={values.effectiveDate}
                      onBlur={() => handleBlur('effectiveDate')}
                      isInvalid={Boolean(errors.effectiveDate && touched.effectiveDate)}
                    />
                    {errors.effectiveDate && touched.effectiveDate && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.effectiveDate}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className='mt-2'>
                    <Form.Label>
                      Carrier <small className='text-danger'>(required)</small>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => handleChange('carrier', e.target.value)}
                      value={values.carrier}
                      onBlur={() => handleBlur('carrier')}
                      isInvalid={Boolean(errors.carrier && touched.carrier)}
                    >
                      <option value=''>Select one</option>
                      {/* <option value='aW5zdXJhbmNlX29wZXJhdG9yX25vZGU6MQ=='>Default</option> */}
                      {dataCarriers?.allInsuranceOperators.edges.map(({node}) => (
                        <option key={node.id} value={node.id}>
                          {node.name}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.carrier && touched.carrier && (
                      <Form.Control.Feedback type='invalid'>{errors.carrier}</Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className='mt-2'>
                    <Form.Label>
                      States <small className='text-danger'>(required)</small>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => handleChange('stateEU', e.target.value)}
                      value={values.stateEU}
                      onBlur={() => handleBlur('stateEU')}
                      isInvalid={Boolean(errors.stateEU && touched.stateEU)}
                    >
                      <option value=''>Select one</option>
                      {dataStates?.allStates?.edges.map(({node}) => (
                        <option key={node.id} value={node.id}>
                          {node.name} ({node.code})
                        </option>
                      ))}
                      {errors.stateEU && touched.stateEU && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.stateEU}
                        </Form.Control.Feedback>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey='1'>
                <Accordion.Header>Physical Address</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Address line #1</Form.Label>
                    <Form.Control defaultValue='STREET' placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Address line #2</Form.Label>
                    <Form.Control defaultValue='4456' placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>City</Form.Label>
                    <Form.Control defaultValue='Illinois' placeholder='' />
                  </Form.Group>
                  <div className='mb-3'>
                    <Form.Label>State/Province</Form.Label>
                    <Select />
                  </div>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>
                      Zip code <span style={{color: 'red'}}>(required)</span>
                    </Form.Label>
                    <Form.Control defaultValue='63466' placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>County</Form.Label>
                    <Form.Control defaultValue='Clark County' placeholder='' />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='2'>
                <Accordion.Header>Mailing Address</Accordion.Header>
                <Accordion.Body>
                  <Form.Check // prettier-ignore
                    type='checkbox'
                    id='idd'
                    label={<span style={{color: 'black'}}>Same as physical address</span>}
                  />
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Mailing address line #1</Form.Label>
                    <Form.Control defaultValue='' placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Mailing address line #2</Form.Label>
                    <Form.Control defaultValue='' placeholder='(Optional)' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Mailing city</Form.Label>
                    <Form.Control defaultValue='' placeholder='' />
                  </Form.Group>
                  <div className='mb-3'>
                    <Form.Label>Mailing state/province</Form.Label>
                    <Select />
                  </div>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Mailing zip code</Form.Label>
                    <Form.Control defaultValue='' placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Mailing county</Form.Label>
                    <Form.Control defaultValue='' placeholder='' />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </div>
        </Col>
      </Row>

      {/* <Row>
        <Col sm={12} md={8}>
          <div className='kt-heading kt-heading--md custom-border-brand'>Legal documents</div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'>
              <Form.Group className=''>
                <Form.Label>Immigration status</Form.Label>
                <Form.Select aria-label='Default select example'>
                  <option>Open this select menu</option>
                  <option value='1'>One</option>
                  <option value='2'>Two</option>
                  <option value='3'>Three</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className='col-lg-6'>
              <Form.Group className=''>
                <Form.Label>Immigration status category</Form.Label>
                <Form.Control defaultValue='' placeholder='eg. C08' />
              </Form.Group>
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'>
              <Form.Label>USCIS #</Form.Label>
              <InputGroup className='mb-3'>
                <Form.Control
                  placeholder="Recipient's username"
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                />
                <Button variant='outline-secondary' className='border' id='button-addon2'>
                  <i className='bi bi-eye-fill' />
                </Button>
              </InputGroup>
            </div>
            <div className='col-lg-6'>
              <Form.Label>Passport #</Form.Label>
              <InputGroup className='mb-3'>
                <Form.Control
                  placeholder="Recipient's username"
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                />
                <Button variant='outline-secondary' className='border' id='button-addon2'>
                  <i className='bi bi-eye-fill' />
                </Button>
              </InputGroup>
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'>
              <Form.Label>Social Security Number (SSN)</Form.Label>
              <InputGroup className='mb-3'>
                <Form.Control
                  placeholder="Recipient's username"
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                />
                <Button variant='outline-secondary' className='border' id='button-addon2'>
                  <i className='bi bi-eye-fill' />
                </Button>
              </InputGroup>
            </div>
            <div className='col-lg-6'>
              <Form.Group className=''>
                <Form.Label>SSN issued date</Form.Label>
                <Form.Control type='date' />
              </Form.Group>
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'>
              <Form.Label>Green card #</Form.Label>
              <InputGroup className='mb-3'>
                <Form.Control
                  placeholder="Recipient's username"
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                />
                <Button variant='outline-secondary' className='border' id='button-addon2'>
                  <i className='bi bi-eye-fill' />
                </Button>
              </InputGroup>
            </div>
            <div className='col-lg-6'>
              <Form.Group className=''>
                <Form.Label>Green card issue date</Form.Label>
                <Form.Control type='date' />
              </Form.Group>
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'></div>
            <div className='col-lg-6'>
              <Form.Group className=''>
                <Form.Label>Green card expiration date</Form.Label>
                <Form.Control type='date' />
              </Form.Group>
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'>
              <Form.Label>Work permit card #</Form.Label>
              <InputGroup className='mb-3'>
                <Form.Control
                  placeholder="Recipient's username"
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                />
                <Button variant='outline-secondary' className='border' id='button-addon2'>
                  <i className='bi bi-eye-fill' />
                </Button>
              </InputGroup>
            </div>
            <div className='col-lg-6'>
              <Form.Group className=''>
                <Form.Label>Work permit issue date</Form.Label>
                <Form.Control type='date' />
              </Form.Group>
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'></div>
            <div className='col-lg-6'>
              <Form.Group className=''>
                <Form.Label>Work permit expiration date</Form.Label>
                <Form.Control type='date' />
              </Form.Group>
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'>
              <Form.Label>Driver license #</Form.Label>
              <InputGroup className='mb-3'>
                <Form.Control
                  placeholder="Recipient's username"
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                />
                <Button variant='outline-secondary' className='border' id='button-addon2'>
                  <i className='bi bi-eye-fill' />
                </Button>
              </InputGroup>
            </div>
            <div className='col-lg-6'>
              <Form.Group className=''>
                <Form.Label>Driver license expiration date</Form.Label>
                <Form.Control type='date' />
              </Form.Group>
            </div>
          </div>
        </Col>
        <Col sm={12} md={4}>
          <div className='kt-heading kt-heading--md custom-border-brand'>
            Employment information
          </div>
          <div>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Source of income #1</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Employer name / Self employed</Form.Label>
                    <Form.Control placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Employer phone number</Form.Label>
                    <Form.Control defaultValue='' placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Position/Occupation</Form.Label>
                    <Form.Control defaultValue='' placeholder='' />
                  </Form.Group>
                  <InputGroup className='mb-0'>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control />
                  </InputGroup>
                  <small>Type digits only.</small>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>Source of income #2</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Employer name / Self employed</Form.Label>
                    <Form.Control placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Employer phone number</Form.Label>
                    <Form.Control defaultValue='' placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Position/Occupation</Form.Label>
                    <Form.Control defaultValue='' placeholder='' />
                  </Form.Group>
                  <InputGroup className='mb-0'>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control />
                  </InputGroup>
                  <small>Type digits only.</small>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='2'>
                <Accordion.Header>Source of income #3</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Employer name / Self employed</Form.Label>
                    <Form.Control placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Employer phone number</Form.Label>
                    <Form.Control defaultValue='' placeholder='' />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Position/Occupation</Form.Label>
                    <Form.Control defaultValue='' placeholder='' />
                  </Form.Group>
                  <InputGroup className='mb-0'>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control />
                  </InputGroup>
                  <small>Type digits only.</small>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>
      </Row> */}

      <div
        className='py-5 px-3 d-flex align-items-center justify-content-between'
        style={{borderTop: '1px solid #e1e1e1'}}
      >
        <Button
          className='d-flex align-items-center gap-2'
          variant='success'
          onClick={onEditPolicy}
          disabled={loadingEdit || isEdit}
        >
          SAVE
          {loadingEdit && <Spinner animation='border' size='sm' />}
        </Button>
        <Button disabled={loadingEdit || loadingCreate} onClick={onNextStep}>
          NEXT STEP
        </Button>
      </div>
    </div>
  )
}
