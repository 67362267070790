import {gql} from '@apollo/client'

export const GET_TRANSACTIONS = gql`
  query transactionsByWallet($filters: TransactionFilterInput) {
    transactionsByWallet(filters: $filters) {
      id
      created
      value
      balance
      type
    }
  }
`
