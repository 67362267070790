import {Alert, Badge} from 'react-bootstrap'
import {INodeNotePolicy} from '../../../../types/policiesApi'
import moment from 'moment'

export const ContentNotes = ({notes}: {notes: INodeNotePolicy[]}) => {
  return (
    <div className='d-flex flex-column gap-4'>
      {notes.length === 0 && (
        <Alert variant='primary'>
          <i className='fa fa-exclamation-circle me-1' style={{color: 'inherit'}} />
          There are no notes created for this policy yet.
        </Alert>
      )}
      {notes.map((note) => (
        <Alert
          style={{width: 'max-content'}}
          key={note.id}
          variant={note.urgent ? 'danger' : 'success'}
        >
          <div className='d-flex align-items-start gap-3'>
            <div className='d-flex align-items-start text-primary gap-2'>
              <span className='avatar' style={{textTransform: 'uppercase', fontWeight: 'bolder'}}>
                {note.user.firstName[0]}
              </span>
              <p className='d-flex flex-column'>
                <span className='fw-bold m-0 p-0'>
                  {note.user.firstName} {note.user.lastName}
                </span>
                <small className='d-block m-0 p-0'>
                  {moment(note.created).format('MMM DD, YYYY hh:mm A')}
                </small>
              </p>
            </div>
            {note.urgent && <Badge bg='danger'>Urgent</Badge>}
          </div>
          <div className='text-dark'>
            <div dangerouslySetInnerHTML={{__html: note.comments}} />
          </div>
        </Alert>
      ))}
    </div>
  )
}
