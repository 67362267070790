import {Col, FloatingLabel, Form} from 'react-bootstrap'
import {CommercialLead} from '../leadTypes'

export const FormComercialLead = ({info}: {info?: CommercialLead | null}) => {
  return (
    <>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='dba' label='DBA'>
          <Form.Control
            name='dba'
            type='text'
            placeholder='DBA'
            defaultValue={info?.dba || undefined}
          />
        </FloatingLabel>
      </Col>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='businessEntity' label='Business Entity'>
          <Form.Control
            name='businessEntity'
            type='text'
            placeholder='Business Entity'
            defaultValue={info?.businessEntity || undefined}
          />
        </FloatingLabel>
      </Col>

      {/* Grupo 2 */}
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='businessClassification' label='Business Classification'>
          <Form.Control
            name='businessClassification'
            type='text'
            placeholder='Business Classification'
            defaultValue={info?.businessClassification || undefined}
          />
        </FloatingLabel>
      </Col>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='federalEin' label='Federal EIN'>
          <Form.Control
            name='federalEin'
            type='text'
            placeholder='Federal EIN'
            defaultValue={info?.federalEin || undefined}
          />
        </FloatingLabel>
      </Col>

      {/* Grupo 3 */}
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='businessStarted' label='Business Started'>
          <Form.Control
            name='businessStarted'
            type='text'
            placeholder='Business Started'
            defaultValue={info?.businessStarted || undefined}
          />
        </FloatingLabel>
      </Col>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='numberEmployees' label='Number of Employees'>
          <Form.Control
            name='numberEmployees'
            type='number'
            placeholder='Number of Employees'
            defaultValue={info?.numberEmployees || undefined}
          />
        </FloatingLabel>
      </Col>

      {/* Grupo 4 */}
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='annualRevenue' label='Annual Revenue'>
          <Form.Control name='annualRevenue' type='text' placeholder='Annual Revenue' />
        </FloatingLabel>
      </Col>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='totalPayroll' label='Total Payroll'>
          <Form.Control
            name='totalPayroll'
            type='text'
            placeholder='Total Payroll'
            defaultValue={info?.totalPayroll || undefined}
          />
        </FloatingLabel>
      </Col>

      {/* Grupo 5 */}
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='secondPhone' label='Second Phone Comercial'>
          <Form.Control
            name='secondPhone'
            type='text'
            placeholder='Second Phone Comercial'
            defaultValue={info?.secondPhone || undefined}
          />
        </FloatingLabel>
      </Col>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='secondEmail' label='Second Email Comercial'>
          <Form.Control
            name='secondEmail'
            type='email'
            placeholder='Second Email Comercial'
            defaultValue={info?.secondEmail || undefined}
          />
        </FloatingLabel>
      </Col>

      {/* Grupo 6 */}
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='contactFirstname' label='Contact First Name'>
          <Form.Control
            name='contactFirstname'
            type='text'
            placeholder='Contact First Name'
            defaultValue={info?.contactFirstname || undefined}
          />
        </FloatingLabel>
      </Col>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='contactMiddlename' label='Contact Middle Name'>
          <Form.Control
            name='contactMiddlename'
            type='text'
            placeholder='Contact Middle Name'
            defaultValue={info?.contactMiddlename || undefined}
          />
        </FloatingLabel>
      </Col>

      {/* Grupo 7 */}
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='contactLastname' label='Contact Last Name'>
          <Form.Control
            name='contactLastname'
            type='text'
            placeholder='Contact Last Name'
            defaultValue={info?.contactLastname || undefined}
          />
        </FloatingLabel>
      </Col>
    </>
  )
}
