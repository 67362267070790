import {useQuery} from '@apollo/client'
import {Card, Spinner} from 'react-bootstrap'
import {IResCatLeadSource} from './leadSourceTypes'
import {GET_ALL_LEAD_SOURCE} from '../../gql/queries/leadSourceQuery'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {ModalAddEditLeadSource} from './components/ModalAddEditLeadSource'
import {useDisclourse} from '../../hooks/useDisclourse'

export const LeadSourcePage = () => {
  const {data, loading, error, refetch} = useQuery<IResCatLeadSource>(GET_ALL_LEAD_SOURCE, {
    fetchPolicy: 'no-cache',
  })

  const {isOpen, onClose, onOpen} = useDisclourse()
  const [info, setInfo] = useState<{id?: string; name?: string; idCat?: string}>({})

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data) {
    return (
      <div>
        <Card>
          <Card.Body style={{height: '40vh'}}>
            <Spinner animation='border' className='ms-4' />
          </Card.Body>
        </Card>
      </div>
    )
  }

  if (data.allCategoryLeadSource.edges.length === 0) {
    return (
      <div>
        <Card>
          <Card.Body
            style={{
              minHeight: '300px',
            }}
          >
            <div>There are no "Lead-sources" currently added</div>
          </Card.Body>
        </Card>
      </div>
    )
  }

  return (
    <div>
      <Card>
        <Card.Header>
          <Card.Title>Lead Sources</Card.Title>
        </Card.Header>
        <Card.Body
          style={{
            minHeight: '300px',
          }}
        >
          <div
            className='d-flex align-items-center gap-4 justify-content-center justify-content-md-start'
            style={{flexWrap: 'wrap'}}
          >
            {data.allCategoryLeadSource.edges.map(({node}) => (
              <div
                key={node.id}
                style={{
                  minWidth: '180px',
                  minHeight: '240px',
                  border: '1px dashed gray',
                }}
                className='d-flex flex-column rounded'
              >
                <h6
                  style={{
                    flex: 0,
                    borderBottom: '1px solid #cccccc',
                    padding: '2px 5px',
                    marginBottom: 0,
                  }}
                >
                  {node.name}
                </h6>
                <div style={{flex: 1, padding: '2px 8px', maxHeight: '220px', overflowY: 'auto'}}>
                  {node.leadSourceSet.edges.map(({node: nodeLead}) => (
                    <div
                      key={nodeLead.id}
                      className='d-flex align-items-center justify-content-between'
                      style={{padding: '2px 2px', borderBottom: '1px solid #cccccc'}}
                    >
                      <div>{nodeLead.name}</div>
                      <div>
                        <button
                          title='Edit Lead Source'
                          style={{border: 0, backgroundColor: 'transparent', padding: 0, margin: 0}}
                          onClick={() => {
                            onOpen()
                            setInfo({
                              id: nodeLead.id,
                              name: nodeLead.name,
                              idCat: node.id,
                            })
                          }}
                        >
                          <i className='bi bi-pencil-fill text-primary' />
                        </button>
                      </div>
                    </div>
                  ))}
                  {node.leadSourceSet.edges.length === 0 && (
                    <div
                      className='d-flex align-items-center justify-content-center text-muted'
                      style={{height: '160px'}}
                    >
                      No Leads Sources added
                    </div>
                  )}
                </div>
                <div
                  style={{flex: 0, borderTop: '1px solid #cccccc', padding: '2px 5px'}}
                  className='d-flex align-items-center justify-content-between'
                >
                  <button
                    style={{
                      border: 0,
                      backgroundColor: 'transparent',
                      padding: 0,
                      margin: 0,
                    }}
                    className='text-primary'
                    onClick={() => {
                      onOpen()
                      setInfo({
                        idCat: node.id,
                      })
                    }}
                  >
                    + Add Lead Source
                  </button>
                  <div className='d-flex algin-items-center gap-1'>
                    <button
                      title='Edit Category'
                      style={{border: 0, backgroundColor: 'transparent', padding: 0, margin: 0}}
                    >
                      <i className='bi bi-pencil-square text-primary' />
                    </button>
                    <button
                      className='Delete Category'
                      style={{border: 0, backgroundColor: 'transparent', padding: 0, margin: 0}}
                    >
                      <i className='bi bi-trash3-fill text-danger' />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>

      {isOpen && info.idCat && (
        <ModalAddEditLeadSource isOpen={isOpen} onClose={onClose} info={info} refetch={refetch} />
      )}
    </div>
  )
}
