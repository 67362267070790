import {useMutation} from '@apollo/client'
import {FormEvent, useEffect} from 'react'
import {Form, Spinner} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {ADD_CARRIER} from '../../../gql/mutations/carriersMutations'
import {toast} from 'react-toastify'
import {IResCreateInsuranceOperator} from '../../../types/carriers'

interface IModalAddCarrierProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}

export const ModalAddCarrier: React.FC<IModalAddCarrierProps> = ({isOpen, onClose, refetch}) => {
  const [gqlCreate, {data, loading, error}] = useMutation<IResCreateInsuranceOperator>(ADD_CARRIER)

  useEffect(() => {
    if (!data) return
    if (data.createInsuranceOperator?.insuranceOperator?.id) {
      toast.success('Carrier added successfully')
      refetch()
      onClose()
    } else if (
      data.createInsuranceOperator.errors &&
      data.createInsuranceOperator.errors?.length > 0
    ) {
      toast.error(`Error: ${data.createInsuranceOperator.errors[0]?.message || 'something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const {name} = Object.fromEntries(new FormData(e.currentTarget))
    if (!name) return
    const insuranceOperatorData = {
      name: name.toString(),
    }
    gqlCreate({
      variables: {
        insuranceOperatorData,
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add service operator</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div>
            <Form.Label htmlFor='name'>Name</Form.Label>
            <Form.Control id='name' name='name' aria-describedby='name-carrier' required />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' variant='secondary' onClick={onClose}>
            Close
          </Button>
          <Button type='submit' variant='primary' disabled={loading}>
            {loading && <Spinner animation='border' size='sm' className='me-2' />}
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
