import {useLazyQuery} from '@apollo/client'
import {GET_REPORT_SOURCE_PERCENT} from '../../../gql/queries/reportsQuery'
import {IResSourcePercent} from '../../../types/reportsTypes'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {parseId} from '../../../helpers'
import Chart from 'react-apexcharts'
import {Spinner} from 'react-bootstrap'

interface SourcePecenProps {
  dates: Date[]
  pipelineId: string
}
export const SourcePecentage: React.FC<SourcePecenProps> = ({pipelineId, dates}) => {
  const [gqlGet, {data, loading, error}] =
    useLazyQuery<IResSourcePercent>(GET_REPORT_SOURCE_PERCENT)

  useEffect(() => {
    if (!pipelineId || !dates || dates.length <= 1) return

    gqlGet({
      variables: {
        pipelineId: parseId(pipelineId),
        startDate: dates[0].toISOString().split('T')[0],
        endDate: dates[1].toISOString().split('T')[0],
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, pipelineId])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data)
    return (
      <div className='d-flex flex-center' style={{height: '190px'}}>
        <Spinner animation='border' />
      </div>
    )

  if (data.leadsBySourcePercentage.length === 0) {
    return (
      <div className='d-flex flex-column h-100'>
        <h5 className='text-center'>Percentage rate of each Lead Source</h5>
        <div style={{flex: 1}} className='d-flex flex-center h-100'>
          There is no information recorded for this pipeline.
        </div>
      </div>
    )
  }

  return (
    <div style={{maxWidth: '380px'}}>
      <Chart
        options={{
          chart: {
            type: 'donut',
          },
          title: {
            text: 'Percentage rate of each Lead Source',
            align: 'center',
            offsetY: 0,
          },
          labels: data.leadsBySourcePercentage.map((inf) => inf.leadSource),
          dataLabels: {
            formatter: (v) => `${v}%`,
          },
          legend: {
            formatter: (v, opt) => {
              const idx = opt.seriesIndex as number
              const val = opt.w.globals.seriesTotals[idx]
              return `${v}: ${Number(val).toFixed(2)}%`
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        }}
        series={data.leadsBySourcePercentage.map((inf) => Number(inf.percentage.toFixed(2)))}
        type='donut'
      />
    </div>
  )
}
