import {useQuery} from '@apollo/client'
import {
  IResAppointments,
  NodeAppointmentSet,
} from '../../leads/components/sectionsTopNav/sectionNavTypes'
import {GET_ALL_APPOINTMENTS_BY_LEAD} from '../../../gql/queries/leadsQuery'
import {Dispatch, SetStateAction, useEffect} from 'react'
import {toast} from 'react-toastify'
import {Badge, Card, Spinner} from 'react-bootstrap'
import Lottie from 'lottie-react'
import noAppointmentsLottie from '../../../assets/lotties/no-appoint.json'
import moment from 'moment'

const COLORS = [
  'primary',
  'success',
  'info',
  'warning',
  'danger',
  'secondary',
  'light',
  'dark',
] as const

interface IAppointmentsProps {
  setInfoEditApp: Dispatch<SetStateAction<Omit<NodeAppointmentSet, 'created'> | null>>
  leadId: string
  onOpenEdit: () => void
  refetchRefAppoint: number
}

export const AppointmentsLead: React.FC<IAppointmentsProps> = ({
  leadId,
  onOpenEdit,
  setInfoEditApp,
  refetchRefAppoint,
}) => {
  const {data, loading, error, refetch} = useQuery<IResAppointments>(GET_ALL_APPOINTMENTS_BY_LEAD, {
    variables: {
      id: leadId,
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (refetchRefAppoint === 0) return
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchRefAppoint])

  if (loading || !data) {
    return (
      <div>
        <Spinner animation='border' />
      </div>
    )
  }

  if (!data.leadById) {
    return (
      <div>
        <h6>Lead not Found</h6>
      </div>
    )
  }

  if (data.leadById.appointmentSet.edges.length === 0) {
    return (
      <div className='mt-6'>
        <h4 className='text-center mt-8'>There are no appointments recorded for this Lead</h4>
        <div style={{margin: '0 auto'}}>
          <Lottie
            animationData={noAppointmentsLottie}
            autoplay
            style={{maxWidth: '50%', margin: '0 auto'}}
          />
        </div>
      </div>
    )
  }
  return (
    <div className='d-flex flex-column gap-4'>
      {data.leadById.appointmentSet.edges.map(({node}, idx) => (
        <Card key={node.id}>
          <Card.Body>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-4'>
                <span
                  style={{
                    display: 'inline-block',
                    width: '11px',
                    height: '11px',
                    borderRadius: '100%',
                  }}
                  className={`bg-${COLORS[idx % COLORS.length]}`}
                />
                <span>{moment(node.date).format('DD MMM YYYY')}</span>
              </div>
              <Badge bg='warning'>{node.duration} min</Badge>
            </div>
            <div className='mt-3'>
              <h4>{node.tittle}</h4>
              <p className='text-muted'>{node.comment} </p>
            </div>
            <div className='d-flex align-items-md-center align-items-start justify-content-around flex-column flex-md-row'>
              <div className='d-flex flex-column'>
                <span className='fw-bold'>Assigned To</span>
                <span>{node.assignedTo}</span>
              </div>
              <div className='d-flex flex-column'>
                <span className='fw-bold'>Credit To</span>
                <span>{node.creditTo}</span>
              </div>
            </div>
            <div className='d-flex align-items-md-center align-items-start justify-content-between mt-3 flex-column flex-md-row gap-4'>
              <div className='mt-4 d-flex align-items-center gap-3'>
                <i className='bi bi-geo-alt fs-1 text-info' />
                <span>{node.meetingLocation}</span>
              </div>
              <div>
                <span
                  style={{
                    display: 'inline-block',
                    padding: '4px 6px',
                    border: '1px solid green',
                    borderRadius: '12px',
                    color: 'green',
                  }}
                >
                  {node.typeAppointment}
                </span>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className='d-flex align-items-md-center align-items-start justify-content-between flex-column flex-md-row gap-4'>
            <div>
              <small style={{fontStyle: 'italic'}}>
                Created At: {moment(node.created).format('DD MMM YYYY, hh:mm A')}
              </small>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <button
                className='btn  btn-sm'
                onClick={() => {
                  onOpenEdit()
                  setInfoEditApp({
                    assignedTo: node.assignedTo,
                    comment: node.comment,
                    creditTo: node.creditTo,
                    date: node.date,
                    duration: node.duration,
                    id: node.id,
                    meetingLocation: node.meetingLocation,
                    tittle: node.tittle,
                    typeAppointment: node.typeAppointment,
                  })
                }}
              >
                <i className='bi bi-pencil fs-3' style={{color: 'blue'}} />
              </button>
              <button className='btn  btn-sm'>
                <i className='bi bi-trash fs-3' style={{color: 'red'}} />
              </button>
            </div>
          </Card.Footer>
        </Card>
      ))}
    </div>
  )
}
