import {
  Breadcrumb,
  Button,
  Col,
  // Dropdown,
  Form,
  InputGroup,
  ListGroup,
  Pagination,
  Row,
  Spinner,
} from 'react-bootstrap'
const FILTERS_NAME_ARR = [
  /* {
    name: 'OE 2024 Obamacare (ACA)',
    icon: 'heart-fill',
  },
  {
    name: 'OE 2024 Medicare',
    icon: 'heart-fill',
  }, */
  {
    name: 'Important',
    icon: 'star-fill',
  },
]

export const LoadingQuotes = () => {
  return (
    <div>
      <header
        className='py-4 d-flex align-items-center justify-content-between'
        style={{width: '100%', marginTop: '-10px'}}
      >
        <Breadcrumb>
          <Breadcrumb.Item className='d-blok d-md-none'>
            <span>
              <i className='bi bi-list fs-1' />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='fs-3'>
            Quotes <Spinner animation='border' className='mx-3' size='sm' />
          </Breadcrumb.Item>
          {/* <Breadcrumb.Item active>Quote </Breadcrumb.Item> */}
        </Breadcrumb>

        <div className='d-flex align-items-center gap-2'>
          <Button size='sm' disabled>
            <i className='bi bi-plus fs-3' /> New Quote
          </Button>
          {/* <Dropdown>
            <Dropdown.Toggle variant='primary' size='sm' id='dropdown-basic' disabled>
              Export
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
              <Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
              <Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </header>
      <Row>
        <Col md={4} lg={4} sm={0} xl={3} className='d-none d-md-block'>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
              backgroundColor: '#fff',
              marginBottom: '20px',
              borderRadius: '4px',
              padding: '8px 10px',
            }}
          >
            <div
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
                paddingBottom: '7px',
              }}
            >
              <h5 style={{fontSize: '13px'}}>Menu</h5>
            </div>

            <div>
              <ListGroup className='mt-5' variant='flush'>
                <ListGroup.Item action variant={'light'} className='my-2 py-4' disabled>
                  <i className='bi bi-person-fill fs-3' style={{color: 'inherit'}} /> Quotes
                </ListGroup.Item>
                {FILTERS_NAME_ARR.map((lis) => (
                  <ListGroup.Item
                    key={lis.name}
                    action
                    variant={'light'}
                    className='my-1 py-4 d-flex align-items-center justify-content-between'
                    disabled
                  >
                    <span className='d-flex align-items-center justify-content-between gap-1'>
                      <i className={`bi bi-${lis.icon} fs-3`} style={{color: 'inherit'}} />{' '}
                      <span
                        className='placeholder-glow'
                        style={{display: 'inline-block', width: '110px'}}
                      >
                        <span className='placeholder col-12' />
                      </span>
                    </span>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item
                  action
                  variant={'light'}
                  className='my-2 py-4'
                  disabled
                  // onClick={() => setTabFilters('Policies')}
                >
                  <i className='bi bi-clock fs-3' style={{color: 'inherit'}} /> Reminders
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  variant={'light'}
                  className='my-2 py-4'
                  disabled
                  // onClick={() => setTabFilters('Policies')}
                >
                  <i className='bi bi-folder-fill fs-3' style={{color: 'inherit'}} /> Archived
                </ListGroup.Item>
              </ListGroup>
            </div>

            <div
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
              }}
              className='d-flex align-items-center justify-content-between'
            >
              <h5 className='m-0 p-0'>Agency Folders</h5>
              <Button variant='link' disabled>
                <i className='bi bi-pencil-square' style={{color: 'inherit'}} /> Manage
              </Button>
            </div>
            <p className='my-4'>Your agency has no folders.</p>
            <div
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
              }}
              className='d-flex align-items-center justify-content-between mt-5'
            >
              <h5 className='m-0 p-0'>
                Agency Folders
                <span>
                  <span data-tooltip-id='asdf'>
                    <i className='bi bi-info-circle-fill fs-5 ms-1' style={{color: '#5867dd'}} />
                  </span>
                </span>
              </h5>
              <Button variant='link' disabled>
                <i className='bi bi-pencil-square' style={{color: 'inherit'}} /> Manage
              </Button>
            </div>
            <p className='my-4'>You have no personal folders.</p>
          </div>
        </Col>
        <Col md={8} lg={8} sm={12} xl={9}>
          {/* <div
            style={{
              backgroundColor: 'white',
              boxShadow: '0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)',
              borderRadius: '6px',
            }}
            className='p-4'
          >
            <div className='row row-no-padding row-col-separator-lg g-4'>
              <div className='col-sm-12 col-md-6 col-xxl-3'>
                <div className='kt-widget24'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='kt-widget24__info'>
                      <h4 className='m-0 p-0'>POLICIES</h4>
                      <span className='text-muted'>Number of policies</span>
                    </div>
                    <span
                      className='text-primary placeholder-glow'
                      style={{
                        fontSize: '1.75rem',
                        fontWeight: 500,
                        paddingLeft: '.5rem',
                        width: '120px',
                      }}
                      id='qty_policies_label'
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-xxl-3'>
                <div className='kt-widget24'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='kt-widget24__info'>
                      <h4 className='m-0 p-0'>APPLICANTS</h4>
                      <span className='text-muted'>Number of applicants</span>
                    </div>
                    <span
                      className='text-primary placeholder-glow'
                      style={{
                        fontSize: '1.75rem',
                        fontWeight: 500,
                        paddingLeft: '.5rem',
                        width: '120px',
                      }}
                      id='qty_policies_label'
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-xxl-3'>
                <div className='kt-widget24'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='kt-widget24__info'>
                      <h4 className='m-0 p-0'>CANCELED POLICIES</h4>
                      <span className='text-muted'>Canceled policies</span>
                    </div>
                    <span
                      className='text-primary placeholder-glow'
                      style={{
                        fontSize: '1.75rem',
                        fontWeight: 500,
                        paddingLeft: '.5rem',
                        width: '120px',
                      }}
                      id='qty_policies_label'
                    >
                      <span className='placeholder col-8' />
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-xxl-3'>
                <div className='kt-widget24'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='kt-widget24__info'>
                      <h4 className='m-0 p-0'>CANCELED APPLICANTS</h4>
                      <span className='text-muted'>Canceled applicants</span>
                    </div>
                    <span
                      className='text-primary placeholder-glow'
                      style={{
                        fontSize: '1.75rem',
                        fontWeight: 500,
                        paddingLeft: '.5rem',
                        width: '120px',
                      }}
                      id='qty_policies_label'
                    >
                      <span className='placeholder col-8' />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
              backgroundColor: '#fff',
              marginBottom: '20px',
              borderRadius: '4px',
              padding: '8px 10px',
            }}
            className='mt-0'
          >
            <div
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
                paddingBottom: '7px',
              }}
              className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between'
            >
              <h3 style={{fontSize: '13px'}}>All Quotes</h3>
              <div>
                <span className='fw-bold me-4'>Effective year:</span>
                <Form.Check disabled inline label='2024' />
                <Form.Check disabled inline label='2023' />
                <Form.Check disabled inline label='2022' />
              </div>
            </div>

            <div className='my-4'>
              <InputGroup>
                <Form.Control
                  disabled
                  placeholder='Type your query here...'
                  aria-label="Recipient's username with two button addons"
                />
                <Button variant='outline-success' disabled style={{border: '1px solid #dbdfe9'}}>
                  Search
                </Button>
                <Button variant='outline-primary' disabled style={{border: '1px solid #dbdfe9'}}>
                  Filter
                </Button>
              </InputGroup>
            </div>
            <div className='m-0'>
              <Form.Check
                disabled
                type='checkbox'
                label={
                  <span>
                    Search by members:{' '}
                    <span className='ms-2 ' data-tooltip-id='my-tooltip-info-1'>
                      <i className='bi bi-question-circle-fill text-primary' />
                    </span>
                  </span>
                }
              />
            </div>

            <Row className='mt-5'>
              <Col sm={12} md={6} xl={4}>
                <div className='d-flex align-items-center gap-1'>
                  <span style={{flex: 0}}>Show</span>
                  <Form.Select defaultValue={10} disabled style={{flex: 0, flexBasis: '80px'}}>
                    <option>10</option>
                  </Form.Select>
                  <span style={{flex: 0}}>entries</span>
                </div>
              </Col>
              <Col className='d-none d-xl-block' xl={4}>
                <p className='d-flex align-items-center justify-content-center h-100 w-100'>
                  Showing 1 to 10 of <Spinner size='sm' animation='border' className='mx-2' />{' '}
                  entries
                </p>
              </Col>
              <Col sm={12} md={6} xl={4}>
                <div className='d-flex align-items-center justify-content-end'>
                  <Pagination size='sm'>
                    <Pagination.Prev disabled />
                    <Pagination.Item active disabled>
                      1
                    </Pagination.Item>
                    <Pagination.Item disabled>2</Pagination.Item>
                    <Pagination.Next disabled />
                  </Pagination>
                </div>
              </Col>
            </Row>
            <div style={{width: '100%', overflowX: 'auto'}} className='mt-5'>
              <table
                className='table table-hover table-sm dataTable no-footer dtr-inline w-100'
                style={{whiteSpace: 'nowrap'}}
              >
                <thead>
                  <tr role='row'>
                    <th></th>
                    <th className='fw-bold'>Agent</th>
                    <th className='fw-bold'>Client</th>
                    <th className='fw-bold'>Policy</th>
                    <th className='fw-bold'>Status</th>
                    <th className='fw-bold'>Effective</th>
                    {/* <th className='fw-bold'>Assigned to</th> */}
                    <th className='fw-bold'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={8}>
                      <div className='d-flex flex-center' style={{height: '30vh'}}>
                        <Spinner animation='border' />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
