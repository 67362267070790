import {gql} from '@apollo/client'

export const CREATE_EDIT_GROUP = gql`
  mutation groupAssignmentCreateupdateMutation(
    $input: group_assignment_createUpdate_mutationInput!
  ) {
    groupAssignmentCreateupdateMutation(input: $input) {
      groupAssignment {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
