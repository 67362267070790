import {Button, Offcanvas, OverlayTrigger, Popover, Spinner} from 'react-bootstrap'
import {LeadByID} from '../../leadTypes'
import {TimeLineRequestConset} from '../../../oneLead/components/TimeLineRequestConset'
import {TEMPLATES} from '../../../../helpers/consts'
import {useMutation} from '@apollo/client'
import {IResReqCons} from '../../../../types/leads'
import {REQUEST_CONSENT} from '../../../../gql/mutations/leadMutations'
import {toast} from 'react-toastify'
import {useEffect, useRef, useState} from 'react'

interface IReqConsProps {
  lead: LeadByID
  children: JSX.Element
  refetch: () => void
}

export const RequesConcentDrawer: React.FC<IReqConsProps> = ({lead, children, refetch}) => {
  const [gqlRequest, {data, loading, error}] = useMutation<IResReqCons>(REQUEST_CONSENT)

  const btnRef = useRef<HTMLButtonElement>(null)

  const [flag, setFlag] = useState(0)

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.createDocusignEnvelopeWithTemplate.success) {
      toast.success('Request processed successfully.')
      refetch()
      setFlag((p) => p + 1)
      btnRef.current?.click()
    } else {
      toast.error(`Error: request could not be processed`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <Offcanvas.Header closeButton className='bg-light'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Offcanvas.Title className='d-flex align-items-center gap-4'>
            Consent Requests
          </Offcanvas.Title>
          <div>
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              overlay={
                <Popover id='popover-basic'>
                  <Popover.Header as='h3'>Confirmation</Popover.Header>
                  <Popover.Body>
                    Are you sure you want to request consent for this lead?
                    <div className='d-flex align-items-center justify-content-end my-5'>
                      <Button
                        variant='info'
                        size='sm'
                        onClick={() => {
                          gqlRequest({
                            variables: {
                              template: TEMPLATES.AGENT,
                              args: {
                                leadId: lead.id,
                                fullName: lead.name,
                                email: lead.email,
                              },
                            },
                          })
                        }}
                        disabled={loading}
                      >
                        {loading && <Spinner animation='border' size='sm' className='me-1' />}
                        Request
                      </Button>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button size='sm' ref={btnRef} variant='info'>
                <i className='bi bi-vector-pen fs-4' />
                Request Consent
              </Button>
            </OverlayTrigger>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {children}
        <div className='mt-6'>
          {/* <pre>{JSON.stringify(data.leadById.archiveSet.edges, null, 2)}</pre> */}
          <TimeLineRequestConset leadId={lead.id} flagRefetch={flag} />
        </div>
      </Offcanvas.Body>
    </>
  )
}
