import {gql} from '@apollo/client'

export const CREATE_TRANSACTION = gql`
  mutation createTransaction($transactionData: transaction_input!) {
    createTransaction(transactionData: $transactionData) {
      transaction {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`
