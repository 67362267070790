import {Col, FloatingLabel, Form} from 'react-bootstrap'
import {PersonalLeadOneLead} from '../leadTypes'

export const FormPersonalLead = ({info}: {info?: PersonalLeadOneLead | null}) => {
  return (
    <>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='birthDay' label='Date of Birth'>
          <Form.Control
            name='birthDay'
            type='date'
            placeholder='Date of Birth'
            defaultValue={info?.birthDay || undefined}
          />
        </FloatingLabel>
      </Col>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='civilStatus' label='Civil Status'>
          <Form.Control
            name='civilStatus'
            type='text'
            placeholder='Civil Status'
            defaultValue={info?.civilStatus || undefined}
          />
        </FloatingLabel>
      </Col>

      {/* Grupo 9 */}
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='secondPhone' label='Second Phone'>
          <Form.Control
            type='text'
            name='secondPhone'
            placeholder='Second Phone'
            defaultValue={info?.secondPhone || undefined}
          />
        </FloatingLabel>
      </Col>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='streetAddress' label='Street Address'>
          <Form.Control
            name='streetAddress'
            type='text'
            placeholder='Street Address'
            defaultValue={info?.streetAddress || undefined}
          />
        </FloatingLabel>
      </Col>

      {/* Grupo 10 */}
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='city' label='City'>
          <Form.Control
            name='city'
            type='text'
            placeholder='City'
            defaultValue={info?.city || undefined}
          />
        </FloatingLabel>
      </Col>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='country' label='Country'>
          <Form.Control
            name='country'
            type='text'
            placeholder='Country'
            defaultValue={info?.country || undefined}
          />
        </FloatingLabel>
      </Col>

      {/* Grupo 11 */}
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='state' label='State'>
          <Form.Control
            type='text'
            name='state'
            placeholder='State'
            defaultValue={info?.state || undefined}
          />
        </FloatingLabel>
      </Col>
      <Col className='col-lg-6 col-md-6 col-12 mb-4'>
        <FloatingLabel controlId='postalCode' label='Postal Code'>
          <Form.Control
            name='postalCode'
            type='text'
            placeholder='Postal Code'
            defaultValue={info?.postalCode || undefined}
          />
        </FloatingLabel>
      </Col>
    </>
  )
}
